import {ClinicalCaseStep} from '../../shared/entities/ClinicalCaseStep';
import {Button} from 'infrastructure/components';
import {ClinicalCaseState} from './context/ClinicalCase.context';
import {useClinicalCase} from './context/useClinicalCase';
import {useDispatchClinicalCase} from './context/useDispatchClinicalCase';
import {useUpdateClinicalCase} from './hooks/useUpdateClinicalCase';
import {GoBackButton} from "../../../infrastructure/components/go-back-button/GoBackButton";
import {ArrowForward} from "@mui/icons-material";

export const EmergencySignDetectedPage = () => {
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const setClinicalCase = useDispatchClinicalCase();
  const {fetch} = useUpdateClinicalCase();

  const onEmergencyControlled = () => {
    clinicalCase.currentStep = ClinicalCaseStep.PRIORITY_SIGNS;
    fetch({
      clinical_case_state: clinicalCase.currentStep
    });
    setClinicalCase(clinicalCase);
  };

  const goBack = () => {
    clinicalCase.currentStep = ClinicalCaseStep.EMERGENCY_SIGNS;
    clinicalCase.emergencySign = null;

    fetch({
      clinical_case_state: clinicalCase.currentStep,
      emergency_sign: null,
      presence_tags: [],
    });
    setClinicalCase(clinicalCase);
  };

  return <div>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      Content Here
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '60px'}}>
      <GoBackButton onClick={goBack}/>
      <Button
        variant="contained"
        onClick={(_) => onEmergencyControlled()}
        endIcon={<ArrowForward/>}
        sx={{
          textTransform: 'none',
          height: '50px',
        }}>
        Patient Is Stable
      </Button>
    </div>
  </div>;
};
