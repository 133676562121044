import { PatientInterface } from '../../modules/shared/entities/PatientInterface';
import { Sex } from '../../modules/shared/entities/SexEnum';

export namespace GetPatient {
  export interface Response {
    id: string;
    created_at: string;
    updated_at: string;
    first_name: string;
    last_name: string;
    sex: string;
    date_of_birth: string;
    medbrain_id: string;
    is_simulated: boolean;
    created_by: {
      id: string;
      name: string;
    };
  }

  export const toPatient =
    (response: GetPatient.Response): PatientInterface => ({
      id: response.id,
      createdAt: new Date(response.created_at),
      updatedAt: new Date(response.updated_at),
      sex: response.sex as Sex,
      firstName: response.first_name,
      medbrainId: response.medbrain_id,
      dateOfBirth: new Date(response.date_of_birth),
      lastName: response.last_name,
      createdBy: {
        id: response.created_by.id,
        name: response.created_by.name,
      },
      isSimulated: response.is_simulated
    });
}
