import { Avatar, CircularProgress, ProfileMiniature } from "../../../infrastructure/components";
import { ClinicalCaseState } from "./context/ClinicalCase.context";
import { useClinicalCase } from "./context/useClinicalCase";
import "./DigitalHealthCard.page.scss";
import useTranslation from "../../../infrastructure/i18n/useTranslation";
import {PatientLocalRepository} from "../../../infrastructure/repositories/PatientLocalRepository";
import {useEffect, useMemo, useRef, useState} from "react";
import { PatientInterface } from "../../shared/entities/PatientInterface";
import { useWindowSize } from "infrastructure/hooks/useWindowSize";
import { DiagnosisCard } from "./components/diagnosis-card/DiagnosisCard";
import { DiseaseRank } from "./ClinicalCase";
import {ClinicalCaseLocalRepository} from "../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import {ManagementPlanCard} from "./components/management-plan/ManagementPlanCard";

export const DigitalHealthCardPage = () => {
  const { width } = useWindowSize();
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const patientRepository = new PatientLocalRepository();
  const [patient, setPatient] = useState<PatientInterface>();
  const [diseases, setDiseases] = useState<DiseaseRank[]>([]);
  const translations = useTranslation('MANAGEMENT_PLAN');
  const patientId = clinicalCase?.patient;
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      patientRepository.findById(patientId).then(p => {
        setPatient(p);
      });
    }
  }, [patientId]);

  useEffect(() => {
    const diseaseIds = clinicalCase.predictedDiseaseId
        ? [clinicalCase.predictedDiseaseId]
        : [...clinicalCase.suspectedDiseaseIds];

    let diseasesUpdated: DiseaseRank[] = clinicalCase.diseaseRankState.diseaseRank
        .sort((a, b) => b.diseaseScore - a.diseaseScore)
        .filter(disease => diseaseIds.includes(disease.diseaseId))
        .map(disease => ({
          diseaseId: disease.diseaseId,
          diseaseName: disease.diseaseName,
          diseaseScore: disease.diseaseScore,
          diseaseIcon: disease.diseaseIcon,
        }));

    diseasesUpdated = diseasesUpdated.slice(0,3) // Get top 3

    setDiseases(diseasesUpdated);
  }, [clinicalCase]);

  const diseasesRenderData = useMemo(() => {
    let diseaseIds = [...clinicalCase.suspectedDiseaseIds];
    if (clinicalCase.predictedDiseaseId) {
      diseaseIds = [clinicalCase.predictedDiseaseId];
    }
    return clinicalCase.diseaseRankState.diseaseRank
        .sort((a, b) => b.diseaseScore - a.diseaseScore)
        .filter(disease => diseaseIds.includes(disease.diseaseId))
        .map(disease => ({
          id: disease.diseaseId,
          icon: disease.diseaseIcon,
          name: disease.diseaseName,
        }));
  }, [clinicalCase]);

  const urgencyLevel = useMemo(() => {
    let ccRepo  = new ClinicalCaseLocalRepository()
    if (clinicalCase.triageState) {
      return ccRepo.getTriageStatusFromTriageState(clinicalCase.triageState)
    }

    return "non-urgent";  
  }, [clinicalCase]);

  const renderPreDiagnosisTitle = () => {
    if (clinicalCase.predictedDiseaseId) {
      return <div className="title">
        <h2>{translations.DIAGNOSIS_PREDICTED}</h2>
        <Avatar sx={{ marginLeft: '8px', width: '26px', height: '26px', marginTop: '13px' }} src={'/assets/PredictedDiagnosis.png'} />
      </div>
    }

    if (clinicalCase.suspectedDiseaseIds.length > 0) {
      return <div className="title">
        <h2>{translations.DIAGNOSTIC_IMPRESSION}</h2>
        <Avatar sx={{ marginLeft: '8px', width: '26px', height: '26px', marginTop: '13px' }} src={'/assets/DiagnosticImpression.png'} />
      </div>
    }

    return <div className="title">
      <h2>{translations.INSUFFICIENT_PATIENT_DATA}</h2>
      <Avatar  imgProps={{crossOrigin: "anonymous"}} sx={{ marginLeft: '8px', width: '26px', height: '26px', marginTop: '13px' }} src={'/assets/InsufficientPatientData.webp'} />
    </div>
  };

  const renderDiagnosisHint = () => {
    if (clinicalCase.predictedDiseaseId) {
      return <div className="health-card-hint">{translations.DIAGNOSIS_PREDICTED_HINT}</div>
    }

    if (clinicalCase.suspectedDiseaseIds.length > 0) {
      return <div className="health-card-hint">{translations.DIAGNOSTIC_IMPRESSION_HINT}</div>
    }

    return <div className="health-card-hint">{translations.INSUFFICIENT_PATIENT_DATA_HINT}</div>
  };

  if (!clinicalCase || !patient) {
    return <CircularProgress />;
  }

  const fullName = `${patient.firstName} ${patient?.lastName}`;
  const subtitle = `${patient.sex === 'M' ? 'Male' : 'Female'}, ${(new Date().getFullYear() - patient.dateOfBirth.getFullYear())} years`;
  const avatarIcon = patient.sex === 'M' ? '/assets/AvatarIconMale.png' : '/assets/AvatarIcon.svg';

  if (width <= 900) {
    return (
      <article className="digital-health-card__container">
        <DiagnosisCard diseases={diseases} type={urgencyLevel}/>
        <ManagementPlanCard disease={diseases[0].diseaseId}/>
      </article>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
        <div className={"health-card " + urgencyLevel}>
          <div className="health-card-summary">
            <div className="health-card-header">
              <div className="urgency-level" style={{textTransform: 'uppercase'}}>{urgencyLevel}</div>
            </div>
            <div className="summary">
              <div className="health-card-patient">
                <ProfileMiniature
                  title={fullName}
                  subtitle={subtitle}
                  listIcon={{ icon: avatarIcon }}
                />
              </div>
              {renderDiagnosisHint()}
            </div>
          </div>
          <div className="health-card-diagnosis">
            <div className="diagnosis-list">
              {renderPreDiagnosisTitle()}
              {diseasesRenderData.map(disease => (
                  <div key={disease.id} className="diagnosis-item">
                    <img crossOrigin="anonymous" className="diagnosis-item-image" src={disease.icon!} alt={disease.name}/>
                    <div className="diagnosis-item-title">{disease.name}</div>
                  </div>
              ))}
            </div>
            <div className="confirmation-tests">
              <div className="title"><h2>CONFIRMATORY TESTS<span style={{fontSize: '21px', marginLeft: '5px'}}>🔬</span>
              </h2></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
