import MUIModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { ReactNode } from 'react';

interface Props {
  open: boolean;
  onClose: (reason: string) => void;
  children: ReactNode;
}

export const Modal = (props: Props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    p: '1.5rem 2rem 1.5rem 1rem',
    mt: '1rem',
  };

  return (
    <MUIModal
      open={props.open}
      onClose={(_, reason) => props.onClose(reason)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box sx={style} width={'80vw'} maxWidth={'lg'}>
          {props.children}
        </Box>
      </Fade>
    </MUIModal>
  );
};