import { TextField } from '@mui/material'

interface Props {
  label?: string
  value: string;
  onChange: (value: string) => void;
  error?: string;
  variant?: 'filled' | 'outlined' | 'standard';
}

export const InputTextField = (props: Props) => {
  return <TextField
    error={!!props.error}
    helperText={props.error}
    fullWidth={true}
    label={props.label}
    variant={props.variant ?? 'outlined'}
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  />
};
