export const OptionApiResponse = {
  title: 'Option API Response',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    option: {
      type: 'string',
    },
    image: {
      type: 'object',
      properties: {
        url: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
      },
      required: ['url', 'description'],
    },
  },
  required: ['id', 'option', 'image'],
};

export const TagImageResponse = {
  title: 'Tag Image Response',
  type: 'object',
  properties: {
    url: {
      type: 'string',
    },
    description: {
      type: 'string',
      nullable: true,
    },
  },
  required: ['url'],
};

export const TagVideoResponse = {
  title: 'Tag Video Response',
  type: 'object',
  properties: {
    url: {
      type: 'string',
    },
    description: {
      type: 'string',
      nullable: true,
    },
  },
  required: ['url'],
};

export const TagApiResponse = {
  title: 'Tag Api Response',
  description: 'Describes tags added by a user',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
    is_related_factor: {
      type: 'boolean',
    },
    is_sign: {
      type: 'boolean',
    },
    is_symptom: {
      type: 'boolean',
    },
    is_test: {
      type: 'boolean',
    },
    description: {
      type: 'string',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    images: {
      type: 'array',
      items: TagImageResponse,
    },
    videos: {
      type: 'array',
      items: TagVideoResponse,
    },
    emergency_sign_category: {
      type: 'string',
    },
    priority_sign_category: {
      type: 'string',
    },
  },
};

export const CategoryApiResponse = {
  title: 'Category API Response',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
    tag_category_options: {
      type: 'array',
      items: OptionApiResponse,
    },
  },
  required: ['id', 'name'],
};

