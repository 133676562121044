import { CategoryApiResponse, TagApiResponse, TagImageResponse, TagVideoResponse } from './TagsSchemaResponse';
import { TagCategoryOptionResponse } from '../../../../../diagnosis/clinical-case/schema/ClinicalCaseSchemaResponses';
import {
  TagCategoryResponse,
} from '../../../../disease-articles/features/get-disease-article/schema/DiseaseArticleSchemaResponses';

export const tagsSchema = {
  title: 'Tag Api Response',
  description: 'Describes tags added by a user',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
    is_related_factor: {
      type: 'boolean',
    },
    is_sign: {
      type: 'boolean',
    },
    is_symptom: {
      type: 'boolean',
    },
    is_test: {
      type: 'boolean',
    },
    description: {
      type: 'string',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    images: {
      type: 'array',
      items: TagImageResponse,
    },
    videos: {
      type: 'array',
      items: TagVideoResponse,
    },
    tag_categories: {
      type: 'array',
      items: CategoryApiResponse,
    },
    emergency_sign_category: {
      type: 'string',
    },
    priority_sign_category: {
      type: 'string',
    },
  },
};

export const tagCategoriesSchema = {
  title: 'Tag Category',
  description: 'Describes a tag category',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
    image: {
      type: 'string',
    },
    tag: TagApiResponse,
    tag_category_options: TagCategoryOptionResponse,
  },
  required: ['id', 'name', 'tag'],
};

export const tagCategoryOptionsSchema = {
  title: 'Tag Category Option',
  description: 'Describes a tag category option',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    image: {
      type: 'string',
    },
    option: {
      type: 'string',
    },
    tag_category: TagCategoryResponse,
  },
  required: ['id', 'name', 'tag'],
};
