export class TagCategoryOptionDataTransformer {
    public transform(entity: any): any
    {
        entity.images = [];
        if (entity.image) {
            entity.images.push({
                name: 'image',
                url: entity.image.url
            });
        }

        return entity;
    }
}
