import { useEffect, useState } from "react";
import { useAppUIStore } from "../../../../../../../infrastructure/store/appUIStore";
import { AccordionVideoModalComponent } from "../../../../../../../infrastructure/components/accordion-template/AccordionVideoModal.component";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, CircularProgress } from '@mui/material';

export const DescriptiveVideos = ({ videos }: { videos: any }) => {
    const [loadingStates, setLoadingStates] = useState<boolean[]>(new Array(videos.length).fill(true));
    const openModal = useAppUIStore(state => state.openModal);

    useEffect(() => {
        setLoadingStates(new Array(videos.length).fill(true));
    }, [videos]);

    const handleVideoLoad = (index: number) => {
        setLoadingStates(oldStates => {
            const newStates = [...oldStates];
            newStates[index] = false;
            return newStates;
        });
    };

    const handleVideoError = (message: string, error: any, index: number) => {
        console.error(message, error);
        setLoadingStates(oldStates => {
            const newStates = [...oldStates];
            newStates[index] = false;
            return newStates;
        });
    };

    return (
        <div className='descriptive-images__container'>
            {videos.map((video: any, index: number) => (
                <div
                    style={{
                        backgroundImage: `url(${video.url})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        position: "relative",
                        width: "330px",
                        height: "220px",
                        overflow: "hidden",
                        padding: 0
                    }}
                    className="descriptive-image"
                    key={video.url}
                    onClick={() => openModal(<AccordionVideoModalComponent video={{ url: video.url, description: video.description }} />)}
                >
                    {loadingStates[index] && (
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="rgba(255, 255, 255, 0.8)"
                            zIndex={1}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    <div style={{ position: 'absolute' }}>
                        <PlayCircleIcon style={{ fontSize: '4.5rem' }} htmlColor={'rgba(255, 255, 255, 0.63)'} />
                    </div>
                    <video
                        crossOrigin="anonymous"
                        title={video.description}
                        id={`video_${index}`}
                        src={video.url}
                        style={{ height: '100%', display: loadingStates[index] ? 'none' : 'block' }}
                        onLoadedData={() => handleVideoLoad(index)}
                        onError={(e) => handleVideoError('Error loading video thumbnail ' + video.url, e, index)}
                        onLoadStart={() => setLoadingStates(oldStates => {
                            const newStates = [...oldStates];
                            newStates[index] = true;
                            return newStates;
                        })}
                    >

                    </video>
                    <p style={{
                        background: "#ffffffa1",
                        padding: "0.2em 1em",
                        borderRadius: "20px",
                        fontSize: "0.95rem",
                        position: "absolute",
                        bottom: 0,
                    }}>{video.description}</p>
                </div>
            ))}
        </div>
    );
};
