import {Box, Typography} from "../../../infrastructure/components";
import {useEffect, useState} from "react";

export const VersionPill = () => {
    const [lastSync, setLastSync] = useState<string>('--');
    const [lastPush, setLastPush] = useState<string>('--');
    const [appVersion, setAppVersion] = useState<any>({
        minorVersion: '--',
        majorVersion: '--',
    });

    useEffect(() => {
        const interval = setInterval(() => {
            let lastSyncDate = localStorage.getItem('last-sync-service-execution-date');
            setLastSync((new Date(lastSyncDate as string)).toLocaleString());
            let lastPushDate = localStorage.getItem('last-push-execution-date') || "2020-01-01T00:00:00.000000Z";
            setLastPush((new Date(lastPushDate as string)).toLocaleString());
            setAppVersion(JSON.parse(localStorage.getItem('app-version')!));
        },  5000);
        return () => {
            clearInterval(interval);
        }
    })


    return (
        <Box display={"flex"} justifyContent={"flex-end"} padding={'1em'} position={'absolute'} right={'0'}>
            <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="body2" textAlign="left" color={'#c2bebe'}>
                    push: {lastPush} sync: {lastSync} (v{appVersion.majorVersion}.{appVersion.minorVersion})
                </Typography>
            </Box>
        </Box>
    )
}