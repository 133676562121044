import React, { useCallback, useReducer } from 'react';
import {ClinicalCaseStep} from "../../../shared/entities/ClinicalCaseStep";
import {useContext} from "react";
import { createContext } from 'react';

interface StepState {
  currentStep: string;
}

export interface Context {
  step: StepState;
  setStep: (step: string) => void;
}

export const initialContext: Context = {
  step: {
    currentStep: ClinicalCaseStep.EMERGENCY_SIGNS
  },
  setStep: (_: string) => {
  },
};
export const StepContext = createContext<Context>(initialContext);

enum ActionType {
  SET_STEP = 'setStep',
}

interface Action {
  type: ActionType,
  payload: any;
}

interface Props {
  children: React.ReactNode;
}

export const StepProvider: React.FC<Props> = ({ children }: Props) => {
  const [step, dispatch] = useReducer((state: StepState, action: Action) => {
    const { type, payload } = action;
    switch (type) {
      case ActionType.SET_STEP:
        return {...state, currentStep: payload};
    }
  }, initialContext.step);

  const setStep = useCallback((step: string) => {
    dispatch({
      type: ActionType.SET_STEP,
      payload: step,
    });
  }, []);

  const value = {
    step,
    setStep,
  };

  return (
    <StepContext.Provider value={value}>
      {children}
    </StepContext.Provider>
  );
};

export const useDispatchStep = () => {
  const { setStep } = useContext(StepContext);
  return setStep;
};

