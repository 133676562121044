export const DiseaseTagResponse = {
  title: 'Disease Tag Response',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    complexity: {
      type: 'string',
    },
    format: {
      type: 'string',
    },
    is_related_factor: {
      type: 'boolean',
    },
    is_sign: {
      type: 'boolean',
    },
    is_symptom: {
      type: 'boolean',
    },
    is_test: {
      type: 'boolean',
    }
  },
  required: ['id'],
};
export const DiseaseTagLikelihoodResponse = {
  title: 'Disease Tag Likelihood Response',
  version: 0,
  primaryKey: 'tag',
  type: 'object',
  properties: {
    absence_likelihood_ratio: {
      type: 'number',
    },
    presence_likelihood_ratio: {
      type: 'number',
    },
    tag: DiseaseTagResponse,
  },
  required: ['absence_likelihood_ratio', 'presence_likelihood_ratio', 'tag'],
};
export const TagCategoryResponse = {
  title: 'Tag Category Response',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    tag: DiseaseTagResponse, // need to define DiseaseTagResponse before
  },
  required: ['id', 'created_at', 'updated_at', 'name', 'tag'],
};
export const DiseaseTagCategoryOptionResponse = {
  title: 'Disease Tag Category Option Response',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    option: {
      type: 'string',
    },
    tag_category: TagCategoryResponse,
  },
  required: ['id', 'created_at', 'updated_at', 'option', 'tag_category'],
};
export const DiseaseTagCategoryOptionLikelihoodResponse = {
  title: 'Disease Tag Category Option Likelihood Response',
  version: 0,
  primaryKey: 'tag_category_option',
  type: 'object',
  properties: {
    likelihood_ratio: {
      type: 'number',
    },
    tag_category_option: DiseaseTagCategoryOptionResponse, // This references the previously defined DiseaseTagCategoryOptionResponse schema
  },
  required: ['likelihood_ratio', 'tag_category_option'],
};
