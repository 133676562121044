
const OwnerSchema = {
  title: 'Owner Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    "id": {
      type: 'string',

    },
    "username": {
      type: 'string',
    },
    "email": {
      type: 'string',
    },
    "first_name": {
      type: 'string',
    },
    "last_name": {
      type: 'string',
    },
    "name": {
      type: 'string',
    },
  }
};
export const patientSchema = {
  title: 'Patient Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    first_name: {
      type: 'string',
    },
    last_name: {
      type: 'string',
    },
    sex: {
      type: 'string',
      enum: ['M', 'F'],
    },
    date_of_birth: {
      type: 'string',
    },
    medbrain_id: {
      type: 'string',
    },
    created_by: {
      type: OwnerSchema,
    },
    is_simulated: {
      type: 'boolean',
    },
  },
  required: [
    'id',
  ],
};

