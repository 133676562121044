import {CACHE_NAME} from "../../config/const";

export class S3Repository {
    public async findOrInsertMedia(url: string) {
        try {
            // Open a cache named 'images-cache'
            const cacheResponse = await this.find(url);
            if (!!cacheResponse) {
                return;
            }
            await this.insert(url);
        } catch (error) {
            console.error("Error fetching media: ", url);
        }
    }

    public async findAndCheckOrInsertMedia(url: string): Promise<void> {
        try {
            // Open a cache named 'images-cache'
            const cacheResponse: Response | undefined = await this.find(url);

            if (!!cacheResponse  && cacheResponse.headers.get('content-type') != 'text/plain;charset=UTF-8') {
                return;
            }


            await this.insert(url);
            console.warn("missing or corrupt cache file restored :" + url);
        } catch (error) {
            console.error("Error fetching media: ", url);
        }
    }

    private async find(url: string): Promise<Response | undefined> {
        const cache = await caches.open(CACHE_NAME);
        return await cache.match(url);
    }

    private async insert(url: string): Promise<void> {
        const cache = await caches.open(CACHE_NAME);

        // Fetch the image from the network
        const response = await fetch(url);
        // Add the response to the cache
        if (response && response.status === 200 && response.type !== 'opaque') {
            await cache.put(url,  response.clone()); // Clone the response since it can only be consumed once
        }
    }
}