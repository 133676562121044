import { SelectableFlipCard } from 'infrastructure/components/selectable-card/SelectableFlipCard';
import { TagViewModel } from '../../medical-library/tags/features/list-tags/requests/Tag.viewModel';
import { ClinicalCaseState } from './context/ClinicalCase.context';
import { useClinicalCase } from './context/useClinicalCase';
import { useDispatchClinicalCase } from './context/useDispatchClinicalCase';
import { useUpdateClinicalCase } from './hooks/useUpdateClinicalCase';
import { ClinicalCaseStep } from 'modules/shared/entities/ClinicalCaseStep';
import './ChiefComplaints.page.scss';
import { GoBackButton } from '../../../infrastructure/components/go-back-button/GoBackButton';
import { CircularProgress } from '../../../infrastructure/components';
import { useEffect, useState } from 'react';
import { ProgressBar } from './components/progress-bar/ProgressBar';
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import { useProgressBarContext } from './context/ProgressBar/useProgressBarContext';
import { useApiService } from 'infrastructure/hooks/useApiService';

export const ChiefComplaintsPage = ({ subtitle }: { subtitle: string }) => {
  const { width } = useWindowSize();
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const setClinicalCase = useDispatchClinicalCase();
  const { fetch } = useUpdateClinicalCase();
  const [isLoading, setIsLoading] = useState(false);
  const {getLocalChiefComplaintOptions} = useApiService();
  const { goToNextStep, goToPrevStep, progressWidth, previousWidth } = useProgressBarContext();

  const [chiefComplaints, setChiefComplaints] = useState<TagViewModel[]>([]);
  const [chiefComplaintsStage0, setChiefComplaintsStage0] = useState<TagViewModel[]>([]);
  const [chiefComplaintsStage1, setChiefComplaintsStage1] = useState<TagViewModel[]>([]);
  const [chiefComplaintsStage2, setChiefComplaintsStage2] = useState<TagViewModel[]>([]);
  const [chiefComplaintPage, setChiefComplaintPage] = useState<Number>(0);

  useEffect(() => {
      const getChiefComplaints = async () => {
        const chiefComplaints = await getLocalChiefComplaintOptions();
        setChiefComplaints(chiefComplaints);

        let first: TagViewModel[] = [];
        let second: TagViewModel[] = [];
        let third: TagViewModel[] = [];
        chiefComplaints.forEach((chiefComplaint: TagViewModel) => {
            if (
                chiefComplaint.name.toLowerCase().includes('respiratory') ||
                chiefComplaint.name.toLowerCase().includes('gastrointestinal') ||
                chiefComplaint.name.toLowerCase().includes('skin')
            ) {
                first.push(chiefComplaint)
            } else if(!chiefComplaint.name.toLowerCase().includes('fever')) {
                second.push(chiefComplaint)
            } else {
                third.push(chiefComplaint)
            }
        });
        setChiefComplaintsStage0(first);
        setChiefComplaintsStage1(second);
        setChiefComplaintsStage2(third)
      };

      getChiefComplaints();
  }, []);

  const onClick = (tag: TagViewModel) => {
    setIsLoading(true);
    // TODO: Move this logic to clinical case local repository
    clinicalCase.chiefComplaints.push(tag);
    clinicalCase.prioritySigns = [tag];
    setClinicalCase(clinicalCase);

    const chiefComplaints = clinicalCase.chiefComplaints.map((tag) => tag.id);
    const prioritySigns = clinicalCase.prioritySigns.map((tag) => tag.id);
    let presenceTags = [...chiefComplaints, ...prioritySigns];

    if (clinicalCase.emergencySign) {
      presenceTags = [...presenceTags, clinicalCase.emergencySign.id];
    }

    if (tag.name.toLowerCase().includes('fever')) {
        fetch({
            presence_tags: presenceTags,
            chief_complaints: clinicalCase.chiefComplaints.map((tag) => tag.id),
        }).then(() => {
            setClinicalCase(clinicalCase);
        });
        setChiefComplaintPage(3)
        setIsLoading(false);
        return
    } else {
        fetch({
            presence_tags: presenceTags,
            chief_complaints: clinicalCase.chiefComplaints.map((tag) => tag.id),
            clinical_case_state: ClinicalCaseStep.ANSWERING_STATIC_QUESTIONS,
        }).then(() => {
            clinicalCase.currentStep = ClinicalCaseStep.ANSWERING_STATIC_QUESTIONS;
            setClinicalCase(clinicalCase);
            goToNextStep();
        });
    }
  };

  const goBack = () => {
    clinicalCase.currentStep = ClinicalCaseStep.SELECTING_CASE_DURATION;

    setClinicalCase(clinicalCase);
    goToPrevStep();

    fetch({
      clinical_case_state: clinicalCase.currentStep,
    });
  };

  if (isLoading || !chiefComplaints) {
    return <CircularProgress />;
  }

  if (width <= 900) {
      switch (chiefComplaintPage) {
          case 0:
              return (
                  <section className="chief-complaints__container">
                      <ProgressBar startWidth={previousWidth} endWidth={progressWidth}/>
                      <div className="title__container">
                          <h1 className="title">
                              {subtitle}
                          </h1>
                      </div>
                      <div className="cards__container">
                          {chiefComplaintsStage0.filter(tag => tag.id != "5c27ba3a-74d6-4e0f-be95-33f62d47deac" && tag.tagImages.length > 0).map((tag: TagViewModel) => (
                              <SelectableFlipCard
                                  key={tag.id}
                                  title={tag.name}
                                  image={tag.tagImages![0].url}
                                  onClick={(_) => onClick(tag)}
                                  description={tag.description}
                                  flipable={true} />
                          ))}
                          <SelectableFlipCard
                              key="0"
                              title="Other complaints +"
                              image="/assets/BlackArrow.svg"
                              description="See more complaints"
                              onClick={() => setChiefComplaintPage(1)}
                              flipable={true} />
                      </div>
                      <div className="back-button__container">
                          <GoBackButton onClick={goBack} />
                      </div>
                  </section>
              );
          case 1:
              return (
                  <section className="chief-complaints__container">
                      <ProgressBar startWidth={previousWidth} endWidth={progressWidth}/>
                      <div className="title__container">
                          <h1 className="title">
                              {subtitle}
                          </h1>
                      </div>
                      <div className="cards__container">
                          {chiefComplaintsStage1.filter(tag => tag.id != "5c27ba3a-74d6-4e0f-be95-33f62d47deac" && tag.tagImages.length > 0).map((tag: TagViewModel) => (
                              <SelectableFlipCard
                                  key={tag.id}
                                  title={tag.name}
                                  image={tag.tagImages![0].url}
                                  onClick={(_) => onClick(tag)}
                                  description={tag.description}
                                  flipable={true} />
                          ))}
                          <SelectableFlipCard
                              key="1"
                              title="Other complaints +"
                              image="/assets/BlackArrow.svg"
                              description="See more complaints"
                              onClick={() => setChiefComplaintPage(2)}
                              flipable={false} />
                      </div>
                      <div className="back-button__container">
                          <GoBackButton onClick={() => setChiefComplaintPage(0)} />
                      </div>
                  </section>
              );
          case 2:
              return (
                  <section className="chief-complaints__container">
                      <ProgressBar startWidth={previousWidth} endWidth={progressWidth}/>
                      <div className="title__container">
                          <h1 className="title">
                              {subtitle}
                          </h1>
                      </div>
                      <div className="cards__container">
                          {chiefComplaintsStage2.filter(tag => tag.id != "5c27ba3a-74d6-4e0f-be95-33f62d47deac" && tag.tagImages.length > 0).map((tag: TagViewModel) => (
                              <SelectableFlipCard
                                  key={tag.id}
                                  title={tag.name}
                                  image={tag.tagImages![0].url}
                                  onClick={(_) => onClick(tag)}
                                  description={tag.description}
                                  flipable={true} />
                          ))}
                      </div>
                      <div className="back-button__container">
                          <GoBackButton onClick={() => setChiefComplaintPage(1)} />
                      </div>
                  </section>
              );
      }

  }

  switch (chiefComplaintPage) {
      case 0:
          return (
              <>
                  <div className="chief-complaints-container">
                      {chiefComplaintsStage0.filter(tag => tag.id != "5c27ba3a-74d6-4e0f-be95-33f62d47deac" && tag.tagImages.length > 0).map((tag: TagViewModel) => (
                          <SelectableFlipCard
                              key={tag.id}
                              title={tag.name}
                              image={tag.tagImages![0].url}
                              onClick={(_) => onClick(tag)}
                              description={tag.description}
                              flipable={true}/>
                      ))}
                      <SelectableFlipCard
                          key="0"
                          title="Other complaints +"
                          image="/assets/BlackArrow.svg"
                          description="See more complaints"
                          onClick={() => setChiefComplaintPage(1)}
                          flipable={false} />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '60px'}}>
                      <GoBackButton onClick={goBack}/>
                  </div>
              </>
          );
      case 1:
          return (
              <>
                  <div className="chief-complaints-container">
                      {chiefComplaintsStage1.filter(tag => tag.id != "5c27ba3a-74d6-4e0f-be95-33f62d47deac" && tag.tagImages.length > 0).map((tag: TagViewModel) => (
                          <SelectableFlipCard
                              key={tag.id}
                              title={tag.name}
                              image={tag.tagImages![0].url}
                              onClick={(_) => onClick(tag)}
                              description={tag.description}
                              flipable={true}/>
                      ))}
                      <SelectableFlipCard
                          key="1"
                          title="Other complaints +"
                          image="/assets/BlackArrow.svg"
                          description="See more complaints"
                          onClick={() => setChiefComplaintPage(2)}
                          flipable={false} />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '60px'}}>
                      <GoBackButton onClick={() => setChiefComplaintPage(0)}/>
                  </div>
              </>
          );
      case 2:
          return (
              <>
                  <div className="chief-complaints-container">
                      {chiefComplaintsStage2.filter(tag => tag.id != "5c27ba3a-74d6-4e0f-be95-33f62d47deac" && tag.tagImages.length > 0).map((tag: TagViewModel) => (
                          <SelectableFlipCard
                              key={tag.id}
                              title={tag.name}
                              image={tag.tagImages![0].url}
                              onClick={(_) => onClick(tag)}
                              description={tag.description}
                              flipable={true}/>
                      ))}
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '60px'}}>
                      <GoBackButton onClick={() => setChiefComplaintPage(1)}/>
                  </div>
              </>
          );
      case 3:
          return (
              <section className="chief-complaints__container">
                  <ProgressBar startWidth={previousWidth} endWidth={progressWidth}/>
                  <div className="title__container">
                      <h1 className="title">
                          Please confirm if you patient has any of the following complaints:
                      </h1>
                  </div>
                  <div className="cards__container cards__small">
                      {chiefComplaints.filter(tag => tag.id != "5c27ba3a-74d6-4e0f-be95-33f62d47deac" && tag.tagImages.length > 0 && !tag.name.toLowerCase().includes('fever')).map((tag: TagViewModel) => (
                          <SelectableFlipCard
                              key={tag.id}
                              title={tag.name}
                              image={tag.tagImages![0].url}
                              onClick={(_) => onClick(tag)}
                              description={tag.description}
                              flipable={true}/>
                      ))}
                  </div>
                  <div className="back-button__container">
                      <GoBackButton onClick={() => setChiefComplaintPage(0)}/>
                  </div>
              </section>
          )
  }
};

