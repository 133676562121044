import { SelectableFlipCard } from 'infrastructure/components/selectable-card/SelectableFlipCard';
import { ClinicalCaseStep } from '../../shared/entities/ClinicalCaseStep';
import {AgeGroup, ClinicalCaseState} from './context/ClinicalCase.context';
import { useClinicalCase } from './context/useClinicalCase';
import { useDispatchClinicalCase } from './context/useDispatchClinicalCase';
import { useUpdateClinicalCase } from './hooks/useUpdateClinicalCase';
import './CaseDuration.page.scss';
import { GoBackButton } from "../../../infrastructure/components/go-back-button/GoBackButton";
import { ProgressBar } from './components/progress-bar/ProgressBar';
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import { useProgressBarContext } from './context/ProgressBar/useProgressBarContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface CaseDurationOption {
  title: string;
  key: string;
  description: string;
  icon: string;
}

const caseDurationOptions: Array<CaseDurationOption> = [
  {
    title: 'Hyperacute',
    key: 'hyperacute',
    description: 'Minutes - Hours',
    icon: '/assets/CaseDurationHyperacuteIcon.svg',
  },
  {
    title: 'Acute',
    key: 'acute',
    description: '1 - 7 Days',
    icon: '/assets/CaseDurationAcuteIcon.svg',
  },
  {
    title: 'Subacute',
    key: 'subacute',
    description: '1 Week - 3 Months',
    icon: '/assets/CaseDurationSubacuteIcon.svg',
  },
  {
    title: 'Chronic',
    key: 'chronic',
    description: '3+ Months',
    icon: '/assets/CaseDurationChronicIcon.svg',
  },
];

export const CaseDurationPage = () => {
  const { width } = useWindowSize();
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const setClinicalCase = useDispatchClinicalCase();
  const navigate = useNavigate()
  const { fetch } = useUpdateClinicalCase();
  const { progressWidth, goToNextStep, previousWidth } = useProgressBarContext();

  const onClick = (option: CaseDurationOption) => {
    clinicalCase.currentStep = ClinicalCaseStep.SELECTING_CHIEF_COMPLAINTS;
    fetch({
      case_duration: option.key,
      clinical_case_state: clinicalCase.currentStep
    });
    setClinicalCase(clinicalCase);
  };

  const goBack = () => {
    navigate(`/patient/${clinicalCase.patient}`);
  };


  useEffect(() => {
    if(!clinicalCase || clinicalCase.ageGroup !== AgeGroup.NEONATE) return;

    clinicalCase.currentStep = ClinicalCaseStep.ANSWERING_STATIC_QUESTIONS;
    setClinicalCase(clinicalCase);
  }, [clinicalCase])

  if (width <= 900) {
    return (
      <section className='case-duration__container'>
        <ProgressBar startWidth={previousWidth} endWidth={progressWidth}/>

        <div className='title__container'>
          <h1 className='title'>
            How long has your patient’s complaint been present for?
          </h1>
        </div>

        <div className="cards__container">
          {caseDurationOptions.map((option) => (
            <SelectableFlipCard
              key={option.title}
              title={option.title}
              image={option.icon}
              onClick={(_) => {
                onClick(option);
                goToNextStep();
              }}
              description={option.description}
              flipable={false} />
          ))}
        </div>

        <div className='back-button__container'>
          <GoBackButton onClick={goBack} />
        </div>
      </section>
    );
  }

  return (
    <>
      <div className="case-duration-container">
        {caseDurationOptions.map((option) => (
          <SelectableFlipCard
            key={option.title}
            title={option.title}
            image={option.icon}
            onClick={(_) => onClick(option)}
            description={option.description}
            flipable={false} />
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '60px' }}>
        <GoBackButton onClick={goBack} />
      </div>
    </>
  );
};

