import {ApiRepositoryBase} from "./ApiRepositoryBase";
import {ApiRepositoryInterface} from "../../modules/shared/interfaces/ApiRepositoryInterface";

export class DiagnosisOutcomeConfigApiRepository extends ApiRepositoryBase implements ApiRepositoryInterface {
    public async getAllFromDatePaginated(...queryStringParams: Array<{name: string, value: any}>): Promise<{ data: Array<any>, next?: string }> {
        const response = await this.handleRequest("/diagnosis/diagnosis_outcome_config", "GET", {}, ...queryStringParams);
        return {
            data: [ response ], // we need to do this because this endpoint is not paginates, it returns just one object
        };
    }
}