export interface ChangelogRecordInterface {
    id: string;
    payload: object;
    event_type: EventType;
    version: number;
    created_at: string;
}

export enum EventType {
    PatientCreated = "patient_created",
    ClinicalCaseCreated = "clinical_case_created",
    ClinicalCaseUpdated = "clinical_case_updated",
}