import { ButtonProps, styled } from '@mui/material';
import MUIButton from '@mui/material/Button';

export const Button = (props: ButtonProps) => {

  const CustomButton = styled(MUIButton)(({ theme }) => ({
    '&.MuiButton-root' : {
      borderRadius: '6px',
      fontSize: '18px',
      height: '48px',
    },

    '&.MuiButton-contained': {
      boxShadow: theme.shadows[0],
    },
    '&.MuiButton-contained:hover': {
      boxShadow: theme.shadows[0],
    },

    '&.MuiButton-outlined': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
      fontWeight: 'bold',
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }));

  return (
      <CustomButton {...props} />
  );
};
