import { ClinicalCaseState } from "./context/ClinicalCase.context";
import { useClinicalCase } from "./context/useClinicalCase";
import { useEffect, useState } from "react";
import { useDispatchClinicalCase } from "./context/useDispatchClinicalCase";
import { TagRankScoreViewModel } from "./TagRankViewModel";
import { AbsenceTag, PresenceTag } from "../../shared/entities/Tag";
import { Grid } from "@mui/material";
import {
  GetTagTemplateFeature
} from "../../medical-library/tag-templates/features/get-tag-template/GetTagTemplateFeature";
import { useUpdateClinicalCase } from "./hooks/useUpdateClinicalCase";
import { ClinicalCaseStep } from "../../shared/entities/ClinicalCaseStep";
import { ContinueButton } from 'infrastructure/components/continue-button/continue-button';
import { TagRankService } from "../tag-rank/services/TagRankService";
import {ClinicalCaseLocalRepository} from "../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import { useWindowSize } from "infrastructure/hooks/useWindowSize";
import { AnsweringYesNoQuestionsStepper } from "./components/answering-yes-no-questions-handler/AnsweringYesNoQuestionsStepper";
import { useYesNoQuestionsStepperContext } from "./context/YesNoQuestionsStepper/useYesNoQuestionsStepperContext";
import './AnsweringStaticQuestions.page.scss';
import { noAnswerString } from "config/const";

export interface TagRankAnswers {
  tagRank: Array<TagRankScoreViewModel>;
  presenceTags: Array<PresenceTag>;
  absenceTags: Array<AbsenceTag>;
  uncertainTags: Array<AbsenceTag>;
}

interface AnswersValid {
  [key: string]: boolean;
}

interface AnsweringDynamicQuestionsPageProps {
  title: string;
  subtitle: string;
}

export const AnsweringDynamicQuestionsPage = ({ title, subtitle }: AnsweringDynamicQuestionsPageProps) => {
  const { width } = useWindowSize();
  const updateClinicalCase = useUpdateClinicalCase().fetch;
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const clinicalCaseRepository = new ClinicalCaseLocalRepository()
  const [tagRank, setTagRank] = useState<Array<TagRankScoreViewModel>>([]);
  const dispatchClinicalCase = useDispatchClinicalCase();
  const { updateClinicalCase: updateClinicalCaseCtx, buildAnswer, isAnswerReady, createDynamicAnswer } = useYesNoQuestionsStepperContext();
  const [tagRankAnswers, setTagRankAnswers] = useState<TagRankAnswers>({
    tagRank: [],
    presenceTags: [],
    absenceTags: [],
    uncertainTags: [],
  });
  const tagRankService = new TagRankService();

  const [answersValid, setAnswersValid] = useState<AnswersValid>({});

  useEffect(() => {
    const newAnswersValid: AnswersValid = {};
    tagRank.forEach(tag => {
      newAnswersValid[tag.id] = false;
    });
    setAnswersValid(newAnswersValid);
  }, [tagRank]);

  const allTagsAreAnswered = (): boolean => {
    return Object.keys(answersValid).length === (tagRankAnswers.presenceTags.length + tagRankAnswers.absenceTags.length + tagRankAnswers.uncertainTags.length);
  };

  const validate = () => {
    return allTagsAreAnswered() && Object.values(answersValid).every(value => value);
  };

  const fetchYesNoQuestions = async () => {
    if (!clinicalCase.clinicalCaseId) {
      return;
    }

    const tagRank = await tagRankService.calculateTagQuestions(clinicalCase.clinicalCaseId);

    if (!tagRank || tagRank.length == 0) {
      updateClinicalCase({
        clinical_case_state: ClinicalCaseStep.DIGITAL_HEALTH_CARD_CREATED,
      }).then(() => {
        clinicalCase.currentStep = ClinicalCaseStep.DIGITAL_HEALTH_CARD_CREATED;
        dispatchClinicalCase(clinicalCase);
      });
    }

    const newAnswersValid: AnswersValid = {};

    const tagRangViewModel: TagRankScoreViewModel[] = tagRank.map(t => ({
      id: t.tag.id,
      name: t.tag.name,
      score: t.tagScore
    }));

    setTagRank(tagRangViewModel);

    tagRank.forEach((tag) => {
      newAnswersValid[tag.tag.id] = false;
    });


    setAnswersValid(newAnswersValid);
    setTagRankAnswers({
      tagRank: [],
      presenceTags: [],
      absenceTags: [],
      uncertainTags: [],
    })
  };

  const handleOnChangeYesNo = (tag: PresenceTag | AbsenceTag, answer?: 'yes' | 'no' | 'unknown') => {
    setTagRankAnswers(prevState => {
      const presenceTags = prevState.presenceTags.filter(presenceTag => presenceTag.id !== tag.id);
      const absenceTags = prevState.absenceTags.filter(absenceTag => absenceTag.id !== tag.id);
      const uncertainTags = prevState.uncertainTags.filter(absenceTag => absenceTag.id !== tag.id);
      if (answer === 'yes') {
        presenceTags.push(tag as PresenceTag)
      } else if (answer === 'no') {
        absenceTags.push(tag as AbsenceTag)
      } else if (answer === 'unknown') {
        uncertainTags.push(tag as AbsenceTag)
      } else {
        Object.keys(tag).includes('tagCategoryOptions') ? presenceTags.push(tag as PresenceTag) : absenceTags.push(tag as AbsenceTag);
      }

      return {
        tagRank: tagRank,
        presenceTags,
        absenceTags,
        uncertainTags,
      };
    });
  };

  const handleOnChangeIsValid = (tagId: string) => (isValid: boolean) => {
    setAnswersValid(prev => ({ ...prev, [tagId]: isValid }));
  };

  const handleOnClickNextQuestions = () => {
    const clinicalPlanSet = {
      question_round: clinicalCase.currentQuestionRound,
      static_subround: clinicalCase.currentStaticQuestionRound,
      presence_tags: tagRankAnswers.presenceTags.map(tag => tag.id),
      absence_tags: tagRankAnswers.absenceTags.map(tag => tag.id),
      uncertain_tags: tagRankAnswers.uncertainTags.map(tag => tag.id),
      tag_category_options: tagRankAnswers.presenceTags
        .map((tag) => tag.tagCategoryOptions)
        .flat()
        .filter(tagCategoryOption => tagCategoryOption !== noAnswerString)
    };

    clinicalCaseRepository.addQuestionRoundAnswer(clinicalCase.clinicalCaseId!, clinicalPlanSet).then(() => {
      clinicalCase.currentQuestionRound += 1;
      clinicalCaseRepository.update(clinicalCase.clinicalCaseId!, { current_question_round: clinicalCase.currentQuestionRound, }).then(() => {
        fetchYesNoQuestions();
      })
    });
  }

  useEffect(() => {
    createDynamicAnswer(fetchYesNoQuestions);
  }, [isAnswerReady]);

  useEffect(() => {
    fetchYesNoQuestions();
  }, []);

  useEffect(() => {
    updateClinicalCaseCtx(clinicalCase);
  }, [clinicalCase]);

  if (width <= 900) {
    return (
      <AnsweringYesNoQuestionsStepper
        title={title}
        subtitle={subtitle}
        tagRank={tagRank}
        createAnswer={buildAnswer}
      />
    )
  }

  return (
    <Grid style={{ marginRight: '87px', borderRadius: '10px', background: '#F8F8F9', padding: '25px 50px 60px 50px' }}>
      <Grid item xs={12}>
        <p style={{ fontSize: '21px', lineHeight: 'normal', color: 'rgba(0, 0, 0, 0.5)', paddingBottom: '10px' }}>Does your patient have:</p>
        <>
          {tagRank.map((tag: TagRankScoreViewModel) => (
            <GetTagTemplateFeature
              id={tag.id}
              key={tag.id}
              onChangeYesNo={handleOnChangeYesNo}
              onChangeIsValid={handleOnChangeIsValid(tag.id)}
              variant={'yesno'}
            />))
          }
        </>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'end', marginRight: '60px', marginTop: '2rem' }}>
        <ContinueButton
          disabled={!validate()}
          onClick={handleOnClickNextQuestions}
        />
      </div>
    </Grid>
  );
};
