import TextField from '@mui/material/TextField';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MUIDatePicker from '@mui/lab/DatePicker';
import { DateTime } from 'luxon';
import {DatePickerView} from "@mui/lab/DatePicker/shared";

interface Props {
  label: string;
  value: DateTime | null;
  onChange: (value: DateTime | null) => void;
  error?: string;
  minDate?: DateTime | null;
  maxDate?: DateTime | null;
  openTo?: DatePickerView;
}

export const DatePicker = (props: Props) => {
  return <>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MUIDatePicker
        inputFormat='dd/MM/yyyy'
        label={props.label}
        value={props.value}
        onChange={(value) => props.onChange(value)}
        minDate={props.minDate}
        maxDate={props.maxDate}
        openTo={props.openTo}
        renderInput={(params: any) =>
          <TextField
            {...params}
            fullWidth={true}
            error={!!props.error}
            helperText={props.error}
          />}
      />
    </LocalizationProvider>
  </>;
};
