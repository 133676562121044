import React, { ReactNode } from 'react';
import { Grid, Header, Modal } from '../../components';
import { useAppUIStore } from '../../store/appUIStore';
import './BasePage.scss';

interface Props {
  children: ReactNode;
  header?: ReactNode;
}

export const BasePage: React.FC<Props> = (props: Props) => {
    const {
    modalContent,
    closeModal,
  } = useAppUIStore((state) => ({
    modalContent: state.modalContent,
    closeModal: state.closeModal,
  }));

  return (
    <Grid container direction={'column'} className="base-page">
      <Modal open={!!modalContent} onClose={() => closeModal()}>
        {modalContent}
      </Modal>
      <Header>
        {props.header}
      </Header>
      <Grid item xs>
        {props.children}
      </Grid>
    </Grid>
  );
};
