export class TagDataTransformer {
    public transform(entity: any): any
    {
        entity.media = [];
        if (entity.videos.length > 0) {
            entity.videos.forEach((video: any) => {
                entity.media.push({
                    name: video.description,
                    url: video.url,
                })
            })
        }

        return entity;
    }
}