import MUIDivider from '@mui/material/Divider';

export const Divider = (props: any) => {
  const { marginTop, ...restOfProps } = props;

  return <MUIDivider
    sx={{
      my: props.marginTop ?? 3,
      mx: props.marginTop ?? 'auto',
    }} light {...restOfProps} />;
};
