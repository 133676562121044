import {TagLocalRepository} from "../../infrastructure/repositories/TagLocalRepository";
import {TagApiRepository} from "../../infrastructure/repositories/TagApiRepository";
import {TagCategoryLocalRepository} from "../../infrastructure/repositories/TagCategoryLocalRepository";
import {TagCategoryApiRepository} from "../../infrastructure/repositories/TagCategoryApiRepository";
import {TagCategoryOptionLocalRepository} from "../../infrastructure/repositories/TagCategoryOptionLocalRepository";
import {TagCategoryOptionApiRepository} from "../../infrastructure/repositories/TagCategoryOptionApiRepository";
import {DiagnosisOutcomeConfigLocalRepository} from "../../infrastructure/repositories/DiagnosisOutcomeConfigLocalRepository";
import {DiagnosisOutcomeConfigApiRepository} from "../../infrastructure/repositories/DiagnosisOutcomeConfigApiRepository";
import {TriageLevelApiRepository} from "../../infrastructure/repositories/TriageLevelApiRepository";
import {TriageLevelLocalRepository} from "../../infrastructure/repositories/TriageLevelLocalRepository";
import {QuestionRoundLocalRepository} from "../../infrastructure/repositories/QuestionRoundLocalRepository";
import {QuestionRoundApiRepository} from "../../infrastructure/repositories/QuestionRoundApiRepository";
import {HealthcareProfessionalTypeLocalRepository} from "../../infrastructure/repositories/HealthcareProfessionalTypeLocalRepository";
import {HealthcareProfessionalTypeApiRepository} from "../../infrastructure/repositories/HealthcareProfessionalTypeApiRepository";
import {ClinicalOutcomeLocalRepository} from "../../infrastructure/repositories/ClinicalOutcomeLocalRepository";
import {ClinicalOutcomeApiRepository} from "../../infrastructure/repositories/ClinicalOutcomeApiRepository";
import {ClinicalInterventionLocalRepository} from "../../infrastructure/repositories/ClinicalInterventionLocalRepository";
import {ClinicalInterventionApiRepository} from "../../infrastructure/repositories/ClinicalInterventionApiRepository";
import {MedbrainUserLocalRepository} from "../../infrastructure/repositories/MedbrainUserLocalRepository";
import {MedbrainUserApiRepository} from "../../infrastructure/repositories/MedbrainUserApiRepository";
import {DiseaseApiRepository} from "../../infrastructure/repositories/DiseaseApiRepository";
import {DiseaseLocalRepository} from "../../infrastructure/repositories/DiseaseLocalRepository";
import {DiseaseArticleLocalRepository} from "../../infrastructure/repositories/DiseaseArticleLocalRepository";
import {DiseaseArticleApiRepository} from "../../infrastructure/repositories/DiseaseArticleApiRepository";
import {PatientApiRepository} from "../../infrastructure/repositories/PatientApiRepository";
import {ClinicalCaseApiRepository} from "../../infrastructure/repositories/ClinicalCaseApiRepository";
import {S3Repository} from "../../infrastructure/repositories/S3Repository";
import {PatientLocalRepository} from "../../infrastructure/repositories/PatientLocalRepository";
import {ClinicalCaseLocalRepository} from "../../infrastructure/repositories/ClinicalCaseLocalRepository";
import {DiagnosticTestLocalRepository} from "../../infrastructure/repositories/DiagnosticTestLocalRepository";
import {DiagnosticTestApiRepository} from "../../infrastructure/repositories/DiagnosticTestApiRepository";

export class ServerPullService {

    private firstTimeSync = "2020-01-01T00:00:00.000000Z";
    private s3Repository: S3Repository = new S3Repository();
    private entityUpdateLog: Array<{name: string, date: string}> = [];

    public async pull(...repos: { entityName: string; hasMedia: boolean }[]): Promise<void> {
        for (const repoInfo of repos) {
            const entities = await this.syncEntityData(repoInfo);
            if (entities.length === 0) {
                continue;
            }
            if (repoInfo.hasMedia) {
                await this.syncEntityMedia(entities);
            }

            this.setEntityUpdateDate(repoInfo.entityName);
            const updateEvent = new CustomEvent('entitySyncFinished', {
                detail: {
                    entityName: repoInfo.entityName,
                    date: this.getEntityUpdateDate(repoInfo.entityName),
                }
            });
            window.dispatchEvent(updateEvent);
        }
        localStorage.setItem('last-sync-service-execution-date', new Date().toISOString())
    }

    private async syncEntityData(repoInfo: { entityName: string; hasMedia: boolean }): Promise<any> {
        let entityName = repoInfo.entityName;
        let lastPullDateByEntity = this.getEntityUpdateDate(entityName);
        let localRepo = this.getLocalRepository(entityName);
        let apiRepo = this.getApiRepository(entityName);

        let response = await apiRepo.getAllFromDatePaginated({name: 'from_date', value: lastPullDateByEntity});
        let entities: any[] = [];
        entities = entities.concat(await this.processEntities(response, localRepo))
        if (entities.length === 0) {
            return entities;
        }
        let lastRecordDate = localRepo.getLastRecordUpsertDate().toISOString();
        this.logRecord(entityName, lastRecordDate);

        while (response.next) {
            const nextPageParams = new URL(response.next).searchParams;
            const page = nextPageParams.get('page')!;
            const pageSize = nextPageParams.get('page_size')!;
            response = await apiRepo.getAllFromDatePaginated(
                {name: 'from_date', value: lastPullDateByEntity},
                {name: 'page', value: page},
                {name: 'page_size', value: pageSize}
            );
            entities = entities.concat(await this.processEntities(response, localRepo));

            lastRecordDate = localRepo.getLastRecordUpsertDate().toISOString();
            this.logRecord(entityName, lastRecordDate);
            if (repoInfo.entityName === 'ClinicalCase') {
                this.setEntityUpdateDate(repoInfo.entityName);
            }
        }

        return entities;
    }

    protected async syncEntityMedia(entities: Array<any>) {
        const mediaInsertPromises = entities.flatMap(entity => {
            const allMedia = [...(entity.images || []), ...(entity.media || [])];
            return allMedia.map((media: { url: string }) => this.s3Repository.findOrInsertMedia(media.url));
        });

        await Promise.all(mediaInsertPromises);
    }

    private async processEntities(response: { data: Array<any>, next?: string }, localRepo: any): Promise<void|Array<any>> {
        if (response.data.length > 0) {
            return Promise.all(response.data.map(async (entity: any) => {
                if (localRepo instanceof ClinicalCaseLocalRepository) {
                    return localRepo.upsertKeepingLocalIfIsNewest(entity)
                }
                return localRepo.upsert(entity)
            }));
        }

        return [];
    }

    private getEntityUpdateDate(entityName: string): string {
        return window.localStorage.getItem('sync_service-last-pull-updated-at_'+entityName) ?? this.firstTimeSync;
    }

    private setEntityUpdateDate(entityName: string): void {
        const matchingRecords = this.entityUpdateLog.filter(row => row.name === entityName);
        // Sorts the matching records by date in descending order
        const sortedByDate = matchingRecords.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        const mostRecentRecord = sortedByDate[0];
        if (mostRecentRecord && mostRecentRecord.date) {
            window.localStorage.setItem('sync_service-last-pull-updated-at_' + entityName, mostRecentRecord.date);
        }
    }

    private logRecord(entityName: string, date: string) {
        this.entityUpdateLog.push({
            name: entityName,
            date: date
        })
    }

    private getLocalRepository(entityName: string) {
        const className = `${entityName}LocalRepository`;
        switch (className) {
            case 'TagLocalRepository':
                return new TagLocalRepository();
            case 'TagCategoryLocalRepository':
                return new TagCategoryLocalRepository();
            case 'TagCategoryOptionLocalRepository':
                return new TagCategoryOptionLocalRepository();
            case 'DiagnosisOutcomeConfigLocalRepository':
                return new DiagnosisOutcomeConfigLocalRepository();
            case 'TriageLevelLocalRepository':
                return new TriageLevelLocalRepository();
            case 'QuestionRoundLocalRepository':
                return new QuestionRoundLocalRepository();
            case 'HealthcareProfessionalTypeLocalRepository':
                return new HealthcareProfessionalTypeLocalRepository();
            case 'ClinicalOutcomeLocalRepository':
                return new ClinicalOutcomeLocalRepository();
            case 'ClinicalInterventionLocalRepository':
                return new ClinicalInterventionLocalRepository();
            case 'MedbrainUserLocalRepository':
                return new MedbrainUserLocalRepository();
            case 'DiseaseArticleLocalRepository':
                return new DiseaseArticleLocalRepository();
            case 'DiseaseLocalRepository':
                return new DiseaseLocalRepository();
            case 'PatientLocalRepository':
                return new PatientLocalRepository();
            case 'ClinicalCaseLocalRepository':
                return new ClinicalCaseLocalRepository();
            case 'DiagnosticTestLocalRepository':
                return new DiagnosticTestLocalRepository();
            default:
                throw new Error(`Repository class no found for: ${className}`);
        }
    }

    private getApiRepository(entityName: string) {
        const className = `${entityName}ApiRepository`;
        switch (className) {
            case 'TagApiRepository':
                return new TagApiRepository();
            case 'TagCategoryApiRepository':
                return new TagCategoryApiRepository();
            case 'TagCategoryOptionApiRepository':
                return new TagCategoryOptionApiRepository();
            case 'DiagnosisOutcomeConfigApiRepository':
                return new DiagnosisOutcomeConfigApiRepository();
            case 'TriageLevelApiRepository':
                return new TriageLevelApiRepository();
            case 'QuestionRoundApiRepository':
                return new QuestionRoundApiRepository();
            case 'HealthcareProfessionalTypeApiRepository':
                return new HealthcareProfessionalTypeApiRepository();
            case 'ClinicalOutcomeApiRepository':
                return new ClinicalOutcomeApiRepository();
            case 'ClinicalInterventionApiRepository':
                return new ClinicalInterventionApiRepository();
            case 'MedbrainUserApiRepository':
                return new MedbrainUserApiRepository();
            case 'DiseaseArticleApiRepository':
                return new DiseaseArticleApiRepository();
            case 'DiseaseApiRepository':
                return new DiseaseApiRepository();
            case 'PatientApiRepository':
                return new PatientApiRepository();
            case 'ClinicalCaseApiRepository':
                return new ClinicalCaseApiRepository();
            case 'DiagnosticTestApiRepository':
                return new DiagnosticTestApiRepository();
            default:
                throw new Error(`Repository class no found for: ${className}`);
        }
    }
}
