import {EventType} from "../ChangelogRecordInterface";
const eventTypeValues = Object.values(EventType);

export const ChangelogSchema = {
    title: 'Changelog Schema',
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: "string",
        },
        payload: {
            type: "string",
        },
        event_type: {
            type: "string",
            enum: eventTypeValues,
        },
        version: {
            type: "number",
            default: 1,
        },
        created_at: {
            type: 'string',
        },
    }
}