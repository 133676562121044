import { TagCategory } from './TagCategory';

interface ITagCategoryOption {
  id: string;
  option: string;
  tagCategory: TagCategory
}
export class TagCategoryOption {
  id: string;
  option: string;
  tagCategory: TagCategory;

  constructor(tagCategoryOption: ITagCategoryOption) {
    this.id = tagCategoryOption.id;
    this.option = tagCategoryOption.option;
    this.tagCategory = tagCategoryOption.tagCategory;
  }
}