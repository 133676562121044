import { decodeToken } from 'react-jwt';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist } from 'zustand/middleware';

interface UserState {
  idToken: string | null,
  sessionToken: string | null,
  claims: UserClaims | null
  setIdToken: (token: string | null) => void
  setSessionToken: (token: string | null) => void
}

interface UserClaims {
  userId: string | null;
  nickName: string | null;
  isActive: boolean | null;
  hasUserDetailsFilled: boolean | null;
  picture: string | null;
}

const toUserClaims = (token: any) => ({
  userId: token.sub,
  nickName: token.nickname || null,
  isActive: token.isActive || null,
  hasUserDetailsFilled: token.hasUserDetailsFilled || null,
  picture: token.picture || null,
} as UserClaims);

export const useUserStore = create(
  devtools(
    persist<UserState>(
      (set) => ({
        idToken: null,
        sessionToken: null,
        claims: null,
        setIdToken: (token: string | null) => set({ idToken: token }),
        setSessionToken: (token: string | null) => set({
          sessionToken: token,
          claims: token ? toUserClaims(decodeToken(token)) : null,
        }),
      }),
      { name: 'user-info' },
    ),
  ),
);
