import * as Sentry from "@sentry/browser";
import version from "./app-version.json";
import {sentryDsn, appUrl} from "config/const";

Sentry.init({
    dsn: sentryDsn,
    release: version.majorVersion+'.'+version.minorVersion,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error', 'warn', 'debug']
        })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    environment: process.env.NODE_ENV,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", appUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

