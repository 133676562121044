import { ChangeEvent, useEffect, useState } from "react";
import { ProgressBar } from "../../progress-bar/ProgressBar";
import { DescriptiveImages } from "../shared/descriptive-images/DescriptiveImages";
import { YesNoButtons } from "../shared/yes-no-buttons/YesNoButtons";
import { TagCategoryQuestion } from "../shared/tag-category-question/TagCategoryQuestion";
import { TagRankScoreViewModel } from "modules/diagnosis/clinical-case/TagRankViewModel";
import { TagViewModel } from "modules/medical-library/tags/features/list-tags/requests/Tag.viewModel";
import { useProgressBarContext } from "modules/diagnosis/clinical-case/context/ProgressBar/useProgressBarContext";
import { useToast } from "modules/shared/context/useToastContex";
import { SuccessToast } from "../../success-toast/SuccessToast";
import { TopAbsoluteProgressBar } from "../../progress-bar/top-absolute-progress-bar/TopAbsoluteProgressBar";
import { GoBackButton } from "infrastructure/components/go-back-button/GoBackButton";
import { useYesNoQuestionsStepperContext } from "modules/diagnosis/clinical-case/context/YesNoQuestionsStepper/useYesNoQuestionsStepperContext";
import { useApiService } from "../../../../../../infrastructure/hooks/useApiService";
import { DescriptiveVideos } from "../shared/descriptive-videos/DescriptiveVideo";
import {useClinicalCase} from "../../../context/useClinicalCase";
import {ModalLoader} from "../../../../../../infrastructure/components/modal/ModalLoader";


const ANSWERS = {
  yes: 'yes',
  no: 'no',
  unknown: 'unknown',
  unset: 'unset'
} as const;

interface YesNoQuestionProps {
  tag?: TagRankScoreViewModel;
  step: number;
}

export function YesNoQuestion({ tag, step }: YesNoQuestionProps) {
  if (!tag) return null;
  const clinicalCase = useClinicalCase();
  const { addTagQuestionAnswer, removeLastTagQuestionAnswer, goToPreviousStep } = useYesNoQuestionsStepperContext();
  const { showToast, isToastShowed } = useToast();
  const { getTagViewModel } = useApiService();
  const [data, setData] = useState<TagViewModel | null>(null);
  const [answer, setAnswer] = useState<keyof typeof ANSWERS>(ANSWERS.unset);
  const [tagCategoryAnswer, setTagCategoryAnswer] = useState<Array<string>>([]);
  const { goToNextStep, progressWidth, goToPrevStep, previousWidth } = useProgressBarContext();
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  const handleTagCategoryAnswer = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newTagCategoryAnswer = [...tagCategoryAnswer];
    newTagCategoryAnswer[index] = e.target.value;
    setTagCategoryAnswer(newTagCategoryAnswer);
  }

  function handleAnswer(value: keyof typeof ANSWERS) {
    setAnswer(value);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tagData = await getTagViewModel(tag.id);
        setData(tagData);
      } catch (error) {
        console.error('Error fetching tag data:', error);
      }
    };

    fetchData();
  }, [tag]);

  useEffect(() => {

    if (!data) return;

    if (answer === ANSWERS.no || answer === ANSWERS.unknown) {
      showToast(() => {
        goToNextStep();
        addTagQuestionAnswer({
          tagId: tag.id,
          yesNo: answer,
          tagCategory: tagCategoryAnswer,
        });
      });
    }

    if (answer === ANSWERS.yes && data.categories.length === 0) {
      showToast(() => {
        goToNextStep();
        addTagQuestionAnswer({
          tagId: tag.id,
          yesNo: answer,
          tagCategory: tagCategoryAnswer,
        });
      });
    }

    if (answer === ANSWERS.yes && data.categories.length === tagCategoryAnswer.length) {
      showToast(() => {
        goToNextStep();
        addTagQuestionAnswer({
          tagId: tag.id,
          yesNo: answer,
          tagCategory: tagCategoryAnswer,
        });
      });
    }
  }, [answer, tagCategoryAnswer]);

  if (!data) return <ModalLoader open={open} onClose={handleClose}></ModalLoader>

  const hide = isToastShowed === 'fadeIn' ? '' : isToastShowed === 'fadeOut' ? 'fadeOut' : 'none';

  return (
    <section className='static-question__container'>
      {isToastShowed !== 'none' && <TopAbsoluteProgressBar />}
      {
        isToastShowed !== 'none' && (
          <SuccessToast hide={hide} animationName={isToastShowed} contentText={"Question Answered Successfully"} />
        )
      }
      <ProgressBar startWidth={previousWidth} endWidth={progressWidth}/>
      <div className='title__container'>
        <h2 className='subtitle'>
          Does your patient have:
        </h2>
        <h1 className='title'>
          {data.name}
        </h1>
      </div>
      {
        data.description ? (
          <div className='question-description__container'>
            <p>{data.description}</p>
          </div>
        ) : null
      }

      {
        data.tagImages.length > 0 && <DescriptiveImages images={data.tagImages} />
      }
      {
        data.tagVideos.length > 0 && <DescriptiveVideos videos={data.tagVideos} />
      }
      <YesNoButtons
        answer={answer}
        handleAnswer={handleAnswer}
      />
      {
        answer === ANSWERS.yes && data.categories?.length > 0 && (
          <>
            {
              data.categories.map((category, index) => (
                <TagCategoryQuestion
                  key={category.categoryId}
                  tagCategoryAnswer={tagCategoryAnswer[index]}
                  handleTagCategoryAnswer={handleTagCategoryAnswer}
                  category={category}
                  index={index}
                />
              ))
            }
          </>

        )
      }
        <div className="back-button__container">
          <GoBackButton onClick={() => {
            if(step === 0) {
              goToPreviousStep(clinicalCase.clinicalCaseId!);
            }
            goToPrevStep();
            removeLastTagQuestionAnswer();
          }}/>
        </div>

    </section>
  )
}
