import { useEffect, useState } from "react";
import { Box, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { Modal } from "../modal/Modal";
import { TagApiResponse } from "../../../modules/medical-library/tags/features/list-tags/requests/Tag.apiResponse";

interface InputWithMediaProps {
    tag: TagApiResponse;
    isSelected?: boolean;
    optionsHandler: (value: string) => void;
}

interface ISelectedItem {
    type: 'image' | 'video';
    src: string;
}

const InputWithMedia: React.FC<InputWithMediaProps> = ({ tag, isSelected, optionsHandler }) => {
    const [checked, setChecked] = useState(isSelected);
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ISelectedItem | null>(null);
    const [isComponentLoading, setIsComponentLoading] = useState(true);
    const [isModalLoading, setIsModalLoading] = useState(false);

    useEffect(() => {
        setChecked(isSelected);
        const hasVideos = tag.videos && tag.videos.length > 0;
        const hasImages = tag.images && tag.images.length > 0;

        if (hasVideos && tag.videos) {
            setSelectedItem({ type: 'video', src: tag.videos[0]?.url ?? '' });
            setIsComponentLoading(true);
        } else if (hasImages && tag.images) {
            setSelectedItem({ type: 'image', src: tag.images[0]?.url ?? '' });
            setIsComponentLoading(false);
        } else {
            setSelectedItem(null);
            setIsComponentLoading(false);
        }
    }, [tag]);

    useEffect(() => {
        if (selectedItem?.type === 'video') {
            setIsComponentLoading(true);
        } else {
            setIsComponentLoading(false);
        }
    }, [selectedItem]);

    const handleOpen = (item: ISelectedItem) => {
        setSelectedItem(item);
        setOpen(true);
        if (item.type === 'video') {
            setIsModalLoading(true);
        }
    };

    const handleChange = () => {
        setChecked(prev => !prev);
        optionsHandler(tag.id);
    }

    const handleClose = () => setOpen(false);

    const handleVideoLoad = () => setIsComponentLoading(false);

    const handleModalVideoLoad = () => setIsModalLoading(false);

    const handleVideoError = (message: string, error: any) => {
        console.error(message, error)
    }

    const renderMediaThumbnail = () => {
        if (selectedItem?.type === 'image') {
            return (
                <img
                    className={'input-image-thumbnail'}
                    src={selectedItem.src}
                    alt="Versión pequeña"
                    onClick={() => handleOpen(selectedItem)}
                />
            );
        } else if (selectedItem?.type === 'video') {
            return (
                <video
                    className={'input-video-thumbnail'}
                    src={selectedItem.src}
                    crossOrigin="anonymous"
                    onClick={() => handleOpen(selectedItem)}
                    onError={(e: any) => {handleVideoError('Error loading video thumbnail ' + selectedItem?.src, e), setIsComponentLoading(false)}}
                    onLoadedData={handleVideoLoad}
                    onLoadStart={() => setIsComponentLoading(true)}
                />
            );
        }
        return null;
    };

    return (
        <div style={{ position: 'relative' }}>
            {isComponentLoading && (
                <div className={'media-checkbox-loader'}>
                    <CircularProgress />
                </div>
            )}
            <div className="input-container media-checkbox-container">
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleChange} />}
                    label={tag.name}
                />

                <div className={'small-media-thumbnail'}>
                    {renderMediaThumbnail()}
                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box>
                        {isModalLoading && (
                            <div className={'modal-loader'}>
                                <CircularProgress />
                            </div>
                        )}
                        {selectedItem?.type === 'image' ? (
                            <img src={selectedItem?.src} alt="full size image" style={{ width: '100%' }} />
                        ) : selectedItem?.type === 'video' ? (
                            <video src={selectedItem?.src}
                                   controls
                                   crossOrigin="anonymous"
                                   style={isModalLoading ? { display: 'none' } : { width: '100%' }}
                                   onError={(e) => {setIsModalLoading(false), handleVideoError('Error loading video modal ' + selectedItem?.src, e)}}
                                   onLoadedData={handleModalVideoLoad}
                                   onLoadStart={() => setIsModalLoading(true)}
                            />
                        ) : null}
                        <p style={{ textAlign: 'center' }}>{tag.name}</p>
                    </Box>
                </Modal>
            </div>
        </div>
    );
};

export default InputWithMedia;
