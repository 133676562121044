import { Box, Chip, Divider, Grid, Typography, useTheme } from '@mui/material';
import { LinearProgress } from '../linear-progress/LinearProgress';

interface Props {
  title: string;
  items: {
    name: string;
    progress: number;
  }[];
  divider?: boolean;
  minProgress?: number;
  maxProgress?: number;
}

export const SubItemsProgress = (props: Props) => {
  const theme = useTheme();

  return (
    <Box bgcolor={theme.palette.background.paper} sx={{ mt: '10px' }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '700',
          color: theme.palette.text.secondary,
          mb: '10px',
        }}>
        {props.title}
      </Typography>
      {props.items.map((item, index) => (
        <Grid
          key={item.name}
          container
          bgcolor={index % 2 === 0 ? theme.palette.background.paper :
            theme.palette.background.default}
          height={'36px'}
          p={'8px'}
        >
          <Grid item xs={8}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Chip sx={{
                width: '12px',
                height: '12px',
                mr: '20px',
                backgroundColor: theme.palette.secondary.main,
              }}/>
              <Typography
                variant={'body2'}
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: theme.palette.secondary.dark,
                }}>
                {item.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mt: '8px' }}>
              <LinearProgress
                variant={'determinate'}
                value={item.progress}
                minProgress={props.minProgress}
                maxProgress={props.maxProgress}
              />
            </Box>
          </Grid>
        </Grid>
      ))}
      {props.divider && <Divider sx={{ mt: '18px' }}/>}
    </Box>
  );
};
