import { Container } from '@mui/material';
import { Alert } from 'infrastructure/components/alert/Alert.component';
import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import useTranslation from 'infrastructure/i18n/useTranslation';
import { useUserStore } from 'infrastructure/store/userStore';
import { WelcomePage } from '../shared/pages/Welcome.page';
import { UserDetailForm } from './components/UserDetailForm';
import { UserDetails } from './components/UserDetails';
import {VersionPill} from "../shared/components/VersionPill";

export const UserPage = () => {

  const { claims } = useUserStore();

  const userTranslations = useTranslation('USERS');

  if (!claims) {
    return (<></>);
  }

  return (
    <BasePage>
      <Container maxWidth="md">
        {
          !claims.isActive && claims.hasUserDetailsFilled
            ?
            <Alert error={userTranslations.NOT_ACTIVE_DISCLAIMER_MESSAGE}
                   severity="info" /> : <></>
        }
        {
          claims.hasUserDetailsFilled ? claims.isActive ? <WelcomePage /> : <UserDetails /> : <UserDetailForm />
        }
      </Container>
      <VersionPill/>
    </BasePage>
  );
};
