import MUICircularProgress from '@mui/material/CircularProgress';
import { Box, SxProps } from '@mui/material';

export const CircularProgress = () => {

  const sx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    mt: 5,
  };

  return (
    <Box sx={sx}><MUICircularProgress/></Box>
  )
};