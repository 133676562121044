import {ApiRepositoryBase} from "./ApiRepositoryBase";
import {ApiRepositoryInterface} from "../../modules/shared/interfaces/ApiRepositoryInterface";
import {diagnosticTestGetApiResource} from "../../config/const";

export class DiagnosticTestApiRepository extends ApiRepositoryBase implements ApiRepositoryInterface {
    private pageSize: string = "10";

    public async getAllFromDatePaginated(...queryStringParams: Array<{ name: string, value: string }>): Promise<{ data: Array<any>, next?: string }> {
        let params = this.setPageSizeParam(queryStringParams);
        const response = await this.handlePaginationRequest(diagnosticTestGetApiResource, ...params);

        return {
            data: response.data,
            next: response.next,
        };
    }

    private setPageSizeParam(params: { name: string; value: string; }[]): { name: string; value: string; }[] {
        params.push({
            name: 'page_size',
            value: this.pageSize
        });
        return params
    }
}