import { create } from 'zustand';
import { ReactNode } from 'react';

interface AppUIState {
  modalContent: ReactNode | null,
  openModal: (content: ReactNode) => void,
  closeModal: () => void,
}

export const useAppUIStore = create<AppUIState>(
  (set) => ({
    modalContent: null,
    openModal: (content: ReactNode) => set({ modalContent: content }),
    closeModal: () => set({ modalContent: null }),
  }),
);
