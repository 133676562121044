import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Button, InputTextField, DatePicker, RadioButton, Grid } from 'infrastructure/components';

import useTranslation from 'infrastructure/i18n/useTranslation';
import { Sex } from '../../../shared/entities/SexEnum';
import {styled, ToggleButton, ToggleButtonGroup} from "@mui/material";

export interface CreatePatientFormData {
  name: string;
  surname: string;
  birthdate: DateTime | null;
  sex: Sex;
  is_simulated?: boolean;
}

interface FormErrors {
  name?: string;
  surname?: string;
  birthdate?: string;
  sex?: string;
  is_simulated?: string;
}

interface CreatePatientFormProps {
  onSubmit?: (form: CreatePatientFormData) => void,
}

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    marginTop: '0.5rem',
    borderColor: 'transparent',
    backgroundColor: theme.palette.secondary.light,
    height: '2.188rem',
    width: '4.313rem',
    textTransform: 'none',
    marginRight: '0.25rem',
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  '&.MuiToggleButton-root:hover': {
    backgroundColor: theme.palette.secondary.main,
  },

  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.info.dark,
  },

}));


export const CreatePatientForm: React.FC<CreatePatientFormProps> = ({ onSubmit }) => {
  const basicInformationPageTranslations = useTranslation('BASIC_INFORMATION_PAGE');
  const formTranslations = useTranslation('FORMS');
  const [errors, setErrors] = useState<FormErrors>({});
  const [form, setForm] = useState<CreatePatientFormData>({
    name: '',
    surname: ' ', //Important, left this space to aboid problems with required field in DB.
    sex: Sex.MALE,
    birthdate: null,
    is_simulated: undefined,
  });

  const handleOnChange = (key: keyof CreatePatientFormData) => (value: unknown) => {
    setForm({ ...form, [key]: value });
  };

  const validateForm = (form: CreatePatientFormData) => {
    let errors: FormErrors = {};

    if (!form.name) {
      const error = formTranslations.REQUIRED;
      errors = { ...errors, name: error };
    }

    if (!form.birthdate) {
      const error = formTranslations.REQUIRED;
      errors = { ...errors, birthdate: error };
    }

    if (form.birthdate && form.birthdate > DateTime.now()) {
      const error = basicInformationPageTranslations.DATE_OF_BIRTH_INPUT_ERROR_TEXT;
      errors = { ...errors, surname: error };
    }

    if (form.is_simulated === undefined) {
      const error = "Missing real or simulated specification";
      errors = { ...errors, is_simulated: error };
    }

    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      throw Error('Invalid Form');
    }
  };

  const submit = () => {
    try {
      validateForm(form);
      onSubmit && onSubmit(form);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container spacing={6} rowSpacing={6}>
      <Grid item xs={6}>
        <InputTextField
          error={errors.name}
          label={basicInformationPageTranslations.FULL_NAME_INPUT_TEXT}
          onChange={handleOnChange('name')}
          value={form.name}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          error={errors.birthdate}
          label={basicInformationPageTranslations.DATE_OF_BIRTH_INPUT_TEXT}
          onChange={handleOnChange('birthdate')}
          value={form.birthdate}
          minDate={DateTime.now().plus({ years: -16 })}
          maxDate={DateTime.now()}
          openTo="year"
        />
      </Grid>
      <Grid item xs={6}>
        <RadioButton
          onChange={handleOnChange('sex')}
          options={[
            { label: basicInformationPageTranslations.MALE_OPTION_TEXT, value: Sex.MALE },
            { label: basicInformationPageTranslations.FEMALE_OPTION_TEXT, value: Sex.FEMALE },
          ]}
          value={form.sex}
          label={basicInformationPageTranslations.SEX_INPUT_TEXT}
        />
      </Grid>
      <Grid item xs={6} style={{paddingTop: '12px'}}>
        <ToggleButtonGroup
          exclusive
          sx={{marginRight:'2.428rem', marginTop: 'auto', marginBottom: 'auto'}}
          onChange={(_, value) => handleOnChange('is_simulated')(value)}>
          <CustomToggleButton  style={{width: 'auto'}}  value={true} selected={form.is_simulated === true}>Simulated</CustomToggleButton>
          <CustomToggleButton style={{width: 'auto'}} value={false} selected={form.is_simulated === false}>Real</CustomToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item style={{paddingTop: 0}} xs={12} >
        {(errors.is_simulated) ? <p style={{color:'red'}}>{errors.is_simulated}</p> : ''}
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={submit}
          size="large"
          variant="contained"
          style={{textTransform: 'none', fontWeight: 'bold'}}
        >
          {basicInformationPageTranslations.SAVE_BUTTON_TEXT}
        </Button>
      </Grid>
    </Grid>
  );
};
