import {Component} from "react";
import {Button} from "../index";
import {ArrowBack} from "@mui/icons-material";

export class GoBackButton extends Component<{ onClick: () => void }> {
  render() {
    return <Button
      variant="outlined"
      onClick={this.props.onClick}
      startIcon={<ArrowBack/>}
      sx={{
        height: "48px",
        textTransform: 'none',
      }}>
      Go Back
    </Button>;
  }
}
