import { Tag } from '../../../shared/entities/Tag';
import { findById, getAllDiseaseArticles } from 'infrastructure/database/Database';
import {
  ClinicalCaseResponse,
} from '../../clinical-case/features/get-clinical-case/requests/ClinicalCase.apiResponse';
import { DiagnosisOutcomeConfigResponse } from '../../diagnosis-outcome-config/response/DiagnosisOutcomeConfigResponse';
import { TagApiResponse } from '../../../medical-library/tags/features/list-tags/requests/Tag.apiResponse';
import { GetClinicalCase } from 'infrastructure/api/GetClinicalCase';

import {
  DiseaseArticleResponse,
} from '../../../medical-library/disease-articles/features/get-disease-article/api/response/DiseaseArticleResponse';
import {
  DiseaseTagLikelihoodResponse,
} from 'modules/medical-library/disease-articles/features/get-disease-article/api/response/DiseaseTagLikelihoodResponse';

const MINIMUM_DISEASE_SCORE_FOR_PREDICTION = 16;
const MINIMUM_DISEASE_PROBABILITY_FOR_PREDICTION = 10;
const MINIMUM_DISEASE_PROBABILITY_DISTANCE_FOR_PREDICTION = 5;

const MINIMUM_DISEASE_SCORE_FOR_SUSPECTED = 8;
const MINIMUM_DISEASE_PROBABILITY_FOR_SUSPECTED = 10;
const DIAGNOSIS_OUTCOME_CONFIG_UUID = 'cda009af-bbed-43fb-8434-0850d384240d';

export class DiagnosisCalculationService {

  private getLocalClinicalCase: (id: string) => Promise<ClinicalCaseResponse>;
  private getLocalDiagnosisOutcomeConfig: () => Promise<DiagnosisOutcomeConfigResponse>;

  constructor() {
    this.getLocalClinicalCase = id => findById('clinicalCases', id).then(c => c.toJSON()) as Promise<ClinicalCaseResponse>;
    this.getLocalDiagnosisOutcomeConfig = () => findById('diagnosisOutcomeConfig', DIAGNOSIS_OUTCOME_CONFIG_UUID).then(c => c.toJSON()) as Promise<DiagnosisOutcomeConfigResponse>;
  }

  public async calculateDiseaseRanks(clinicalCaseId: string): Promise<GetClinicalCase.DiseaseRankResponse[]> {
    const clinicalCase: ClinicalCaseResponse = await this.getLocalClinicalCase(clinicalCaseId);

    if (!clinicalCase) {
      throw new Error('Clinical Case not found');
    }

    this.validateClinicalCaseHasTags(clinicalCase);

    const diseasesWithMatch = await this.getDiseaseArticleWithAtLeastOneTagOccurrence(clinicalCase);

    await this.cleanDiseaseRanks(clinicalCase);
    const diseaseRank = await this.createDiseaseRank(clinicalCase, diseasesWithMatch);

    await this.updateSuspectedDiseasesInClinicalCase(clinicalCase, diseaseRank);
    await this.updatePredictedDiseaseInClinicalCase(clinicalCase, diseaseRank);
    await this.updateConfirmedDiagnosisInClinicalCase(clinicalCase, diseasesWithMatch);

    return diseaseRank;
  }

  private validateClinicalCaseHasTags(clinicalCase: ClinicalCaseResponse) {
    if (!clinicalCase.tag_category_options.length && !clinicalCase.absence_tags.length && !clinicalCase.presence_tags.length) {
      throw new Error('The provided Clinical Case has no tags.');
    }
  }

  private async createDiseaseRank(clinicalCase: ClinicalCaseResponse, diseasesWithMatch: DiseaseArticleResponse[]): Promise<GetClinicalCase.DiseaseRankResponse[]> {
    let totalScore = 0.0;

    let diseasesRank: GetClinicalCase.DiseaseRankResponse[] = [];
    for (const disease of diseasesWithMatch) {
      const equationParameters = this.getEquationParameters(clinicalCase, disease);

      const totalMatches =
        equationParameters.tagsWithMatch.length + equationParameters.absenceTagsWithMatch.length;

      const diseaseMatchesScore =
        equationParameters.sumOfTagLikelihood +
        equationParameters.sumOfTcoLikelihood +
        equationParameters.sumOfAbsenceTagLikelihood +
        equationParameters.sumOfDurationLikelihood +
        equationParameters.sumOfAgeGroupLikelihood;

      const diseaseScore = diseaseMatchesScore * equationParameters.diseasePrevalence;

      this.logDiseasesMatchSummary(
        clinicalCase,
        disease,
        diseaseMatchesScore,
        diseaseScore,
        equationParameters,
        totalMatches,
      );

      if (diseaseScore > 0) {
        diseasesRank.push({
          disease_id: disease.id,
          disease_article: disease,
          disease_score: diseaseScore,
          disease_name: disease.name,
          disease_icon: disease.disease_icon,
          relative_probability: 0,
          rank_position: 0,
        });

        totalScore += diseaseScore;
      }
    }

    // Obtener el ranking de enfermedades
    diseasesRank = diseasesRank
      .sort((a, b) => b.disease_score - a.disease_score)
      .map((diseaseRank: GetClinicalCase.DiseaseRankResponse, rankPosition: number) => ({
        ...diseaseRank,
        relative_probability: diseaseRank.disease_score / totalScore,
        rank_position: rankPosition + 1,
      }));

    const clinicalCaseToSave = await findById('clinicalCases', clinicalCase.id);
    await clinicalCaseToSave.update({
      $set: {
        disease_rank: diseasesRank,
      },
    });

    return diseasesRank;
  }

  private getEquationParameters(clinicalCase: ClinicalCaseResponse, disease: DiseaseArticleResponse): any {

    const tagCategoryOptionIdsWithMatch = this.getTagCategoryOptionsWithMatch(clinicalCase, disease);
    const tagsWithMatch = this.getTagsWithMatch(clinicalCase, disease);
    const absenceTagsWithMatch = this.getAbsenceTagsWithMatch(clinicalCase, disease);
    const sumOfTagLikelihood = this.calculateTagLikelihoodSumatory(disease, tagsWithMatch);
    const sumOfTcoLikelihood = this.calculateTCOLikelihoodSumatory(disease, tagCategoryOptionIdsWithMatch);
    const sumOfAbsenceTagLikelihood = this.calculateAbsenceTagLikelihoodSumatory(absenceTagsWithMatch, disease);
    const sumOfDurationLikelihood = this.calculateDurationLikelihood(clinicalCase, disease);
    const sumOfAgeGroupLikelihood = this.calculateAgeGroupLikelihood(clinicalCase, disease);
    const diseasePrevalence = disease.prevalence;

    return {
      tagCategoryOptionIdsWithMatch,
      tagsWithMatch,
      absenceTagsWithMatch,
      sumOfTagLikelihood,
      sumOfTcoLikelihood,
      sumOfAbsenceTagLikelihood,
      sumOfDurationLikelihood,
      sumOfAgeGroupLikelihood,
      diseasePrevalence,
    };
  }

  // Marcos
  private calculateDurationLikelihood(clinicalCase: ClinicalCaseResponse, disease: DiseaseArticleResponse): number {
    const caseDuration = clinicalCase.case_duration;

    switch (caseDuration) {
      case 'hyperacute':
        return disease.hiperacute_likelihood_ratio;
      case 'acute':
        return disease.acute_likelihood_ratio;
      case 'subacute':
        return disease.subacute_likelihood_ratio;
      case 'chronic':
        return disease.chronic_likelihood_ratio;
      default:
        return 0;
    }
  }

  // Marcos
  private calculateAgeGroupLikelihood(clinicalCase: ClinicalCaseResponse, disease: DiseaseArticleResponse): number {
    const ageGroup = clinicalCase.age_group;

    switch (ageGroup) {
      case 'neonate':
        return disease.neonates_likelihood_ratio;
      case 'infant':
        return disease.infants_likelihood_ratio;
      case 'under5':
        return disease.under5_likelihood_ratio;
      case 'child':
        return disease.children_likelihood_ratio;
      case 'adolescent':
        return disease.adolescents_likelihood_ratio;
      default:
        return 0;
    }
  }

  private calculateAbsenceTagLikelihoodSumatory(absenceTagsWithMatch: Tag[], disease: DiseaseArticleResponse): number {
    let sumatoryOfAbsenceTagWithMatchLikelihood = 0;

    for (const tag of absenceTagsWithMatch) {

      const diseaseTag = disease.tag.find((diseaseTag) => diseaseTag.tag === tag);

      const absenceTagLikelihood = diseaseTag ? diseaseTag.absence_likelihood_ratio : 0;

      sumatoryOfAbsenceTagWithMatchLikelihood += absenceTagLikelihood;
    }

    return sumatoryOfAbsenceTagWithMatchLikelihood;
  }

  private calculateTagLikelihoodSumatory(disease: DiseaseArticleResponse, tagsWithMatch: Tag[]): number {
    let sumatoryOfTagTagWithMatchLikelihood = 0;

    for (const tag of tagsWithMatch) {

      const diseaseTag = disease.tag.find((diseaseTag) => diseaseTag.tag === tag);

      const presenceLikelihood = diseaseTag ? diseaseTag.presence_likelihood_ratio : 0;

      sumatoryOfTagTagWithMatchLikelihood += presenceLikelihood;
    }

    return sumatoryOfTagTagWithMatchLikelihood;
  }

  private calculateTCOLikelihoodSumatory(disease: DiseaseArticleResponse, tcoIdsWithMatch: string[]): number {
    let sumOfTagCategoryOptionsWithMatchLikelihood = 0;

    for (const tagCategoryOptionId of tcoIdsWithMatch) {
      const diseaseTagCategoryOption = disease.tag_category_options.find(option => option.tag_category_option.id === tagCategoryOptionId);

      const tagCategoryOptionLikelihood = diseaseTagCategoryOption ? diseaseTagCategoryOption.likelihood_ratio : 0;

      sumOfTagCategoryOptionsWithMatchLikelihood += tagCategoryOptionLikelihood;
    }

    return sumOfTagCategoryOptionsWithMatchLikelihood;
  }

  // Cristian
  private getAbsenceTagsWithMatch(clinicalCase: ClinicalCaseResponse, disease: DiseaseArticleResponse): Tag[] {
    return clinicalCase.absence_tags.flatMap((tag: TagApiResponse) =>
      disease.tag.filter((diseaseTag) => diseaseTag.tag.id === tag.id)
        .map((diseaseTag) => diseaseTag.tag),
    );
  }

  // Santiago
  private getTagsWithMatch(clinicalCase: ClinicalCaseResponse, disease: DiseaseArticleResponse): Tag[] {
    const presenceTagsInClinicalCase = clinicalCase.presence_tags.map(tag => tag.id);
    const diseaseTags = disease.tag.map((diseaseTag: DiseaseTagLikelihoodResponse) => diseaseTag.tag);

    return diseaseTags.filter((tag: TagApiResponse) => presenceTagsInClinicalCase.includes(tag.id));
  }

  private getTagCategoryOptionsWithMatch(clinicalCase: ClinicalCaseResponse, disease: DiseaseArticleResponse): string[] {
    const diseaseTagCategoryOptions = disease.tag_category_options.map((tagCategoryOption) => tagCategoryOption.tag_category_option.id);

    return diseaseTagCategoryOptions.filter((diseaseTagCategoryOption) => clinicalCase.tag_category_options.map(tco => tco.id).includes(diseaseTagCategoryOption));
  }

  private async getDiseaseArticleWithAtLeastOneTagOccurrence(clinicalCase: ClinicalCaseResponse): Promise<DiseaseArticleResponse[]> {
    const tagsInClinicalCaseFromTCO = new Set(
      clinicalCase.tag_category_options.map((tco: any) => tco.tag_category.tag),
    );

    const absenceTags = clinicalCase.absence_tags;
    const presenceTags = clinicalCase.presence_tags;

    const tagsInClinicalCase = new Set([...tagsInClinicalCaseFromTCO, ...absenceTags, ...presenceTags]);
    const tagIds = Array.from(tagsInClinicalCase).map((tag: Tag) => tag.id);
    const diseaseArticles: DiseaseArticleResponse[] = await getAllDiseaseArticles();
    return diseaseArticles.filter(diseaseArticle => diseaseArticle.tag.find(tag => tagIds.includes(tag.tag.id)));
  }

  private logDiseasesMatchSummary(clinicalCase: ClinicalCaseResponse, disease: DiseaseArticleResponse, diseaseMatchesScore: number, diseaseScore: number, equationParameters: any, totalMatches: number) {

    console.log('--------------------------------------------------------------------');
    console.log(`Match Summary for ${disease.name} in ${clinicalCase}`);
    console.log('--------------------------------------------------------------------');
    console.log(`Total Matches: ${totalMatches}`);
    console.log(`TAGs with Match: ${equationParameters.tagsWithMatch.length}`);
    console.log(`TCOs with Match: ${equationParameters.tagCategoryOptionIdsWithMatch.length}`);
    console.log(`Absence TAGs with Match: ${equationParameters.absenceTagsWithMatch.length}`);
    console.log('SUM of TCO with match Likelihood: ', equationParameters.sumOfTcoLikelihood);
    console.log('SUM of TAG with match Likelihood: ', equationParameters.sumOfTagLikelihood);
    console.log('SUM of Absence TAG with match Likelihood: ', equationParameters.sumOfAbsenceTagLikelihood);
    console.log('Total Likelihood of Disease: ', diseaseMatchesScore);
    console.log('Disease Prevalence: ', equationParameters.diseasePrevalence);
    console.log('\n');
    console.log(`The disease score for disease ${disease.name} is ${diseaseScore}`);
    console.log('--------------------------------------------------------------------');

  }

  private async cleanDiseaseRanks(clinicalCase: ClinicalCaseResponse) {
    const clinicalCaseToSave = await findById('clinicalCases', clinicalCase.id);
    await clinicalCaseToSave.update({
      $set: {
        disease_rank: [],
      },
    });
  }

  private async updatePredictedDiseaseInClinicalCase(clinicalCase: ClinicalCaseResponse, diseaseRank: GetClinicalCase.DiseaseRankResponse[]) {
    const highestDiseaseInRank = diseaseRank[0];
    const secondDiseaseInRank = diseaseRank.length >= 2 ? diseaseRank[1] : null;

    let predictedMinScore = MINIMUM_DISEASE_SCORE_FOR_PREDICTION;
    let predictedMinProbability = MINIMUM_DISEASE_PROBABILITY_FOR_PREDICTION;
    let predictedMinProbabilityDistance = MINIMUM_DISEASE_PROBABILITY_DISTANCE_FOR_PREDICTION;

    console.log({predictedMinScore, predictedMinProbability, predictedMinProbabilityDistance})

    const diagnosisOutcomeConfig = await this.getLocalDiagnosisOutcomeConfig();
    if (diagnosisOutcomeConfig) {
      predictedMinScore = diagnosisOutcomeConfig.diagnostic_prediction_score_threshold;
      predictedMinProbability = diagnosisOutcomeConfig.diagnostic_prediction_probability_threshold;
      predictedMinProbabilityDistance = diagnosisOutcomeConfig.diagnostic_prediction_diff_probability;
    }

    if (highestDiseaseInRank &&
      highestDiseaseInRank.disease_score >= predictedMinScore &&
      highestDiseaseInRank.relative_probability * 100 >= predictedMinProbability &&
      (!secondDiseaseInRank || (highestDiseaseInRank.relative_probability - secondDiseaseInRank.relative_probability) * 100 >= predictedMinProbabilityDistance)) {
      clinicalCase.predicted_disease = highestDiseaseInRank.disease_id;
    } else {
      clinicalCase.predicted_disease = undefined;
    }

    // TODO extract this logic
    const clinicalCaseToSave = await findById('clinicalCases', clinicalCase.id);
    await clinicalCaseToSave.update({
      $set: {
        predicted_disease: clinicalCase.predicted_disease,
      },
    });
  }

  private async updateSuspectedDiseasesInClinicalCase(clinicalCase: ClinicalCaseResponse, diseaseRank: GetClinicalCase.DiseaseRankResponse[]) {
    const suspectedDiseases: string[] = [];

    let suspectedMinScore = MINIMUM_DISEASE_SCORE_FOR_SUSPECTED;
    let suspectedMinProbability = MINIMUM_DISEASE_PROBABILITY_FOR_SUSPECTED;

    const diagnosisOutcomeConfig = await this.getLocalDiagnosisOutcomeConfig();
    if (diagnosisOutcomeConfig) {
      suspectedMinScore = diagnosisOutcomeConfig.diagnostic_suspicion_score_threshold;
      suspectedMinProbability = diagnosisOutcomeConfig.diagnostic_suspicion_probability_threshold;
    }

    for (let disease of diseaseRank) {
      if (disease &&
        disease.disease_score >= suspectedMinScore &&
        disease.relative_probability * 100 >= suspectedMinProbability) {
        suspectedDiseases.push(disease.disease_id);
      }
    }

    clinicalCase.suspected_diseases = suspectedDiseases;

    // TODO extract this logic
    const clinicalCaseToSave = await findById('clinicalCases', clinicalCase.id);
    await clinicalCaseToSave.update({
      $set: {
        suspected_diseases: clinicalCase.suspected_diseases,
      },
    });
  }

  private async updateConfirmedDiagnosisInClinicalCase(clinicalCase: ClinicalCaseResponse, diseasesWithMatch: DiseaseArticleResponse[]) {
    // TODO confirmed_diagnostic not being used
    // check if this is even needed
    return [clinicalCase, diseasesWithMatch];
  }
}
