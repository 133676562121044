import { createContext, FC, ReactNode, useState } from "react";

export interface ProgressBarContextProps {
  progressWidth: number;
  previousWidth: number;
  max_steps: number;
  resetProgress: () => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
}

export const ProgressBarContext = createContext<ProgressBarContextProps | null>(null);

export const ProgressBarContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [progressWidth, setProgressWidth] = useState({
    prev: 0,
    curr: 0,
  });
  const [currentStep, setCurrentStep] = useState({
    real: 0,
    showed: 0,
  });
  const MAX_STEPS = 11;

  function resetProgress() {
    setProgressWidth({
      prev: 0,
      curr: 0
    });
  }

  function updateProgressWidth(progress: number) {
    setProgressWidth({
      prev: progressWidth.curr,
      curr: progress,
    });
  }

  function goToNextStep() {
    const nextRealStep = currentStep.real + 1;

    if (nextRealStep < MAX_STEPS) {
      setCurrentStep({
        real: nextRealStep,
        showed: nextRealStep
      });
      updateProgressWidth(8 * nextRealStep);
      return;
    }

    let asymptoticProgress = nextRealStep - MAX_STEPS;
    let total = 8 * currentStep.showed

    if(total + (asymptoticProgress * 2) < 100) {
      updateProgressWidth(total + asymptoticProgress)
    } else {
      updateProgressWidth(98);
    }

    setCurrentStep({
      real: nextRealStep,
      showed: currentStep.showed
    });
  }

  function goToPrevStep() {
    const prevRealStep = currentStep.real - 1;

    if (prevRealStep < MAX_STEPS) {
      setCurrentStep({
        real: prevRealStep,
        showed: prevRealStep
      });
      updateProgressWidth(8 * prevRealStep);
      return;
    }

    let asymptoticProgress = prevRealStep - MAX_STEPS;
    let total = 8 * currentStep.showed

    if(total + (asymptoticProgress * 2) < 100) {
      updateProgressWidth(total + asymptoticProgress)
    } else {
      updateProgressWidth(98);
    }

    setCurrentStep({
      real: prevRealStep,
      showed: currentStep.showed
    });
  }

  return (
    <ProgressBarContext.Provider
      value={{
        progressWidth: progressWidth.curr,
        previousWidth: progressWidth.prev,
        max_steps: MAX_STEPS,
        resetProgress,
        goToNextStep,
        goToPrevStep,
      }}
    >
      {children}
    </ProgressBarContext.Provider>
  )
} 