import { useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  return (route: string, params: {}) => {
    let newroute = route;
    Object.keys(params).forEach((key) => {
      newroute = newroute.replace(`:${key}`, params[key as keyof typeof params])
    });
    navigate(newroute)
  }
};
