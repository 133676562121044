import {ClinicalCaseStep} from "../../shared/entities/ClinicalCaseStep";
import {Button} from "../../../infrastructure/components";
import {ClinicalCaseState} from "./context/ClinicalCase.context";
import {useClinicalCase} from "./context/useClinicalCase";
import {useDispatchClinicalCase} from "./context/useDispatchClinicalCase";
import {useUpdateClinicalCase} from "./hooks/useUpdateClinicalCase";
import {GoBackButton} from "../../../infrastructure/components/go-back-button/GoBackButton";
import {ArrowForward} from "@mui/icons-material";

export const PrioritySignDetectedPage = () => {
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const setClinicalCase = useDispatchClinicalCase();
  const updateClinicalCase = useUpdateClinicalCase().fetch;

  const onClickNoSignsDetected = () => {
    clinicalCase.currentStep = ClinicalCaseStep.SELECTING_CASE_DURATION;
    setClinicalCase(clinicalCase);
  };

  const goBack = () => {
    clinicalCase.currentStep = ClinicalCaseStep.PRIORITY_SIGNS;
    clinicalCase.prioritySigns = [];
    let presenceTags: string[] = [];

    if (clinicalCase.emergencySign) {
      presenceTags = [clinicalCase.emergencySign.id];
    }

    updateClinicalCase({
      clinical_case_state: clinicalCase.currentStep,
      priority_signs: [],
      presence_tags: presenceTags,
    });

    setClinicalCase(clinicalCase);
  };

  return <div>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      Content Here
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '60px'}}>
      <GoBackButton onClick={goBack}/>
      <Button
        variant="contained"
        onClick={(_) => onClickNoSignsDetected()}
        endIcon={<ArrowForward/>}
        sx={{
          textTransform: 'none',
          height: '48px',
        }}>
        Patient Is Stable
      </Button>
    </div>
  </div>;
};
