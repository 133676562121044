import { Avatar, List, ListItem, ListItemAvatar, ListItemText, styled } from '@mui/material';
import './ProfileMiniature.component.scss';

interface ProfileMiniatureIcon {
  icon: string;
}

interface Props {
  title: string;
  subtitle?: string;
  listIcon?: ProfileMiniatureIcon;
  hideIcon?: boolean;
}

const CustomListItemText = styled(ListItemText)(() => ({
  margin: 'auto',
  '.MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
  },
  '.MuiListItemText-secondary': {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  }
}));

export const ProfileMiniature = (props: Props) => {
  const renderIcon = () => {
    return <>
      <Avatar
        sx={{ width: 80, height: 80, margin: '0 20px auto 0'}}
        src={props.listIcon?.icon ?? '/assets/AvatarIcon.svg'}/>
    </>;
  };

  return (
    <List>
      <ListItem className="profile-miniature"
        sx={{ padding: 0 }}
        alignItems="flex-start">
        <ListItemAvatar className={"profile-miniature-avatar " + (props.hideIcon ? 'hide-icon' : '')}>
          {renderIcon()}
        </ListItemAvatar>
        <CustomListItemText className="profile-miniature-text"
          primary={props.title}
          secondary={props.subtitle}
        />
      </ListItem>
    </List>
  );
};
