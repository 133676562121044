import { Accordion, AccordionSummary, Avatar, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import {Box, Card, Divider, Grid} from 'infrastructure/components';
import { Typography } from '../typography/Typography';
import { AccordionTemplateProps, FilledTemplate } from './Types';
import { AccordionTemplateDetails } from './accordion-template-details/AccordionTemplateDetails.component';
import React, { useMemo, useState } from 'react';
import { TemplateImages } from './TemplateImages.component';
import { TemplateImagesPreview } from './TemplateImagesPreview.component';

export const AccordionTemplate = (props: AccordionTemplateProps) => {
  const hasTagImages = useMemo(
    () => props.template.tagImages && props.template.tagImages.length > 0,
    [props.template.tagImages],
  );

  const [templateStatus, setTemplateStatus] = useState(false);
  const [expanded, setExpanded] = useState(hasTagImages);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const renderStatusIcon = () => {
    const theme = useTheme();
    return <>
      <Avatar sx={{
        bgcolor: templateStatus ? theme.palette.success.light : theme.palette.error.light,
        width: '26px',
        height: '26px',
      }}>
        {templateStatus ?
          <img crossOrigin="anonymous" src={'/assets/CompletedCheck.svg'} alt="Completed" /> :
          <img crossOrigin="anonymous" src={'/assets/InProgressCheck.svg'} alt="Incomplete" />}
      </Avatar>
    </>;
  };

  const allTemplateCategoriesAreFilled = (filledTemplate: FilledTemplate): boolean => {
    return !Object.entries(filledTemplate.categories).some(([key, _]) => !filledTemplate.categories[key]);
  };

  const handleOnChange = (filledTemplate: FilledTemplate) => {
    if (allTemplateCategoriesAreFilled(filledTemplate)) {
      setTemplateStatus(true);

      if (Object.keys(filledTemplate.categories).length) {
        setExpanded(false);
      }
    } else {
      setExpanded(true);
    }
    props.onChange && props.onChange(filledTemplate);
  };

  const renderTemplateImagesPreview = (): React.ReactNode => {
    if (!hasTagImages || expanded) {
      return <></>;
    }

    return (
      <TemplateImagesPreview
        template={props.template}
      />
    );
  };

  const renderAccordionSummary = (): React.ReactNode => {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        height="default"
      >
        <Grid item sx={{maxWidth: "90%"}}>
          <Grid
            container
            alignItems="center"
          >
            {renderStatusIcon()}
            <Typography
              variant="body2"
              fontWeight={500}
              fontSize="16px"
              lineHeight="22px"
              marginLeft="16px"
              maxWidth="92%"
            >
              {props.template.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Grid item>
              {renderTemplateImagesPreview()}
            </Grid>
            <Grid
              item
              sx={{
                paddingLeft: '18px',
                paddingRight: '2px',
              }}
            >
              <IconButton
                onClick={props.onClickDelete}
              >
                <img crossOrigin="anonymous" src={'/assets/DeleteIcon.svg'} alt="Remove tag" style={{width: '18px', height: '18px'}} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const hasTemplateCategories = props.template.categories.length > 0;

  return (
    <Card sx={{
      marginTop: '18px',
      borderRadius: expanded ? '25px' : '45px',
      transition: 'border-radius 1.5s',
      borderWidth: 0,
      width: '700px',
    }}>
      <Accordion
        expanded={expanded}
        onChange={(hasTemplateCategories || hasTagImages) ? handleChange : undefined}
        disableGutters
      >
        <AccordionSummary
          expandIcon={(hasTemplateCategories || hasTagImages) && <ExpandMoreIcon sx={{color: '#D2D2D2'}}/>}
        >
          {renderAccordionSummary()}
        </AccordionSummary>
        <Divider
          sx={{
            marginTop: 0,
            marginBottom: "22px",
            marginLeft: "16px",
            marginRight: "16px",
          }}
        />
        <Box sx={{color: '#9F9F9F', padding: '0 20px 20px 20px'}}>
          {props.template.description}
        </Box>

        <TemplateImages
          tag={props.template}
        />
        <AccordionTemplateDetails
          template={props.template}
          selected={props.selected}
          onChange={handleOnChange}
        />
      </Accordion>
    </Card>
  );
};
