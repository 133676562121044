import { useEffect, useState } from 'react';
import './OffilineLogin.page.scss';
import { useNavigate } from 'react-router-dom';
import { checkUserAlreadyLogged, getLoggedUser } from 'infrastructure/database/Database';

export function OfflineLoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  async function handleSubmit(e: any) {
    e.preventDefault();
    const loggedUser = await getLoggedUser();

    if(!loggedUser) {
      return setError('You need to login online first');
    }

    if(!email) {
      return setError('Email is empty');
    }

    if(!email.includes(loggedUser?.user?.email)) {
      return setError('Email does not match with your local user');
    }

    localStorage.setItem('email', email);
    navigate('/');
  }

  function handleChangeForms(value: string) {
    setEmail(value);
  }

  useEffect(() => {
    const autoRedirect = async () => {
      if(await checkUserAlreadyLogged()) {
        return navigate('/');
      }
    }

    autoRedirect();
  }, []);

  return (
    <main className="login__container">
      <img crossOrigin="anonymous" src="/medbrain-logo.svg" alt="logo" height={65} />
      <form className="form__container" onSubmit={handleSubmit}>
        <h4>Welcome</h4>
        <p>Log in to Medbrain to continue to Medbrain</p>
        <input 
          name='email' 
          type='email' 
          placeholder='Email' 
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeForms(e.target.value)} 
        />
        {error && <p className='error__message'>{error}</p>}
        <button type='submit'>Log in</button>
      </form>
    </main>
  )
}