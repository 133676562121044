import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

interface TextAreaCustomProps  {
  onChange: (newVal: string) => void
}

type TextAreaProps = TextAreaCustomProps & TextFieldProps;

export const TextArea = (props: TextAreaProps) => {

  const propsWithDefaults = {
    fullWidth: true,
    multiline: true,
    minRows: 4,
    maxRows: 8,
    ...props,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => props.onChange && props.onChange(e.target.value),
  };

  return <TextField {...propsWithDefaults} />;
};
