import './SelectableFlipCard.scss';
import { useState } from 'react';
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';

export interface SelectableFlipCardProps {
  title: string;
  image: string;
  description?: string;
  onClick?: (isSelected: boolean) => void;
  flipable?: boolean;
}

interface SelectableFlipCardTabletProps {
  onClickCard: () => void;
  image: string;
  title: string;
  description?: string;
  isSelected: boolean;
}

function SelectableFlipCardTablet({onClickCard, image, title, description, isSelected}: SelectableFlipCardTabletProps) {

  return <div
      className={'new__selectable-card ' + (isSelected ? 'is-selected' : '')}
      onClick={onClickCard}
    >
        <div className="no-flip">
          <img className="selectable-card-image" src={image} alt={title}/>
          <p className="selectable-card-title">
            {title}
          </p>
          <span className="selectable-card-description">
            {description}
          </span>
        </div>
    </div>;
}

export const SelectableFlipCard = (props: SelectableFlipCardProps) => {
  const flipable = props.flipable ?? true;
  const [isSelected, setIsSelected] = useState(false);
  const { width } = useWindowSize();

  const onClickCard = () => {
    const newVal = !isSelected;
    setIsSelected(newVal);
    if (props?.onClick) {
      props.onClick(newVal);
    }
  };

  const cardCheck = (isSelected: boolean) => {
    if (isSelected) {
      return <img crossOrigin="anonymous" className="selectable-card-selected-check" src={'/assets/Selected.svg'} alt="Selected"/>;
    }

    return null;
  };

  if(width <= 900) {
    return (
      <SelectableFlipCardTablet
        isSelected={isSelected}
        description={props.description}
        image={props.image}
        onClickCard={onClickCard}
        title={props.title}
      />
    );
  }

  if (!flipable) {
   return <div
      className={'selectable-card ' + (isSelected ? 'is-selected' : '')}
      onClick={onClickCard}
    >
        <div className="no-flip">
          {cardCheck(isSelected)}
          <img className="selectable-card-image" src={props.image} alt={props.title}/>
          <p className="selectable-card-title">
            {props.title}
          </p>
          <span className="selectable-card-description">
            {props.description}
          </span>
        </div>
    </div>;
  }

  return (
    <div
      className={'selectable-card ' + (isSelected ? 'is-selected' : '')}
      onClick={onClickCard}
    >
      <div className="flip-card-inner">
        <div className="flip-card-front">
          {cardCheck(isSelected)}
          <img className="selectable-card-image" src={props.image} alt={props.title}/>
          <p className="selectable-card-title">
            {props.title}
          </p>
        </div>
        <div className="flip-card-back">
          {cardCheck(isSelected)}
          <img className="selectable-card-image" src={props.image} alt={props.title}/>
          <p className="selectable-card-title">
            {props.title}
          </p>
          <p className="selectable-card-description" title={undefined}>
            {props.description}
          </p>
        </div>
      </div>
    </div>
  );
};
