import {
  LabelResponse,
  QuestionRoundAnswersResponse,
  TagCategoryOptionResponse,
  TriageStateSchema,
} from "./ClinicalCaseSchemaResponses";
import { patientSchema } from "../../patient/schema/PatientSchema";

export const clinicalCaseSchema = {
  title: "Clinical Case Schema",
  version: 1,
  primaryKey: "id",
  type: "object",
  properties: {
    id: {
      type: "string",
    },
    patient: {
      type: patientSchema,
    },
    patient_first_name: {
      type: "string",
    },
    patient_last_name: {
      type: "string",
    },
    patient_sex: {
      type: "string",
      enum: ["M", "F"],
    },
    tag_category_options: {
      type: "array",
      items: TagCategoryOptionResponse,
    },
    diagnostic_tests_used: {
      type: "array",
      items: LabelResponse,
    },
    diagnostic_made_by: {
      type: "array",
      items: LabelResponse,
    },
    confirmed_diagnostic_labels: {
      type: "array",
      items: LabelResponse,
    },
    confirmed_triage: {
      type: "array",
      items: LabelResponse,
    },
    triage_made_by: {
      type: "array",
      items: LabelResponse,
    },
    clinical_interventions: {
      type: "array",
      items: LabelResponse,
    },
    clinical_outcomes: {
      type: "array",
      items: LabelResponse,
    },
    predicted_disease: {
      type: "string",
    },
    suspected_diseases: {
      type: "array",
      items: "string",
    },
    question_rounds_answers: {
      type: "array",
      item: QuestionRoundAnswersResponse,
      case_notes: {
        type: "string",
      },
    },
    case_notes: {
      type: "string",
    },
    clinical_diagnosis: {
      type: "array",
      items: LabelResponse,
    },
    triage_state: {
      type: TriageStateSchema,
      default: null,
    },
    admission_decision_date: {
      type: ["null", "string"],
      default: null,
    },
    clinical_outcomes_date: {
      type: ["null", "string"],
      default: null,
    }
  },
  required: ["id"],
};
