export class DiseaseArticleDataTransformer {
    public transform(entity: any)
    {
        entity.images = [];
        if (entity.disease_icon) {
            entity.images.push({
                name: 'disease_icon',
                url: entity.disease_icon
            });
        }
        if (entity.icon) {
            entity.images.push({
                name: 'icon_'+entity.icon.name,
                url: entity.icon.icon
            });
        }
        if (entity.disease_image) {
            entity.images.push({
                name: 'disease_image',
                url: entity.disease_image
            });
        }
        if (entity.image) {
            entity.images.push({
                name: 'image',
                url: entity.image
            });
        }

        return entity;
    }
}
