import axios, { AxiosError, Method } from 'axios';
import { baseApiUrl } from '../../config/const';
import { useUserStore } from '../store/userStore';

interface RequestConfig {
  method?: Method,
  params?: object,
  body?: any,
  token?: string,
  headers?: object
}

export const useApi = () => {

  const sessionToken = useUserStore((state) => state.sessionToken);
  axios.defaults.baseURL = baseApiUrl;

  const makeRequest = (method?: Method) => {
    return async (route: string, config?: RequestConfig) => {
      try {
        return await axios.request({
          headers:{ Authorization: `Bearer ${config?.token || sessionToken}` },
          method: method || config?.method || 'GET',
          url: route,
          data: config?.body,
          params: config?.params,
        });
      } catch (err) {
        const error = err as AxiosError;
        console.log('error', error);
        throw error;
      }
    };
  };

  return {
    get: makeRequest('GET'),
    post: makeRequest('POST'),
    put: makeRequest('PUT'),
    patch: makeRequest('PATCH'),
    delete: makeRequest('DELETE'),
    http: makeRequest()
  };
};