import { Box } from '@mui/material';
import { Typography } from '../typography/Typography';
import {TagVideo} from "../../../modules/medical-library/tags/features/list-tags/requests/Tag.viewModel";

interface Props {
    video: TagVideo;
}

export const AccordionVideoModalComponent = (props: Props) => {
    return (
        <>
            <Box position={'relative'} overflow={'hidden'} mx={2} mt={1} mb={3}>
                <video crossOrigin="anonymous" src={props.video.url} key={props.video.url} title={props.video.url} controls style={{width:'100%'}}>
                </video>
                <Typography textAlign="center"
                            display="block"
                            mx="auto"
                            variant="body2"
                            fontSize="18px"
                            width="70%">
                    {props.video.description}
                </Typography>
            </Box>
        </>
    );
};
