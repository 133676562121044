import MUIAppBar from '@mui/material/AppBar';
import { styled, Toolbar, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { RouterLink } from '../router-link/RouterLink';
import { UserMenu } from '../user-menu/UserMenu';
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';

const CustomAppBar = styled(MUIAppBar)(({ theme }) => ({
  '&.MuiAppBar-root': {
    backgroundColor: theme.paletteExtension?.medbrainColors?.medbrainEmphasisDark || '#0E2939',
    height: '5rem',
  },
}));

interface Props {
  children: ReactNode;
}

export const Header = (props: Props) => {
  const { width } = useWindowSize();

  return (
    <CustomAppBar
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, paddingRight: width <= 900 ? '0px' : '52px' }}>
      <Toolbar>
        <RouterLink to={'/'} sx={{lineHeight: 0}}>
          <img crossOrigin="anonymous"
            src={'/assets/MedbrainLogo.svg'}
            alt="Medbrain-logo"
            style={{height: width <= 900 ? '40px' : '50px', margin: width <= 900 ? '20px 0' : '20px 21px'}}
          />
        </RouterLink>
        <Typography component="div" sx={{ flexGrow: 1 }} />
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-start', flexDirection: 'row-reverse'}}>
          {props.children}
        </div>
        <UserMenu />
      </Toolbar>
    </CustomAppBar>
  );
};
