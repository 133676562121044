import { Grid } from '../grid/Grid';
import { AccordionImageModalComponent } from './AccordionImageModal.component';
import { Typography } from '../typography/Typography';
import { useAppUIStore } from '../../store/appUIStore';
import {TagViewModel} from "../../../modules/medical-library/tags/features/list-tags/requests/Tag.viewModel";

import './TemplateImages.component.scss';
interface Props {
  tag: TagViewModel;
}

export const TemplateImages = (props: Props) => {
  if (!props.tag.tagImages || props.tag.tagImages.length == 0) {
    return <></>;
  }

  const openModal = useAppUIStore((state) => (state.openModal));

  const renderImage = (image: any) => {
    return (
      <Grid item key={`${image.description}${image.url}`} style={{margin: '0 10px 30px 10px'}}>
        <div style={{justifyContent: 'center', display: 'flex', marginBottom: '7px'}}>
          <img
            className={'image'}
            style={{cursor: 'pointer' }}
            src={image.url}
            onClick={() => openModal(<AccordionImageModalComponent image={image} />)}
          />
        </div>
        <Typography
          variant="body2"
          fontSize="16px"
          fontWeight={500}
          lineHeight="19px"
          maxWidth="350px"
          textAlign="center"
          margin="auto"
        >
          {image.description}
        </Typography>
      </Grid>
    );
  };

  const renderVideo = (video: any) => {
    return (
      <Grid item key={`${video.description}${video.url}`} style={{margin: '0 10px 30px 10px'}}>
        <div style={{justifyContent: 'center', display: 'flex', marginBottom: '7px'}}>
          <video crossOrigin="anonymous" className={'image'} key={video.url} controls title={video.description} src={video.url}></video>
        </div>
        <Typography
          variant="body2"
          fontSize="16px"
          fontWeight={500}
          lineHeight="19px"
          maxWidth="350px"
          textAlign="center"
          margin="auto"
        >
          {video.description}
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="top"
    >
      {props.tag.tagImages.map(renderImage)}
      {props.tag.tagVideos?.map(renderVideo)}
    </Grid>
  );
};
