import {findById, getDB, upsertEntity} from "../database/Database";
import {LocalRepositoryBase} from "./LocalRepositoryBase";
import {LocalRepositoryInterface} from "../../modules/shared/interfaces/LocalRepositoryInterface";
import {SignType, TagApiResponse} from "../../modules/medical-library/tags/features/list-tags/requests/Tag.apiResponse";
import {TagDataTransformer} from "../../modules/medical-library/tags/transformers/TagDataTransformer";

const TABLE_NAME = "tags";
export class TagLocalRepository extends LocalRepositoryBase implements LocalRepositoryInterface {
    private transformer: TagDataTransformer;

    constructor() {
        super();
        this.transformer = new TagDataTransformer();
    }

    public async getAll() {
        const db = await getDB();
        return await db[TABLE_NAME]
          .find()
          .exec()
          .then((documents: any[]) => documents.map(document => this.transformer.transform(document.toJSON())))
    }

    public async findById(id: string): Promise<TagApiResponse> {
        const db = await getDB();
        return await db[TABLE_NAME]
            .findOne()
            .where('id')
            .eq(id)
            .exec()
            .then((c: any) => c ? c.toJSON() : null)
    }

    public async findBySignType(type: SignType): Promise<TagApiResponse[]> {
        const db = await getDB();
        let tags = [];
        if (type === 'priority') {
            tags = await db[TABLE_NAME]
                .find()
                .where('is_priority_sign')
                .eq(true)
                .exec()
        } else if(type === 'emergency') {
            tags = await db[TABLE_NAME]
                .find()
                .where('is_emergency_sign')
                .eq(true)
                .exec()
        }

        return tags.map((tag: any) => tag.toJSON());
    }

    public async upsert(entity: any): Promise<void> {
        await upsertEntity(TABLE_NAME, entity);
        this.records = entity;

        return this.transformer.transform(entity)
    }

    public async tagsIdsToTags(tagsIds: string[]) { // TODO: old useApiService.tagsIdsToTags
        return await Promise.all(
            tagsIds
                .map(async (tagId) => {
                    const tag = await findById(TABLE_NAME, tagId);

                    return tag.toJSON() as TagApiResponse;
                })
                .filter((tag) => tag)
        );
    }

    public async tagCategoryOptionsIdsToTags(tagsIds: string[]) { // TODO: old useApiService.tagCategoryOptionsIdsToTags
        const tagCategoryOptionsWithTagId = await Promise.all(
            tagsIds.map(async (tagId) => {
                const tag = await findById("tagCategoryOptions", tagId);

                return tag.toJSON();
            })
        );

        for (let tagCategoryOption of tagCategoryOptionsWithTagId) {
            const tag = await findById(TABLE_NAME, tagCategoryOption.tag_category.tag);

            tagCategoryOption.tag_category.tag = tag.toJSON();
        }

        return tagCategoryOptionsWithTagId;
    }
}
