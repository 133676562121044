import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Typography } from 'infrastructure/components';
import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import useTranslation from 'infrastructure/i18n/useTranslation';
import {PatientLocalRepository} from "../../../infrastructure/repositories/PatientLocalRepository";
import {ClinicalCaseLocalRepository} from "../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import { ClinicalCase } from '../clinical-case/ClinicalCase';
import { CreateClinicalCaseFeature } from '../clinical-case/features/create-clinical-case/CreateClinicalCase.feature';
import { ClinicalCaseList } from './components/ClinicalCaseList';
import { PatientHeaderWidgets } from './components/PatientHeaderWidgets';
import { PatientProfile } from './components/PatientProfile';
import {useEffect, useState} from 'react';
import {VersionPill} from "../../shared/components/VersionPill";

export const PatientPage = () => {
  const navigate = useNavigate();
  const patientTranslations = useTranslation('PATIENT_PAGE');
  const [patient, setPatient] = useState<any>(undefined);
  const [clinicalCases, setClinicalCases] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const {id} = useParams();
  const patientRepository = new PatientLocalRepository();
  const clinicalCaseRepository = new ClinicalCaseLocalRepository();

  const navigateToClinicalCase = (clinicalCase: ClinicalCase) => {
    navigate(`/clinical-case/${clinicalCase.id}`);
  };

  useEffect(() => {
    if (patient && id === patient.patientId) return;

    if (isLoading) return;
    const fetchPatientsAndClinicalCases = async () => {
      setIsLoading(true);
      const patient = await patientRepository.findById(id!);
      const clinicalCases = await clinicalCaseRepository.findByPatientId(id!);

      setClinicalCases(clinicalCases);
      setPatient(patient);
      setIsLoading(false);
    }

    fetchPatientsAndClinicalCases();
  }, [id]);

  return (
    <BasePage header={<PatientHeaderWidgets/>}>
      <Container
        disableGutters
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '10px',
          padding: '50px 52px 0px 52px',
        }}
      >
        <Typography
          textTransform="uppercase"
          fontSize="20px"
          fontWeight={900}
          lineHeight="27px"
          letterSpacing="0.1rem"
          marginBottom="30px"
        >
          {patientTranslations.PATIENT_PROFILE}
        </Typography>

        <PatientProfile isLoading={isLoading} patient={patient}/>

        <Typography
          textTransform="uppercase"
          fontSize="20px"
          fontWeight={900}
          lineHeight="27px"
          letterSpacing="0.1rem"
          marginBottom="30px"
          marginTop="50px"
        >
          {patientTranslations.CLINICAL_CASES}
        </Typography>
        <ClinicalCaseList onClinicalCaseSelect={navigateToClinicalCase}
                          isLoading={isLoading}
                          clinicalCases={clinicalCases}/>

        {
          id ? (
            <Box mx={'auto'} width={'fit-content'}>
              <CreateClinicalCaseFeature patientId={id}/>
            </Box>
          ) : <></>
        }

      </Container>
      <VersionPill/>
    </BasePage>
  );
};
