import {ChangelogLocalRepository} from "../../infrastructure/repositories/ChangelogLocalRepository";
import {ChangelogRecordInterface, EventType} from "./changelog/ChangelogRecordInterface";

export class ChangelogCompactorService
{
    private changelogRepository: ChangelogLocalRepository;

    constructor() {
        this.changelogRepository = new ChangelogLocalRepository();
    }

    public async compact() {
        let record: ChangelogRecordInterface|undefined = await this.changelogRepository.getFirstIn();
        if (!record) {
            return;
        }

        let allRelatedOrderByDateAsc = await this.changelogRepository.findRecordsByPayloadId((record.payload as any).id)
        let newestRecord = allRelatedOrderByDateAsc[allRelatedOrderByDateAsc.length -1]

        let recordsToBeDeleted = allRelatedOrderByDateAsc.filter((row:any) => row.id !== newestRecord.id)
        if (record.event_type === EventType.ClinicalCaseCreated) {
            recordsToBeDeleted = allRelatedOrderByDateAsc.filter((row:any) => row.id !== record?.id && row.id !== newestRecord.id)
        }
        recordsToBeDeleted.map(async (row: any) => await this.changelogRepository.deleteRecordById(row.id))
    }
}