import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { PatientInterface } from "modules/shared/entities/PatientInterface";

export const PatientInfo = ({ patient, index }: { patient: PatientInterface; index: number }) => {
  return (
    <Grid
      container
      alignItems="center"
    >
      <Grid item>
        <Typography
          fontSize="18px"
          fontWeight={900}
          lineHeight="25px"
          variant={'body1'}
        >
          {`${index + 1}.`} {`${patient.firstName} ${patient.lastName}`}
        </Typography>
      </Grid>
    </Grid>
  );
};