export const userLoginSchema = {
  title: 'UserLogin Schema',
  description: 'Collection for database user login management',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    auth_provider_id: {
      type: 'string',
    },
    health_center: {
      address: {
        type: 'string',
      },
      created_at: {
        type: 'string',
      },
      email: {
        type: 'string',
      },
      id: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      phone: {
        type: 'string',
      },
      updated_at: {
        type: 'string',
      },
    },
    profile_picture: {
      type: 'string',
    },
    type_of_professional: {
      type: 'string',
    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    user: {
      id: {
        type: 'number',
      },
      email: {
        type: 'string',
      },
      first_name: {
        type: 'string',
      },
      last_name: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      username: {
        type: 'string',
      },
    }
  },
  required: [
    'id',
  ],

}