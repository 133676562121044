import { CaseNote, Severity } from '../../modules/diagnosis/case-notes/CaseNote';
import { MedbrainUser, TypeOfProfessional } from '../../modules/user/MedbrainUser';

export namespace GetClinicalCaseNote {
  interface User {
    id: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    name: string;
  }

  interface HealthCenterResponse {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    address: string;
    email: string;
    phone: string;
  }

  interface MedbrainUserResponse {
    id: string;
    user: User;
    health_center: HealthCenterResponse;
    created_at: string;
    updated_at: string;
    profile_picture?: any;
    auth_provider_id: string;
    type_of_professional: string;
  }

  export interface Response {
    id: string;
    created_by: MedbrainUserResponse;
    created_at: string;
    updated_at?: string;
    level_of_care: string;
    diagnostic_tests?: string;
    diagnostic_confirmation?: string;
    treatment?: string;
    clinical_outcomes?: string;
    general_and_management?: string;
    clinical_case: string;
  }

  const toSeverity = (response: string): Severity => {
    switch (response) {
      case 'primary':
        return Severity.NON_URGENT;
      case 'secondary':
        return Severity.EXPEDIENT;
      case 'tertiary':
        return Severity.MEDICAL_EMERGENCY;
      default:
        return Severity.NON_URGENT;
    }
  };

  export const toMedBrainUser = (response: MedbrainUserResponse): MedbrainUser => {
    return {
      name: response.user.first_name,
      surname: response.user.last_name,
      professionalType: response.type_of_professional as TypeOfProfessional,
      healthCenter: response.health_center.name,
    };
  };

  export const toClinicalCaseNote = (response: GetClinicalCaseNote.Response): CaseNote => {
    return {
      id: response.id,
      created_at: new Date(),
      created_by: toMedBrainUser(response.created_by),
      clinicalOutcomes: response.clinical_outcomes,
      generalAndManagement: response.general_and_management,
      treatment: response.treatment,
      diagnosticTests: response.diagnostic_tests,
      diagnosisConfirmation: response.diagnostic_confirmation,
      severity: toSeverity(response.level_of_care),
    };
  };
}