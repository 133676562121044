import {
  Accordion,
  AccordionSummary, Avatar,
  Box,
  Container,
  IconButton,
  styled,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import { AccordionTemplateProps, FilledTemplate } from '../accordion-template/Types';
import { AccordionTemplateDetails } from '../accordion-template/accordion-template-details/AccordionTemplateDetails.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AbsenceTag, PresenceTag } from '../../../modules/shared/entities/Tag';
import {TemplateImagesPreview} from '../accordion-template/TemplateImagesPreview.component';
import {Grid} from '../grid/Grid';
import {TemplateImages} from '../accordion-template/TemplateImages.component';
import ReactMarkdown from 'react-markdown';
import { Divider } from '../divider/Divider.component';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  marginTop: '1rem',
  backgroundColor: 'background.paper',
  paddingTop: '0.5rem',
  minHeight: '4.313rem',
  paddingBottom: '0.5rem',

  '&.Mui-expanded': {
    backgroundColor: theme.palette.background.paper,
    marginTop: '1rem',
    '&:last-of-type': {
      marginTop: '1rem',
    }
  },
}));
const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  height: 0,
  '&.Mui-expanded': {
    backgroundColor: theme.palette.background.paper,
  },
}));
const CustomContainer = styled(Container)({
  '&.MuiContainer-root': {
    padding: 0,
    margin: 0,
  },
  '&.MuiContainer-root > p': {
    margin: 10,
    fontSize: '18px',
  },

  display: 'flex',
  alignItems: 'center',
});
const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    marginTop: '0.5rem',
    borderColor: 'transparent',
    backgroundColor: theme.palette.secondary.light,
    height: '2.188rem',
    minWidth: '76px',
    textTransform: 'none',
    marginRight: '0.25rem',
    fontWeight: 'bold',
    padding: '11px 22px',
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  '&.MuiToggleButton-root:hover': {
    backgroundColor: theme.palette.secondary.main,
  },

  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.info.dark,
  },

}));

interface Props extends AccordionTemplateProps {
  onChangeYesNo?: (tag: PresenceTag | AbsenceTag, answer?: 'yes' | 'no' | 'unknown') => void;
  onChangeIsValid?: (isValid: boolean) => void;
}

export const AccordionYesNo = (props: Props) => {
  const [ expanded, setExpanded ] = useState(false);
  const [ hasTag, setHasTag ] = useState<'yes' | 'no' | 'unknown' | undefined>(undefined);
  const [ filledCategories, setFilledCategories ] = useState<boolean>(false);
  const [ noFilledCategories, setNoFilledCategories ] = useState<boolean>(false);
  const hasTemplateCategories = props.template.categories.length > 0;
  const hasTagImages = useMemo(
    () => props.template.tagImages && props.template.tagImages.length > 0,
    [props.template.tagImages],
  );

  const allTemplateCategoriesAreFilled = (filledTemplate: FilledTemplate) => {
    return Object.entries(filledTemplate.categories).length > 0 && !Object.entries(filledTemplate.categories).some(([ key, _ ]) => !filledTemplate.categories[key]);
  };

  const noTemplateCategoriesAreFilled = (filledTemplate: FilledTemplate) => {
    return Object.entries(filledTemplate.categories).length > 0 && !Object.entries(filledTemplate.categories).some(([ key, _ ]) => filledTemplate.categories[key]);
  };

  const canExpand = () => {
    return (hasTag !== 'unknown' && hasTemplateCategories)
      || hasTagImages
      || props.template.description;
  };

  const isAccordionExpanded = () => {
    return canExpand() ? expanded : false;
  };

  const getTemplateStatus = () => {
    if(hasTag === 'no' || hasTag === 'unknown') {
      return 'answered';
    }

    if(isAccordionExpanded() && !hasTag) {
      return 'incomplete';
    }

    if(!hasTag && noFilledCategories) {
      return 'unanswered';
    }

    if (!hasTag && !hasTemplateCategories) {
      return 'unanswered';
    }

    if(!hasTemplateCategories || (filledCategories && hasTag === 'yes')) {
      return 'answered';
    }

    return 'incomplete';
  };

  // @ts-ignore
  const validate = () => {
    return getTemplateStatus() === 'answered';
  };

  const handleOnChange = (filledTemplate: FilledTemplate) => {
    const areAllTemplateCategoriesAreFilled = allTemplateCategoriesAreFilled(filledTemplate);
    const noFilledCategories = noTemplateCategoriesAreFilled(filledTemplate);
    setFilledCategories(areAllTemplateCategoriesAreFilled);
    setNoFilledCategories(noFilledCategories);
    if (areAllTemplateCategoriesAreFilled) {
      setExpanded(false);
      setHasTag('yes');
      const presenceTag: PresenceTag = {
        id: filledTemplate.id!,
        name: filledTemplate.name!,
        tagCategoryOptions: Object.values(filledTemplate.categories).map(category => category!),
      };
      props.onChangeYesNo && props.onChangeYesNo(presenceTag, 'yes');
    }

    props.onChangeIsValid && props.onChangeIsValid(validate());
  };

  const handleOnYesNoChange = (value: 'yes' | 'no' | 'unknown') => {
    setHasTag(value);
    if (value === "no") {
      setExpanded(false);
      return;
    }

    if (hasTemplateCategories) {
      setExpanded(true);
    }
  };

  useEffect(() => {
    if (hasTag === 'yes') {
      if (!hasTemplateCategories) {
        const presenceTag: PresenceTag = {
          id: props.template.id!,
          name: props.template.name!,
          tagCategoryOptions: [],
        };
        props.onChangeYesNo && props.onChangeYesNo(presenceTag, 'yes');
        props.onChangeIsValid && props.onChangeIsValid(validate());
        return;
      }
      props.onChangeIsValid && props.onChangeIsValid(validate());

    }

    if (hasTag === 'no') {
      const absenceTag: AbsenceTag = {
        id: props.template.id!,
        name: props.template.name!,
      };
      props.onChangeYesNo && props.onChangeYesNo(absenceTag, 'no');
      props.onChangeIsValid && props.onChangeIsValid(validate());
      setExpanded(false);
    }

    if (hasTag === 'unknown') {
      const absenceTag: AbsenceTag = {
        id: props.template.id!,
        name: props.template.name!,
      };
      props.onChangeYesNo && props.onChangeYesNo(absenceTag, 'unknown');
      props.onChangeIsValid && props.onChangeIsValid(validate());
      setExpanded(false);
    }
  }, [ hasTag ]);

  useEffect(() => {
    if (getTemplateStatus() === 'answered') {
      props.onChangeIsValid && props.onChangeIsValid(true);
    }
  }, [hasTag, filledCategories, noFilledCategories, expanded]);
  // @ts-ignore
  const renderTemplateImagesPreview = (): React.ReactNode => {
    if (!hasTagImages) {
      return <></>;
    }

    return (
      <TemplateImagesPreview
        template={props.template}
      />
    );
  };

  const renderExpandButton = () => {
    return (
      <IconButton
        disabled={!canExpand()}
        onClick={() => setExpanded(!expanded)}>
        <ExpandMoreIcon
          sx={{ color: canExpand() ? undefined : 'transparent' }}/>
      </IconButton>
    );
  };

  const renderStatusIcon = () => {
    const theme = useTheme();
    const templateStatus = getTemplateStatus();


    return <>
      <Avatar sx={{
        bgcolor: theme.palette.success.light,
        width: '24px',
        height: '24px',
      }}>
        {(templateStatus === 'answered') && <img crossOrigin="anonymous" src={'/assets/CompletedCheck.svg'} alt="Completed" />}
        {(templateStatus === 'unanswered') && <img crossOrigin="anonymous" src={'/assets/UnansweredIcon.svg'} alt="Unanswered" />}
        {(templateStatus === 'incomplete') && <img crossOrigin="anonymous" src={'/assets/InProgressCheck.svg'} alt="Incomplete" />}
      </Avatar>
    </>;
  };

  const cursor = canExpand() ? 'pointer' : 'default';

  return (
    <Box>
      <CustomAccordion style={{borderRadius: '35px', cursor: cursor, marginTop:'24px'}}
                       expanded={isAccordionExpanded()}
                       onClick={() => {if (canExpand()) setExpanded(!expanded);}}
      >
        <CustomAccordionSummary  style={{borderRadius: '35px', cursor: cursor, paddingLeft:'30px'}}
                                 expandIcon={renderExpandButton()}
        >
          <CustomContainer>
            {renderStatusIcon()}
            <p style={{marginLeft: '16px'}}>{props.template.name}</p>
          </CustomContainer>
          <Grid item sx={{marginRight:'1.25rem', marginTop: 'auto', marginBottom: 'auto', marginLeft:'auto'}}>
            {renderTemplateImagesPreview()}
          </Grid>
          <ToggleButtonGroup
            exclusive
            sx={{marginRight:'12px', marginTop: 'auto', marginBottom: 'auto', borderRadius: '25px'}}
            onChange={(event, value) => {handleOnYesNoChange(value as 'yes' | 'no' | 'unknown'); event.stopPropagation(); }}>
            <CustomToggleButton style={{borderTopLeftRadius: '23px', borderBottomLeftRadius: '23px'}}  value="yes" selected={hasTag === 'yes'}>Yes</CustomToggleButton>
            <CustomToggleButton value="no" selected={hasTag === 'no'}>No</CustomToggleButton>
            <CustomToggleButton style={{borderTopRightRadius: '23px', borderBottomRightRadius: '23px'}} value="unknown" selected={hasTag === 'unknown'}>Unknown</CustomToggleButton>
          </ToggleButtonGroup>
        </CustomAccordionSummary>
        <Divider sx={{margin:"0 20px"}}/>
        <div style={{paddingLeft: '50px', paddingRight: '50px'}} onClick={(event) => event.stopPropagation()}>
          <Box sx={{color: '#464646', padding: '0 20px 20px 20px'}}>
            <ReactMarkdown>{props.template.description || ''}</ReactMarkdown>
          </Box>

          <TemplateImages
            tag={props.template}
          />
          <AccordionTemplateDetails
            template={props.template}
            selected={props.selected}
            onChange={handleOnChange}
          />
        </div>


      </CustomAccordion>
    </Box>
  );
};
