export const metaSchema = {
  title: 'Meta collection',
  description: 'Collection for database meta management',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    initial_load: {
      type: 'boolean',
    },
    json_data: {
      type: 'json',
    },
  },
  required: [
    'id',
  ],
};

