import {LocalRepositoryBase} from "./LocalRepositoryBase";
import {LocalRepositoryInterface} from "../../modules/shared/interfaces/LocalRepositoryInterface";
import {upsertEntity, findById, getDB} from "../database/Database";
import {DiseaseArticleDataTransformer} from "../../modules/medical-library/disease-articles/transformers/DiseaseArticleDataTransformer";
import {DiseaseArticleViewModel} from "../../modules/medical-library/disease-articles/features/get-disease-article/view-models/DiseaseArticleViewModel";

const TABLE_NAME = "diseaseArticles";
export class DiseaseArticleLocalRepository extends LocalRepositoryBase implements LocalRepositoryInterface {
    private transformer: DiseaseArticleDataTransformer;

    constructor() {
        super();
        this.transformer = new DiseaseArticleDataTransformer();
    }

    public async findById(id: string): Promise<DiseaseArticleViewModel>{
        return new DiseaseArticleViewModel(
            await findById(TABLE_NAME, id).then((r) => r.toJSON())
        );
    }

    public async upsert(entity: any): Promise<any> {
        await upsertEntity(TABLE_NAME, entity);
        // TODO: Do we need to inject the DiseaseLocalRepository?
        let disease = {'id': entity.id, 'name': entity.name}
        await upsertEntity("disease", disease);
        this.records = entity;

        return this.transformer.transform(entity);
    }

    public async getAll() {
        const db = await getDB();
        return await db[TABLE_NAME]
          .find()
          .exec()
          .then((documents: any[]) => documents.map(document => this.transformer.transform(document.toJSON())))
    }
}
