import { useEffect, useState } from "react";
import {Box, Checkbox} from "@mui/material";
import ReactMarkdown from "react-markdown";
import {ClinicalCaseStep} from '../../shared/entities/ClinicalCaseStep';
import { Button, TemplateImages } from 'infrastructure/components';
import { TagFilters } from '../../medical-library/tags/features/list-tags/TagFilter.enum';
import { TagViewModel } from '../../medical-library/tags/features/list-tags/requests/Tag.viewModel';
import { ClinicalCaseState } from './context/ClinicalCase.context';
import { useClinicalCase } from './context/useClinicalCase';
import { useDispatchClinicalCase } from './context/useDispatchClinicalCase';
import { useUpdateClinicalCase } from "./hooks/useUpdateClinicalCase";
import './PrioritySigns.page.scss';
import { GoBackButton } from "infrastructure/components/go-back-button/GoBackButton";
import { ContinueButton } from "infrastructure/components/continue-button/continue-button";
import { findTagsBy } from "infrastructure/database/Database";

export const PrioritySignsPage = async () => {
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const setClinicalCase = useDispatchClinicalCase();
  const updateClinicalCase = useUpdateClinicalCase().fetch;
  const [previewTag, setPreviewTag] = useState<TagViewModel | undefined>()
  const [selectedTags, setSelectedTags] = useState<TagViewModel[]>([])

  const {data} = await findTagsBy(TagFilters.isPrioritySign);

  const onClickNoSignsDetected = () => {
    clinicalCase.currentStep = ClinicalCaseStep.SELECTING_CASE_DURATION;

    updateClinicalCase({
      clinical_case_state: clinicalCase.currentStep,
    });
    setClinicalCase(clinicalCase);
  };

  const onClickNextStep = () => {
    clinicalCase.currentStep = ClinicalCaseStep.SELECTING_CASE_DURATION;
    clinicalCase.prioritySigns = selectedTags;

    const prioritySigns = clinicalCase.prioritySigns.map((tag) => tag.id);
    let presenceTags = [...prioritySigns];

    if (clinicalCase.emergencySign) {
      presenceTags = [...presenceTags, clinicalCase.emergencySign.id];
    }

    updateClinicalCase({
      clinical_case_state: clinicalCase.currentStep,
      priority_signs: prioritySigns,
      presence_tags: presenceTags,
    });
    setClinicalCase(clinicalCase);
  }

  const goBack = () => {
    clinicalCase.currentStep = ClinicalCaseStep.EMERGENCY_SIGN_DETECTED;
    clinicalCase.emergencySign = null;

    updateClinicalCase({
      clinical_case_state: clinicalCase.currentStep,
      emergency_sign: null,
    });
    setClinicalCase(clinicalCase);
  };

  const isTagSelected = (tag: TagViewModel) => {
    return selectedTags.map(t => t.id).includes(tag.id);
  };

  const onSelectPrioritySign = (tag: TagViewModel) => {
    let newSelectedTags = [...selectedTags];
    if (isTagSelected(tag)) {
      selectedTags.splice(selectedTags.indexOf(tag), 1)
      newSelectedTags = [...selectedTags];
    }
    else {
      newSelectedTags.push(tag);
    }

    setSelectedTags(newSelectedTags);
  };

  useEffect(() => {
    if (data && data[0]){
      setPreviewTag(data[0]);
    }
  }, [data]);

  const filteredTagList = (section: string, selectedTag: TagViewModel) => {
    // @ts-ignore
    return data.filter((tag: TagViewModel) => tag.prioritySignCategory == section).map((tag: TagViewModel) => (
      <Button className={(tag.id == selectedTag.id ? 'selected' : '' ) + " priority-sign-button"}
              key={tag.id}
              onClick={() => setPreviewTag(tag) }
              variant="contained">
        <Checkbox
          sx={{
            color: '#D9AD13',
            '&.Mui-checked': {
              color: '#D9AD13',
            }}}
          checked={isTagSelected(tag)}
          onClick={() => onSelectPrioritySign(tag)}/>
        <div className="priority-sign-name">{tag.name}</div>
        <img crossOrigin="anonymous" style={{
          width: '25px',
          height: '28px',
          marginLeft: '9px',
          fontSize: '12px',
        }} src={(tag.id == selectedTag.id ? '/assets/WhiteArrow.svg' : '/assets/BlackArrow.svg' )}/>
      </Button>
    ));
  }

  if (!data || !previewTag) {
    return <></>;
  }

  return <div>
    <div className="priority-signs-pill">
      <div>PRIORITY SIGNS</div>
    </div>
    <div className="triage-container">
      <div className="sidebar">
        <div className="sign-category">
          <img crossOrigin="anonymous" src={'/assets/intestines.svg'}/>
          <h2>Hydration & Nutrition</h2>
        </div>
        {filteredTagList('hydration_and_nutrition', previewTag)}
        <div className="sign-category">
          <img crossOrigin="anonymous" src={'/assets/thermometer.svg'}/>
          <h2>Vital Signs</h2>
        </div>
        {filteredTagList('vital_signs', previewTag)}
        <div className="sign-category">
          <img crossOrigin="anonymous" src={'/assets/musclePain.svg'}/>
          <h2>Systems Examination</h2>
        </div>
        {filteredTagList('systems_examination', previewTag)}
        <div className="sign-category">
          <img crossOrigin="anonymous" src={'/assets/blood.svg'}/>
          <h2>Accidents</h2>
        </div>
        {filteredTagList('accidents', previewTag)}
      </div>
      <div className="priority-sign">
        <div className="priority-title">
          <div className="warning-icon">
            <img crossOrigin="anonymous" src={'/assets/EmergencySignWarning.svg'}/>
          </div>
          <h2>{previewTag.name}</h2>
        </div>
        <Box sx={{color: '#464646', padding: '0 20px 20px 20px'}}>
          <ReactMarkdown>{previewTag.description || ''}</ReactMarkdown>
        </Box>

        <TemplateImages
          tag={previewTag}
        />
      </div>
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between', marginRight: '60px'}}>
      <GoBackButton onClick={goBack}/>
      <Button
        className="no-priority-button"
        variant="contained"
        onClick={(_) => onClickNoSignsDetected()}>
        No Priority Signs
      </Button>
      <ContinueButton
        onClick={onClickNextStep}
      />
    </div>
  </div>;
};


