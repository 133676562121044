export const medbrainUserSchema = {
  title: 'MedbrainUser Schema',
  description: 'Schema for a Medbrain user',
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
    surname: {
      type: 'string',
    },
    professionalType: {
      type: 'string',
      enum: ['medical_doctor', 'nurse', 'health_worker', 'chemist', 'other'],
    },
    healthCenter: {
      type: 'string',
    },
  },
  required: ['name', 'surname', 'professionalType', 'healthCenter'],
};
