import { Avatar } from "@mui/material";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { findById } from "infrastructure/database/Database";
import {ClinicalCaseLocalRepository} from "../../../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import useTranslation from "infrastructure/i18n/useTranslation";
import { ClinicalCase } from "modules/diagnosis/clinical-case/ClinicalCase";
import { DiseaseArticleResponse } from "modules/medical-library/disease-articles/features/get-disease-article/api/response/DiseaseArticleResponse";
import { PatientInterface } from "modules/shared/entities/PatientInterface";
import { useEffect, useState } from "react";
import { CaseNotesStatus } from "./CaseNotesStatus/CaseNotesStatus";

const useGetClinicalCase = ({ id }: { id: string }) => {
  const [clinicalCase, setClinicalCase] = useState<ClinicalCase | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const clinicalCaseRepository = new ClinicalCaseLocalRepository();

  useEffect(() => {
    if (isLoading) return;
    const fetchPatientsAndClinicalCases = async () => {
      setIsLoading(true);
      const clinicalCases = await clinicalCaseRepository.findByPatientId(id!);

      setClinicalCase(clinicalCases[0]);
      setIsLoading(false);
    }

    fetchPatientsAndClinicalCases();
  }, [id]);

  return { clinicalCase, isLoading };
}

const Diagnosis = ({ clinicalCase }: { clinicalCase: ClinicalCase }) => {
  const patientTranslations = useTranslation('PATIENT_PAGE');
  const [predictedDisease, setPredictedDisease] = useState<DiseaseArticleResponse | undefined>();

  useEffect(() => {
    if (!clinicalCase.predictedDisease) return;

    const getDiseaseArticle = async () => {
      const newPredictedDisease = await findById('diseaseArticles', clinicalCase.predictedDisease!).then(r => r.toJSON());
      setPredictedDisease(newPredictedDisease);
    }

    getDiseaseArticle();
  }, []);

  return (
    <Grid
      container
      alignItems="center"
    >
      <Grid
        item
        marginTop="0.5rem"
        marginLeft="0"
      >
        <Avatar
          variant="square"
          src={predictedDisease?.disease_icon ?? '/assets/PictureTemplate.svg'}
          alt={predictedDisease?.name || patientTranslations.UNDIAGNOSED}
          sx={{
            height: "46px",
            width: "46px",
          }}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          marginLeft="10px"
          marginTop={'10px'}
        >
          <Grid item>
            <Typography
              fontSize="18px"
              fontWeight={900}
              lineHeight="25px"
              variant={'body1'}
            >
              {predictedDisease?.name
                || patientTranslations.UNDIAGNOSED}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ClinicalCaseInfo = ({ patient }: { patient: PatientInterface; }) => {
  const { isLoading, clinicalCase } = useGetClinicalCase({ id: patient.id });

  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      {isLoading ? <CircularProgress /> : null}
      {!isLoading && !clinicalCase ? <p>There is no recent clinical case</p> : null}
      {!isLoading && clinicalCase ? (
        <>
          <Grid item>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Diagnosis clinicalCase={clinicalCase!} />
            </div>
          </Grid>
          <Grid item>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CaseNotesStatus clinicalCase={clinicalCase} />
            </div>
          </Grid>
        </>
      )
        : null
      }
    </Grid>
  );
};