import {Grid} from '@mui/material';
import {ApiCallRender} from 'infrastructure/hooks/apiCallRender';
import {ClinicalCaseState} from './context/ClinicalCase.context';
import {useClinicalCase} from './context/useClinicalCase';
import {useEffect, useState} from 'react';
import {useDispatchClinicalCase} from './context/useDispatchClinicalCase';
import {TagRankScoreViewModel} from './TagRankViewModel';
import {AbsenceTag, PresenceTag} from '../../shared/entities/Tag';
import {
  GetTagTemplateFeature
} from '../../medical-library/tag-templates/features/get-tag-template/GetTagTemplateFeature';
import {ClinicalCaseStep} from '../../shared/entities/ClinicalCaseStep';
import {useUpdateClinicalCase} from './hooks/useUpdateClinicalCase';

import {ContinueButton} from "../../../infrastructure/components/continue-button/continue-button";
import {TagRankService} from '../tag-rank/services/TagRankService';
import {ClinicalCaseLocalRepository} from "../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import './AnsweringStaticQuestions.page.scss';
import { AnsweringYesNoQuestionsStepper } from './components/answering-yes-no-questions-handler/AnsweringYesNoQuestionsStepper';
import { useYesNoQuestionsStepperContext } from './context/YesNoQuestionsStepper/useYesNoQuestionsStepperContext';
import { noAnswerString } from 'config/const';

export interface TagRankAnswers {
  tagRank: Array<TagRankScoreViewModel>;
  presenceTags: Array<PresenceTag>;
  absenceTags: Array<AbsenceTag>;
  uncertainTags: Array<AbsenceTag>;
}

interface AnswersValid {
  [key: string]: boolean;
}

interface AnsweringStaticQuestionsPageProps {
  title: string;
  subtitle: string;
}

export const AnsweringStaticQuestionsPage = ({ title, subtitle }: AnsweringStaticQuestionsPageProps) => {
  const { width } = useWindowSize();
  const updateClinicalCase = useUpdateClinicalCase().fetch;
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const tagRankService = new TagRankService();
  const clinicalCaseRepository =  new ClinicalCaseLocalRepository();

  const [ tagRankAnswers, setTagRankAnswers ] = useState<TagRankAnswers>({
    tagRank: [],
    presenceTags: [],
    absenceTags: [],
    uncertainTags: [],
  });
  const dispatchClinicalCase = useDispatchClinicalCase();
  const [answersValid, setAnswersValid] = useState<AnswersValid>({});
  const [tagRank, setTagRank] = useState<Array<TagRankScoreViewModel>>([]);
  const { 
    updateClinicalCase: updateClinicalCaseCtx,
    buildAnswer, 
    createStaticAnswer,
    isAnswerReady,
  } = useYesNoQuestionsStepperContext();

  const allTagsAreAnswered = (): boolean => {
    return Object.keys(answersValid).length === (tagRankAnswers.presenceTags.length + tagRankAnswers.absenceTags.length + tagRankAnswers.uncertainTags.length);
  };

  const validate = () => {
    return allTagsAreAnswered() && Object.values(answersValid).every(value => value);
  };

  const fetchYesNoQuestions = async () => {
    const tagRankExec = async () => {
      if (!clinicalCase.clinicalCaseId) {
        return;
      }

      const tagRank = await tagRankService.calculateTagQuestions(clinicalCase.clinicalCaseId);

      if (!tagRank || tagRank.length == 0) {
        updateClinicalCase({
          clinical_case_state: ClinicalCaseStep.ANSWERING_DYNAMIC_QUESTIONS,
          current_question_round: 1,
        }).then(() => {
          clinicalCase.currentStep = ClinicalCaseStep.ANSWERING_DYNAMIC_QUESTIONS;
          clinicalCase.currentQuestionRound = 1;
          dispatchClinicalCase(clinicalCase);
        });
      }

      const newAnswersValid: AnswersValid = {};

      const tagRangViewModel: TagRankScoreViewModel[] = tagRank.map(t => ({
        id: t.tag.id,
        name: t.tag.name,
        score: t.tagScore
      }));

      setTagRank(tagRangViewModel);

      tagRank.forEach((tag) => {
        newAnswersValid[tag.tag.id] = false;
      });
      setAnswersValid(newAnswersValid);
      setTagRankAnswers({
        tagRank: [],
        presenceTags: [],
        absenceTags: [],
        uncertainTags: [],
      });
    };

    await tagRankExec();

  };

  const handleOnChangeYesNo = (tag: PresenceTag | AbsenceTag, answer?: 'yes' | 'no' | 'unknown') => {
    setTagRankAnswers(prevState => {
      const presenceTags = prevState.presenceTags.filter(presenceTag => presenceTag.id !== tag.id);
      const absenceTags = prevState.absenceTags.filter(absenceTag => absenceTag.id !== tag.id);
      const uncertainTags = prevState.uncertainTags.filter(absenceTag => absenceTag.id !== tag.id);
      if (answer === 'yes') {
        presenceTags.push(tag as PresenceTag)
      } else if (answer === 'no') {
        absenceTags.push(tag as AbsenceTag)
      } else if (answer === 'unknown') {
        uncertainTags.push(tag as AbsenceTag)
      } else {
        Object.keys(tag).includes('tagCategoryOptions') ? presenceTags.push(tag as PresenceTag) : absenceTags.push(tag as AbsenceTag);
      }

      return {
        tagRank: tagRank,
        presenceTags,
        absenceTags,
        uncertainTags,
      };
    });
  };

  const handleOnChangeIsValid = (tagId: string) => (isValid: boolean) => {
    setAnswersValid(prev => ({ ...prev, [tagId]: isValid }));
  };

  const handleOnClickNextQuestions = () => {
    const questionRoundAnswers = {
      question_round: clinicalCase.currentQuestionRound,
      static_subround: clinicalCase.currentStaticQuestionRound,
      presence_tags: tagRankAnswers.presenceTags.map(tag => tag.id),
      absence_tags: tagRankAnswers.absenceTags.map(tag => tag.id),
      uncertain_tags: tagRankAnswers.uncertainTags.map(tag => tag.id),
      tag_category_options: [ ...new Set((tagRankAnswers.presenceTags
                  .map(pt => pt.tagCategoryOptions)
                  .flat()
                  .filter(tagCategoryOption => tagCategoryOption !== noAnswerString)
          )
      )],
    };

    clinicalCaseRepository.addQuestionRoundAnswer(clinicalCase.clinicalCaseId!, questionRoundAnswers).then(() => {
      clinicalCase.currentStaticQuestionRound += 1;
      clinicalCaseRepository.update(clinicalCase.clinicalCaseId!, {current_static_question_round: clinicalCase.currentStaticQuestionRound}).then(() => {
        fetchYesNoQuestions();
      });
    });
  };

  useEffect(() => {
    const fetchYesNo = async () => {
      await fetchYesNoQuestions();
    }

    fetchYesNo();
  }, []);

  useEffect(() => {
    updateClinicalCaseCtx(clinicalCase);
  }, [clinicalCase]);
  // TODO: Evaluate if we can move this to the context
  useEffect(() => {
    if(isAnswerReady) createStaticAnswer(fetchYesNoQuestions);
  }, [isAnswerReady]);

  if (width <= 900) {
    return (
      <ApiCallRender
        loading={false}
        error={''}>
        <AnsweringYesNoQuestionsStepper
          title={title}
          subtitle={subtitle}
          tagRank={tagRank}
          createAnswer={buildAnswer}
        />
      </ApiCallRender>

    );
  }

  return <Grid style={{ marginRight: '87px', borderRadius: '10px', background: '#F8F8F9', padding: '25px 50px 60px 50px' }}>
    <Grid item xs={12}>
      <p style={{ fontSize: '21px', lineHeight: 'normal', color: 'rgba(0, 0, 0, 0.5)', paddingBottom: '10px' }}>Does your patient have:</p>
      <ApiCallRender
          loading={false}
          error={''}>
        <>
          {  tagRank.map((tag: TagRankScoreViewModel) => (
              <GetTagTemplateFeature
                  id={tag.id}
                  key={tag.id}
                  onChangeYesNo={handleOnChangeYesNo}
                  onChangeIsValid={handleOnChangeIsValid(tag.id)}
                  variant="yesno"
              />))
          }
        </>
      </ApiCallRender>
    </Grid>
    <div style={{ display: 'flex', justifyContent: 'end', marginRight: '60px', marginTop: '2rem' }}>
      <ContinueButton
          disabled={!validate()}
          onClick={handleOnClickNextQuestions}
      />
    </div>
  </Grid>;
};
