import { noAnswerString } from "config/const";
import { CategoryViewModel } from "modules/medical-library/tag-templates/features/get-tag-template/view-models/Category.viewModel";
import { ChangeEvent } from "react";

interface TagCategoryQuestionProps {
  handleTagCategoryAnswer: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  category: CategoryViewModel;
  tagCategoryAnswer: string;
  index: number;
}

export function TagCategoryQuestion({ category, tagCategoryAnswer, handleTagCategoryAnswer, index }: TagCategoryQuestionProps) {
  return (
    <div className='tag-category-question--container'>
      <h2 className='title'>{category.name}</h2>
      <div className='tag-category-answers--container'>
        {
          category.options.map((option) => (
            <div className='tag-category-answer__container' key={option.optionId}>
              <label htmlFor={option.optionId} className={tagCategoryAnswer === option.optionId ? 'is-selected' : ''}>{option.name}</label>
              <input
                type="radio"
                id={option.optionId}
                name="tag-category-answers"
                value={option.optionId}
                checked={tagCategoryAnswer === option.optionId}
                onChange={(e) => handleTagCategoryAnswer(e, index)}
              />
            </div>
          ))
        }
        <div className='tag-category-answer__container'>
          <label htmlFor={`no-answer-${index}`} className={tagCategoryAnswer === noAnswerString ? 'is-selected' : ''}>No answer</label>
          <input
            type="radio"
            id={`no-answer-${index}`}
            name="tag-category-answers"
            value="no-answer"
            checked={tagCategoryAnswer === noAnswerString}
            onChange={(e) => handleTagCategoryAnswer(e, index)}
          />
        </div>
      </div>
    </div>
  )
}