import { DateTime } from 'luxon';
import { createContext } from 'react';
import { Sex } from '../SexEnum';
import { TagContextViewModel } from './TagContext.viewModel';
import {AbsenceTag, PresenceTag} from '../../../shared/entities/Tag';
import {ClinicalCaseStep} from '../../../shared/entities/ClinicalCaseStep';
import { TagViewModel } from 'modules/medical-library/tags/features/list-tags/requests/Tag.viewModel';
import { DiseaseRank } from "../ClinicalCase";
import {TagApiResponse} from "../../../medical-library/tags/features/list-tags/requests/Tag.apiResponse";
import {TagCategoryOptionResponse} from "../features/get-clinical-case/requests/ClinicalCase.apiResponse";
import {TriageQuestionsValues} from "../components/triage/TriageQuestionsValues";

export interface Context {
  clinicalCase: ClinicalCaseState,
  setClinicalCase: (clinicalCase: ClinicalCaseState) => void;
  setClinicalCaseId: (clinicalCaseId: string) => void;
  setBasicInformation: (basicInformation: BasicInformationState) => void;
  setPresentingComplaint: (presentingComplaint: PresentingComplaintState) => void;
  setPhysicalExamination: (physicalExamination: PhysicalExaminationState) => void;
  setClinicalPlan: (clinicalPlan: ClinicalPlanState) => void;
  setDiseaseRankState: (diseaseRank: DiseaseRankState) => void;
}

export interface ClinicalCaseState {
  clinicalCaseId: string | null;
  basicInformation: BasicInformationState;
  presentingComplaint: PresentingComplaintState;
  physicalExamination: PhysicalExaminationState;
  clinicalPlan: ClinicalPlanState;
  diseaseRankState: DiseaseRankState;
  confirmedDiagnostic: string | null;
  currentStep?: ClinicalCaseStep;
  emergencySign: TagViewModel | null;
  prioritySigns: Array<TagViewModel>;
  chiefComplaints: Array<TagViewModel>;
  currentQuestionRound: number;
  currentStaticQuestionRound: number;
  patient: string;
  diagnostic_tests_used: { id: string, name: string }[];
  diagnostic_made_by: { id: string, name: string }[];
  confirmed_diagnostic_labels: { id: string, name: string }[];
  clinical_diagnosis: { id: string, name: string }[];
  confirmed_triage: { id: string, name: string }[];
  triage_made_by: { id: string, name: string }[];
  clinical_interventions: { id: string, name: string }[];
  clinical_outcomes: { id: string, name: string }[];
  case_notes: string;
  predictedDiseaseId: string | null;
  suspectedDiseaseIds: Array<string>;
  questionRoundAnswers: Array<{
    static_subround: number;
    question_round: number;
    presence_tags: Array<TagApiResponse>;
    absence_tags: Array<TagApiResponse>;
    uncertain_tags: Array<TagApiResponse>;
    tag_category_options: Array<TagCategoryOptionResponse>;
  }>;
  triageState: TriageState|null;
  ageGroup: AgeGroup|null;
}

export interface NutritionalStatus {
  muac: null,
  wh: null,
  oedema: null
}

export interface TriageState {
  respiratory_rate?: TriageQuestionsValues|null,
  heart_rate?: TriageQuestionsValues|null,
  fever?: TriageQuestionsValues|null,
  nutritional_status?: NutritionalStatus|null,
  priority_signs?: string[],
  emergency_signs?: string[],
  level?: string|null,
}

export enum AgeGroup {
  NEONATE = 'neonate',
  INFANT = 'infant',
  UNDER5 = 'under5',
  CHILD = 'child',
  ADOLESCENT = 'adolescent',
  ADULT = 'adult',
}

export interface DiseaseRankState {
  calculated: boolean,
  diseaseRank: Array<DiseaseRank>;
}

export interface BasicInformationState {
  name: string;
  surname: string;
  birthdate: DateTime;
  sex: Sex;
}

export interface PresentingComplaintState {
  tags: TagContextViewModel;
}

export interface PhysicalExaminationState {
  tags: TagContextViewModel;
}

export interface ClinicalPlanState {
  presenceTags: Array<PresenceTag>;
  absenceTags: Array<AbsenceTag>;
  uncertainTags: Array<AbsenceTag>;
}

export const initialContext: Context = {
  clinicalCase: {
    clinicalCaseId: null,
    patient: '',
    confirmedDiagnostic: null,
    basicInformation: {
      name: '',
      surname: '',
      sex: Sex.FEMALE,
      birthdate: DateTime.now(),
    },
    presentingComplaint: {
      tags: {},
    },
    physicalExamination: {
      tags: {},
    },
    clinicalPlan: {
      presenceTags: [],
      absenceTags: [],
      uncertainTags: [],
    },
    diseaseRankState: {
      calculated: false,
      diseaseRank: []
    },
    currentStep: undefined,
    emergencySign: null,
    prioritySigns: [],
    chiefComplaints: [],
    currentQuestionRound: 0,
    currentStaticQuestionRound: 0,
    diagnostic_tests_used: [],
    diagnostic_made_by: [],
    confirmed_diagnostic_labels: [],
    clinical_diagnosis: [],
    confirmed_triage: [],
    triage_made_by: [],
    clinical_interventions: [],
    clinical_outcomes: [],
    predictedDiseaseId: null,
    suspectedDiseaseIds: [],
    questionRoundAnswers: [],
    case_notes: '',
    triageState: {
      respiratory_rate: null,
      heart_rate: null,
      fever: null,
      nutritional_status: {
        muac: null,
        wh: null,
        oedema: null,
      },
      priority_signs: [],
      emergency_signs: [],
    },
    ageGroup: null,
  },
  setClinicalCase: (_: ClinicalCaseState) => {
  },
  setClinicalCaseId: (_: string) => {
  },
  setBasicInformation: (_: BasicInformationState) => {
  },
  setPresentingComplaint: (_: PresentingComplaintState) => {
  },
  setPhysicalExamination: (_: PhysicalExaminationState) => {
  },
  setClinicalPlan: (_: ClinicalPlanState) => {
  },
  setDiseaseRankState: (_: DiseaseRankState) => {
  }
};
export const ClinicalCaseContext = createContext<Context>(initialContext);
