import { DateTime } from 'luxon';
import { ClinicalCase } from '../../modules/diagnosis/clinical-case/ClinicalCase';
import { TagCategoryOption } from '../../modules/shared/entities/TagCategoryOption';
import { GetClinicalCaseNote } from './GetClinicalCaseNote';
import { GetClinicalCaseCaseNotes } from './GetClinicalCaseCaseNotes';

export namespace GetPatientClinicalCases {
  export interface Response {
    id: string;
    created_at: string;
    created_by?: { id: string, name: string };
    updated_at: string;
    updated_by?: { id: string, name: string };
    patient?: string | null;
    patient_first_name?: string;
    patient_last_name?: string;
    patient_birth_date?: string;
    patient_sex?: 'M' | 'F';
    predicted_disease?: string;
    confirmed_diagnostic: string | null;
    case_duration: string | null;
    tag_category_options: TagCategoryOption[];
    presence_tags: string[];
    absence_tags: string[];
    disease_rank: string[];
    notes: GetClinicalCaseNote.Response[];
    age_group: string;
    clinical_case_state: string;
  }

  export const toClinicalCase =
    (response: GetPatientClinicalCases.Response): ClinicalCase => ({
      id: response.id,
      patient: response.patient || null,
      createdAt: DateTime.fromISO(response.created_at),
      updatedAt: DateTime.fromISO(response.updated_at),
      predictedDisease: response.predicted_disease || null,
      confirmedDiagnostic: response.confirmed_diagnostic,
      caseDuration: response.case_duration,
      absenceTags: response.absence_tags,
      diseaseRank: response.disease_rank,
      presenceTags: response.presence_tags,
      tagCategoryOptions: response.tag_category_options,
      notes: GetClinicalCaseCaseNotes.toCaseNoteCollection(response.notes),
      ageGroup: response.age_group,
      clinical_case_state: response.clinical_case_state,
    });

}
