import { Tag } from './Tag';
import { TagCategoryOption } from './TagCategoryOption';

export interface DiseaseTagLikelihood {
  absenceLikelihoodRatio: number;
  presenceLikelihoodRatio: number;
  tag: Tag;
}

export interface DiseaseTagCategoryOptionLikelihood {
  likelihoodRatio: number;
  tagCategoryOption: TagCategoryOption;
}

interface IDiseaseArticle {
  id: string;
  name: string;
  overview: string;
  prevalence: number;
  diseaseImage: string;
  diseaseIcon: string;
  tags: DiseaseTagLikelihood[];
  tagCategoryOptions: DiseaseTagCategoryOptionLikelihood[];

  neonatesLikelihoodRatio: number;
  infantsLikelihoodRatio: number;
  under5LikelihoodRatio: number;
  childrenLikelihoodRatio: number;
  adolescentsLikelihoodRatio: number;

  hyperacuteLikelihoodRatio: number;
  acuteLikelihoodRatio: number;
  subacuteLikelihoodRatio: number;
  chronicLikelihoodRatio: number;

  clinicalPlanHomeCare: string;
  clinicalPlanHomeCareImages: [];
  clinicalPlanManagementPlan: string;
  clinicalPlanManagementPlanImages: [];
  clinicalPlanMedication: string;
  clinicalPlanMedicationImages: [];
  clinicalPlanReferral: string;
  clinicalPlanReferralImages: [];
  clinicalPlanTests: string;
  clinicalPlanTestsImages: [];
  sources: [];
}

export class DiseaseArticle {
  id: string;
  name: string;
  overview: string;
  prevalence: number;
  diseaseImage: string;
  diseaseIcon: string;
  tags: DiseaseTagLikelihood[];
  tagCategoryOptions: DiseaseTagCategoryOptionLikelihood[];

  neonatesLikelihoodRatio: number;
  infantsLikelihoodRatio: number;
  under5LikelihoodRatio: number;
  childrenLikelihoodRatio: number;
  adolescentsLikelihoodRatio: number;
  hyperacuteLikelihoodRatio: number;
  acuteLikelihoodRatio: number;
  subacuteLikelihoodRatio: number;
  chronicLikelihoodRatio: number;

  clinicalPlanHomeCare: string;
  clinicalPlanHomeCareImages: [];
  clinicalPlanManagementPlan: string;
  clinicalPlanManagementPlanImages: [];
  clinicalPlanMedication: string;
  clinicalPlanMedicationImages: [];
  clinicalPlanReferral: string;
  clinicalPlanReferralImages: [];
  clinicalPlanTests: string;
  clinicalPlanTestsImages: [];
  sources: [];

  constructor(diseaseArticle: IDiseaseArticle) {
    this.id = diseaseArticle.id;
    this.name = diseaseArticle.name;
    this.overview = diseaseArticle.overview;
    this.prevalence = diseaseArticle.prevalence;
    this.diseaseImage = diseaseArticle.diseaseImage;
    this.diseaseIcon = diseaseArticle.diseaseIcon;
    this.tags = diseaseArticle.tags;
    this.tagCategoryOptions = diseaseArticle.tagCategoryOptions;
    this.neonatesLikelihoodRatio = diseaseArticle.neonatesLikelihoodRatio;
    this.infantsLikelihoodRatio = diseaseArticle.infantsLikelihoodRatio;
    this.under5LikelihoodRatio = diseaseArticle.under5LikelihoodRatio;
    this.childrenLikelihoodRatio = diseaseArticle.childrenLikelihoodRatio;
    this.adolescentsLikelihoodRatio = diseaseArticle.adolescentsLikelihoodRatio;
    this.hyperacuteLikelihoodRatio = diseaseArticle.hyperacuteLikelihoodRatio;
    this.acuteLikelihoodRatio = diseaseArticle.acuteLikelihoodRatio;
    this.subacuteLikelihoodRatio = diseaseArticle.subacuteLikelihoodRatio;
    this.chronicLikelihoodRatio = diseaseArticle.chronicLikelihoodRatio;
    this.clinicalPlanHomeCare = diseaseArticle.clinicalPlanHomeCare;
    this.clinicalPlanHomeCareImages = diseaseArticle.clinicalPlanHomeCareImages;
    this.clinicalPlanManagementPlan = diseaseArticle.clinicalPlanManagementPlan;
    this.clinicalPlanManagementPlanImages = diseaseArticle.clinicalPlanManagementPlanImages;
    this.clinicalPlanMedication = diseaseArticle.clinicalPlanMedication;
    this.clinicalPlanMedicationImages = diseaseArticle.clinicalPlanMedicationImages;
    this.clinicalPlanReferral = diseaseArticle.clinicalPlanReferral;
    this.clinicalPlanReferralImages = diseaseArticle.clinicalPlanReferralImages;
    this.clinicalPlanTests = diseaseArticle.clinicalPlanTests;
    this.clinicalPlanTestsImages = diseaseArticle.clinicalPlanTestsImages;
    this.sources = diseaseArticle.sources;
  }
}
