import { Avatar, Box } from '@mui/material';
import { Grid } from '../grid/Grid';
import { Image } from '@mui/icons-material';
import { Typography } from '../typography/Typography';
import {TagImage} from "../../../modules/medical-library/tags/features/list-tags/requests/Tag.viewModel";
interface Props {
  image: TagImage;
}

export const AccordionImageModalComponent = (props: Props) => {
  return (
    <>
      <Box position={'relative'} paddingBottom={'56.25%'} overflow={'hidden'} mx={2} mt={1} mb={3}>
        <Avatar src={props.image.url}
                sx={{
                  borderRadius: '5px',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  bgcolor: 'transparent',
                  '& .MuiAvatar-img': {
                    objectFit: 'contain',
                  }
                }}>
          <Grid container alignItems={'center'} justifyContent={'center'}
                sx={{ bgcolor: 'secondary.main', width: '100%', height: '100%' }}>
            <Image fontSize="large"/>
          </Grid>
        </Avatar>
      </Box>
      <Typography textAlign="center"
                  display="block"
                  mx="auto"
                  variant="body2"
                  fontSize="18px"
                  width="70%">
        {props.image?.description}
      </Typography>
    </>
  );
};
