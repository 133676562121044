export enum ClinicalCaseStep {
  EMERGENCY_SIGNS = 'EMERGENCY_SIGNS',
  EMERGENCY_SIGN_DETECTED = 'EMERGENCY_SIGN_DETECTED',
  PRIORITY_SIGNS = 'PRIORITY_SIGNS',
  PRIORITY_SIGN_DETECTED = 'PRIORITY_SIGN_DETECTED',
  SELECTING_CASE_DURATION = 'SELECTING_CASE_DURATION',
  SELECTING_CHIEF_COMPLAINTS = 'SELECTING_CHIEF_COMPLAINTS',
  FILLING_CHIEF_COMPLAINTS_TEMPLATES = 'FILLING_CHIEF_COMPLAINTS_TEMPLATES',
  ANSWERING_STATIC_QUESTIONS = 'ANSWERING_STATIC_QUESTIONS',
  ANSWERING_DYNAMIC_QUESTIONS = 'ANSWERING_DYNAMIC_QUESTIONS',
  DIGITAL_HEALTH_CARD_CREATED = 'DIGITAL_HEALTH_CARD_CREATED',
}

