import {ClinicalCaseStep} from "../../shared/entities/ClinicalCaseStep";
import {useDispatchStep} from "./context/useStep";
import {Button} from "../../../infrastructure/components";
import {GoBackButton} from "../../../infrastructure/components/go-back-button/GoBackButton";

export const FillingChiefComplaintsTemplatesPage = () => {
  const dispatchStep = useDispatchStep();

  const onClickNoSignsDetected = () => {
    dispatchStep(ClinicalCaseStep.ANSWERING_STATIC_QUESTIONS);
  };

  const goBack = () => {
    dispatchStep(ClinicalCaseStep.SELECTING_CHIEF_COMPLAINTS);
  }

  return <div>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
      Content Here
    </div>
    <div style={{display: 'flex', justifyContent: 'center', marginRight: '60px'}}>
      <GoBackButton onClick={goBack}/>
      <Button
        variant="contained"
        onClick={(_) => onClickNoSignsDetected()}
        endIcon={<img style={{
          width: '25px',
          height: '28px',
          marginLeft: '9px',
          fontSize: '12px',
        }} src={'/assets/Arrow.svg'}/>}
        sx={{
          textTransform: 'none',
          height: '50px',
        }}>
        Next Step
      </Button>
    </div>
  </div>;
};
