import { useAuth0 } from '@auth0/auth0-react';
import { Launch, Logout, Person } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useApiService } from '../../hooks/useApiService';
import useTranslation from '../../i18n/useTranslation';
import { useUserStore } from '../../store/userStore';
import { Avatar } from '../avatar/Avatar';
import { Grid } from '../grid/Grid';
import { Menu, MenuItem } from '../menu/Menu';
import { Typography } from '../typography/Typography';
import './UserMenu.scss';
import { upsertEntity } from 'infrastructure/database/Database';

type TypeOfProfessional = 'medical_doctor' | 'nurse' | 'health_worker' | 'chemist' | 'other';

export const UserMenu = () => {
  const userClaims = useUserStore(state => state.claims);
  const userTranslations = useTranslation('USERS');
  const authTranslations = useTranslation('AUTHORIZATION');
  const generalTranslations = useTranslation('GENERAL');

  const { logout: logoutAuth0 } = useAuth0();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dropdownOpen = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const logout = () => {
    handleClose();
    useUserStore.persist.clearStorage();
    logoutAuth0({ returnTo: window.location.origin });
  };

  const openMedBrainLanding = () => {
    handleClose();
    window.open('https://medbrain.io', '_blank');
  };

  const { fetchUserInfo } = useApiService();

  const { data, isLoading } = useQuery({
    queryKey: ['me'],
    queryFn: async () => await fetchUserInfo(),
    refetchOnWindowFocus: false,
  });

  const professionalTypes = {
    medical_doctor: userTranslations.MEDICAL_DOCTOR,
    nurse: userTranslations.NURSE,
    health_worker: userTranslations.HEALTH_WORKER,
    chemist: userTranslations.CHEMIST,
    other: userTranslations.OTHER,
  };

  useEffect(() => {
    if(!data) return;

    if(navigator.onLine) {
      upsertEntity('userLogin', data);
    }
  }, [data]);

  return (
    <>
      <Grid container alignItems={'center'}
            maxWidth={'12rem'}
            ml={3}
            sx={{ color: 'white', '&:hover': { cursor: 'pointer' }, marginLeft: 0 }}
            wrap="nowrap"
            onClick={handleOpen}
            width="auto"
      >
        <Grid item xs={'auto'}>
          <Avatar sx={{ border: 2, borderColor: 'white', bgcolor: 'primary.main', height: '50px', width: '50px' }}
                  src={userClaims?.picture || undefined}>
            <Person />
          </Avatar>
        </Grid>
        <Grid item xs pl={2} className="user-menu-name">
          <Typography color={'white'} fontSize={'18px'} fontWeight={'bold'} noWrap>
            {
              data?.user?.name.trim()
                ? data.user.name
                : userClaims?.nickName
            }</Typography>
          <Typography color={'secondary.main'} noWrap fontSize={'15px'} style={{lineHeight: '21px', paddingBottom: '1px'}}>{isLoading
            ? 'Loading...'
            : professionalTypes[data?.type_of_professional as TypeOfProfessional]}</Typography>
        </Grid>
      </Grid>
      <Menu open={dropdownOpen}
            onClose={handleClose}
            anchorEl={anchorEl}
            PaperProps={{ sx: { width: '240px', maxWidth: '100%', color: 'text.secondary', mt: 0.5 } }}
      >
        <MenuItem onClick={openMedBrainLanding}>{generalTranslations.GO_TO_MEDBRAIN}<Launch fontSize="small"
                                                                                            sx={{ ml: 'auto' }} /></MenuItem>
        <Divider />

        <MenuItem onClick={logout}>{authTranslations.LOGOUT}<Logout fontSize="small" sx={{ ml: 'auto' }} /></MenuItem>
      </Menu>
    </>
  );
};
