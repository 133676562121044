export const baseApiUrl: string = process.env.REACT_APP_BASE_API_URL || "";
export const userbackToken: string = process.env.REACT_APP_USERBACK_TOKEN || "";
export const userbackURL: string = process.env.REACT_APP_USERBACK_URL || "";
export const auth0Domain: string = process.env.REACT_APP_AUTH0_DOMAIN || "";
export const auth0ClientId: string =
  process.env.REACT_APP_AUTH0_CLIENT_ID || "";
export const noAnswerString = "no-answer";
export const userInfoLocalStorage = "user-info";
export const patientCreateApiResource: string = "/diagnosis/patient/";
export const clinicalCaseCreateApiResource: string = "/diagnosis/clinical_case/clinical_case";
export const clinicalCaseUpdateApiResource: string = "/diagnosis/clinical_case/%uuid/";
export const clinicalInterventionGetApiResource: string = "/case_note/clinical_intervention";
export const clinicalOutcomeGetApiResource: string = "/case_note/clinical_outcome";
export const diseaseGetApiResource: string = "/medical_library/disease_article/diseases";
export const diseaseArticleGetApiResource: string = "/medical_library/disease_article/disease_articles";
export const healthCareProfessionalTypeGetApiResource: string = "/case_note/health_care_professional_type";
export const medbrainUserGetApiResource: string = "/user_management/medbrain_users";
export const questionRoundGetApiResource: string = "/diagnosis/question_round";
export const tagGetApiResource: string = "/medical_library/tag/tags";
export const tagCategoryGetApiResource: string = "/medical_library/tag_template/tag_categories";
export const tagCategoryOptionGetApiResource: string = "/medical_library/tag_template/tag_category_options"
export const triageLevelGetApiResource: string = "/case_note/triage";
export const patientGetApiResource: string = "/diagnosis/patient";
export const clinicalCaseGetApiResource: string = "/diagnosis/clinical_case/clinical_cases";
export const diagnosticTestGetApiResource: string = "/case_note/diagnostic_test";
export const medicalLibraryEntities = [
    {entityName: "Tag", hasMedia: true},
    {entityName: "TagCategory", hasMedia: false},
    {entityName: "TagCategoryOption", hasMedia: true},
    {entityName: "DiagnosisOutcomeConfig", hasMedia: false},
    {entityName: "TriageLevel", hasMedia: false},
    {entityName: "QuestionRound", hasMedia: false},
    {entityName: "HealthcareProfessionalType", hasMedia: false},
    {entityName: "ClinicalOutcome", hasMedia: false},
    {entityName: "ClinicalIntervention", hasMedia: false},
    {entityName: "DiagnosticTest", hasMedia: false},
    {entityName: "MedbrainUser", hasMedia: false}, // User's profile picture is not stored in S3
    {entityName: "DiseaseArticle", hasMedia: true},
];
export const diagnosisEntities = [
    {entityName: "Patient", hasMedia: true},
    {entityName: "ClinicalCase", hasMedia: true}
];
export const medicalLibraryLastEntityToBeUpdated: string = medicalLibraryEntities[medicalLibraryEntities.length - 1].entityName;
export const neoNatalChiefComplaintId: string = '5c27ba3a-74d6-4e0f-be95-33f62d47deac';

export const sentryDsn: string = process.env.REACT_APP_MEDBRAIN_SENTRY_DSN || "";
export const appUrl: string = process.env.REACT_APP_MEDBRAIN_URL || "";

export const CACHE_NAME = 'medbrain-offline-v1-32.0';