import { useContext } from "react";
import { ProgressBarContext } from "./ProgressBarContext";

export function useProgressBarContext() {
  const context = useContext(ProgressBarContext);

  if(!context) {
    throw new Error(
      "useProgressBarContext must be used within a ProgressBarContextProvider"
    )
  }

  return context;
}