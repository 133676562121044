import './AnsweringYesNoQuestionsStepper.scss';
import { TagRankScoreViewModel } from 'modules/diagnosis/clinical-case/TagRankViewModel';
import { YesNoQuestion } from './YesNoQuestion/StaticQuestion';
import {useEffect, useState} from 'react';
import { useYesNoQuestionsStepperContext } from '../../context/YesNoQuestionsStepper/useYesNoQuestionsStepperContext';
import {ModalLoader} from "../../../../../infrastructure/components/modal/ModalLoader";

interface AnsweringYesNoQuestionsStepperProps {
  title?: string;
  subtitle?: string;
  tagRank: TagRankScoreViewModel[];
  createAnswer: any;
}

export const AnsweringYesNoQuestionsStepper = ({ tagRank }: AnsweringYesNoQuestionsStepperProps) => {
  const {
    tagQuestionsWave,
    updateTagRank,
    updateIsAnswerReady
  } = useYesNoQuestionsStepperContext();
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if(!tagRank) return;

    updateTagRank(tagRank);
  }, [tagRank]);

  useEffect(() => {
    if(tagRank.length === 0) return;
    if(tagQuestionsWave.tagQuestionAnswers.length < tagRank.length) return;

    updateIsAnswerReady(true);
  }, [tagQuestionsWave]);

  if (tagRank.length === 0) return <ModalLoader open={open} onClose={handleClose}></ModalLoader>

  return (
    <YesNoQuestion
      tag={tagRank.find(({ id }) => id === tagQuestionsWave.currentTagQuestionId)}
      key={tagQuestionsWave.currentTagQuestionId}
      step={tagQuestionsWave.step}
    />
  )
};
