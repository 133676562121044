import { Image } from '@mui/icons-material';
import { AccordionDetails, Box, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Avatar } from '../../avatar/Avatar';
import { AccordionTemplateDetailsProps, FilledTemplate } from '../Types';
import { Category, CategoryOption } from '../../Types';
import { AccordionImageModalComponent } from '../AccordionImageModal.component';
import { useAppUIStore } from '../../../store/appUIStore';
import { Grid } from '../../grid/Grid';
import { noAnswerString } from 'config/const';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#262A31',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  '&.MuiToggleButton-root:hover': {
    backgroundColor: theme.palette.secondary.main,
  },

  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.info.dark,
  },
}));

export const AccordionTemplateDetails = (props: AccordionTemplateDetailsProps) => {
  const openModal = useAppUIStore((state) => (state.openModal));

  const buildInitialTemplate = () => {
    const categoryIds = props.template.categories
      .map(category => category.categoryId)
      .reduce((accumulator, categoryId) => ({
        ...accumulator,
        [categoryId]: props.selected ? props.selected.categories[categoryId as keyof FilledTemplate] : undefined,
      }), {});
    return {
      id: props.template.id,
      name: props.template.name,
      categories: categoryIds,
    };
  };

  const [filledTemplate, setFilledTemplate] = useState<FilledTemplate>(buildInitialTemplate());

  const handleOnChange = (categoryId: string, newValue: string) => () => {
    setFilledTemplate((prevState: FilledTemplate) => {
      const categories = { ...prevState.categories, [categoryId]: newValue };
      return { ...prevState, categories };
    });
  };

  useEffect(() => props.onChange(filledTemplate), [ filledTemplate ]);

  const renderCategoryOptionImage = (option: CategoryOption) => {

    return (
      <Avatar
        src={option.imageUrl}
        sx={{
          marginLeft: '8px',
          width: '30px',
          height: '30px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          openModal(<AccordionImageModalComponent image={{
            url: option.imageUrl!,
            description: option.imageDescription,
          }}/>);
        }}>
        <Image/>
      </Avatar>
    );
  };

  const renderCategoryOptionButton = (categoryOption: CategoryOption) => {
    return (
      <Grid
        container
        justifyContent={categoryOption.imageUrl ? 'space-between' : 'center'}
        alignItems="center"
        wrap="nowrap"
      >
        <Typography
          variant="body2"
          fontSize="14px"
          fontWeight={500}
          lineHeight="19px"
          maxWidth="98%"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {categoryOption.name}
        </Typography>
        {renderCategoryOptionImage(categoryOption)}
      </Grid>
    );
  };

  const renderCategoryOptionsToggleButtons = (category: Category) => {
    const categoryOptionsWithNoAnswerOptionAppended = category.options.concat({
      optionId: noAnswerString,
      name: 'No answer',
      imageUrl: undefined,
    });

    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          width: '100%',
          columnGap: '36px',
          rowGap: '10px',
          marginTop: '10px',
        }}
      >
        {
          categoryOptionsWithNoAnswerOptionAppended.map((categoryOption) => {
            return (
              <CustomToggleButton
                key={`${categoryOption.optionId}`}
                value={categoryOption.optionId}
                onClick={handleOnChange(category.categoryId, categoryOption.optionId)}
                sx={{
                  width: '100%',
                  borderRadius: '90px',
                  borderColor: '#DBD8E6',
                  height: '46px',
                  textTransform: 'none',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '20px',
                  paddingRight: categoryOption.imageUrl ? '12px' : '20px',
                }}
                selected={filledTemplate.categories[category.categoryId] === categoryOption.optionId}
              >
                {renderCategoryOptionButton(categoryOption)}
              </CustomToggleButton>
            );
          })
        }
      </Box>
    );
  };

  const renderCategory = (category: Category, index: number): React.ReactNode => {
    return <Box key={category.categoryId}>
      <Typography
        fontSize="12px"
        fontWeight={900}
        lineHeight="16px"
        letterSpacing="0.1rem"
        color="#AAAAAA"
        textTransform="uppercase"
        sx={{ marginTop: index === 0 ? 0 : '30px', paddingBottom: '7px'}}
      >
        {category.name}
      </Typography>
      <ToggleButtonGroup
        exclusive
        value={filledTemplate.categories[category.categoryId]}
        sx={{ flexWrap: 'wrap', width: '100%' }}
      >
        {renderCategoryOptionsToggleButtons(category)}
      </ToggleButtonGroup>
    </Box>;
  };

  return (
    <AccordionDetails>
      {props.template.categories.map(renderCategory)}
    </AccordionDetails>
  );
};
