import { Avatar, Box, Card, CircularProgress, Grid, Typography } from 'infrastructure/components';
import useTranslation from 'infrastructure/i18n/useTranslation';
import { ClinicalCase } from '../../clinical-case/ClinicalCase';
import './ClinicalCaseList.scss';
import {useEffect, useState} from "react";
import {findById} from "../../../../infrastructure/database/Database";
import {
  DiseaseArticleResponse
} from "../../../medical-library/disease-articles/features/get-disease-article/api/response/DiseaseArticleResponse";

interface ClinicalCaseListProps {
  isLoading?: boolean;
  clinicalCases?: ClinicalCase[];
  onClinicalCaseSelect?: (clinicalCase: ClinicalCase) => void;
}

export const ClinicalCaseList = (
  {
    clinicalCases = [] as ClinicalCase[],
    onClinicalCaseSelect,
    isLoading = false,
  }: ClinicalCaseListProps,
) => {
  const patientTranslations = useTranslation('PATIENT_PAGE');

  const renderDiagnosis = (clinicalCase: ClinicalCase, index: number) => {
    const [predictedDisease, setPredictedDisease] = useState<DiseaseArticleResponse | undefined>();

    useEffect(() => {
      if (clinicalCase.clinical_case_state !== 'DIGITAL_HEALTH_CARD_CREATED') return;
      if (!clinicalCase.predictedDisease) return;

      console.log(clinicalCase.diseaseRank);

      const getDiseaseArticle = async () => {
        const newPredictedDisease = await findById('diseaseArticles', clinicalCase.predictedDisease!).then(r => r.toJSON());
        setPredictedDisease(newPredictedDisease);
      }

      getDiseaseArticle();
    }, []);

    return (
      <Grid
        container
        alignItems="center"
      >
        <Grid item>
          <Typography
            fontSize="18px"
            fontWeight={900}
            lineHeight="25px"
            variant={'body1'}
          >
            {`${index + 1}.`}
          </Typography>
        </Grid>
        <Grid
          item
          marginLeft="20px"
        >
          <Avatar
            variant="square"
            src={predictedDisease?.disease_icon ?? '/assets/PictureTemplate.svg'}
            alt={predictedDisease?.name || patientTranslations.UNDIAGNOSED}
            sx={{
              height: "46px",
              width: "46px",
            }}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            marginLeft="20px"
          >
            <Grid item>
              <Typography
                fontSize="18px"
                fontWeight={900}
                lineHeight="25px"
                variant={'body1'}
              >
                {predictedDisease?.name
                  || patientTranslations.UNDIAGNOSED}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderTimestamps = (clinicalCase: ClinicalCase) => {
    return (
      <div className="clinical-case-timestamps">
        <span className="clinical-case-created">
          <span className="clinical-case-created-on-text">
            {patientTranslations.CREATED_AT}
          </span>
          <span className="clinical-case-created-on-value">
            {`\u00a0${clinicalCase.createdAt.toFormat('dd/MM/yyyy')}`}
          </span>
        </span>
      </div>
    );
  };

  const render = (clinicalCase: ClinicalCase, index: number) => {
    const marginTop: string = index == 0 ? '0px' : '18px';

    return (
      <Card
        key={clinicalCase.id}
        sx={{
          borderRadius: "10px",
          border: "1px solid #DBD8E6",
          padding: "20px 30px",
          marginTop: marginTop,
          '&:hover': {
            cursor: "pointer",
          }
        }}
        onClick={() => {
          onClinicalCaseSelect && onClinicalCaseSelect(clinicalCase);
        }}
      >
        <Grid
          container
          key={clinicalCase.id}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {renderDiagnosis(clinicalCase, index)}
          </Grid>

          <Grid item>
            {renderTimestamps(clinicalCase)}
          </Grid>
        </Grid>
      </Card>
    );
  };

  return <Box>
    {
      !isLoading ?
        clinicalCases.length
          ? clinicalCases.map(render)
          : patientTranslations.THERE_ARENT_ANY_CLINICAL_CASES_YET
        : <CircularProgress />
    }
  </Box>;
};
