import MUIModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import '../../../../src/modules/diagnosis/clinical-case/ModalLoader.component.scss';

interface LoaderProps {
    open: boolean;
    onClose: (reason: string) => void;
}

export const ModalLoader = (props: LoaderProps) => {
    return (
        <MUIModal
            open={props.open}
            onClose={(_, reason) => props.onClose(reason)}
            hideBackdrop={true}
        >
            <Fade in={props.open}>
                <Box className={'loader-box'}>
                    <img crossOrigin="anonymous" className={'loader-img'} alt={'medbrain logo'} src={'/assets/medbrain_logo_loader.png'}/>
                    <p className={'loader-text'}>Running Diagnostic Algorithms</p>
                    <div className={"dot-carousel"}></div>
                </Box>
            </Fade>
        </MUIModal>
    );
};