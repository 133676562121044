import { createTheme, Theme } from '@mui/material';
import { lightBlue, orange, red } from '@mui/material/colors';

declare module '@mui/material' {
  interface Theme {
    paletteExtension?: {
      medbrainColors?: {
        medbrainEmphasisDark: string,
      };
    };
  }

  interface ThemeOptions {
    paletteExtension?: {
      medbrainColors?: {
        medbrainEmphasisDark: string,
      };
    };
  }
}

export function configureTheme(): Theme {
  const customTheme = createTheme({
    typography: {
      fontFamily: 'Avenir',
      h1: {
        fontWeight: 400,
        fontSize: '52px',
        lineHeight: '71.03px',
        alignContent: 'center',
        color: '#262A31',
      },
      h2: {
        fontWeight: 400,
        fontSize: '40px',
        lineHeight: '54.4px',
        color: '#262A31',
      },
      body1: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        fontStyle: 'normal',
        color: '#262A31',
      }
    },
    paletteExtension: {
      medbrainColors: {
        medbrainEmphasisDark:'#0E2939',
      },
    },
    palette: {
      primary: {
        main: '#4265CF',
        light: '#6DD7FC',
        dark: '#007698',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#ECEFF1',
        main: '#CFD8DC',
        dark: '#808E95',
      },
      error: {
        light: 'rgba(182, 35, 35, 0.1)',
        main: red[300],
        dark: '#B62323',
      },
      warning: {
        light: 'rgba(182, 167, 35, 0.1)',
        main: orange[300],
        dark: '#B6A723',
      },
      info: {
        light: lightBlue[100],
        main: lightBlue[200],
        dark: lightBlue[700],
      },
      success: {
        light: '#e7faf6',
        main: '#6ccfbe',
        dark: '#23B69D',
      },
      background: {
        default: '#f5f4f9',
        paper: '#FFFFFF',
      },
    },
  });
  customTheme.shadows[1] = '4px -4px 10px rgba(84, 249, 150, 0.12), 4px 4px 10px rgba(45, 220, 255, 0.09)';

  return customTheme;
}
