import {LocalRepositoryBase} from "./LocalRepositoryBase";
import {addEntity, findById, getDB, updateById, upsertEntity} from "../database/Database";
import {Utils} from "../../modules/shared/Utils";
import {ClinicalCaseStep} from "../../modules/shared/entities/ClinicalCaseStep";
import {GetPatientClinicalCases} from "../api"; // TODO: Refactor this (why is it placed under "api"? idk
import {
    ClinicalCaseViewModel
} from "../../modules/diagnosis/clinical-case/features/get-clinical-case/view-models/ClinicalCase.viewModel";
import {
    ClinicalCaseResponse,
    TagCategoryOptionResponse
} from "../../modules/diagnosis/clinical-case/features/get-clinical-case/requests/ClinicalCase.apiResponse";
import {DiagnosisCalculationService} from "../../modules/diagnosis/disease-rank/services/DiagnosisCalculationService";
import {
    UpdateClinicalCaseApiRequest
} from "../../modules/diagnosis/clinical-case/features/update-clinical-case/UpdateClinicalCase.apiRequest"; // TODO: Refactor this (why is it placed under "api"? idk
import {
    QuestionRoundAnswer
} from "../../modules/diagnosis/clinical-case/features/create-question-round-answer/CreateQuestionRoundAnswerApiCall"; // TODO: Refactor this (why is it placed under "api"? idk
import {ClinicalCase} from "../../modules/diagnosis/clinical-case/ClinicalCase";
import {TagApiResponse} from "../../modules/medical-library/tags/features/list-tags/requests/Tag.apiResponse"; // TODO: Refactor this (why is it placed under "api"? idk
import {PatientLocalRepository} from "./PatientLocalRepository";
import {TagLocalRepository} from "./TagLocalRepository";
import {ChangelogLocalRepository} from "./ChangelogLocalRepository";
import {EventType} from "../../modules/sync/changelog/ChangelogRecordInterface";
import {LocalRepositoryInterface} from "../../modules/shared/interfaces/LocalRepositoryInterface";
import {AgeGroup, TriageState} from "../../modules/diagnosis/clinical-case/context/ClinicalCase.context";
import {neoNatalChiefComplaintId} from "../../config/const";
import {TriageQuestionsValues} from "../../modules/diagnosis/clinical-case/components/triage/TriageQuestionsValues";

const TABLE_NAME = "clinicalCases";
export class ClinicalCaseLocalRepository extends LocalRepositoryBase implements LocalRepositoryInterface {
    private patientRepository: PatientLocalRepository;
    private tagRepository: TagLocalRepository;
    private changelog: ChangelogLocalRepository;

    constructor() {
        super();
        this.patientRepository = new PatientLocalRepository();
        this.tagRepository = new TagLocalRepository();
        this.changelog = new ChangelogLocalRepository();
    }

    public async getAll() {
        const db = await getDB();
        return await db[TABLE_NAME]
          .find()
          .exec()
          .then((documents: any[]) => documents.map(document => document.toJSON()))
    }

    // TODO: old useApiService.getLocalClinicalCase
    public async findById(id: string): Promise<ClinicalCaseViewModel> {
        const data = await findById(TABLE_NAME, id).then((clinicalCase) =>
            clinicalCase.toJSON()
        );
        return new ClinicalCaseViewModel({ ...data, patient: data.patient.id });
    }

    //TODO: old useApiService.getLocalPatientClinicalCases
    public async findByPatientId(patientId: string): Promise<ClinicalCase[]> {
        const db = await getDB();
        const clinicalCaseDocuments = await db[TABLE_NAME]
            .find()
            .where("patient.id")
            .eq(patientId)
            .sort({ created_at: -1 })
            .exec();

        return clinicalCaseDocuments.map((doc: any) => GetPatientClinicalCases.toClinicalCase(doc.toJSON()));
    }

    // TODO: old useApiService.deleteLocalClinicalCaseChiefComplains
    public async deleteClinicalCaseChiefComplains(newClinicalCase: ClinicalCaseResponse) {
        newClinicalCase.clinical_case_state =
            ClinicalCaseStep.SELECTING_CHIEF_COMPLAINTS;
        let chiefComplaints = newClinicalCase.chief_complaints;

        newClinicalCase.chief_complaints = [];
        newClinicalCase.presence_tags = newClinicalCase.presence_tags.filter(
            (tag) => !!chiefComplaints.find((ccTag) => ccTag.id === tag.id)
        );

        await this._updateAndRecalculateRank(
            newClinicalCase.id,
            newClinicalCase
        );

        return;
    }

    // TODO: old useApiService.updateLocalClinicalCase
    public async update(id: string, body: UpdateClinicalCaseApiRequest) {
        const localClinicalCase = await findById(TABLE_NAME, id).then((c) =>
            c.toJSON()
        );

        // Tag Category Options update
        if (body.tag_category_options) {
            const tcoAlreadyAnswered = localClinicalCase.tag_category_options.map(
                (tcor: TagCategoryOptionResponse) => tcor.option
            );

            const nonAnsweredTco = body.tag_category_options.filter(
                (tco) => !tcoAlreadyAnswered.includes(tco)
            );

            for (let tcoId of nonAnsweredTco) {
                const tagCategoryOption = await findById(
                    "tagCategoryOptions",
                    tcoId
                ).then((t) => t.toJSON());

                const newAnswer = {
                    id: Utils.generateUUID(),
                    option: tcoId,
                    tag_category: tagCategoryOption.tag_category,
                };

                localClinicalCase.tag_category_options.push(newAnswer);
            }
        }

        if (body.presence_tags) {
            const existingPresenceTags = localClinicalCase.presence_tags.map(
                (existingPresenceTag: TagApiResponse) => existingPresenceTag.id
            );
            const unmatchedPresenceTags = body.presence_tags.filter(
                (presenceTag) => !existingPresenceTags.includes(presenceTag)
            );

            for (let unmatchedPresenceTagId of unmatchedPresenceTags) {
                const tag = await findById("tags", unmatchedPresenceTagId).then(
                    (tags) => tags.toJSON()
                );
                localClinicalCase.presence_tags.push(tag);
            }
        }

        if (body.emergency_sign) {
            localClinicalCase.emergency_sign = await findById(
                "tags",
                body.emergency_sign
            ).then((t) => t.toJSON());
        }

        if (body.priority_signs) {
            localClinicalCase.priority_signs = [];

            for (let prioritySignId of body.priority_signs) {
                const tag = await findById("tag", prioritySignId).then((t) =>
                    t.toJSON()
                );

                localClinicalCase.priority_signs.push(tag);
            }
        }

        if (body.clinical_case_state) {
            localClinicalCase.clinical_case_state = body.clinical_case_state;
        }

        if (body.case_duration) {
            localClinicalCase.case_duration = body.case_duration;
        }

        if (body.chief_complaints) {
            localClinicalCase.chief_complaints = [];

            for (let ccId of body.chief_complaints) {
                const tag = await findById("tags", ccId).then((t) => t.toJSON());

                localClinicalCase.chief_complaints.push(tag);
            }
        }

        if (body.current_question_round) {
            localClinicalCase.current_question_round = body.current_question_round;
        }

        if (body.current_static_question_round) {
            localClinicalCase.current_static_question_round =
                body.current_static_question_round;
        }

        if (body.diagnostic_made_by) {
            localClinicalCase.diagnostic_made_by = body.diagnostic_made_by;
        }

        if (body.clinical_diagnosis) {
            localClinicalCase.clinical_diagnosis = body.clinical_diagnosis;
        }

        if (body.diagnostic_tests_used) {
            localClinicalCase.diagnostic_tests_used = body.diagnostic_tests_used;
        }

        if (body.confirmed_diagnostic_labels) {
            localClinicalCase.confirmed_diagnostic_labels =
                body.confirmed_diagnostic_labels;
        }

        if (body.confirmed_triage) {
            localClinicalCase.confirmed_triage = body.confirmed_triage;
        }

        if (body.triage_made_by) {
            localClinicalCase.triage_made_by = body.triage_made_by;
        }

        if (body.clinical_interventions) {
            localClinicalCase.clinical_interventions = body.clinical_interventions;
        }

        if (body.clinical_outcomes) {
            localClinicalCase.clinical_outcomes = body.clinical_outcomes;
        }

        if (body.case_notes) {
            localClinicalCase.case_notes = body.case_notes;
        }

        if (body.admission_decision_date) {
            localClinicalCase.admission_decision_date =
                body.admission_decision_date;
        }

        if (body.clinical_outcomes_date) {
            localClinicalCase.clinical_outcomes_date = body.clinical_outcomes_date;
        }

        if (body.triage_state) {
            localClinicalCase.triage_state = body.triage_state;
        }

        localClinicalCase.triage_state = await this._updateAndRecalculateTriageLevel(localClinicalCase.triage_state)

        await this._updateAndRecalculateRank(id, localClinicalCase);

        return await this.findById(id);
    }

    public async upsert(entity: any): Promise<void> {
        await upsertEntity(TABLE_NAME, entity);
        this.records = entity;

        return entity;
    }

    public async upsertKeepingLocalIfIsNewest(entity: any): Promise<void> {
        let localEntity = await findById(TABLE_NAME, entity.id)
        try {
            localEntity = localEntity.toJSON()
        } catch {
            localEntity = undefined
        }
        // Si existe el clinical case ya guardado en local
        if (localEntity) {
            // si el updated_at local es posterior al campo updated_at del server entonces hago un skip.
            let localEntityDate = new Date(localEntity.updated_at)
            let newEntityVersion = new Date(entity.updated_at)
            if ( localEntityDate > newEntityVersion) {
                return entity
            }
        }
        await upsertEntity(TABLE_NAME, entity);
        this.records = entity;

        return entity;
    }

    // TODO: old useApiService.createLocalClinicalCase
    public async create(patientId: string, clinicalCaseState: string = ClinicalCaseStep.EMERGENCY_SIGNS): Promise<string> {
        const patient = await this.patientRepository.findById(patientId);
        const date = new Date();
        const dateOfBirth = `${patient.dateOfBirth.getFullYear()}-${(
            patient.dateOfBirth.getMonth() + 1
        )
            .toString()
            .padStart(2, "0")}-${patient.dateOfBirth
            .getDate()
            .toString()
            .padStart(2, "0")}`;

        let age_group = this._getAgeGroupFromBirthDate(patient.dateOfBirth)
        let default_chief_complaints = []
        let default_presence_tags = []

        if (age_group === AgeGroup.NEONATE) {
            let tagRepo = new TagLocalRepository()
            let neonateTag = await tagRepo.findById(neoNatalChiefComplaintId)
            if (neonateTag) {
                default_chief_complaints.push(neonateTag)
                default_presence_tags.push(neonateTag)
            }
        }

        const clinicalCase = {
            id: Utils.generateUUID(),
            created_at: date.toISOString(),
            updated_at: date.toISOString(),
            tag_category_options: [],
            absence_tags: [],
            predicted_disease: null,
            confirmed_diagnostic: null,
            case_duration: null,
            disease_rank: [],
            notes: [],
            age_group: age_group,
            presence_tags: default_presence_tags,
            uncertain_tags: [],
            chief_complaints: default_chief_complaints,
            priority_signs: [],
            clinical_case_state: clinicalCaseState,
            patient: {
                id: patient.id,
                created_at: patient.createdAt,
                updated_at: patient.updatedAt,
                first_name: patient.firstName,
                last_name: patient.lastName,
                sex: patient.sex,
                date_of_birth: dateOfBirth,
                medbrain_id: patient.medbrainId,
                created_by: {
                    id: patient.createdBy.id,
                    name: patient.createdBy.name,
                },
                is_simulated: patient.isSimulated,
            },
            suspected_diseases: [],
            current_question_round: 0,
            current_static_question_round: 0,
            question_rounds_answers: [],
            triage_state: {
                emergency_signs: [],
                respiratory_rate: null,
                fever: null,
                heart_rate: null,
                nutritional_status: {
                    muac: null,
                    wh: null,
                    oedema: null,
                },
                priority_signs: []
            },
        };

        await addEntity(TABLE_NAME, clinicalCase);
        await this.changelog.insert(clinicalCase, EventType.ClinicalCaseCreated);

        if (age_group === AgeGroup.NEONATE) {
            const diagnosisService = new DiagnosisCalculationService();
            await diagnosisService.calculateDiseaseRanks(clinicalCase.id);
        }

        return clinicalCase.id;
    }

    // TODO: old useApiService.createLocalAnswer
    public async addQuestionRoundAnswer(clinicalCaseId: string, body: QuestionRoundAnswer): Promise<void> {
        const {
            presence_tags: presenceTagsIds,
            absence_tags: absenceTagsIds,
            uncertain_tags: uncertainTagsIds,
            tag_category_options: tagCategoryOptionsIds,
        } = body;
        const presenceTags = await this.tagRepository.tagsIdsToTags(presenceTagsIds);
        const absenceTags = await this.tagRepository.tagsIdsToTags(absenceTagsIds);
        const uncertainTags = await this.tagRepository.tagsIdsToTags(uncertainTagsIds);
        const tcos = await this.tagRepository.tagCategoryOptionsIdsToTags(tagCategoryOptionsIds);

        const questionRoundsAnswer: any = {
            absence_tags: absenceTags,
            presence_tags: presenceTags,
            uncertain_tags: uncertainTags,
            clinical_case: clinicalCaseId,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            question_round: body.question_round,
            tag_category_options: tcos,
            id: Utils.generateUUID(),
            static_subround: body.static_subround,
        };

        const rxdbClinicalCase = await findById(TABLE_NAME, clinicalCaseId);

        const newClinicalCase: ClinicalCaseResponse = rxdbClinicalCase.toJSON();

        newClinicalCase.question_rounds_answers.push(questionRoundsAnswer);

        newClinicalCase.presence_tags = [
            ...newClinicalCase.presence_tags,
            ...presenceTags,
        ];
        newClinicalCase.absence_tags = [
            ...newClinicalCase.absence_tags,
            ...absenceTags,
        ];
        newClinicalCase.uncertain_tags = [
            ...newClinicalCase.uncertain_tags,
            ...uncertainTags,
        ];
        newClinicalCase.tag_category_options = [
            ...newClinicalCase.tag_category_options,
            ...tcos,
        ];

        newClinicalCase.updated_at = new Date().toISOString();

        // Guardar
        await updateById(TABLE_NAME, clinicalCaseId, newClinicalCase);

        const diagnosisService = new DiagnosisCalculationService();

        if (newClinicalCase.chief_complaints) {
            await diagnosisService.calculateDiseaseRanks(clinicalCaseId);
        }
    }

    // TODO: old useApiService.deleteLastLocalAnswer
    public async deleteLastAnswer(clinicalCaseId: string): Promise<void> {
        const rxdbClinicalCase = await findById(TABLE_NAME, clinicalCaseId);
        const newClinicalCase: ClinicalCaseResponse = rxdbClinicalCase.toJSON();

        let questionRound = newClinicalCase.current_question_round;
        let staticQuestionRound = newClinicalCase.current_static_question_round;

        if (questionRound !== 0) {
            questionRound--;
        }

        if (questionRound == 0) {
            staticQuestionRound--;
        }

        if (staticQuestionRound < 0) {
            return await this.deleteClinicalCaseChiefComplains(newClinicalCase);
        }

        let questionRoundToBeRemoved =
            newClinicalCase.question_rounds_answers.find(
                (qr) =>
                    qr.static_subround === staticQuestionRound &&
                    qr.question_round === questionRound
            );

        newClinicalCase.question_rounds_answers =
            newClinicalCase.question_rounds_answers.filter(
                (qr) =>
                    qr.question_round !== questionRound ||
                    qr.static_subround !== staticQuestionRound
            );
        newClinicalCase.absence_tags = newClinicalCase.absence_tags.filter(tag => !questionRoundToBeRemoved?.absence_tags.find(qrTag => qrTag.id === tag.id));
        newClinicalCase.presence_tags = newClinicalCase.presence_tags.filter(tag => !questionRoundToBeRemoved?.presence_tags.find(qrTag => qrTag.id === tag.id));
        newClinicalCase.uncertain_tags = newClinicalCase.uncertain_tags.filter(tag => !questionRoundToBeRemoved?.uncertain_tags.find(qrTag => qrTag.id === tag.id));

        newClinicalCase.tag_category_options = newClinicalCase.tag_category_options.filter(tco => !questionRoundToBeRemoved?.tag_category_options.find(qrTCO => qrTCO.id === tco.id));

        newClinicalCase.current_question_round = questionRound;
        newClinicalCase.current_static_question_round = staticQuestionRound;

        newClinicalCase.clinical_case_state =
            questionRound > 0
                ? ClinicalCaseStep.ANSWERING_DYNAMIC_QUESTIONS
                : ClinicalCaseStep.ANSWERING_STATIC_QUESTIONS;

        await this._updateAndRecalculateRank(
            clinicalCaseId,
            newClinicalCase
        );
    }

    public getTriageStatusFromTriageState(triageState: TriageState): "non-urgent" | "emergency" | "priority"
    {
        let emergency = Object.values(triageState?.emergency_signs ?? {})
        let priority= Object.values(triageState?.priority_signs ?? {})
        if (emergency.length > 0) {
            return "emergency";
        }

        if (priority.length > 0) {
            return "priority";
        }

        const triageStateValues = Object.values(triageState ?? {});

        if (!triageState) {
            return "non-urgent";
        }
        if (triageState!.emergency_signs  && triageState!.emergency_signs.length > 0) {
            return "emergency";
        }
        if (triageStateValues.find((status) => status === TriageQuestionsValues.SEVERE)) {
            return "emergency";
        }

        let nutritionalStatus = Object.values(triageState?.nutritional_status ?? {})
        if (triageStateValues.find((status) => status === TriageQuestionsValues.SEVERE)) {
            return "emergency";
        }
        if (nutritionalStatus.find((status) => status === TriageQuestionsValues.SEVERE)) {
            return "emergency";
        }
        if (triageState!.priority_signs  && triageState!.priority_signs.length > 0) {
            return "priority";
        }
        if (triageStateValues.find((status) => status === TriageQuestionsValues.MODERATE || status === TriageQuestionsValues.MODERATE_L || status === TriageQuestionsValues.MODERATE_H)) {
            return "priority";
        }
        if (nutritionalStatus.find((status) => status === TriageQuestionsValues.MODERATE || status === TriageQuestionsValues.MODERATE_L || status === TriageQuestionsValues.MODERATE_H)) {
            return "priority";
        }

        return "non-urgent";
    }

    private async _getTriageLevelIdFromName(name: string) {
        const db = await getDB();
        let triageLevels = await db["triage"].find().exec();
        triageLevels = triageLevels.map((d: any) => d.toJSON());

        return triageLevels.find((e:any) => e.name.toLowerCase().replace(' ', '-') === name).id
    }

    private async _updateAndRecalculateTriageLevel(triageState: TriageState) {
        const calculatedTriageStateString = this.getTriageStatusFromTriageState(triageState)
        triageState.level = await this._getTriageLevelIdFromName(calculatedTriageStateString)

        return triageState
    }

    // TODO: old useApiService.updateClinicalCaseAndRecalculateRank
    private async _updateAndRecalculateRank(clinicalCaseId: string, clinicalCase: ClinicalCaseResponse) {
        clinicalCase.updated_at = new Date().toISOString();
        await updateById(TABLE_NAME, clinicalCaseId, clinicalCase);
        await this.changelog.insert(clinicalCase, EventType.ClinicalCaseUpdated);

        const diagnosisService = new DiagnosisCalculationService();

        if (
            clinicalCase.clinical_case_state === ClinicalCaseStep.ANSWERING_STATIC_QUESTIONS ||
            clinicalCase.clinical_case_state === ClinicalCaseStep.ANSWERING_DYNAMIC_QUESTIONS
        ) {
            await diagnosisService.calculateDiseaseRanks(clinicalCaseId);
        }
    }

    private _getAgeGroupFromBirthDate(birthDate: Date): AgeGroup {
        const now = new Date();
        const age = now.getTime() - birthDate.getTime();
        const days = age / (1000 * 60 * 60 * 24);

        if (days <= 29) {
            return AgeGroup.NEONATE
        } else if (days > 29 && days <= 2 * 365) {
            return AgeGroup.INFANT
        } else if (days > 2 * 365 && days <= 5 * 365) {
            return AgeGroup.UNDER5
        } else if (days > 5 * 365 && days <= 12 * 365) {
            return AgeGroup.CHILD
        } else if (days > 12 * 365 && days <= 16 * 365) {
            return AgeGroup.ADOLESCENT
        } else {
            return AgeGroup.ADULT
        }
    }
}