import { Route, Routes } from "react-router-dom";
import { Routes as Config } from "./Routes";

export const Router = () => {
  return (
    <Routes>
      <Route {...Config.ClinicalCase} />
      <Route {...Config.CaseNotesPage} />
      <Route {...Config.DiseaseArticle} />
      <Route {...Config.UserDashboard} />
      <Route {...Config.MetricsDashboard} />
      <Route {...Config.PatientPage} />
      <Route {...Config.ErrorPage} />
      <Route {...Config.DatabaseInsertTesting} />
      <Route {...Config.OfflineLogin} />
      <Route {...Config.UserProfile} />
      <Route {...Config.UserProfile} />
    </Routes>
  );
};
