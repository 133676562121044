import { TagContextViewModel } from '../../../context/TagContext.viewModel';
import { ClinicalCaseResponse, TagCategoryOptionResponse } from '../requests/ClinicalCase.apiResponse';
import { BasicInformationViewModel } from './BasicInformation.viewModel';
import { PhysicalExaminationViewModel } from './PhysicalExamination.viewModel';
import { PresentingComplaintViewModel } from './PresentingComplaint.viewModel';
import {
  AgeGroup,
  ClinicalCaseState,
  ClinicalPlanState,
  DiseaseRankState,
  TriageState
} from '../../../context/ClinicalCase.context';
import { ClinicalCaseStep } from 'modules/shared/entities/ClinicalCaseStep';
import {GetClinicalCase} from 'infrastructure/api/GetClinicalCase';
import {TagViewModel} from '../../../../../medical-library/tags/features/list-tags/requests/Tag.viewModel';
import { TagApiResponse } from 'modules/medical-library/tags/features/list-tags/requests/Tag.apiResponse';
import { noAnswerString } from 'config/const';

export class ClinicalCaseViewModel implements ClinicalCaseState {
  clinicalCaseId: string;
  confirmedDiagnostic: string;
  basicInformation: BasicInformationViewModel;
  presentingComplaint: PresentingComplaintViewModel;
  physicalExamination: PhysicalExaminationViewModel;
  clinicalPlan: ClinicalPlanState;
  chiefComplaints: Array<TagViewModel>;
  currentStep: ClinicalCaseStep;
  diseaseRankState: DiseaseRankState;
  emergencySign: TagViewModel | null;
  prioritySigns: Array<TagViewModel>;
  patient: string;
  currentQuestionRound: number;
  currentStaticQuestionRound: number;
  diagnostic_tests_used: { id: string, name: string }[];
  diagnostic_made_by: { id: string, name: string }[];
  confirmed_diagnostic_labels: { id: string, name: string }[];
  clinical_diagnosis: { id: string, name: string }[];
  confirmed_triage: { id: string, name: string }[];
  triage_made_by: { id: string, name: string }[];
  clinical_interventions: { id: string, name: string }[];
  clinical_outcomes: { id: string, name: string }[];
  predictedDiseaseId: string | null;
  suspectedDiseaseIds: Array<string>;
  questionRoundAnswers: { static_subround: number; question_round: number; presence_tags: TagApiResponse[]; absence_tags: TagApiResponse[]; uncertain_tags: TagApiResponse[]; tag_category_options: TagCategoryOptionResponse[]}[];
  case_notes: string;
  admission_decision_date: string;
  clinical_outcomes_date: string;
  triageState: TriageState|null;
  ageGroup: AgeGroup
  updated_at: string;

  constructor(apiResponse: ClinicalCaseResponse) {
    this.clinicalCaseId = apiResponse.id;
    this.confirmedDiagnostic = apiResponse.confirmed_diagnostic;
    this.basicInformation = new BasicInformationViewModel(apiResponse);

    const signs = apiResponse.tag_category_options ? apiResponse.tag_category_options
      .filter(tagCategoryOption => tagCategoryOption.tag_category.tag.is_sign) : [];
    const symptomsAndRelatedFactors = apiResponse.tag_category_options ? apiResponse.tag_category_options
      .filter(tagCategoryOption => tagCategoryOption.tag_category.tag.is_symptom ||
        tagCategoryOption.tag_category.tag.is_related_factor) : [];

    const presentingComplaintTags = this.parseTagCategoryOptions(
      symptomsAndRelatedFactors,
      this.parsePresenceTags(
        apiResponse.presence_tags.filter(tag => tag.is_symptom || tag.is_related_factor)
      )
    );

    const physicalExaminationTags = this.parseTagCategoryOptions(
      signs,
      this.parsePresenceTags(apiResponse.presence_tags.filter(tag => tag.is_sign))
    );

    this.presentingComplaint = new PresentingComplaintViewModel(presentingComplaintTags);
    this.physicalExamination = new PhysicalExaminationViewModel(physicalExaminationTags);
    this.clinicalPlan = {
      presenceTags: apiResponse.presence_tags
        .map(tag => ({
          id: tag.id,
          name: tag.name,
          isSign: tag.is_sign,
          isSymptom: tag.is_symptom,
          isTest: tag.is_test,
          isRelatedFactor: tag.is_related_factor,
          tagCategoryOptions: [],
        })),
      absenceTags: apiResponse.absence_tags.map(tag => ({
        id: tag.id,
        name: tag.name,
        isSign: tag.is_sign,
        isSymptom: tag.is_symptom,
        isTest: tag.is_test,
        isRelatedFactor: tag.is_related_factor,
      })),
      uncertainTags: apiResponse.uncertain_tags.map(tag => ({
        id: tag.id,
        name: tag.name,
        isSign: tag.is_sign,
        isSymptom: tag.is_symptom,
        isTest: tag.is_test,
        isRelatedFactor: tag.is_related_factor,
      })),
    };
    
    this.chiefComplaints = apiResponse.chief_complaints.map(tag => new TagViewModel(tag));
    this.currentStep = apiResponse.clinical_case_state as ClinicalCaseStep;
    this.diseaseRankState = {
      diseaseRank: apiResponse.disease_rank.map((disease: GetClinicalCase.DiseaseRankResponse) => ({
          diseaseId: disease.disease_id,
          diseaseName: disease?.disease_name,
          diseaseScore: disease.disease_score,
          diseaseIcon: disease?.disease_icon,
        })),
      calculated: !!apiResponse.disease_rank,
    };

    this.emergencySign = apiResponse.emergency_sign ? new TagViewModel(apiResponse.emergency_sign) : null;
    this.prioritySigns = apiResponse.priority_signs.map(tag => new TagViewModel(tag));
    this.patient = apiResponse.patient;
    this.currentQuestionRound = apiResponse.current_question_round;
    this.currentStaticQuestionRound = apiResponse.current_static_question_round;
    this.diagnostic_tests_used = apiResponse.diagnostic_tests_used;
    this.diagnostic_made_by = apiResponse.diagnostic_made_by;
    this.confirmed_diagnostic_labels = apiResponse.confirmed_diagnostic_labels;
    this.clinical_diagnosis = apiResponse.clinical_diagnosis;
    this.confirmed_triage = apiResponse.confirmed_triage;
    this.triage_made_by = apiResponse.triage_made_by;
    this.clinical_interventions = apiResponse.clinical_interventions;
    this.clinical_outcomes = apiResponse.clinical_outcomes;
    this.predictedDiseaseId = apiResponse.predicted_disease || null;
    if (apiResponse.predicted_disease != null && typeof apiResponse.predicted_disease === 'object') {
      this.predictedDiseaseId = (apiResponse.predicted_disease as {id: string}).id;
    }
    this.suspectedDiseaseIds = apiResponse.suspected_diseases;
    this.questionRoundAnswers = apiResponse.question_rounds_answers;
    this.case_notes = apiResponse.case_notes;
    this.admission_decision_date = apiResponse.admission_decision_date;
    this.clinical_outcomes_date = apiResponse.clinical_outcomes_date;
    this.triageState = apiResponse.triage_state;
    this.ageGroup = apiResponse.age_group
    this.updated_at = apiResponse.updated_at
  }


  parseTagCategoryOptions(tagCategoryOptionResponses: Array<TagCategoryOptionResponse>, tagsWithItsTagTemplates: TagContextViewModel) {
    tagCategoryOptionResponses.forEach(tagCategoryOption => {
      const tag = tagCategoryOption.tag_category.tag;
      if (!(tag.id in tagsWithItsTagTemplates)) {
        tagsWithItsTagTemplates[tag.id] = {
          id: tag.id,
          name: tag.name,
          description: tag.description,
          categories: {},
        };
      }
      const { id: tagCategoryId } = tagCategoryOption.tag_category;

      tagsWithItsTagTemplates[tag.id].categories[tagCategoryId] = tagCategoryOption.id || noAnswerString;
    });
    return  tagsWithItsTagTemplates;
  }

  parsePresenceTags(tags: Array<TagApiResponse>): TagContextViewModel {
    const tagsWithoutTagTemplate: TagContextViewModel = {};

    tags.forEach(tag => {
      const categories:{[categoryId: string]: string | undefined;} = {};
      if (!tag.tag_categories) {
        return;
      }
      tag.tag_categories.forEach(category => (categories[category.id] = noAnswerString));
      tagsWithoutTagTemplate[tag.id] = {
        id: tag.id,
        name: tag.name,
        description: tag.description,
        categories: categories,
      };
    });
    return tagsWithoutTagTemplate;
  }
}
