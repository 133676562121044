import { Grid } from '@mui/material';
import {ClinicalCaseLocalRepository} from "../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import { PatientHeaderWidgets } from '../patient/components/PatientHeaderWidgets';
import { ClinicalCaseSidebar } from './components/ClinicalCaseSidebar.component';
import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import { UpdateClinicalCaseFeature } from './features/update-clinical-case/UpdateClinicalCase.feature';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatchClinicalCase } from './context/useDispatchClinicalCase';
import './ClinicalCase.basePage.scss';
import {useDispatchClinicalCaseId} from "./context/useDispatchClinicalCaseId";
import {VersionPill} from "../../shared/components/VersionPill";

export const ClinicalCaseBasePage = () => {
  const clinicalCaseRepository = new ClinicalCaseLocalRepository();
  const dispatchClinicalCase = useDispatchClinicalCase();
  const dispatchClinicalCaseId = useDispatchClinicalCaseId();
  const {id} = useParams();
  useEffect(() => {
    
    clinicalCaseRepository.findById(id!).then( async (clinicalCase) => {
      dispatchClinicalCase(clinicalCase);
      dispatchClinicalCaseId(clinicalCase.clinicalCaseId);
    });
  }, []);

  return (
      <BasePage header={<PatientHeaderWidgets/>}>
        <div className="clinical-case-base-page">
          <div className="clinical-case-base-page-sidebar">
            <ClinicalCaseSidebar/>
          </div>
          <Grid item xs className="clinical-case-base-page-content">
            <Grid container direction="column" style={{height: '100%'}}>
              <Grid item xs sx={{height: '100%', overflowY: 'auto'}}>
                <UpdateClinicalCaseFeature/>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <VersionPill/>
      </BasePage>
  );
};

