export interface PresenceTag {
  id: string;
  name: string;
  isSymptom?: boolean;
  isSign?: boolean;
  isRelatedFactor?: boolean;
  isTest?: boolean;
  tagCategoryOptions: Array<string>;
}

export interface AbsenceTag {
  id: string;
  name: string;
  isSymptom?: boolean;
  isSign?: boolean;
  isRelatedFactor?: boolean;
  isTest?: boolean;
}

interface ITag {
  id: string;
  name: string;
  isSymptom?: boolean;
  isSign?: boolean;
  isRelatedFactor?: boolean;
  isTest?: boolean;
  description?: string;
  complexity: string;
}

export class Tag {
  id: string;
  name: string;
  isSymptom?: boolean;
  isSign?: boolean;
  isRelatedFactor?: boolean;
  isTest?: boolean;
  description?: string;
  complexity: string;

  constructor(tag: ITag) {
    this.id = tag.id;
    this.name = tag.name;
    this.isSymptom = tag.isSymptom;
    this.isSign = tag.isSign;
    this.isRelatedFactor = tag.isRelatedFactor;
    this.isTest = tag.isTest;
    this.description = tag.description;
    this.complexity = tag.complexity;
  }
}
