import { useEffect } from 'react';
import {userbackToken, userbackURL} from "../../../config/const";

declare global {
    interface Window {
        Userback:any;
    }
}

export const UserBack = () => {

    window.Userback = window.Userback || {};
    window.Userback.access_token = userbackToken;

    const userBackConnect = (document: Document) => {
        const userBackScript = document.createElement('script');
        userBackScript.async =true;
        userBackScript.src = userbackURL;
        (document.head || document.body).appendChild(userBackScript);
    };

    useEffect(() => {
        userBackConnect(document);
    }, []);

    return (
        <>
        </>
    );

};