import { useState } from "react";

type ToastShowed = 'fadeIn' | 'fadeOut' | 'none';

export const useToast = () => {
  const [isToastShowed, setIsToastShowed] = useState<ToastShowed>('none');
  const [isOnTransition, setIsOnTransition] = useState(false);

  function hideToast(callback: () => void) {
    setIsToastShowed('fadeOut');
    setTimeout(() => {
      setIsToastShowed('none')
      setIsOnTransition(false);
      callback();
    }, 300);
  }

  function showToast(callback: () => void) {
    setIsOnTransition(true);
    setIsToastShowed('fadeIn');
    setIsOnTransition(false);

    setTimeout(() => {
      hideToast(callback);
    }, 1000);
  }

  return {
    showToast,
    isToastShowed,
    hideToast,
    isOnTransition
  }
}