const ANSWERS = {
  yes: 'yes',
  no: 'no',
  unknown: 'unknown',
  unset: 'unset'
} as const;

interface YesNoButtonsProps {
  answer: "yes" | "no" | "unknown" | "unset";
  handleAnswer(value: keyof typeof ANSWERS): void
}

export function YesNoButtons({answer, handleAnswer}: YesNoButtonsProps) {

  return (
    <div className='answer-buttons__container'>
      <button
        className={answer === ANSWERS.yes ? 'is-selected' : ''}
        onClick={() => handleAnswer(ANSWERS.yes)}
      >
        yes
      </button>
      <button
        className={answer === ANSWERS.no ? 'is-selected' : ''}
        onClick={() => handleAnswer(ANSWERS.no)}
      >
        no
      </button>
      <button
        className={answer === ANSWERS.unknown ? 'is-selected' : ''}
        onClick={() => handleAnswer(ANSWERS.unknown)}
      >
        unknown
      </button>
    </div>
  )
}