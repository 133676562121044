import {useAppUIStore} from 'infrastructure/store/appUIStore';
import {CreatePatientModal} from './CreatePatientModal';
import {PatientSearch} from './PatientSearch';
import {Button} from "../../../../infrastructure/components";
import './PatientHeaderWidgets.scss';
import {styled} from "@mui/material";

const CustomButton = styled(Button)(({ theme }) => ({

  '&.MuiButton-outlined': {
    color: theme.palette.primary.contrastText,
    backgroundColor: "#0E2939",
    boxShadow: theme.shadows[0],
    borderColor: theme.palette.primary.contrastText,
    borderWidth: '1px',
    fontWeight: 'normal',
  },
  '&.MuiButton-outlined:hover': {
    backgroundColor: "#263e4c",
    color: theme.palette.primary.contrastText,
  },
}));


export const PatientHeaderWidgets = () => {
    const modal = useAppUIStore((state) => ({
        openModal: state.openModal,
    }));

    return <>
        <CustomButton className="new-patient-button"
                      variant="outlined"
                      sx={{
                          mx: 2,
                          textTransform: 'none',
                          height: '50px',
                          fontSize: '16px',
                          color: '#fff',
                          backgroundColor: 'rgb(38, 62, 76) !important',
                          border: 'none',
                          '&.MuiButton-root': {
                              borderRadius: '6px',
                              fontSize: '16px',
                          },
                          '&:hover': {
                              backgroundColor: '#fff',
                          },
                      }}
                      onClick={() => modal.openModal(<CreatePatientModal/>)}
        >
            <img crossOrigin="anonymous"
                 src={'/assets/add-patient.svg'}
                 alt="Add patient icon"
                 style={{height: '40px'}}
            />
        </CustomButton>
        <PatientSearch/>
    </>;
};
