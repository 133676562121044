import { ApiCallRender } from 'infrastructure/hooks/apiCallRender';
import {useEffect, useState} from 'react';
import { Title } from 'infrastructure/components';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { ItemsProgress } from 'infrastructure/components';
import { SubItemsProgress } from 'infrastructure/components';
import { AccordionWrapper } from 'infrastructure/components';
import { Divider } from 'infrastructure/components';
import { DiseaseArticleViewModel } from './view-models/DiseaseArticleViewModel';
import {useApiService} from "../../../../../infrastructure/hooks/useApiService";

interface Props {
  diseaseArticleId: string;
}

export const GetDiseaseArticleFeature = (props: Props) => {
  const MIN_LIKELIHOOD_SCORE_DISPLAY = 0;
  const MAX_LIKELIHOOD_SCORE_DISPLAY = 15;
  const { getDiseaseArticleViewModel } = useApiService();
  const [data, setData] = useState<DiseaseArticleViewModel | null>(null);
  const diseaseArticle = data as DiseaseArticleViewModel;
  const theme = useTheme();
  const loading = false;
  let error = '';

  const getDiseaseImageProps = (): Object => {
    const imageStyles = {
      width: '100%',
      maxHeight: '800px',
      maxWidth: '800px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    };

    if (!diseaseArticle.diseaseImage) {
      return {
        src: '/assets/MedbrainLogo.svg',
        alt: 'Disease image',
        style: {
          ...imageStyles,
          backgroundColor: '#0E2939',
        },
      };
    }
    return {
      src: diseaseArticle.diseaseImage,
      style: {
        ...imageStyles,
      },
    };
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const tagData = await getDiseaseArticleViewModel(props.diseaseArticleId);
        setData(tagData);
      } catch (e) {
        error = 'Error fetching disease article';
      }
    };

    fetchData().then(r => r);
  }, [props.diseaseArticleId, error]);

  return (
    <ApiCallRender loading={loading} error={error}>
      {diseaseArticle ? (
          <Box width={'95%'}>
            <Box sx={{ position: 'sticky', top: 0, zIndex: 1, pt: 4, background: theme.palette.background.default }}>
              <Box display={'flex'} alignItems={'center'}>
                <Avatar src={diseaseArticle.diseaseIcon ?? '/assets/MedbrainIcon.png'} sx={{
                  marginRight: '8px',
                  width: '50px',
                  height: '50px',
                }}/>
                <Title
                  title={diseaseArticle.name}
                  fontWeight={700}
                />
              </Box>
              <Divider/>
            </Box>
            <img {...getDiseaseImageProps()} alt={'Disease Image'}/>

            <Divider/>

            {diseaseArticle.symptoms.length > 0 ?
              <AccordionWrapper title={'Presenting Complaint'}>
                {diseaseArticle.symptoms
                  .map((symptom, index, array) => (
                    <ItemsProgress
                      key={symptom.name}
                      title={symptom.name}
                      divider={index !== array.length - 1}
                      items={symptom.tagCategoryOptions}
                      minProgress={MIN_LIKELIHOOD_SCORE_DISPLAY}
                      maxProgress={MAX_LIKELIHOOD_SCORE_DISPLAY}
                      progress={symptom.presenceLikelihoodRatio}
                    />
                  ))}
              </AccordionWrapper> : null}

            {diseaseArticle.signs.length > 0 ?
              <AccordionWrapper title={'Physical Examination'}>
                {diseaseArticle.signs
                  .map((sign, index, array) => (
                    <ItemsProgress
                      key={sign.name}
                      title={sign.name}
                      divider={index !== array.length - 1}
                      items={sign.tagCategoryOptions}
                      minProgress={MIN_LIKELIHOOD_SCORE_DISPLAY}
                      maxProgress={MAX_LIKELIHOOD_SCORE_DISPLAY}
                      progress={sign.presenceLikelihoodRatio}
                    />
                  ))}
              </AccordionWrapper> : null}

            {diseaseArticle.relatedFactors.length > 0 ?
              <AccordionWrapper title={'Related Factors'}>
                {diseaseArticle.relatedFactors
                  .map((relatedFactor, index, array) => (
                    <ItemsProgress
                      key={relatedFactor.name}
                      title={relatedFactor.name}
                      divider={index !== array.length - 1}
                      items={relatedFactor.tagCategoryOptions}
                      minProgress={MIN_LIKELIHOOD_SCORE_DISPLAY}
                      maxProgress={MAX_LIKELIHOOD_SCORE_DISPLAY}
                      progress={relatedFactor.presenceLikelihoodRatio}
                    />
                  ))}
              </AccordionWrapper> : null}

            {diseaseArticle.tests.length > 0 ?
              <AccordionWrapper title={'Test Results'}>
                {diseaseArticle.tests
                  .map((test, index, array) => (
                    <SubItemsProgress
                      key={test.name}
                      title={test.name}
                      divider={index !== array.length - 1}
                      minProgress={MIN_LIKELIHOOD_SCORE_DISPLAY}
                      maxProgress={MAX_LIKELIHOOD_SCORE_DISPLAY}
                      items={test.tagCategoryOptions.map((tco) => ({
                        name: tco.name,
                        progress: tco.likelihood,
                      }))}
                    />
                  ))}
              </AccordionWrapper> : null}

            <AccordionWrapper title={'Disease Overview'}>
              <Typography variant={'body2'} sx={{ color: theme.palette.text.secondary }}>
                {diseaseArticle.overview}
              </Typography>
            </AccordionWrapper>
          </Box>) : <></>}
    </ApiCallRender>);
};
