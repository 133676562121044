import { Box } from '@mui/material';
import { AccordionTemplate } from 'infrastructure/components';
import { FilledTemplate } from 'infrastructure/components';
import { AccordionYesNo} from 'infrastructure/components';
import { AbsenceTag, PresenceTag } from '../../../../shared/entities/Tag';
import { TagViewModel } from "../../../tags/features/list-tags/requests/Tag.viewModel";
import {useApiService} from "../../../../../infrastructure/hooks/useApiService";
import { useEffect, useState } from 'react';

interface Props {
  id: string;
  variant: 'template' | 'yesno';
  value?: FilledTemplate;

  onChangeTagTemplate?: (template: FilledTemplate) => void;
  onChangeYesNo?: (tag: PresenceTag | AbsenceTag, answer?: 'yes' | 'no' | 'unknown') => void;
  onClickDelete?: () => void;
  onChangeIsValid?: (isValid: boolean) => void;
}

export const GetTagTemplateFeature = (props: Props) => {
  const { getTagViewModel } = useApiService();
  const [data, setData] = useState<TagViewModel | undefined>(undefined);

  useEffect(() => {
    getTagViewModel(props.id).then(r => {
      setData(r);
    });
  }, []);

  if (!data) {
    return null;
  }

  switch (props.variant) {
    case 'template':
      return (
        <Box>
          <AccordionTemplate
            template={data}
            selected={props.value}
            onClickDelete={props.onClickDelete}
            onChange={props.onChangeTagTemplate}/>
        </Box>
      );
    case 'yesno':
      return (
        <Box>
          <AccordionYesNo
            template={data}
            selected={props.value}
            onChangeYesNo={props.onChangeYesNo}
            onChangeIsValid={props.onChangeIsValid}/>
        </Box>
      );
  }
};
