import {ServerPushService} from "./ServerPushService";
import {ServerPullService} from "./ServerPullService";
import {ChangelogCompactorService} from "./ChangelogCompactorService";
import {diagnosisEntities, medicalLibraryEntities} from "../../config/const";

export class ServerSyncService {
    private pusher;
    private puller;
    private compactor;
    private isRunning: boolean = false;

    constructor() {
        this.pusher = new ServerPushService();
        this.puller = new ServerPullService();
        this.compactor = new ChangelogCompactorService();
    }

    public async sync(syncOnlyMedicalLibrary: boolean = false){
        if(this.isRunning) {
            return false;
        }
        try {
            this.isRunning = true;
            await this.compactor.compact();
            await this.pusher.push();
            await this.puller.pull(...medicalLibraryEntities);
            if (!syncOnlyMedicalLibrary) {
                await this.puller.pull(...diagnosisEntities);
            }

        } catch(e) {
            console.error(e);
        }
        this.isRunning = false;
        return true;
    }
}
