import { CategoryApiResponse } from '../requests/Category.apiResponse';
import { OptionApiResponse } from '../requests/Option.apiResponse';
import { OptionViewModel } from './Option.viewModel';

export class CategoryViewModel {
  categoryId: string;
  name: string;
  options: Array<OptionViewModel>;

  constructor(apiResponse: CategoryApiResponse) {
    this.categoryId = apiResponse.id;
    this.name = apiResponse.name;
    this.options = apiResponse.tag_category_options.map((option: OptionApiResponse) => new OptionViewModel(option));
  }
}
