import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Alert } from 'infrastructure/components';
import { Button } from 'infrastructure/components';
import { InputTextField } from 'infrastructure/components';
import { Select, SelectOption } from 'infrastructure/components';
import { useApiService } from 'infrastructure/hooks/useApiService';
import useTranslation from 'infrastructure/i18n/useTranslation';

interface Form {
  name: string;
  surname: string;
  professionalType: string;
  healthCenter: string;
}

interface FormErrors {
  name?: string;
  surname?: string;
  professionalType?: string;
  healthCenter?: string;
}

const toOption = (response: any) => ({
  label: response.name,
  value: response.id,
} as SelectOption);

export const UserDetailForm = () => {
  const basicInformationPageTranslations = useTranslation('BASIC_INFORMATION_PAGE');
  const usersTranslations = useTranslation('USERS');
  const formTranslations = useTranslation('FORMS');
  const { login, fetchHealthCenters, saveUserInfo } = useApiService();

  const professionalsTypes = [
    { label: usersTranslations.MEDICAL_DOCTOR, value: 'medical_doctor' },
    { label: usersTranslations.NURSE, value: 'nurse' },
    { label: usersTranslations.HEALTH_WORKER, value: 'health_worker' },
    { label: usersTranslations.CHEMIST, value: 'chemist' },
    { label: usersTranslations.OTHER, value: 'other' },
  ];

  const [healthCenters, setHealthCenters] = useState([]);

  const [form, setForm] = useState<Form>(() => ({
    name: '',
    surname: '',
    professionalType: 'medical_doctor',
    healthCenter: '',
  }));

  const [errors, setErrors] = useState<FormErrors>({});

  const [requestError, _] = useState<string>('');
  const handleOnChange = (key: keyof Form) => (value: unknown) => {
    setForm({ ...form, [key]: value });
  };

  const validateForm = (form: Form) => {
    let errors: FormErrors = {};

    if (!form.name) {
      const error = formTranslations.REQUIRED;
      errors = { ...errors, name: error };
    }
    if (!form.surname) {
      const error = formTranslations.REQUIRED;
      errors = { ...errors, surname: error };
    }

    if (!form.healthCenter) {
      const error = formTranslations.REQUIRED;
      errors = { ...errors, healthCenter: error };
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      throw Error('Invalid Form');
    }
  };

  const submit = () => {
    try {
      validateForm(form);
      saveUserInfo({
        user: {
          first_name: form.name,
          last_name: form.surname,
        },
        type_of_professional: form.professionalType,
        health_center_id: form.healthCenter,
      }).then(_ => login());

    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const loadFetchCenters = async () => {
      let healthCenters = await fetchHealthCenters();
      healthCenters = healthCenters.map(toOption);

      setHealthCenters(healthCenters);
    };

    loadFetchCenters();
  }, []);

  const { name, surname, professionalType, healthCenter } = form;

  return (
    <Box>
      <Alert error={requestError} />
      <Grid container
            spacing={3}
            direction="column"
            maxWidth={'sm'}
            marginX={'auto'}
            marginTop={'7rem'}
      >
        <Grid item xs={12}>
          <InputTextField
            label={basicInformationPageTranslations.NAME_INPUT_TEXT}
            onChange={handleOnChange('name')}
            value={name}
            error={errors.name} />
        </Grid>
        <Grid item xs={12}>
          <InputTextField
            label={basicInformationPageTranslations.SURNAME_INPUT_TEXT}
            onChange={handleOnChange('surname')}
            value={surname}
            error={errors.surname} />
        </Grid>
        <Grid item xs={12}>
          <Select
            label={usersTranslations.TYPE_OF_PROFESSIONAL}
            value={professionalType}
            options={professionalsTypes}
            onChange={handleOnChange('professionalType')}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label={usersTranslations.HEALTH_CENTER}
            value={healthCenter}
            options={healthCenters}
            error={errors.healthCenter}
            onChange={handleOnChange('healthCenter')}
          />
        </Grid>
        <Grid item xs={12} marginX={'auto'}>
          <Button
            size="large"
            variant="contained"
            onClick={submit}
          >
            {basicInformationPageTranslations.SAVE_BUTTON_TEXT}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
