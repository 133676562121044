import "./instrument";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import setupLocalStorageProxy from "./modules/shared/utils/setupLocalStorageProxy";

setupLocalStorageProxy();

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
