import {Autocomplete, Box, Grid} from 'infrastructure/components';
import useTranslation from 'infrastructure/i18n/useTranslation';
import {PatientInterface} from '../../../shared/entities/PatientInterface';
import {PatientLocalRepository} from "../../../../infrastructure/repositories/PatientLocalRepository";
import {useNavigate} from 'react-router-dom';
import {HTMLAttributes, useEffect, useState} from 'react';
import {AutocompleteRenderInputParams} from "@mui/material";
import './PatientSearch.scss';

function formatDate(date: string) {
  let [day, month, year] = date.split('/');
  try {
    if(parseInt(day) < 10) {
      day = `0${day}`;
    }

    if(parseInt(month) < 10) {
      month = `0${month}`;
    }

    return `${day}/${month}/${year}`;
  } catch (error) {
    return date;
  }
}

export const PatientSearch = () => {
  const navigate = useNavigate();
  const patientRepository = new PatientLocalRepository();
  const [patients, setPatients] = useState<any>([]);
  
  const [inputValue, setInputValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const userTranslations = useTranslation('BASIC_INFORMATION_PAGE');
  const autoCompleteId = 'patient-search-id';

  const [laggedFocus, setLaggedFocus] = useState<boolean>(false);


  const sexLabel = (sex: 'M' | 'F') => sex === 'M'
    ? userTranslations.MALE_OPTION_TEXT
    : userTranslations.FEMALE_OPTION_TEXT;

  const blurAutocomplete = () => {
    const autocomplete = document.getElementById(autoCompleteId);
    if (autocomplete) {
      setTimeout(() => {
        autocomplete.blur();
      }, 0);
    }
  };

  const navigateToPatient = (patient: PatientInterface) => {
    patient && navigate(`/patient/${patient.id}`);
    blurAutocomplete();
  };

  const getFullPatientName = (patient: PatientInterface): string => {
    return (patient.firstName + ' ' +
      patient.lastName + ' ' +
      patient.medbrainId).toLowerCase();
  };
  const patientFilter = (patient: PatientInterface): boolean => {
    if(inputValue.length < 3) return false;
    return getFullPatientName(patient).includes(inputValue.toLowerCase());
  };

  const fetchPatients = async () => {
      const patients = await patientRepository.findAll();
      setPatients(patients);
  }

  useEffect(() => {
    fetchPatients().then(r => r);
  }, []);


  useEffect(() => {
    setTimeout(() => {
        setLaggedFocus(isFocused);
      }
      , 500);
  }, [isFocused]);

  // TODO: Refactor code, clean up
  const focusedRenderInput = (params: AutocompleteRenderInputParams) => (
    <div
      ref={params.InputProps.ref}
      className="focused-render"
      style={{height: "48px"}}
    >
      <img crossOrigin="anonymous" className={'hand-cursor-on-hover'} src={'/assets/SearchIcon.svg'} style={{
        marginLeft: '16px',
        marginTop: '15px',
        marginBottom: '15px',
        marginRight: '11px',
        background: 'rgb(255, 255, 255)'
      }} alt={'SearchIcon'}></img>

      <input
        type="text"
        {...params.inputProps}
        style={{
          color: 'rgb(36, 35, 42)',
          fontSize: '17px',
          borderRadius: '6px',
          width: '20px',
          transition: 'width 0.5s',
          border: '0px solid transparent',
          position: 'relative',
          outline: 'none',
          fontWeight: '400',
          fontFamily: 'Avenir',
        }}
        placeholder={ userTranslations.SEARCH_PATIENT_BAR_PLACEHOLDER }
      />

      <img crossOrigin="anonymous" className={'hand-cursor-on-hover'} onClick={() => blurAutocomplete()} src={'/assets/CrossIcon.svg'} alt="image" style={{
        marginLeft: '29px',
        marginTop: '21px',
        marginBottom: '21px',
        marginRight: '21px',
        background: 'rgb(255, 255, 255)',
        height: '15px',
        width: '15px'
      }}/>

    </div>
  );

  const unFocusedRenderInput = (params: AutocompleteRenderInputParams) => (
    <div
      style={{
        width: '250px',
        transition: 'width 0.5s',
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        background: '#263E4C',
        border: '1px solid #263E4C',
        borderRadius: '6px',
        cursor: 'text',
      }}
      ref={params.InputProps.ref}
    >
      <img crossOrigin="anonymous" src={'/assets/SearchIconWhite.svg'} style={{
        marginLeft: '16px',
        marginTop: '15px',
        marginBottom: '15px',
        marginRight: '11px',
        background: '#263E4C',
        color: '#white',
      }} alt={'SearchIcon'}></img>

      <input
        type="text"
        {...params.inputProps}
        style={{
          color: 'white',
          fontSize: '17px',
          borderRadius: '6px',
          border: '0px solid transparent',
          width: '0px',
          transition: 'width 0.5s',
          background: '#263E4C',
          position: 'relative',
          outline: 'none',
          fontWeight: '400',
          fontFamily: 'Avenir',
        }}
        placeholder="Search"
      />
    </div>
  );

  const focusedRenderOptions = (props: HTMLAttributes<HTMLLIElement>, option: any) => {
    const patient = option as PatientInterface;

    return (
      <li {...props} key={patient.id} onClick={(_) => navigateToPatient(patient)}>
        <Grid container sx={
          {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'nowrap',
            paddingY: '10px',
          }
        }>
          <Grid item mr={2}>
            <img crossOrigin="anonymous" src={'/assets/PatientSearchIcon.svg'} alt={'Patient search icon'}/>
          </Grid>
          <Grid item>
            <Box>
              <Box fontWeight={'700'} fontSize={'16px'}>
                {patient.firstName + ' ' + patient.lastName}&nbsp;

              </Box>
              <Box fontSize={'14px'} sx={{color: '#767676', fontWeight: '400'}}>
                {sexLabel(patient.sex)}&nbsp; | &nbsp;
                {formatDate(patient.dateOfBirth.toLocaleDateString())}&nbsp;
              </Box>
              <Box fontSize={'14px'} sx={{color: '#767676', fontWeight: '400'}}>
                {patient.medbrainId}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </li>
    );
  };

    return (
        <Autocomplete
            open={isFocused}
            options={patients}
            id={autoCompleteId}
            filterOptions={(patients: PatientInterface[]) => patients.filter(patientFilter)}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(_, newInputValue: PatientInterface) => {
                navigateToPatient(newInputValue);
                setInputValue('');
            }}
            onFocus={async () => {
                await fetchPatients()
                setIsFocused(true)
            }}
            onBlur={() => {
                setIsFocused(false)
            }}
            onTouchStart={() => {
                setIsFocused(true);
            }}
            getOptionLabel={(option: PatientInterface) => option.id}
            loading={false}
            readOnly={!laggedFocus}
            renderInput={isFocused ? focusedRenderInput : unFocusedRenderInput}
            renderOption={focusedRenderOptions}
            noOptionsText={inputValue.length < 3 ? '' : 'No options found.'}
        />
    );
};
