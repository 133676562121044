import { addRxPlugin, createRxDatabase } from "rxdb";
import { RxDBMigrationPlugin } from "rxdb/plugins/migration";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";

import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { diseaseArticleSchema } from "../../modules/medical-library/disease-articles/features/get-disease-article/schema/DiseaseArticleSchema";
import { clinicalCaseSchema } from "../../modules/diagnosis/clinical-case/schema/ClinicalCaseSchema";
import {ChangelogSchema} from "../../modules/sync/changelog/schema/ChangelogSchema";
import {
  clinicalInterventionSchema,
  clinicalOutcomeSchema,
  diagnosticTestSchema,
  diseaseSchema,
  healthcareProfessionalTypeSchema,
  triageSchema,
} from "../../modules/diagnosis/case-notes/schema/ClinicalLabelsSchema";
import { patientSchema } from "../../modules/diagnosis/patient/schema/PatientSchema";
import {
  tagCategoriesSchema,
  tagCategoryOptionsSchema,
  tagsSchema,
} from "../../modules/medical-library/tags/features/list-tags/schema/TagsSchema";
import { metaSchema } from "./MetaSchema";

import { medbrainUserSchema } from "../../modules/user/schema/MedbrainUserSchema";
import patients from "./initial-data/patients.json";
import diseasearticles from "./initial-data/disease-articles.json";
import tags from "./initial-data/tags.json";
import tagCategories from "./initial-data/tag-categories.json"; // loaded from api medical_library/tag_template/tag_category
import tagCategoryOptions from "./initial-data/tag-category-options.json"; // loaded from api /medical_library/tag_template/tag_categories_options
import medbrainusers from "./initial-data/medbrainusers.json";
import clinicalCases from "./initial-data/clinical-cases.json";
import questionRound from "./initial-data/question-round.json";
import diseases from "./initial-data/diseases.json";
import triage from "./initial-data/triage.json";
import changelog from "./initial-data/changelog.json";
import diagnosisOutcomeConfig from "./initial-data/diagnosis-outcome-config.json";
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder";
import healthcareProfessionalTypes from "./initial-data/healthcare-professional-types.json";
import { diagnosisOutcomeConfigSchema } from "../../modules/diagnosis/diagnosis-outcome-config/schema/DiagnosisOutcomeConfigSchema";
import { RxDBUpdatePlugin } from "rxdb/plugins/update";
import { IDBKeyRange, indexedDB } from "fake-indexeddb";
import { userLoginSchema } from "infrastructure/components/user-menu/schema/userLogin";

import { questionRoundSchema } from "../../modules/diagnosis/clinical-case/features/get-question-round/schema/QuestionRoundSchema";
import { userInfoLocalStorage } from "config/const";
import {ClinicalCaseMigrations} from "../../modules/diagnosis/clinical-case/schema/ClinicalCaseSchemaMigrations";

addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

let dbPromise = null;

const _create = async () => {
  console.log("DatabaseService: creating database..");
  const environment = process.env.ENV_APP;

  const storageOptions =
    environment === "testing"
      ? { indexedDB: indexedDB, IDBKeyRange: IDBKeyRange }
      : undefined;

  const db = await createRxDatabase({
    name: "medbraindb",
    storage: getRxStorageDexie(storageOptions),
  });

  console.log("DatabaseService: created database");

  // show leadership in title
  if (environment !== "testing") {
    db.waitForLeadership().then(() => {
      document.title = "♛ " + document.title;
    });
  }

  // create collections
  console.log("DatabaseService: create collections");
  await db.addCollections({
    patients: {
      schema: patientSchema,
    },
    clinicalCases: {
      schema: clinicalCaseSchema, migrationStrategies: ClinicalCaseMigrations
    },
    diseaseArticles: {
      schema: diseaseArticleSchema,
    },
    tags: {
      schema: tagsSchema,
    },
    tagCategories: {
      schema: tagCategoriesSchema,
    },
    tagCategoryOptions: {
      schema: tagCategoryOptionsSchema,
    },
    clinicalIntervention: { schema: clinicalInterventionSchema },
    diagnosticTest: { schema: diagnosticTestSchema },
    healthcareProfessionalType: { schema: healthcareProfessionalTypeSchema },
    disease: { schema: diseaseSchema },
    medbrainUsers: {
      schema: medbrainUserSchema,
    },
    diagnosisOutcomeConfig: {
      schema: diagnosisOutcomeConfigSchema,
    },
    userLogin: {
      schema: userLoginSchema,
    },
    meta: {
      schema: metaSchema,
    },
    questionRounds: {
      schema: questionRoundSchema,
    },
    clinicalOutcome: {
      schema: clinicalOutcomeSchema,
    },
    triage: {
      schema: triageSchema,
    },
    changelog: {
      schema: ChangelogSchema,
    }
  });

  // Setup replications
  // await setupReplication(db.patients);
  return db;
};

export const getDB = () => {
  if (!dbPromise) dbPromise = _create();
  return dbPromise;
};

export const addEntity = async (collection, entity) => {
  const entityExists = async (collection, entityId) => {
    const db = await getDB();
    const entity = await db[collection].findOne(entityId).exec();
    return !!entity;
  };

  const db = await getDB();

  // Don't insert if entity already exists in the collection
  if (await entityExists(collection, entity.id)) {
    console.log(`Entity already exists with id: ${entity.id}`);
    return;
  }

  try {
    await db[collection].upsert(entity);
  } catch (error) {
    console.log("Data insertion failed: ", error);
  }
};

export const upsertEntity = async (collection, entity) => {
  const db = await getDB();

  try {
    if (entity.deleted) {
      let document = await db[collection].findOne(entity.id).exec();

      if (!!document) {
        console.log("delete", document);
        document.remove();
      }
      return;
    }
  
    await db[collection].upsert(entity);
  } catch (error) {
    console.log("Data insertion failed: ", error);
  }
};

export const addEntities = async (entities, collection) => {
  for (const entity of entities) {
    await addEntity(collection, entity);
  }
};

export const addAllCollections = async () => {
  const shouldProceedWithLoading = async () => {
    const db = await getDB();

    // Find the entity with id '1' in the 'meta' collection
    const metaEntity = await db["meta"].findOne("1").exec();

    // If the meta entity does not exist, create it
    if (!metaEntity) {
      const newMetaEntity = {
        id: "1",
        initial_load: false,
      };
      await addEntity("meta", newMetaEntity);
      return true;
    }

    return metaEntity.initial_load;
  };

  if (!(await shouldProceedWithLoading())) {
    return;
  }

  await addEntities(patients, "patients");
  await addEntities(diseasearticles, "diseaseArticles");
  await addEntities(tags, "tags");
  await addEntities(tagCategories, "tagCategories");
  await addEntities(tagCategoryOptions, "tagCategoryOptions");
  await addEntities(medbrainusers, "medbrainUsers");
  await addEntities(clinicalCases, "clinicalCases");
  await addEntities(diseases, "disease");
  await addEntities(healthcareProfessionalTypes, "healthcareProfessionalType");
  await addEntities(diagnosisOutcomeConfig, "diagnosisOutcomeConfig");
  await addEntities(questionRound, "questionRounds");
  await addEntities(triage, "triage");
  await addEntities(changelog, "changelog");
};

export const findById = async (collection, id) => {
  const db = await getDB();
  const document = await db[collection].findOne(id).exec();

  if (!document) {
    console.log(`No document found with id ${id} in collection ${collection}`);
    return {};
  }

  return document;
};

export async function insertItem(collection, item) {
  const db = await getDB();

  try {
    await db[collection].insert(item);

  } catch (error) {
    console.log("Data insertion failed: ", error);
    return { error: true };
  }
}

export const findRelatedTcos = async (id) => {
  const db = await getDB();
  const document = await db["tagCategoryOptions"]
    .find({
      selector: {
        "tag_category.tag": {
          $eq: id,
        },
      },
    })
    .exec()
    .then((tcos) => tcos.map((tco) => tco.toJSON()));

  if (!document) {
    console.log(
      `No document found with id ${id} in collection tagCategoryOptions`
    );
    return {};
  }

  return document;
};

export const findRelatedTagCategories = async (id) => {
  const db = await getDB();
  const document = await db["tagCategories"]
    .find({
      selector: {
        "tag.id": {
          $eq: id,
        },
      },
    })
    .exec()
    .then((tcs) => tcs.map((tc) => tc.toJSON()));

  if (!document) {
    console.log(`No document found with id ${id} in collection tagCategories`);
    return {};
  }

  return document;
};

export const updateById = async (collection, id, data) => {
  const db = await getDB();
  const query = await db[collection].findOne(id);

  if (!query.exec()) {
    console.log(
      `No document found to update with id ${id} in collection ${collection}`
    );
    return {};
  }

  await query.update({ $set: data });

  return query.exec();
};

export const create = async (collection, data) => {
  const db = await getDB();

  const query = db[collection];

  await query.create({ $set: data });

  return query.exec();
};

export const count = async (collection) => {
  const db = await getDB();

  const query = db[collection];

  let countRes = await query.count().exec();

  return countRes;
};

export const findMedbrainUser = async (id) => {
  try {
    const db = await getDB();
    const user = await db["medbrainUsers"].findOne(id).exec();

    return user.toJSON();
  } catch (error) {
    console.error("Error fetching user with id: " + id, error);
    return undefined;
  }
};

export const getPatientsFromDate = async (date) => {
  const userInfo = localStorage.getItem(userInfoLocalStorage);
  let userInfoJSON = {
    state: {
      claims: {
        userId: null,
        nickName: null,
      },
    },
  };

  if (userInfo) {
    userInfoJSON = JSON.parse(userInfo);
  }

  const medbrainUser = await findMedbrainUser(userInfoJSON.state.claims.userId);

  try {
    const db = await getDB();
    const patients = await db["patients"]
      .find()
      .where("updated_at")
      .gt(date)
      .exec();

    return patients
      .map((patient) => patient.toJSON())
      .map((patient) => {
        if (typeof patient.user.id === "string") {
          patient.user.id = medbrainUser?.user.id;
        }

        return patient;
      });
  } catch (error) {
    console.error("Error fetching patients:", error);
    return [];
  }
};

export const getLoggedUser = async () => {
  try {
    const db = await getDB();
    const userLoginQuery = await db["userLogin"].find().exec();

    const userLoginAsArray = userLoginQuery.map((clinicalCase) =>
      clinicalCase.toJSON()
    );

    return userLoginAsArray[0];
  } catch (e) {
    console.error("Error fetching userLogin");
    return undefined;
  }
};

export async function checkUserAlreadyLogged() {
  const emailSaved = localStorage.getItem("email");

  if (!emailSaved) return false;

  const userLogged = await getLoggedUser();
  if (!userLogged) return false;

  return emailSaved === userLogged?.user?.email;
}

export const getClinicalCasesFromDate = async (date) => {
  try {
    const db = await getDB();

    const clinicalCases = await db["clinicalCases"]
      .find()
      .where("created_at")
      .gt(date)
      .exec();

    return clinicalCases.map((clinicalCase) => clinicalCase.toJSON());
  } catch (error) {
    console.error("Error fetching clinical cases:", error);
    return [];
  }
};

export const getAllDiseaseArticles = async () => {
  try {
    const db = await getDB();
    let diseaseArticles = await db["diseaseArticles"].find().exec();

    return diseaseArticles.map((d) => d.toJSON());
  } catch (error) {
    console.error("Error fetching disease articles:", error);
    return [];
  }
};

export const getAllHealthcareProffesionalTypes = async () => {
  try {
    const db = await getDB();
    let healthcareProfessionalTypes = await db["healthcareProfessionalType"]
      .find()
      .exec();
    return healthcareProfessionalTypes.map((d) => d.toJSON());
  } catch (error) {
    console.error("Error fetching disease articles:", error);
    return [];
  }
};

export const getAllClinicalInterventions = async () => {
  try {
    const db = await getDB();
    let clinicalInterventions = await db["clinicalIntervention"].find().exec();
    return clinicalInterventions.map((d) => d.toJSON());
  } catch (error) {
    console.error("Error fetching disease articles:", error);
    return [];
  }
};

export const getAllDiagnosticTests = async () => {
  try {
    const db = await getDB();
    let diagnosticTests = await db["diagnosticTest"].find().exec();
    return diagnosticTests.map((d) => d.toJSON());
  } catch (error) {
    console.error("Error fetching disease articles:", error);
    return [];
  }
};

export const getAllClinicalOutcomes = async () => {
  try {
    const db = await getDB();
    let diagnosticTests = await db["clinicalOutcome"].find().exec();
    return diagnosticTests.map((d) => d.toJSON());
  } catch (error) {
    console.error("Error fetching disease articles:", error);
    return [];
  }
};

export const getAllTriage = async () => {
  try {
    const db = await getDB();
    let triages = await db["triage"].find().exec();
    return triages.map((d) => d.toJSON());
  } catch (error) {
    console.error("Error fetching triage", error);
    return [];
  }
};

export const findTagsBy = async (property) => {
  try {
    const db = await getDB();
    return await db["tags"]
      .find()
      .where(property)
      .eq(true)
      .exec()
      .then((r) => r.map((c) => c.toJSON()));
  } catch (error) {
    console.error("Error fetching tags with property: " + property, error);
    return [];
  }
};
