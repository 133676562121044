import { Tag } from './Tag';

interface ITagCategory {
  id: string;
  name: string;
  tag: Tag;
}

export class TagCategory {
  id: string;
  name: string;
  tag: Tag;

  constructor(tagCategory: ITagCategory) {
    this.id = tagCategory.id;
    this.name = tagCategory.name;
    this.tag = tagCategory.tag;
  }
}