import './ManagementPlanCard.component.scss';
import { Box } from "@mui/material";
import { useEffect, useState, useRef, useCallback } from "react";
import { DiseaseArticleLocalRepository } from "../../../../../infrastructure/repositories/DiseaseArticleLocalRepository";
import {
    DiseaseArticleViewModel
} from "../../../../medical-library/disease-articles/features/get-disease-article/view-models/DiseaseArticleViewModel";
import ReactMarkdown from "react-markdown";
import { DescriptiveImages } from "../answering-yes-no-questions-handler/shared/descriptive-images/DescriptiveImages";
import { useInView } from 'react-intersection-observer';

interface ManagementPlanCardProps {
    disease?: string
}

export function ManagementPlanCard({ disease }: ManagementPlanCardProps) {
    const [activeTab, setActiveTab] = useState('1');
    const [diseaseArticle, setDiseaseArticle] = useState<DiseaseArticleViewModel | null>(null);

    useEffect(() => {
        if (disease) {
            const repo = new DiseaseArticleLocalRepository();
            repo.findById(disease).then((article: any) => {
                console.log(article.sources);
                setDiseaseArticle(article);
            }).catch(error => {
                console.error("Error fetching disease article:", error);
            });
        }
    }, [disease]);

    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);
    const ref4 = useRef<HTMLDivElement>(null);
    const ref5 = useRef<HTMLDivElement>(null);

    const handleTabChange = (newValue: string) => {
        setActiveTab(newValue);
        const refs = [ref1, ref2, ref3, ref4, ref5];
        const index = parseInt(newValue, 10) - 1;
        refs[index].current?.scrollIntoView({ behavior: 'smooth' });
    };

    const tabs = [
        { label: 'Tests', value: '1', icon: '/assets/microscope-icon.png' },
        { label: 'Management', value: '2', icon: '/assets/stethoscope-icon.png' },
        { label: 'Medication', value: '3', icon: '/assets/capsules.png' },
        { label: 'Referral', value: '4', icon: '/assets/referral.png' },
        { label: 'Home Care', value: '5', icon: '/assets/homecare.png' },
    ];

    const [inViewRef1, inView1] = useInView({ threshold: 0.1, trackVisibility: true, delay: 100 });
    const [inViewRef2, inView2] = useInView({ threshold: 0.1, trackVisibility: true, delay: 100 });
    const [inViewRef3, inView3] = useInView({ threshold: 0.1, trackVisibility: true, delay: 100 });
    const [inViewRef4, inView4] = useInView({ threshold: 0.1, trackVisibility: true, delay: 100 });
    const [inViewRef5, inView5] = useInView({ threshold: 0.1, trackVisibility: true, delay: 100 });

    const setRefs = useCallback((node, inViewRef, domRef) => {
        inViewRef(node);
        if (node) {
            domRef.current = node;
        }
    }, []);

    useEffect(() => {
        if (inView1) setActiveTab('1');
        else if (inView2) setActiveTab('2');
        else if (inView3) setActiveTab('3');
        else if (inView4) setActiveTab('4');
        else if (inView5) setActiveTab('5');
    }, [inView1, inView2, inView3, inView4, inView5]);

    return (
        <section className="management-plan-card">
            <Box className="tab_container">
                <Box className="tab_list">
                    {tabs.map((tab) => (
                        <button
                            key={tab.value}
                            className={`custom_tab ${activeTab === tab.value ? 'active' : ''}`}
                            onClick={() => handleTabChange(tab.value)}
                        >
                            <span className={'icon-container'}><img src={tab.icon} alt={tab.label} className="tab_icon"/></span>
                            <span>{tab.label}</span>
                        </button>
                        ))}
                </Box>
            </Box>
            <Box className="tab_content">
                <div ref={(node) => setRefs(node, inViewRef1, ref1)}
                     className={`custom_tab_panel ${activeTab === '1' ? 'active' : ''}`}>
                    <div className={'tab-content'}>
                        <ReactMarkdown>{diseaseArticle?.clinicalPlanTests || ''}</ReactMarkdown>
                        {diseaseArticle?.clinicalPlanTestsImages != undefined && diseaseArticle?.clinicalPlanTestsImages?.length > 0 &&
                            <DescriptiveImages images={[]}
                                               planImages={diseaseArticle?.clinicalPlanTestsImages}></DescriptiveImages>}
                    </div>
                </div>
                <div ref={(node) => setRefs(node, inViewRef2, ref2)}
                     className={`custom_tab_panel ${activeTab === '2' ? 'active' : ''}`}>
                    <div className={'tab-content'}>
                        <ReactMarkdown>{diseaseArticle?.clinicalPlanManagementPlan || ''}</ReactMarkdown>
                        {diseaseArticle?.clinicalPlanManagementPlanImages != undefined && diseaseArticle?.clinicalPlanManagementPlanImages?.length > 0 &&
                            <DescriptiveImages images={[]}
                                               planImages={diseaseArticle?.clinicalPlanManagementPlanImages}></DescriptiveImages>}
                    </div>
                </div>
                <div ref={(node) => setRefs(node, inViewRef3, ref3)}
                     className={`custom_tab_panel ${activeTab === '3' ? 'active' : ''}`}>
                    <div className={'tab-content'}>
                        <ReactMarkdown>{diseaseArticle?.clinicalPlanMedication || ''}</ReactMarkdown>
                        {diseaseArticle?.clinicalPlanMedicationImages != undefined && diseaseArticle?.clinicalPlanMedicationImages?.length > 0 &&
                            <DescriptiveImages images={[]}
                                               planImages={diseaseArticle?.clinicalPlanMedicationImages}></DescriptiveImages>}
                    </div>
                </div>
                <div ref={(node) => setRefs(node, inViewRef4, ref4)}
                     className={`custom_tab_panel ${activeTab === '4' ? 'active' : ''}`}>
                    <div className={'tab-content'}>
                        <ReactMarkdown>{diseaseArticle?.clinicalPlanReferral || ''}</ReactMarkdown>
                        {diseaseArticle?.clinicalPlanReferralImages != undefined && diseaseArticle?.clinicalPlanReferralImages?.length > 0 &&
                            <DescriptiveImages images={[]}
                                               planImages={diseaseArticle?.clinicalPlanReferralImages}></DescriptiveImages>}
                    </div>
                </div>
                <div ref={(node) => setRefs(node, inViewRef5, ref5)}
                     className={`custom_tab_panel ${activeTab === '5' ? 'active' : ''}`}
                     style={{border: "none"}}>
                    <div className={'tab-content'}>
                        <ReactMarkdown>{diseaseArticle?.clinicalPlanHomeCare || ''}</ReactMarkdown>
                        {diseaseArticle?.clinicalPlanHomeCareImages != undefined && diseaseArticle?.clinicalPlanHomeCareImages?.length > 0 &&
                            <DescriptiveImages images={[]}
                                               planImages={diseaseArticle?.clinicalPlanHomeCareImages}></DescriptiveImages>}
                    </div>
                </div>
                <div className={'sources-block'}>
                    <h2>Sources</h2>
                    <ul>
                        {diseaseArticle?.sources?.map((source: any, key: number) => (
                            <li>
                                <div id={'el_' + key} className={'sources-block-row'}>
                                    <div className={'sources-block-link'}>
                                        <img crossOrigin="anonymous" src={'/assets/source.svg'}/>
                                        <a target={'_blank'} href={source.document}>{source.name}</a>
                                    </div>
                                    <div className={'sources-block-image'}>
                                        <img src={source.icon}/>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </Box>
        </section>
    );
}
