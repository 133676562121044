import { Box, Chip, Divider, Grid, styled, Typography } from '@mui/material';
import { LinearProgress } from '../linear-progress/LinearProgress';

const CustomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  border: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.text.secondary,
  height: '24px',
  fontSize: '13px',
  marginRight: '2px',
}));

interface Props {
  title: string;
  items: string[];
  progress: number;
  divider?: boolean;
  minProgress?: number;
  maxProgress?: number;
}

export const ItemsProgress = (props: Props) => {
  return (
    <>
      <Grid
        container
        mt={'2px'}
        mb={'8px'}
        p={'8px'}
      >
        <Grid item xs={8}>
          <Box>
            <Typography variant={'body2'} color={'text.secondary'}>
              {props.title}
            </Typography>
            {props.items.map((item) => (
              <CustomChip
                key={item}
                sx={{ mt: '8px' }}
                label={item}
              />))}
          </Box>
        </Grid>
          <Grid item xs={4}>
            <Box sx={{ mt: '8px' }}>
              <LinearProgress
                variant={'determinate'}
                value={props.progress}
                minProgress={props.minProgress}
                maxProgress={props.maxProgress}
              />
            </Box>
          </Grid>
      </Grid>
      {props.divider && <Divider />}
    </>
  );
};
