interface TriageStateField {
    triage_state: object|null;
    admission_decision_date: string|null;
    clinical_outcomes_date: string|null;
    triage_questions: object
}

export const ClinicalCaseMigrations = {
    1: (oldVersion: any) => {
        const newVersion: TriageStateField = { ...oldVersion };
        newVersion.triage_state = null;
        newVersion.admission_decision_date = null;
        newVersion.clinical_outcomes_date = null;
        return newVersion;
    },
};
