import {PatientApiRepository} from "../../infrastructure/repositories/PatientApiRepository";
import {ChangelogLocalRepository} from "../../infrastructure/repositories/ChangelogLocalRepository";
import {ClinicalCaseApiRepository} from "../../infrastructure/repositories/ClinicalCaseApiRepository";
import {EventType} from "./changelog/ChangelogRecordInterface";

export class ServerPushService {
    private patientApiRepository: PatientApiRepository;
    private changelogRepository: ChangelogLocalRepository;
    private clinicalCaseApiRepository: ClinicalCaseApiRepository;

    constructor() {
        this.patientApiRepository = new PatientApiRepository();
        this.clinicalCaseApiRepository = new ClinicalCaseApiRepository();
        this.changelogRepository = new ChangelogLocalRepository();
    }

    public async push() {
        do {
            let record = await this.changelogRepository.getFirstIn();
            if (!record) {
                localStorage.setItem('last-push-execution-date', new Date().toISOString())
                return;
            }
            switch (record.event_type) {
                case EventType.PatientCreated:
                    await this.patientApiRepository.create(record.payload);
                    break;
                case EventType.ClinicalCaseCreated:
                    await this.clinicalCaseApiRepository.create(record.payload);
                    break;
                case EventType.ClinicalCaseUpdated:
                    await this.clinicalCaseApiRepository.update(record.payload);
                    break;
                default:
                    console.error(JSON.stringify(record));
                    return Error('changelog record found without event type');
            }

            await this.changelogRepository.deleteRecordById(record.id);
        } while (true);
    }
}
