import React, { useCallback, useReducer } from 'react';
import {
  BasicInformationState,
  ClinicalCaseContext,
  ClinicalCaseState, ClinicalPlanState, DiseaseRankState,
  initialContext,
  PhysicalExaminationState,
  PresentingComplaintState,
} from './ClinicalCase.context';

enum ActionType {
  SET_CLINICAL_CASE_ID = 'setClinicalCaseId',
  SET_CLINICAL_CASE = 'setClinicalCase',
  SET_BASIC_INFORMATION = 'setBasicInformation',
  SET_PRESENTING_COMPLAINT = 'setPresentingComplaint',
  SET_PHYSICAL_EXAMINATION = 'setPhysicalExamination',
  SET_CLINICAL_PLAN = 'setClinicalPlan',
  SET_DISEASE_RANK_STATE = 'setDiseaseRankState',
}

interface Action {
  type: ActionType,
  payload: any;
}

interface Props {
  children: React.ReactNode
}

export const ClinicalCaseProvider: React.FC<Props> = ({ children }: Props) => {
  const [clinicalCase, dispatch] = useReducer((state: ClinicalCaseState, action: Action) => {
    const { type, payload } = action;
    switch (type) {
      case ActionType.SET_CLINICAL_CASE_ID:
        return { ...state, clinicalCaseId: payload };
      case ActionType.SET_BASIC_INFORMATION:
        return { ...state, basicInformation: payload };
      case ActionType.SET_PRESENTING_COMPLAINT:
        return { ...state, presentingComplaint: payload };
      case ActionType.SET_PHYSICAL_EXAMINATION:
        return { ...state, physicalExamination: payload };
      case ActionType.SET_CLINICAL_PLAN:
        return { ...state, clinicalPlan: payload };
      case ActionType.SET_CLINICAL_CASE:
        return { ...state,...payload };
      case ActionType.SET_DISEASE_RANK_STATE:
        return { ...state, diseaseRankState: payload };
    }
  }, initialContext.clinicalCase);

  const setClinicalCaseId = useCallback((clinicalCaseId: string) => {
    dispatch({
      type: ActionType.SET_CLINICAL_CASE_ID,
      payload: clinicalCaseId,
    });
  }, []);

  const setBasicInformation = useCallback((basicInformation: BasicInformationState) => {
    dispatch({
      type: ActionType.SET_BASIC_INFORMATION,
      payload: basicInformation,
    });
  }, []);

  const setPresentingComplaint = useCallback((presentingComplaint: PresentingComplaintState) => {
    dispatch({
      type: ActionType.SET_PRESENTING_COMPLAINT,
      payload: presentingComplaint,
    });
  }, []);

  const setPhysicalExamination = useCallback((physicalExamination: PhysicalExaminationState) => {
    dispatch({
      type: ActionType.SET_PHYSICAL_EXAMINATION,
      payload: physicalExamination,
    });
  }, []);

  const setClinicalPlan = useCallback((clinicalPlan: ClinicalPlanState) => {
    dispatch({
      type: ActionType.SET_CLINICAL_PLAN,
      payload: clinicalPlan,
    });
  }, []);

  const setClinicalCase = useCallback((clinicalCase: ClinicalCaseState) => {
    dispatch({
      type: ActionType.SET_CLINICAL_CASE,
      payload: clinicalCase,
    });
  }, []);

  const setDiseaseRankState = useCallback((diseaseRankState: DiseaseRankState) => {
    dispatch({
      type: ActionType.SET_DISEASE_RANK_STATE,
      payload: diseaseRankState,
    });
  }, []);

  const value = {
    clinicalCase,
    setClinicalCase,
    setClinicalCaseId,
    setBasicInformation,
    setPresentingComplaint,
    setPhysicalExamination,
    setClinicalPlan,
    setDiseaseRankState
  };

  return (
    <ClinicalCaseContext.Provider value={value}>
      {children}
    </ClinicalCaseContext.Provider>
  );
};
