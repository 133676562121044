import { Typography } from '@mui/material';
import { Box } from '../box/Box';
import {ReactNode} from "react";

interface Props {
  title: ReactNode;
  subtitle?: string;
  variant?: 'h6' | 'h5' | 'h4' | 'h3' | 'h2' | 'h1';
  fontWeight?: number;
  fontSize?: number | string;
  fontSizeSubtitle?: number | string;
  align?: 'left' | 'center' | 'right';
  ml?: number;
  mt?: number;
  mb?: number;
}

export const Title = (props: Props) => {
  return (<Box
    display={'flex'}
    alignItems={props.align || 'center'}
    flexDirection={'column'}
    ml={props.ml || 0}
    mt={props.mt || 0}
    mb={props.mb || 0}
    justifyContent={props.align || 'center'}
  >
    <Typography
      variant={props.variant ?? 'h4'}
      component="span"
      style={{paddingBottom: '5px'}}
      fontWeight={props.fontWeight}
      fontSize={props.fontSize}
    >
      {props.title}
    </Typography>
    <Typography fontSize={props.fontSizeSubtitle} lineHeight={'normal'} variant='subtitle1' color='rgba(0, 0, 0, 0.5)' style={{paddingBottom: '3px'}}>
      {props.subtitle}
    </Typography>
  </Box>);
};
