import { Typography } from "@mui/material";
import { MedbrainUser } from "modules/user/MedbrainUser";
import { useMemo } from "react";

type UserProfileProps = {
  user: MedbrainUser;
};

function RenderProfileFields({ user }: UserProfileProps) {
  const proffesionalType = useMemo(() => {
    if (user.professionalType === "medical_doctor") {
      return "Doctor";
    }

    if (user.professionalType === "chemist") {
      return "Chemist";
    }

    if (user.professionalType === "health_worker") {
      return "Health Worker";
    }

    if (user.professionalType === "nurse") {
      return "Nurse";
    }

    return "Other";
  }, [user.professionalType]);

  return (
    <>
      <Typography
        variant="body1"
        fontSize="22px"
        fontWeight={900}
        lineHeight="30px"
      >
        {`${user.name} ${user.surname}`}
      </Typography>
      <div
        style={{
          borderLeft: "1px solid #DBD8E6",
          height: "35px",
          margin: "1rem",
        }}
      ></div>
      <Typography
        variant="body1"
        fontSize="22px"
        fontWeight={900}
        lineHeight="30px"
      >
        {user.healthCenter}
      </Typography>
      <div
        style={{
          borderLeft: "1px solid #DBD8E6",
          height: "35px",
          margin: "1rem",
        }}
      ></div>
      <Typography
        variant="body1"
        fontSize="22px"
        fontWeight={900}
        lineHeight="30px"
      >
        {proffesionalType}
      </Typography>
    </>
  );
}

export const UserProfile = ({ user }: UserProfileProps) => {
  return (
    <div
      style={{
        border: "1px solid #DBD8E6",
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <RenderProfileFields user={user} />
    </div>
  );
};
