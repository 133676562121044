import { ClinicalCase } from "modules/diagnosis/clinical-case/ClinicalCase";
import "./CaseNotesStatus.scss";
import { useEffect, useMemo, useState } from "react";
import { ClinicalCaseViewModel } from "modules/diagnosis/clinical-case/features/get-clinical-case/view-models/ClinicalCase.viewModel";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {ClinicalCaseLocalRepository} from "../../../../../../infrastructure/repositories/ClinicalCaseLocalRepository";

const STATUS_TYPE = {
  INFORMATION_MISSING: {
    message: "Information Missing",
    className: "information-missing",
  },
  DIAGNOSIS_CONFIRMED: {
    message: "Diagnosis Confirmed",
    className: "diagnosis-confirmed",
  },
  CASE_COMPLETED: {
    message: "Case Completed",
    className: "case-completed",
  },
  NONE: {
    message: "",
    className: "none-message",
  },
} as const;

const useGetClinicalCase = ({ id }: { id: string }) => {
  const [clinicalCaseViewModel, setClinicalCaseViewModel] = useState<
    ClinicalCaseViewModel | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const clinicalCaseRepository = new ClinicalCaseLocalRepository();

  useEffect(() => {
    if (isLoading) return;
    const fetchPatientsAndClinicalCases = async () => {
      setIsLoading(true);
      const clinicalCase = await clinicalCaseRepository.findById(id!);

      setClinicalCaseViewModel(clinicalCase);
      setIsLoading(false);
    };

    fetchPatientsAndClinicalCases();
  }, [id]);

  return { clinicalCaseViewModel, isLoading };
};

export const CaseNotesStatus = ({
  clinicalCase,
}: {
  clinicalCase: ClinicalCase;
}) => {
  const { clinicalCaseViewModel, isLoading } = useGetClinicalCase({
    id: clinicalCase.id,
  });
  const navigate = useNavigate();

  const status = useMemo(() => {
    if (!clinicalCaseViewModel) return STATUS_TYPE.NONE;

    if (
      !clinicalCaseViewModel?.diagnostic_tests_used ||
      !clinicalCaseViewModel?.diagnostic_made_by ||
      !clinicalCaseViewModel?.clinical_diagnosis ||
      !clinicalCaseViewModel?.confirmed_diagnostic_labels
    ) {
      return STATUS_TYPE.INFORMATION_MISSING;
    }

    if (clinicalCaseViewModel?.diagnostic_tests_used.length === 0)
      return STATUS_TYPE.INFORMATION_MISSING;
    if (clinicalCaseViewModel?.diagnostic_made_by.length === 0)
      return STATUS_TYPE.INFORMATION_MISSING;
    if (clinicalCaseViewModel?.clinical_diagnosis.length === 0)
      return STATUS_TYPE.INFORMATION_MISSING;
    if (clinicalCaseViewModel?.confirmed_diagnostic_labels.length === 0)
      return STATUS_TYPE.INFORMATION_MISSING;

    if (
      clinicalCaseViewModel?.triage_made_by.length < 0 ||
      clinicalCaseViewModel?.confirmed_triage.length < 0 ||
      clinicalCaseViewModel?.clinical_interventions.length < 0 ||
      clinicalCaseViewModel?.clinical_outcomes.length < 0
    ) {
      return STATUS_TYPE.DIAGNOSIS_CONFIRMED;
    }

    return STATUS_TYPE.CASE_COMPLETED;
  }, [clinicalCaseViewModel]);
  return (
    <div className="case-notes-status__container">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <p className={status.className} style={{ marginTop: "-23px" }}>
          {status.message}
        </p>
      )}
      <button
        onClick={(event) => {
          navigate(`/clinical-case/${clinicalCase.id}/case-notes`);
          event.stopPropagation();
        }}
        style={{ marginTop: "10px" }}
      >
        Case Notes
      </button>
    </div>
  );
};
