import {createContext, FC, ReactNode, useEffect, useState} from "react";
import {ClinicalCaseState} from "../ClinicalCase.context";
import {
  TagRankScoreViewModel
} from "modules/diagnosis/clinical-case/TagRankViewModel";
import {ClinicalCaseLocalRepository} from "../../../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import {noAnswerString} from "config/const";

export interface YesNoQuestionsStepperContextProps {
  buildAnswer: ({ answers }: BuildAnswerArgs) => any;
  updateClinicalCase(clinicalCase: ClinicalCaseState): void;
  updateTagRank(tagRank: TagRankScoreViewModel[]): void;
  addTagQuestionAnswer(tagQuestionAnswer: TagQuestion): void;
  removeLastTagQuestionAnswer(): void;
  goToPreviousStep(clinicalCaseId: string): Promise<void>;
  tagQuestionsWave: {
    currentTagQuestionId: string;
    tagQuestionAnswers: TagQuestionsWave;
    step: number;
  }
  createStaticAnswer: (callback: () => void) => void;
  createDynamicAnswer: (callback: () => void) => void;
  isAnswerReady: boolean;
  updateIsAnswerReady: (flag: boolean) => void
}

export type BuildAnswerArgs = {
  answers: {
    absence_tags: string[];
    presence_tags: string[];
    uncertain_tags: string[];
    tag_category_options: string[];
  }
}

export type TagQuestion = {
  tagId: string;
  yesNo: 'yes' | 'no' | "unknown";
  tagCategory: string[];
  
}

type TagQuestionsWave = Array<TagQuestion>

export const YesNoQuestionsStepperContext = createContext<YesNoQuestionsStepperContextProps | null>(null);

export const YesNoQuestionsStepperContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const clinicalCaseRepository = new ClinicalCaseLocalRepository();
  const [clinicalCase, setClinicalCase] = useState<ClinicalCaseState | undefined>(undefined);
  const [tagRank, setTagRank] = useState<TagRankScoreViewModel[]>([]);
  const [tagQuestionsWave, setTagQuestionsWave] = useState<{ currentTagQuestionId: string; tagQuestionAnswers: TagQuestionsWave; step: number }>({
    currentTagQuestionId: tagRank?.length > 0 ? tagRank[0].id : '',
    tagQuestionAnswers: [],
    step: 0,
  });
  const [isAnswerReady, setAnswerReady] = useState(false);

  function updateClinicalCase(clinicalCase: ClinicalCaseState) {
    setClinicalCase(clinicalCase);
  }

  function updateTagRank(tagRank: TagRankScoreViewModel[]) {
    setTagRank(tagRank);
  }

  function updateIsAnswerReady(flag: boolean) {
    setAnswerReady(flag);
  }

  function addTagQuestionAnswer(tagQuestionAnswer: TagQuestion) {
    const newTagQuestionAnswers = [...tagQuestionsWave.tagQuestionAnswers];
    newTagQuestionAnswers.push(tagQuestionAnswer);

    if (!tagQuestionsWave.currentTagQuestionId) return;

    const index = tagRank.findIndex(({ id }) => id === tagQuestionsWave.currentTagQuestionId);
    if (index === -1) return;

    const nextIndex = index === tagRank.length - 1 ? index : index + 1;

    setTagQuestionsWave({ currentTagQuestionId: tagRank[nextIndex].id, tagQuestionAnswers: newTagQuestionAnswers, step: tagQuestionsWave.step + 1 });
  }

  function removeLastTagQuestionAnswer() {

    const index = tagRank.findIndex(({ id }) => id === tagQuestionsWave.currentTagQuestionId);
    if (index === -1 || index === 0) return;

    const _tagQuestionAnswers = [...tagQuestionsWave.tagQuestionAnswers];
    _tagQuestionAnswers.pop();


    setTagQuestionsWave({ currentTagQuestionId: tagRank[index - 1].id, tagQuestionAnswers: _tagQuestionAnswers, step: tagQuestionsWave.step - 1 });
  }

  function buildAnswer({ answers }: BuildAnswerArgs) {

    return {
      question_round: clinicalCase?.currentQuestionRound!,
      static_subround: clinicalCase?.currentStaticQuestionRound!,
      presence_tags: answers.presence_tags,
      absence_tags: answers.absence_tags,
      uncertain_tags: answers.uncertain_tags,
      tag_category_options: answers.tag_category_options.filter((tco) => tco !== noAnswerString),
    };
  }

  function createStaticAnswer(callback: () => void) {
    if(tagRank.length === 0) return;
    if(tagQuestionsWave.tagQuestionAnswers.length !== tagRank.length) return;
    if(!clinicalCase) return;
    
    let presence_tags: string[] = [];
    let absence_tags: string[] = [];
    let uncertain_tags: string[] = [];
    let tag_category_options: string[] = [];

    for(const tagQuestionAnswer of tagQuestionsWave.tagQuestionAnswers) {
      if(tagQuestionAnswer.yesNo === 'yes') {
        presence_tags.push(tagQuestionAnswer.tagId);
      } else  if (tagQuestionAnswer.yesNo === 'no') {
        absence_tags.push(tagQuestionAnswer.tagId);
      } else {
        uncertain_tags.push(tagQuestionAnswer.tagId);
      }

      tag_category_options = [...tag_category_options, ...tagQuestionAnswer.tagCategory];
    }

    const questionRoundAnswers = buildAnswer({answers: {presence_tags, absence_tags, uncertain_tags, tag_category_options}});

    clinicalCaseRepository.addQuestionRoundAnswer(clinicalCase.clinicalCaseId!, questionRoundAnswers).then(() => {
      clinicalCase.currentStaticQuestionRound += 1;
      clinicalCaseRepository.update(clinicalCase.clinicalCaseId!, {current_static_question_round: clinicalCase.currentStaticQuestionRound}).then(() => {
        callback();
      });
    });
  }

  function createDynamicAnswer(callback: () => void) {
    if(tagRank.length === 0) return;
    if(tagQuestionsWave.tagQuestionAnswers.length !== tagRank.length) return;
    if(!clinicalCase) return;

    let presence_tags: string[] = [];
    let absence_tags: string[] = [];
    let uncertain_tags: string[] = [];
    let tag_category_options: string[] = [];

    for (const tagQuestionAnswer of tagQuestionsWave.tagQuestionAnswers) {
      if (tagQuestionAnswer.yesNo === 'yes') {
        presence_tags.push(tagQuestionAnswer.tagId);
      } else if (tagQuestionAnswer.yesNo === 'no') {
        absence_tags.push(tagQuestionAnswer.tagId);
      } else {
        uncertain_tags.push(tagQuestionAnswer.tagId);
      }

      tag_category_options = [...tag_category_options, ...tagQuestionAnswer.tagCategory];
    }

    const clinicalPlanSet = buildAnswer({ answers: { presence_tags, absence_tags, uncertain_tags, tag_category_options } });

    clinicalCaseRepository.addQuestionRoundAnswer(clinicalCase.clinicalCaseId!, clinicalPlanSet).then(() => {
      clinicalCase.currentQuestionRound += 1;
      clinicalCaseRepository.update(clinicalCase.clinicalCaseId!, { current_question_round: clinicalCase.currentQuestionRound, }).then(() => {
        callback();
      })
    });
  }

  async function goToPreviousStep(clinicalCaseId: string) {
    await clinicalCaseRepository.deleteLastAnswer(clinicalCaseId);
    console.log('yes-no q: page reloads');
    window.location.reload();
  }

  useEffect(() => {
    if (tagRank.length === 0) return;
    setTagQuestionsWave({ ...tagQuestionsWave, currentTagQuestionId: tagRank[0].id, tagQuestionAnswers: [], step: 0 });
    updateIsAnswerReady(false);
  }, [tagRank]);

  return (
    <YesNoQuestionsStepperContext.Provider
      value={{
        buildAnswer,
        updateClinicalCase,
        updateTagRank,
        removeLastTagQuestionAnswer,
        addTagQuestionAnswer,
        createStaticAnswer,
        tagQuestionsWave,
        isAnswerReady,
        updateIsAnswerReady,
        createDynamicAnswer,
        goToPreviousStep,
      }}
    >
      {children}
    </YesNoQuestionsStepperContext.Provider>
  )
} 
