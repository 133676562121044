import { Card, CircularProgress, ProfileMiniature, Grid, Button } from 'infrastructure/components';
import { useNavigate, useParams } from 'react-router-dom';
import {PatientLocalRepository} from "../../../../infrastructure/repositories/PatientLocalRepository";
import { useClinicalCase } from "../context/useClinicalCase";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import './ClinicalCaseSidebar.component.scss';
import { PatientInterface } from 'modules/shared/entities/PatientInterface';
import { useEffect, useState } from 'react';

export const ClinicalCaseSidebar = () => {
  const { width } = useWindowSize();
  const { id } = useParams();
  const patientRepository = new PatientLocalRepository();
  const navigate = useNavigate();
  const clinicalCase = useClinicalCase();
  const [patient, setPatient] = useState<PatientInterface | undefined>(undefined);
  const patientId = clinicalCase?.patient;

  useEffect(() => {
    const fetchLocalPatient = async () => {
      if(!clinicalCase || !clinicalCase.patient) {
        return;
      }
      
      const patient = await patientRepository.findById(clinicalCase.patient);

      setPatient(patient);
    }

    fetchLocalPatient();
  }, [clinicalCase]);

  if (!patient) {
    return <CircularProgress />;
  }

  const fullName = `${patient.firstName} ${patient?.lastName}`;
  const subtitle = `${patient.sex === 'M' ? 'Male' : 'Female'}, ${(new Date().getFullYear() - patient.dateOfBirth.getFullYear())} years`;
  const avatarIcon = patient.sex === 'M' ? '/assets/AvatarIconMale.png' : '/assets/AvatarIcon.svg';

  if (width <= 900) {
    return (
      <aside className='clinical-case-sidebar__container'>
        <Card sx={{ '&:hover': { cursor: 'pointer' }, borderRadius: '10px', border: '0px', boxShadow: '0px 40px 30px -25px rgba(0, 0, 0, 0.05)' }} onClick={() => navigate(`/patient/${patientId}`)}>
          <ProfileMiniature
            title={fullName}
            subtitle={subtitle}
            listIcon={{ icon: avatarIcon }}
            hideIcon={true}
          />
        </Card>
        <Button
          className='clinical-case-sidebar__btn'
          sx={{ marginTop: '20px', textTransform: 'none' }}
          size="medium"
          variant="contained"
          onClick={() => navigate(`/clinical-case/${id}/case-notes`)}
          startIcon={<NoteAltIcon />}
        >
          Case Notes
        </Button>
      </aside>
    );
  }

  return (
    <Grid container direction={'column'} height={'100%'}>
      <Card sx={{ '&:hover': { cursor: 'pointer' }, borderRadius: '10px', border: '0px', boxShadow: '0px 40px 30px -25px rgba(0, 0, 0, 0.05)' }} onClick={() => navigate(`/patient/${patientId}`)}>
        <ProfileMiniature
          title={fullName}
          subtitle={subtitle}
          listIcon={{ icon: avatarIcon }}
          hideIcon={true}
        />
      </Card>
      <Button
        sx={{ marginTop: '20px', textTransform: 'none' }}
        size="medium"
        variant="contained"
        onClick={() => navigate(`/clinical-case/${id}/case-notes`)}
        startIcon={<NoteAltIcon />}
      >
        Case Notes
      </Button>
    </Grid>
  );
};
