import { CircularProgress } from '../components';
import { Alert } from '../components';
import { ReactElement } from 'react';

interface Props {
  loading: boolean;
  error: string;
  children: ReactElement;
}

export const ApiCallRender = (props: Props): ReactElement => {
  const { loading, error, children } = props;

  if (loading) {
    return <CircularProgress/>;
  }

  if (error) {
    return <Alert error={error}/>;
  }

  return children;
};