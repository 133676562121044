import { Tag } from "./Tag";

export class TagRank {
    tag: Tag;
    tagScore: number;

    constructor(tag: Tag, tagScore: number) {
        this.tag = tag;
        this.tagScore = tagScore;
    }
}