import { Box, Button, Typography } from 'infrastructure/components';
import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import { PatientHeaderWidgets } from '../../diagnosis/patient/components/PatientHeaderWidgets';
import { useEffect, useState } from 'react';
import { useApiService } from 'infrastructure/hooks/useApiService';

export const MetricsPage = () => {
  const { width, height } = useWindowSize();
  const calculatedWidth = width > 1024 ? width / 3 : width / 2;
  const [firstSyncInProgress, setFirstSyncInProgress] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<{ key: string, value: number }[]>([]);
  const [diagnosisMetrics, setDiagnosisMetrics] = useState<{ key: string, value: number }[]>([]);
  const [numOfImages, setNumOfImages] = useState<number>(0);
  const { syncMedicalLibrary, getMedicalLibraryMetrics, getDiagnosisMetrics, syncPatients, syncClinicalCases } = useApiService();

  let dateMedicalLibrary = localStorage.getItem('last-sync-date-medical-library');

  let localMedicalLibraryTime = (new Date(dateMedicalLibrary as string)).toLocaleString();

  const getMetrics = async () => {
    let newMetrics = await getMedicalLibraryMetrics();
    let newDiagnosisMetrics = await getDiagnosisMetrics();
    caches.open('images').then(c => {
      c.keys().then(ks => setNumOfImages(ks.length));
    });
    setMetrics(newMetrics);
    setDiagnosisMetrics(newDiagnosisMetrics);
  };

  const syncMedicalLibraryActuib = async () => {

    const dateFirstSync: string = "2020-01-01T00:00:00.000000Z";

    setFirstSyncInProgress(true);

    await syncMedicalLibrary(dateFirstSync);

    await getMetrics();

    setFirstSyncInProgress(false);  
  };


  const syncDiagnosticData = async () => {

    const dateFirstSync: string = "2020-01-01T00:00:00.000000Z";

    setFirstSyncInProgress(true);

    await syncPatients('pull', dateFirstSync);
    await syncClinicalCases('pull', dateFirstSync);

    await getMetrics();

    setFirstSyncInProgress(false);
  };

  useEffect(() => {
    getMetrics();
  }, []);

  if (firstSyncInProgress) return <p>
    Cargando datos...
    <img crossOrigin="anonymous" src="/sync-solid.svg" alt="logo" height={65} />
  </p>;

  return (
    <BasePage header={<PatientHeaderWidgets />}>
      <Box sx={{
        height: height / 1.35,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{ width: calculatedWidth, mt: 4 }}>
          <Typography variant="h4" color="primary" textAlign="center">Medical Library Metrics</Typography>
          <br />
          <Typography variant="body1" textAlign="justify">
            Last updated at: {dateMedicalLibrary} (local time: {localMedicalLibraryTime})
          </Typography>
          {metrics.map(metric => (
            <Typography key={metric.key} variant="body1" textAlign="justify">
              {metric.key}: {metric.value}
            </Typography>

          ))}
          <Typography variant="body1" textAlign="justify">
            numOfImages Cached: {numOfImages}
          </Typography>

        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            sx={{ fontWeight: 'bold', textTransform: 'none', height: '48px', width: '177px' }}
            onClick={() => syncMedicalLibraryActuib()}
          >
            Refresh Medical Library
          </Button>
        </Box>

        <Box sx={{ width: calculatedWidth, mt: 4 }}>
          <Typography variant="h4" color="primary" textAlign="center">Diagnosis Metrics</Typography>
          <br />
          {diagnosisMetrics.map(metric => (
            <Typography key={metric.key} variant="body1" textAlign="justify">
              {metric.key}: {metric.value}
            </Typography>

          ))}
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            sx={{ fontWeight: 'bold', textTransform: 'none', height: '48px', width: '177px' }}
            onClick={() => syncDiagnosticData()}
          >
            Refresh Diagnosis Data
          </Button>
        </Box>

      </Box>
    </BasePage>
  );
};
