import {Tag} from "../../../shared/entities/Tag";
import {TagRank} from "../../../shared/entities/TagRank";
import {
  ClinicalCaseResponse,
  TagCategoryOptionResponse
} from "modules/diagnosis/clinical-case/features/get-clinical-case/requests/ClinicalCase.apiResponse";
import {GetClinicalCase} from "../../../../infrastructure/api/GetClinicalCase";
import {findById, findRelatedTcos} from "../../../../infrastructure/database/Database";
import {
  QuestionRoundResponse
} from "../../clinical-case/features/get-question-round/requests/QuestionRound.apiResponse";
import {TagApiResponse} from "../../../medical-library/tags/features/list-tags/requests/Tag.apiResponse";
import {
  DiseaseArticleResponse
} from "../../../medical-library/disease-articles/features/get-disease-article/api/response/DiseaseArticleResponse";

export class TagRankService {

  private readonly getLocalClinicalCase: (id: string) => Promise<ClinicalCaseResponse>;

  constructor() {
    this.getLocalClinicalCase = id => findById('clinicalCases', id).then(c => c.toJSON()) as Promise<ClinicalCaseResponse>;
  }

  public async calculateTagQuestions(clinicalCaseId: string): Promise<TagRank[]> {
    const clinicalCase: ClinicalCaseResponse = await this.getLocalClinicalCase(clinicalCaseId);
    const questionRound: QuestionRoundResponse | undefined = await this.getLocalQuestionRound(clinicalCase.current_question_round);

    if (!questionRound) {
      return [];
    }

    if (clinicalCase.predicted_disease && questionRound) {
      return [];
    }

    const [diseaseRanksList, unmatchedTagsBetweenClinicalCaseAndDiseaseRank] =
        await this.getUnmatchedTagsBetweenClinicalCaseAndItsDiseaseRank(clinicalCase);

    let tagCategoryOptionsByTagDict: { [tagId: string]: TagCategoryOptionResponse[] } = {};

    let tagsOfTagRank: TagApiResponse[] = unmatchedTagsBetweenClinicalCaseAndDiseaseRank.filter((tag: TagApiResponse) => !tag.is_test);
    tagCategoryOptionsByTagDict = await this.getTagCategoryOptionsByTag(tagsOfTagRank);

    if (questionRound.round_number === 0) {
      const chiefComplaintsRelatedTags: TagApiResponse[] = await this.getChiefComplaintsRelatedTags(clinicalCase);

      tagCategoryOptionsByTagDict = await this.getTagCategoryOptionsByTag(chiefComplaintsRelatedTags);

      const unmatchedIds = unmatchedTagsBetweenClinicalCaseAndDiseaseRank.map(tag => tag.id);
      tagsOfTagRank = chiefComplaintsRelatedTags.filter((tag: TagApiResponse) => {
        return unmatchedIds.includes(tag.id);
      });
    }

    console.log('|');
    console.log('|');
    console.log('|');
    console.log('|');
    console.log('|');
    console.log('|');
    console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
    console.log(`Summary for tag rank in clinical case ${clinicalCase.id}`);
    console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');

    let diseasesRankListTrimed = diseaseRanksList.filter(diseaseRank => diseaseRank.rank_position <= questionRound.disease_rank_threshold);

    const tagRank: TagRank[] = [];
    for (let tag of tagsOfTagRank) {
      const tcoOfTag = tagCategoryOptionsByTagDict[tag.id];
      const tagScore = await this.calculateTagScore(tag, diseasesRankListTrimed, tcoOfTag)
      tagRank.push(new TagRank(
          tag,
          tagScore
      ));
    }
    console.log('###### Tag rank: ');
    console.log(tagRank);
    return tagRank
        .sort((a, b) => b.tagScore - a.tagScore)
        .slice(0, questionRound.question_count);

  }


  private async getLocalTagCategoryOptions(id: string): Promise<TagCategoryOptionResponse[]> {
    const tagCategoryOptions = await findRelatedTcos(id);

    return tagCategoryOptions;
  }

  private async getLocalQuestionRound(round_number: number): Promise<QuestionRoundResponse | undefined> {
    const questionRound = await findById('questionRounds', {
      selector: {
        round_number: {
          $eq: round_number
        }
      }
    }).then(c => {
      if (Object.keys(c).length === 0) {
        return {};
      } else {
        return c.toJSON();
      }
    });

    if (Object.keys(questionRound).length === 0) {
      return undefined;
    }

    return questionRound as QuestionRoundResponse;
  }

  private async getChiefComplaintsRelatedTags(clinicalCase: ClinicalCaseResponse): Promise<TagApiResponse[]> {
    const chiefComplaintsRelatedTags: TagApiResponse[] = [];

    for (const chiefComplaint of clinicalCase.chief_complaints) {
      const relatedTags = chiefComplaint.chief_complaint_related_tags;

      for (let rt of relatedTags!) {
        const relatedTag = await findById('tags', rt).then(tag => tag.toJSON());
        chiefComplaintsRelatedTags.push(relatedTag);
      }

    }

    return chiefComplaintsRelatedTags;
  }

  private async getTagCategoryOptionsByTag(unmatchedTagsBetweenClinicalCaseAndDiseaseRank: Tag[]): Promise<{ [tagId: string]: TagCategoryOptionResponse[] }> {
    const tagIds: string[] = unmatchedTagsBetweenClinicalCaseAndDiseaseRank.map(tag => tag.id);

    const tagCategoryOptionsByTagDict: { [tagId: string]: TagCategoryOptionResponse[] } = {};

    for (const tagId of tagIds) {
      tagCategoryOptionsByTagDict[tagId] = [];
      const categoryOptions = await this.getLocalTagCategoryOptions(tagId);
      tagCategoryOptionsByTagDict[tagId] = categoryOptions ? categoryOptions : [];
    }

    return tagCategoryOptionsByTagDict;
  }

  private async calculateTagScore(tag: Tag, diseaseRanks: GetClinicalCase.DiseaseRankResponse[], tagCategoryOptions: TagCategoryOptionResponse[]): Promise<number> {
    let totalTagScore = 0;
    console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
    for (const diseaseRank of diseaseRanks) {
      let totalLikelihoodInDisease = 0;

      const diseaseArticle: DiseaseArticleResponse = await findById('diseaseArticles', diseaseRank.disease_id).then(r => r.toJSON());
      const tagLikelihoods = diseaseArticle.tag.find(diseaseTagLikelihoodResponse => diseaseTagLikelihoodResponse.tag.id === tag.id);
      totalLikelihoodInDisease += tagLikelihoods ? this.getScore(tagLikelihoods.presence_likelihood_ratio) : 0;
      totalLikelihoodInDisease += tagLikelihoods ? this.getScore(tagLikelihoods.absence_likelihood_ratio) : 0;
      if (tagLikelihoods) {
        console.log('   * Tag presence/absence score for the disease '+diseaseRank.disease_name+': '+totalLikelihoodInDisease);
      }
      let tcoScoreSum = 0;
      for (const tagCategoryOption of tagCategoryOptions) {
        const categoryOption = diseaseArticle.tag_category_options.find(categoryOption => categoryOption.tag_category_option.id === tagCategoryOption.id);
        totalLikelihoodInDisease += categoryOption ? this.getScore(categoryOption.likelihood_ratio) : 0;
        tcoScoreSum += categoryOption ? this.getScore(categoryOption.likelihood_ratio) : 0
      }
      console.log("   * Tag TCOs score for disease "+diseaseRank.disease_name+": "+tcoScoreSum)
      const tagScoreInDisease = totalLikelihoodInDisease * diseaseRank.disease_score;
      console.log('      - Tag score calculus: total likelihood in disease * disease rank disease score = '+totalLikelihoodInDisease+'*'+diseaseRank.disease_score+' = '+tagScoreInDisease);
      console.log(" ");
      totalTagScore += tagScoreInDisease;
    }
    console.log('Tag score calculus summary for:');
    console.log('   - Tag: '+tag.name);
    console.log('   - ID: '+tag.id);
    console.log('   - Score in rank (before rounding): '+totalTagScore);
    console.log(" ");
    console.log(" ");
    return Math.round(totalTagScore);
  }

  private getScore(likelihood: number): number {
    // No me cuentes lo negativo:  0.25 da 0.25, -3 da 0
    return Math.max(likelihood, 0);
  }

  private async getUnmatchedTagsBetweenClinicalCaseAndItsDiseaseRank(clinicalCase: ClinicalCaseResponse): Promise<[GetClinicalCase.DiseaseRankResponse[], TagApiResponse[]]> {

    const clinicalCaseTags: TagApiResponse[] = [...clinicalCase.presence_tags, ...clinicalCase.absence_tags, ...clinicalCase.uncertain_tags];

    let questionRound: QuestionRoundResponse | undefined = await this.getLocalQuestionRound(clinicalCase.current_question_round);

    let diseaseRankTags: TagApiResponse[] = [];

    for (const disease of clinicalCase.disease_rank) {
      if (
          questionRound &&
          (questionRound.disease_score_threshold > disease.disease_score ||
              questionRound.disease_rank_threshold < disease.rank_position ||
              questionRound.disease_percentage_threshold > disease.relative_probability * 100.0)
      ) {
        continue;
      }

      const diseaseArticle: DiseaseArticleResponse = await findById('diseaseArticles', disease.disease_id).then(r => r.toJSON());

      const tags: TagApiResponse[] = [
        ...diseaseArticle.tag.map( t => t.tag),
        ...diseaseArticle.tag_category_options.map(tco => tco.tag_category_option.tag_category.tag)
      ];

      diseaseRankTags = [...diseaseRankTags, ...tags];
    }

    // To delete duplicate tags
    const tagIds = [...new Set(diseaseRankTags.map(obj => obj.id))];
    diseaseRankTags = tagIds.map(tagId => diseaseRankTags.find(tag => tag.id === tagId)!);

    const filteredTags: TagApiResponse[] = diseaseRankTags
        .filter((tagId: Tag) => !clinicalCaseTags.some((cTag: TagApiResponse) => cTag.id === tagId.id))
        .map((tagId: TagApiResponse) => tagId);

    return [clinicalCase.disease_rank, filteredTags];
  }
}
