import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import { Grid, useTheme } from '@mui/material';
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import { GetDiseaseArticleFeature } from '../../medical-library/disease-articles/features/get-disease-article/GetDiseaseArticleFeature';
import { useParams } from 'react-router-dom';
import { PatientHeaderWidgets } from '../patient/components/PatientHeaderWidgets';
import { ClinicalCaseSidebar } from './components/ClinicalCaseSidebar.component';
import {VersionPill} from "../../shared/components/VersionPill";

export const DiseaseArticlePage = () => {
  const { height } = useWindowSize();
  const theme = useTheme();
  const calculatedHeight = height - parseInt(theme.mixins.toolbar.minHeight as string);
  const { diseaseArticleId } = useParams();

  return (<BasePage header={<PatientHeaderWidgets/>}>
    <Grid container spacing={2} sx={{ height: (calculatedHeight) }}>
      <Grid item xs={3} sm={3} xl={3}>
        <ClinicalCaseSidebar/>
      </Grid>
      <Grid item xs pr={2} pl={2} style={{ height: '100%', overflow: 'auto', paddingBottom: '10%' }}>
        <GetDiseaseArticleFeature diseaseArticleId={diseaseArticleId!!}/>
      </Grid>
    </Grid>
    <VersionPill/>
  </BasePage>);
};
