import { Accordion, AccordionSummary, styled } from '@mui/material';
import { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  title: string;
  children: ReactNode;
}

const CustomAccordion = styled(Accordion)(({theme}) => ({
  background: theme.palette.background.paper,
  padding: '10px 20px',
  borderRadius: '10px',
  boxShadow: 'none',
  margin: '20px 0',

  '&:before': {
    background: theme.palette.background.paper,
  },
  '&.Mui-expanded': {
    padding: '10px 20px',
  }
}));

const CustomAccordionSummary = styled(AccordionSummary)(({theme}) => ({
  padding: '0px',
  fontSize: '16px',
  fontWeight: '700',
  margin: '0px',
  color: theme.palette.text.secondary,

  '.MuiAccordionSummary-content': {
    margin: '0px',
  },
}));

export const AccordionWrapper = (props: Props) => {
  return <CustomAccordion>
    <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
      {props.title}
    </CustomAccordionSummary>
    {props.children}
  </CustomAccordion>;
};