
export const clinicalInterventionSchema = {
  title: 'Clinical Intervention Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
  },
  required: [
    'id',
    'name',
  ],
};

// TODO review as it should be used
export const clinicalOutcomeSchema = {
  title: 'Clinical Intervention Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
  },
  required: [
    'id',
    'name',
  ],
};

export const diagnosticTestSchema = {
  title: 'Diagnostic Test Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
  },
  required: [
    'id',
    'name',
  ],
};

export const healthcareProfessionalTypeSchema = {
  title: 'Healthcare Processional Type Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
  },
  required: [
    'id',
    'name',
  ],
};

export const diseaseSchema = {
  title: 'Disease Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
  },
  required: [
    'id',
    'name',
  ],
};

export const triageSchema = {
  title: 'Triage Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
  },
  required: [
    'id',
    'name',
  ],
};

