import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {auth0ClientId, auth0Domain, medicalLibraryLastEntityToBeUpdated} from './config/const';
import { configureTheme } from './config/Theme';
import { AuthGuard } from './infrastructure/high-order-components/AuthGuard';
import { Router } from './infrastructure/router/Router';
import { BrowserRouter } from 'react-router-dom';
import { ClinicalCaseProvider } from './modules/diagnosis/clinical-case/context/ClinicalCase.provider';
import { UserBack } from './infrastructure/components';
import {Modal, Typography} from './infrastructure/components';
import './App.scss';
import React, { useEffect, useState } from 'react';
import { ErrorBoundaryPage } from './infrastructure/high-order-components/error-boundary-page/ErrorBoundaryPage';
import { StepProvider } from './modules/diagnosis/clinical-case/context/useStep';
import { addAllCollections, checkUserAlreadyLogged } from './infrastructure/database/Database';
import { YesNoQuestionsStepperContextProvider } from 'modules/diagnosis/clinical-case/context/YesNoQuestionsStepper/YesNoQuestionsStepperContext';
import {ServerSyncService} from "./modules/sync/ServerSyncService";
import {useUserStore} from "./infrastructure/store/userStore";
import {CacheRestorationService} from "./modules/sync/CacheRestorationService";

interface State {
  hasError: boolean;
}

type AppVersion = {
  majorVersion: undefined | number;
  minorVersion: undefined | number;
}

const FIVE_MIN_IN_MILISECONDS = 5 * 60 * 1000;
class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false } as State;
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryPage />;
    }

    return this.props.children as React.ReactElement;
  }
}

function App() {
  const syncService =  new ServerSyncService();
  const cacheRestorationService = new CacheRestorationService();
  const queryClient = new QueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstSyncInProgress, setFirstSyncInProgress] = useState<boolean>(false);
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  const sessionToken = useUserStore((state) => state.sessionToken);

  function getAppVersionLocalStorage(): AppVersion | undefined {
    const localAppVersion = localStorage.getItem('app-version');

    if (!localAppVersion) return undefined;

    return JSON.parse(localAppVersion);
  }

  function saveAppVersionLocalStorage(appVersion: AppVersion) {
    localStorage.setItem('app-version', JSON.stringify(appVersion));
  }

  function handleOrientationChange() {
    let userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.includes('mobi') || userAgent.includes('tablet')) {
      if(window.matchMedia("(orientation: portrait)").matches) {
        setIsLandscape(false);
      } else {
        setIsLandscape(true);
      }
    }
  }

  function getMedicalLibraryLastEntityUpdateDate() {
    return localStorage.getItem('sync_service-last-pull-updated-at_' + medicalLibraryLastEntityToBeUpdated);
  }

  function handleStorageChange(event: any){
    if (event.detail.entityName === medicalLibraryLastEntityToBeUpdated) {
      // Disease article is the last entity to be updated as part of the medical library
      setFirstSyncInProgress(false);
      setIsLoading(false);
    }
  }

  async function performAction(){
    if (!navigator.onLine) return;

    let isFirstSync = !(getMedicalLibraryLastEntityUpdateDate());
    if (isFirstSync) {
      setFirstSyncInProgress(true);
    }

    try {
      cacheRestorationService.reviewAndRestoreCache();
      let isFinished = await syncService.sync(isFirstSync);
      if (isFinished) {
        setFirstSyncInProgress(false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    handleOrientationChange();
    const orientationChange = () => handleOrientationChange();
    window.addEventListener("resize", orientationChange);

    return () => window.removeEventListener("resize", orientationChange);
  }, []);

  function getNewCodeVersion(newAppVersion: AppVersion) {
    caches.keys().then((cacheNames) => {
      for (const name of cacheNames) {
        if (!name.includes('medbrain-offline') && !name.includes('images')) {
          caches.delete(name);
        }
      }
    }).then(() => {
      saveAppVersionLocalStorage(newAppVersion!);
      console.log('app: page reloads');
      window.location.reload();
    })
  }

  useEffect(() => {
    if (navigator.onLine) {
      fetch('/app-version.json')
        .then((response) => response.json())
        .then((newAppVersion: AppVersion) => {
          const localAppVersion = getAppVersionLocalStorage();

          if (localAppVersion
            && newAppVersion?.majorVersion === localAppVersion?.majorVersion
            && newAppVersion?.minorVersion === localAppVersion?.minorVersion) {
            return;
          }

          getNewCodeVersion(newAppVersion)
        })
        .catch(() => {
          console.error('Invalid json app-version');
        })
    }
  }, []);

  useEffect(() => {
    const handleOfflineSync = async () => {
      setIsLoading(true);
      await addAllCollections();
      setIsLoading(false);
    }

    handleOfflineSync();
  }, []);

  useEffect(() => {
    const checkIfIsOnline = async () => {
      if (navigator.onLine) return;

      const isLogged = await checkUserAlreadyLogged();

      if (!isLogged && !window.location.pathname.includes('offline-login')) {
        window.location.href = '/offline-login';
      }
    }

    if (!isLoading) {
      checkIfIsOnline();
    }

  }, [isLoading]);

  useEffect(() => {
    window.addEventListener('entitySyncFinished', handleStorageChange);
    performAction();

    const interval = setInterval(() => {
      performAction();
    }, FIVE_MIN_IN_MILISECONDS);

    // Clear the interval and the listener when the component is unmounted
    return () => {
      clearInterval(interval);
      window.removeEventListener('storage', handleStorageChange);
    }

  }, [sessionToken]);

  if (isLoading) {
    return <div>.</div>;
  }
  if (firstSyncInProgress) {
    return (
      <Modal open={true} onClose={() => null}>
        <div className={"landscape-modal"}>
          <img crossOrigin="anonymous" alt="medbrain-logo" src={"/medbrain-logo-app.svg"}/>
          <Typography variant="body1" fontSize={'large'} marginTop={'1em'}>
            Please, wait until the medical library is synchronized...
          </Typography>
        </div>
      </Modal>
    )
  }

  if (isLandscape) {
    return (
      <Modal open={true} onClose={() => null}>
        <div className={"landscape-modal"}>
          <img crossOrigin="anonymous" alt="medbrain-logo" src={"/medbrain-logo-app.svg"}/>
          <Typography variant="body1" fontSize={'large'}>
            <p>
              Please, use this app just in portrait mode
            </p>
          </Typography>
        </div>
      </Modal>
    );
  }

  if (navigator.onLine) {
    return (
      <ThemeProvider theme={configureTheme()}>
        <Auth0Provider domain={auth0Domain} clientId={auth0ClientId}>
          {/* TODO Delete queryCLient usage to avoid this provider and the library */}
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <ErrorBoundary>
                <CssBaseline />
                <ClinicalCaseProvider>
                  <StepProvider>
                    <YesNoQuestionsStepperContextProvider>
                      <AuthGuard>
                        <Router />
                      </AuthGuard>
                    </YesNoQuestionsStepperContextProvider>
                  </StepProvider>
                </ClinicalCaseProvider>
                <UserBack />
              </ErrorBoundary>
            </BrowserRouter>
          </QueryClientProvider>
        </Auth0Provider>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={configureTheme()}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary>
            <CssBaseline />
            <ClinicalCaseProvider>
              <StepProvider>
                <YesNoQuestionsStepperContextProvider>
                  <Router />
                </YesNoQuestionsStepperContextProvider>
              </StepProvider>
            </ClinicalCaseProvider>
            <UserBack />
          </ErrorBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
