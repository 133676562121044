
interface CryptoNew extends Crypto {
  randomUUID?() : string;
}
export class Utils {
  public static deleteDuplicates(arr: any[]): any[] {
    return arr.reduce((acc: any[], curr) => {
      if (!acc.find(item => JSON.stringify(item) === JSON.stringify(curr))) {
        acc.push(curr);
      }
      return acc;
    }, []);
  }
  public static clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value));
  }
  public static normalize(value: number, min: number, max: number): number {
    return ((value - min) * 100) / (max - min);
  }
  public static clampAndNormalize(value: number, min: number, max: number): number {
    return this.normalize(this.clamp(value, min, max), min, max);
  }

  /**
   * Returns an empty string if Crypto API or randomUUID is not supported by browser.
   */
  public static generateUUID() : string {
    let cryptoRef: CryptoNew;
    let r: string | undefined = "";

    // @ts-ignore
    if (typeof self.crypto !== "undefined") {
      // @ts-ignore
      cryptoRef = self.crypto;
      r = cryptoRef.randomUUID?.();
    }

    return r ? r : "";
  }
}

