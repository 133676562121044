import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CircularProgress } from 'infrastructure/components';
import { useApiService } from 'infrastructure/hooks/useApiService';
import useTranslation from 'infrastructure/i18n/useTranslation';

type TypeOfProfessional = 'medical_doctor' | 'nurse' | 'health_worker' | 'chemist' | 'other';

interface UserDetails {
  name: string,
  surname: string,
  typeOfProfessional: TypeOfProfessional,
  healthCenter: string,
}

const toUserDetails = (response: any) => ({
  name: response.user?.first_name,
  surname: response.user?.last_name,
  typeOfProfessional: response.type_of_professional,
  healthCenter: response.health_center?.name,
} as UserDetails);

export const UserDetails = () => {

  const { fetchUserInfo } = useApiService();
  const [user, setUserDetails] = useState<UserDetails | null>(null);

  useEffect(() => {
    fetchUserInfo().then(response => setUserDetails(toUserDetails(response)));
  }, []);

  const basicInformationPageTranslations = useTranslation('BASIC_INFORMATION_PAGE');
  const userTranslations = useTranslation('USERS');

  const professionalTypes = {
    medical_doctor: userTranslations.MEDICAL_DOCTOR,
    nurse: userTranslations.NURSE,
    health_worker: userTranslations.HEALTH_WORKER,
    chemist: userTranslations.CHEMIST,
    other: userTranslations.OTHER,
  };

  if (!user) {
    return <CircularProgress/>;
  }

  return <Box>
    <Typography component={'p'}>
      <Typography component={'strong'}
                  marginRight={'0.5rem'}
                  fontWeight={'bold'}>{basicInformationPageTranslations.NAME_INPUT_TEXT}:</Typography>
      {user?.name}
    </Typography>
    <Typography component={'p'}>
      <Typography component={'strong'}
                  marginRight={'0.5rem'}
                  fontWeight={'bold'}>{basicInformationPageTranslations.SURNAME_INPUT_TEXT}:</Typography>
      {user?.surname}
    </Typography>
    <Typography component={'p'}>
      <Typography component={'strong'}
                  marginRight={'0.5rem'}
                  fontWeight={'bold'}>{userTranslations.TYPE_OF_PROFESSIONAL}:</Typography>
      {professionalTypes[user.typeOfProfessional] || professionalTypes['other']}
    </Typography>
    <Typography component={'p'}>
      <Typography component={'strong'}
                  marginRight={'0.5rem'}
                  fontWeight={'bold'}>{userTranslations.HEALTH_CENTER}:</Typography>
      {user.healthCenter}
    </Typography>
  </Box>;
};
