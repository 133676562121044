import { useState, useEffect } from 'react';
import {PatientLocalRepository} from "../repositories/PatientLocalRepository";
import {PatientInterface} from "../../modules/shared/entities/PatientInterface";
import {Sex} from "../../modules/diagnosis/clinical-case/SexEnum";

interface NutritionalStatusMedia {
    muac: string|undefined,
    wh: string|undefined,
    oedema: string|undefined
}

function monthDiff(d1: Date, d2: Date) {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();

    let daysDifference = d2.getDate() - d1.getDate();
    let daysInMonth = new Date(d2.getFullYear(), d2.getMonth() + 1, 0).getDate();
    let monthFraction = daysDifference / daysInMonth;
    let totalMonths = months + monthFraction;

    return totalMonths <= 0 ? 0 : Math.round(totalMonths);
}

export function usePatientData(patientId: string) {
    const [patientAgeInMonths, setPatientAgeInMonths] = useState<number | undefined>(undefined);
    const [patient, setPatient] = useState<PatientInterface | undefined>(undefined)
    const [nutritionalStatusImages, setNutritionalStatusImages] = useState<NutritionalStatusMedia>({
        muac: undefined,
        wh: undefined,
        oedema: undefined
    })

    useEffect(() => {
        const patientRepository = new PatientLocalRepository();
        let isMounted = true;

        (async () => {
            const patient = await patientRepository.findById(patientId);
            if (isMounted) {
                setPatientAgeInMonths(monthDiff(patient.dateOfBirth, new Date()));
                setPatient(patient)
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [patientId]);

    useEffect(() => {
        if (patientAgeInMonths) {
            if (patient?.sex === Sex.MALE) {
                if (patientAgeInMonths < 24) {
                    setNutritionalStatusImages({muac: undefined, wh: '/assets/boys-0-2.png', oedema: undefined})
                }
                if (patientAgeInMonths >= 24) {
                    setNutritionalStatusImages({muac: undefined, wh: '/assets/boys-2-5.png', oedema: undefined})
                }
            }
            if (patient?.sex === Sex.FEMALE) {
                if (patientAgeInMonths < 24) {
                    setNutritionalStatusImages({muac: undefined, wh: '/assets/girls-0-2.png', oedema: undefined})
                }
                if (patientAgeInMonths >= 24) {
                    setNutritionalStatusImages({muac: undefined, wh: '/assets/girls-2-5.png', oedema: undefined})
                }
            }
        }

    }, [patient, patientAgeInMonths]);

    return {
        entity: patient,
        patientAgeInMonths: patientAgeInMonths,
        nutritionalStatusImages: nutritionalStatusImages,
    };
}
