import { TagContextViewModel } from '../../../context/TagContext.viewModel';

export class PresentingComplaintViewModel {
  tags: TagContextViewModel;

  constructor(apiResponse: TagContextViewModel) {
    this.tags = apiResponse;
  }

}
