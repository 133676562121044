import { BasePage } from '../base-page/BasePage';
import { Box, Button, Typography } from '../../components';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {VersionPill} from "../../../modules/shared/components/VersionPill";

export const ErrorBoundaryPage = () => {

  const navigate: NavigateFunction = useNavigate();

  return (
    <BasePage>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="h3" align="center" sx={{ mt: 20 }}>
          Something went wrong.
        </Typography>
        <Typography variant="h5" align="center" sx={{ mb: 15, mt: 5 }}>
          Please try again later.
        </Typography>
        <Button variant="contained" onClick={() => {
          navigate('/');
          console.log('error: page reloads');
          window.location.reload();
        }}>
          Go to home page
        </Button>
      </Box>
      <VersionPill/>
    </BasePage>
  );
};