import { Box, Button, Typography } from 'infrastructure/components';
import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import useTranslation from 'infrastructure/i18n/useTranslation';
import { useAppUIStore } from 'infrastructure/store/appUIStore';
import { CreatePatientModal } from '../../diagnosis/patient/components/CreatePatientModal';
import { PatientHeaderWidgets } from '../../diagnosis/patient/components/PatientHeaderWidgets';

export const WelcomePage = () => {
  const {width, height} = useWindowSize();
  const calculatedWidth = width > 1024 ? width / 3 : width / 2;
  const welcomePageTranslations = useTranslation('WELCOME_PAGE');

  const modal = useAppUIStore((state) => ({
    openModal: state.openModal,
  }));

  return (
    <BasePage header={<PatientHeaderWidgets/>}>
      <Box sx={{
        height: height / 1.35,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <img crossOrigin="anonymous" src={'/assets/Welcome.svg'} width={calculatedWidth} alt="image"/>
        <Box sx={{width: calculatedWidth, mt: 4}}>
          <Typography variant="h4" color="primary" textAlign="center">{welcomePageTranslations.TITLE}</Typography>
          <br/>
          <Typography variant="body1" textAlign="justify">
            {welcomePageTranslations.MESSAGE}
          </Typography>
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            sx={{fontWeight: 'bold', textTransform: 'none', height: '48px', width: '177px'}}
            onClick={() => modal.openModal(<CreatePatientModal/>)}
          >
            {welcomePageTranslations.CREATE_PATIENT}
          </Button>
        </Box>
      </Box>
    </BasePage>
  );
};
