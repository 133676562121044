import { CaseDurationPage } from "../CaseDuration.page";
import { ClinicalCaseStep } from "../../../shared/entities/ClinicalCaseStep";
import useTranslation from "../../../../infrastructure/i18n/useTranslation";
import { Steps } from "./Steps";
import { PrioritySignsPage } from "../PrioritySigns.page";
import { ChiefComplaintsPage } from "../ChiefComplaints.page";
import { EmergencySignDetectedPage } from "../EmergencySignDetected.page";
import { PrioritySignDetectedPage } from "../PrioritySignDetected.page";
import { FillingChiefComplaintsTemplatesPage } from "../FillingChiefComplaintsTemplates.page";
import { AnsweringStaticQuestionsPage } from "../AnsweringStaticQuestions.page";
import { AnsweringDynamicQuestionsPage } from "../AnsweringDynamicQuestions.page";
import { DigitalHealthCardPage } from "../DigitalHealthCard.page";
import { TriagePage } from "../Triage.page";

let clinicalCaseTranslations = useTranslation("CLINICAL_CASE");
let steps = {
  0: {
    title: clinicalCaseTranslations.STATES.EMERGENCY_SIGNS,
    subtitle: clinicalCaseTranslations.STATES.EMERGENCY_SIGNS_HINT,
    content: TriagePage,
    name: ClinicalCaseStep.EMERGENCY_SIGNS,
  },
  1: {
    title: clinicalCaseTranslations.STATES.EMERGENCY_SIGN_DETECTED,
    subtitle: clinicalCaseTranslations.STATES.EMERGENCY_SIGN_DETECTED_HINT,
    content: EmergencySignDetectedPage,
    name: ClinicalCaseStep.EMERGENCY_SIGN_DETECTED,
  },
  2: {
    title: clinicalCaseTranslations.STATES.PRIORITY_SIGNS,
    subtitle: clinicalCaseTranslations.STATES.PRIORITY_SIGNS_HINT,
    content: PrioritySignsPage,
    name: ClinicalCaseStep.PRIORITY_SIGNS,
  },
  3: {
    title: clinicalCaseTranslations.STATES.PRIORITY_SIGN_DETECTED,
    subtitle: clinicalCaseTranslations.STATES.PRIORITY_SIGN_DETECTED_HINT,
    content: PrioritySignDetectedPage,
    name: ClinicalCaseStep.PRIORITY_SIGN_DETECTED,
  },
  4: {
    title: clinicalCaseTranslations.STATES.SELECTING_CASE_DURATION,
    subtitle: clinicalCaseTranslations.STATES.SELECTING_CASE_DURATION_HINT,
    content: CaseDurationPage,
    name: ClinicalCaseStep.SELECTING_CASE_DURATION,
  },
  5: {
    title: clinicalCaseTranslations.STATES.SELECTING_CHIEF_COMPLAINTS,
    subtitle: clinicalCaseTranslations.STATES.SELECTING_CHIEF_COMPLAINTS_HINT,
    content: ChiefComplaintsPage,
    name: ClinicalCaseStep.SELECTING_CHIEF_COMPLAINTS,
  },
  6: {
    title: clinicalCaseTranslations.STATES.FILLING_CHIEF_COMPLAINTS_TEMPLATES,
    subtitle:
      clinicalCaseTranslations.STATES.FILLING_CHIEF_COMPLAINTS_TEMPLATES_HINT,
    content: FillingChiefComplaintsTemplatesPage,
    name: ClinicalCaseStep.FILLING_CHIEF_COMPLAINTS_TEMPLATES,
  },
  7: {
    title: clinicalCaseTranslations.STATES.ANSWERING_STATIC_QUESTIONS,
    subtitle: clinicalCaseTranslations.STATES.ANSWERING_STATIC_QUESTIONS_HINT,
    content: AnsweringStaticQuestionsPage,
    name: ClinicalCaseStep.ANSWERING_STATIC_QUESTIONS,
  },
  8: {
    title: clinicalCaseTranslations.STATES.ANSWERING_DYNAMIC_QUESTIONS,
    subtitle: clinicalCaseTranslations.STATES.ANSWERING_DYNAMIC_QUESTIONS_HINT,
    content: AnsweringDynamicQuestionsPage,
    name: ClinicalCaseStep.ANSWERING_DYNAMIC_QUESTIONS,
  },
  9: {
    title: "",
    subtitle: "",
    content: DigitalHealthCardPage,
    name: ClinicalCaseStep.DIGITAL_HEALTH_CARD_CREATED,
  },
};

export const useSteps = (): Steps => {
  return steps;
};
