import {ClinicalCaseLocalRepository} from "../../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import { useClinicalCase } from '../context/useClinicalCase';
import { UpdateClinicalCaseApiRequest } from '../features/update-clinical-case/UpdateClinicalCase.apiRequest';
import { useDispatchClinicalCase } from "../context/useDispatchClinicalCase";
export const useUpdateClinicalCase = () => {
  const clinicalCase = useClinicalCase();
  const clinicalCaseRepository = new ClinicalCaseLocalRepository()
  const setClinicalCase = useDispatchClinicalCase();

  const execute = (attribute: UpdateClinicalCaseApiRequest) => {
    return clinicalCaseRepository.update(clinicalCase.clinicalCaseId!, {...attribute}).then(_ => {
      clinicalCaseRepository.findById(clinicalCase.clinicalCaseId!).then((clinicalCase) => {
        setClinicalCase(clinicalCase);
      });
    });
  };

  return { fetch: execute };
};
