import MUIAlert from '@mui/material/Alert';
import { Box } from '@mui/material';

interface Props {
  error?: string;
  severity?: 'error' | 'info' | 'warning';
}

export const Alert = (props: Props) => {
  if (!props.error) {
    return null;
  }

  const severity = props.severity || 'error';

  return <Box p={2}>
    <MUIAlert severity={severity}>{props.error}</MUIAlert>
  </Box>;
};