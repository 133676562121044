import { useNavigate } from 'react-router-dom';
import { CreatePatient } from 'infrastructure/api';
import { Typography } from 'infrastructure/components';
import {PatientLocalRepository} from "../../../../infrastructure/repositories/PatientLocalRepository";
import useTranslation from 'infrastructure/i18n/useTranslation';
import { useAppUIStore } from 'infrastructure/store/appUIStore';
import { CreatePatientForm, CreatePatientFormData } from './CreatePatientForm';
import {DateTime} from "luxon";

export const CreatePatientModal = () => {
  const welcomePageTranslations = useTranslation('WELCOME_PAGE');
  const modal = useAppUIStore((state) => ({
    closeModal: state.closeModal,
  }));

  const patientRepository = new PatientLocalRepository();
  const navigate = useNavigate();

  const toCreatePatientRequest: (form: CreatePatientFormData) => CreatePatient.Request =
    form => ({
      date_of_birth: form.birthdate ? form.birthdate.toFormat('yyyy-MM-dd') : DateTime.now().toFormat('yyyy-MM-dd'),
      sex: form.sex,
      first_name: form.name,
      last_name: form.surname,
      is_simulated: form.is_simulated,
    });

  const createPatient = (form: CreatePatientFormData) => {
      patientRepository.create(toCreatePatientRequest(form)).then((patient) => {
          modal.closeModal();
          navigate(`/patient/${patient.id}`);
      });
  };

  return <>
    <Typography variant="h2" fontSize={'x-large'} fontWeight={'normal'} mb={3}>
      {welcomePageTranslations.CREATE_PATIENT}
    </Typography>
    <CreatePatientForm onSubmit={createPatient} />
  </>;
};
