import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface RadioButtonOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  label: string;
  onChange: (value: string) => void;
  value: string;
  options: Array<RadioButtonOption>;
}

export const RadioButton = (props: Props) => {
  function renderOptions() {
    return <>
      {
        props.options.map(option =>
          <FormControlLabel
            key={option.label}
            value={option.value}
            control={<Radio/>}
            label={option.label}
          />)
      }
    </>;
  }

  return (
    <FormControl>
      <FormLabel>{props.label}</FormLabel>
      <RadioGroup
        row
        value={props.value}
        onChange={(_, value: string) => props.onChange(value)}
      >
        {renderOptions()}
      </RadioGroup>
    </FormControl>
  );
};