import { DiseaseRank } from "../../ClinicalCase";

interface SigleDiseasesProps {
  disease?: DiseaseRank
}

interface DiagnosisCardProps {
  diseases: DiseaseRank[];
  type: 'emergency' | 'priority' | 'non-urgent';
}

function MultipleDiseases({ diseases, type }: DiagnosisCardProps) {
  const totalScore = diseases.reduce((prev, curr) => {
    return prev + curr.diseaseScore;
  }, 0);

  return (
    <>
      {
        diseases.map((disease, index) => (
          <div className={`disease__item ${type}`} key={disease.diseaseId}>
            <p className="disease-number">{index + 1}.</p>
            <p className="disease-name">{disease.diseaseName}</p>
            <p className="disease-percentage">{((disease.diseaseScore/totalScore)*100).toFixed(2)}%</p>
          </div>
        ))
      }
    </>
  )
}

function SigleDiseases({ disease }: SigleDiseasesProps) {
  return (
    <div className="disease__item">
      <p className="disease-name">{disease?.diseaseName}</p>
    </div>
  )
}

export function DiagnosisCard({ diseases, type }: DiagnosisCardProps) {
  return (
      <section className={`diagnosis-card__container ${type}`}>
          <div className="disease__list">
              {
                  diseases?.length > 1 ? <MultipleDiseases diseases={diseases} type={type}/> :
                      <SigleDiseases disease={diseases[0]}/>
              }
          </div>
          <div className={`triage-status__label ${type}`}>
              <span className={`bullet ${type}`}></span>
              <p>{type}</p>
          </div>
      </section>
  )
}