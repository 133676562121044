import {baseApiUrl, userInfoLocalStorage} from "../../config/const";

export abstract class ApiRepositoryBase {
    protected async handlePaginationRequest(resource: string, ...queryStringParams: Array<{ name: string, value: string }>): Promise<{ data: Array<any>, next: string }> {
        const paginationParams = this.constructQueryString(queryStringParams);

        const response = await this.doPaginatedRequest(resource, paginationParams);
        return {
            data: response.results,
            next: response.next,
        };
    }

    protected async handleRequest(resource: string, method: string = "GET", payload: object = {}, ...queryStringParams: Array<{ name: string, value: string }>) {
        const params = this.constructQueryString(queryStringParams);
        return this.doRequestWithSessionToken(resource, params, method, payload);
    }

    protected async doPaginatedRequest(resource: string, paginationParams: URLSearchParams): Promise<any> {
        return this.doRequestWithSessionToken(resource, paginationParams, "GET", {});
    }

    protected async doRequestWithSessionToken(resource: string, params: URLSearchParams, method: string, payload: object): Promise<any> {
        const sessionToken = this.getSessionToken();

        const url = new URL(baseApiUrl+resource);
        url.search = params.toString();

        const headers = new Headers({ Authorization: `Bearer ${sessionToken}` });
        if (method !== 'GET') {
            headers.append('Content-Type', 'application/json');
        }

        const response = await fetch(url.toString(), {
            method,
            headers,
            body: method !== 'GET' ? JSON.stringify(payload) : undefined,
        });

        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
    }

    private constructQueryString(queryStringParams: Array<{ name: string, value: string }>): URLSearchParams {
        const params = new URLSearchParams();
        queryStringParams.forEach(param => params.set(param.name, param.value));
        return params;
    }

    private getSessionToken(): string {
        const userSession = window.localStorage.getItem(userInfoLocalStorage);
        const parsedUserSession = JSON.parse(userSession!);

        if (! parsedUserSession.state.sessionToken ) throw new Error('Session token not found');
        return parsedUserSession.state.sessionToken;
    }
}
