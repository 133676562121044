
export const questionRoundSchema = {
  title: 'Question Round Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    round_number: {
      type: 'number',
    },
    disease_score_threshold: {
      type: 'number',
    },
    disease_percentage_threshold: {
      type: 'number',
    },
    disease_rank_threshold: {
      type: 'number',
    },
    question_round: {
      type: 'number',
    }
  },
  required: [
    'id',
  ],
};
