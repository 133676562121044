import { DateTime } from 'luxon';
import React from 'react';
import useTranslation from 'infrastructure/i18n/useTranslation';
import { PatientInterface } from '../../../shared/entities/PatientInterface';
import { Avatar, CircularProgress, Grid, Typography } from 'infrastructure/components';
import { Sex } from '../../../shared/entities/SexEnum';
import './PatientProfile.scss';

interface PatientProfileProps {
  patient?: PatientInterface;
  isLoading?: boolean;
}

export const PatientProfile: React.FC<PatientProfileProps> = (
  {
    patient = {
      id: '1',
      updatedAt: new Date(),
      dateOfBirth: new Date(),
      firstName: '-',
      lastName: '-',
      medbrainId: '-',
      sex: Sex.MALE,
      createdAt: new Date(),
      createdBy: { name: 'Javi', id: '2' },
    } as PatientInterface,
    isLoading = false,
  }) => {

  const basicInformationTranslations = useTranslation('BASIC_INFORMATION_PAGE');

  const getSexTranslatedText = (sex: string) => {
    if (sex.toUpperCase() == 'M') {
      return basicInformationTranslations.MALE_OPTION_TEXT;
    }
    if (sex.toUpperCase() == 'F') {
      return basicInformationTranslations.FEMALE_OPTION_TEXT;
    }
    return '';
  };

  const getSexAvatarIcon = (sex: string) => {
    if (sex.toUpperCase() == 'M') {
      return '/assets/AvatarIconMale.png';
    }
    if (sex.toUpperCase() == 'F') {
      return '/assets/AvatarIcon.svg';
    }
    return '';
  };

  const profileFieldsData = [
    {
      name: basicInformationTranslations.NAME_INPUT_TEXT,
      value: patient?.firstName + ' ' + patient?.lastName,
    },
    {
      name: basicInformationTranslations.SEX_INPUT_TEXT,
      value: patient?.id === '1' ? '-' : getSexTranslatedText(patient?.sex),
    },
    {
      name: basicInformationTranslations.DATE_OF_BIRTH_INPUT_TEXT,
      value: patient?.id === '1' ? '-' : DateTime.fromJSDate(patient.dateOfBirth).toFormat('dd/MM/yyyy')
    },
    {
      name: basicInformationTranslations.MEDBRAIN_NUMBER,
      value: patient?.medbrainId || '-',
    },
  ];

  const renderProfileFields = () => {
    return profileFieldsData.map((profileField) => {
      return (
        <Grid
          key={`${profileField.name}${profileField.value}`}
          item

        >
          <Grid
            container
            direction="column"
            className="patient-profile-field"
          >
            <Typography
              fontSize="20px"
              fontWeight={500}
              color="#8F8F8F"
              lineHeight="27px"
              noWrap={true}
            >
              {profileField.name}
            </Typography>
            <Typography
              variant="body1"
              fontSize="22px"
              fontWeight={900}
              lineHeight="30px"
            >
              {profileField.value}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  if (isLoading) {
    <CircularProgress />
  }
  return <>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: "1px solid #DBD8E6",
        borderRadius: "10px",
        padding: "26px 38px",
        marginTop: "30px",
      }}
    >
      <Grid item className="patient-profile-avatar">
        <Avatar
          sx={{
            width: "120px",
            height: "120px",
          }}
          src={getSexAvatarIcon(patient?.sex)}
        />
      </Grid>

      {renderProfileFields()}
    </Grid>
  </>;
};
