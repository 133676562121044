import {
  Autocomplete,
  Button,
  ClinicalOutcomesIcon, DatePicker,
  DiagnosisConfirmationIcon,
  GeneralAndManagementIcon,
  Grid, TextArea,
  Title,
} from 'infrastructure/components';
import { PatientHeaderWidgets } from '../patient/components/PatientHeaderWidgets';
import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import { TextField } from '@mui/material';
import useTranslation from 'infrastructure/i18n/useTranslation';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiService } from 'infrastructure/hooks/useApiService';
import { useDispatchClinicalCase } from '../clinical-case/context/useDispatchClinicalCase';
import { GoBackButton } from 'infrastructure/components/go-back-button/GoBackButton';
import { CaseNoteSection } from './components/CaseNoteSection';
import { ClinicalCaseViewModel } from '../clinical-case/features/get-clinical-case/view-models/ClinicalCase.viewModel';
import {ClinicalCaseLocalRepository} from "../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import {VersionPill} from "../../shared/components/VersionPill";

type SelectOption = {
  id: string;
  name: string;
}

type SelectOptions = SelectOption[];

export const CaseNotesPage = () => {
  const translations = useTranslation('CASES_NOTES');
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getLocalAllDiseaseArticle,
    getLocalAllHealthcareProffesionalTypes,
    getLocalAllClinicalinterventons,
    getLocalAllDiagnosticTest,
    getLocalAllClinicalOutcome,
    getLocalAllTriage,
  } = useApiService();
  const clinicalCaseRepository = new ClinicalCaseLocalRepository();
  const dispatchClinicalCase = useDispatchClinicalCase();
  const [isLoading, setIsLoading] = useState(true);
  const [diseases, setDiseases] = useState<Array<{ id: string; name: string }>>([]);
  const [clinicalInterventions, setClinicalInterventions] = useState<Array<{ id: string; name: string }>>([]);
  const [diagnosticTests, setDiagnosticTests] = useState<Array<{ id: string; name: string }>>([]);
  const [healthcareProffesionalTypes, setHealthcareProffesionalTypes] = useState<Array<{ id: string; name: string }>>([]);
  const [clinicalOutcomes, setClinicalOutcomes] = useState<Array<{ id: string; name: string }>>([])
  const [triages, setTriages] = useState<Array<{ id: string; name: string }>>([])
  const [errors, _] = useState<{ [key: string]: any }>({});

  const [selectedValues, setSelectedValues] = useState<{ [key: string]: any }>({
    diagnostic_tests_used: [],
    diagnostic_made_by: [],
    confirmed_diagnostic_labels: [],
    confirmed_triage: [],
    triage_made_by: [],
    clinical_interventions: [],
    clinical_outcomes: [],
    clinical_diagnosis: [],
    case_notes: '',
    admission_decision_date: '',
    clinical_outcomes_date: '',
  });

  const handleOnChange = (key: string) => (value: unknown) => {
    setSelectedValues({...selectedValues, [key]: value });
  };

  function idsToSelectOptions(idsArray: string[], optionsArray: SelectOptions) {
    return idsArray.map((id: any) => {
      let elementFound;
      if (id.id) {
         elementFound = optionsArray.find((option) => option.id === id.id);
      } else {
         elementFound = optionsArray.find((option) => option.id === id);
      }
      if (!elementFound) {
        return {
          id: '',
          name: '',
        };
      }
      return {
        id: elementFound.id,
        name: elementFound.name
      }
    }).filter((el) => el.id !== '');
  }

  function checkClinicalCaseAttributes(clinicalCase: ClinicalCaseViewModel) {
    let diagnosticTestsUsed: SelectOptions = [];
    let diagnosticMadeBy: SelectOptions = [];
    let confirmedDiagnosticLabels: SelectOptions = [];
    let confirmedTriage: SelectOptions = [];
    let triageMadeBy: SelectOptions = [];
    let clinicalInterventionsOptions: SelectOptions = [];
    let clinicalOutcomesOptions: SelectOptions = [];
    let clinicalDiagnosis: SelectOptions = [];

    if (clinicalCase.diagnostic_tests_used) {
      let auxDiagnosticTestsUsed: any[] = clinicalCase.diagnostic_tests_used;
      diagnosticTestsUsed = idsToSelectOptions(auxDiagnosticTestsUsed, diagnosticTests);
    }

    if (clinicalCase.diagnostic_made_by) {
      let auxDiagnosticMadeBy: any[] = clinicalCase.diagnostic_made_by;
      diagnosticMadeBy = idsToSelectOptions(auxDiagnosticMadeBy, healthcareProffesionalTypes);
    }

    if (clinicalCase.confirmed_diagnostic_labels) {
      let auxConfirmedDiagnosticLabels: any[] = clinicalCase.confirmed_diagnostic_labels;
      confirmedDiagnosticLabels = idsToSelectOptions(auxConfirmedDiagnosticLabels, diseases);
    }

    if (clinicalCase.confirmed_triage) {
      let auxConfirmedTriage: any[] = clinicalCase.confirmed_triage;
      confirmedTriage = idsToSelectOptions(auxConfirmedTriage, triages);
    }

    if (clinicalCase.triage_made_by) {
      let auxTriageMadeBy: any[] = clinicalCase.triage_made_by;
      triageMadeBy = idsToSelectOptions(auxTriageMadeBy, healthcareProffesionalTypes);
    }

    if (clinicalCase.clinical_interventions) {
      let auxClinicalInterventions: any[] = clinicalCase.clinical_interventions;
      clinicalInterventionsOptions = idsToSelectOptions(auxClinicalInterventions, clinicalInterventions);
    }

    if (clinicalCase.clinical_diagnosis) {
      let auxClinicalDiagnosis: any[] = clinicalCase.clinical_diagnosis;
      clinicalDiagnosis = idsToSelectOptions(auxClinicalDiagnosis, diseases);
    }

    if (clinicalCase.clinical_outcomes) {
      let auxClinicalOutcomes: any[] = clinicalCase.clinical_outcomes;
      clinicalOutcomesOptions = idsToSelectOptions(auxClinicalOutcomes, clinicalOutcomes);
    }

    return {
      diagnosticTestsUsed,
      diagnosticMadeBy,
      confirmedDiagnosticLabels,
      confirmedTriage,
      triageMadeBy,
      clinicalInterventions: clinicalInterventionsOptions,
      clinicalOutcomes: clinicalOutcomesOptions,
      clinicalDiagnosis
    }
  }



  const setValues = (key: string, values: [{ id: string, name: string }] | string) => {
    if (key === 'case_notes') {
      return setSelectedValues({ ...selectedValues, case_notes: values });
    }
    selectedValues[key] = (values as [{ id: string, name: string }]).map((value) => value);
    setSelectedValues({ ...selectedValues });
  };

  const save = () => {
    clinicalCaseRepository.update(id!, {
      diagnostic_tests_used: selectedValues.diagnostic_tests_used.map((el: any) => el.id),
      diagnostic_made_by: selectedValues.diagnostic_made_by.map((el: any) => el.id),
      confirmed_diagnostic_labels: selectedValues.confirmed_diagnostic_labels.map((el: any) => el.id),
      triage_made_by: selectedValues.triage_made_by.map((el: any) => el.id),
      clinical_interventions: selectedValues.clinical_interventions.map((el: any) => el.id),
      clinical_outcomes: selectedValues.clinical_outcomes.map((el: any) => el.id),
      clinical_diagnosis: selectedValues.clinical_diagnosis.map((el: any) => el.id),
      case_notes: selectedValues.case_notes,
      confirmed_triage: selectedValues.confirmed_triage.map((el: any) => el.id),
      admission_decision_date: (typeof selectedValues.admission_decision_date === 'object') ? selectedValues.admission_decision_date?.toFormat('yyyy-MM-dd') : selectedValues.admission_decision_date,
      clinical_outcomes_date: (typeof selectedValues.clinical_outcomes_date === 'object') ? selectedValues.clinical_outcomes_date?.toFormat('yyyy-MM-dd') : selectedValues.clinical_outcomes_date,
    });
    navigate(`/clinical-case/${id}`);
  };

  useEffect(() => {
    const fetchLabels = async () => {
      const diseasesArticles = await getLocalAllDiseaseArticle();
      const healthcareProffesionalTypes = await getLocalAllHealthcareProffesionalTypes();
      const clinicalInterventions = await getLocalAllClinicalinterventons();
      const diagnosticTests = await getLocalAllDiagnosticTest();
      const clinicalOutcomes = await getLocalAllClinicalOutcome();
      const triages = await getLocalAllTriage();

      setDiseases(diseasesArticles.map((diseaseArticle: any) => {
        return {
          id: diseaseArticle.id,
          name: diseaseArticle.name,
        }
      }));
      setHealthcareProffesionalTypes(healthcareProffesionalTypes.map((healthcareProffesionalType: any) => {
        return {
          id: healthcareProffesionalType.id,
          name: healthcareProffesionalType.name,
        }
      }));
      setClinicalInterventions(clinicalInterventions.map((clinicalIntervention: any) => {
        return {
          id: clinicalIntervention.id,
          name: clinicalIntervention.name,
        }
      }));
      setDiagnosticTests(diagnosticTests.map((diagnosticTest: any) => {
        return {
          id: diagnosticTest.id,
          name: diagnosticTest.name,
        }
      }));
      setClinicalOutcomes(clinicalOutcomes.map((clinicalOutcome: any) => {
        return {
          id: clinicalOutcome.id,
          name: clinicalOutcome.name,
        }
      }));
      setTriages(triages.map((triage: any) => {
        return {
          id: triage.id,
          name: triage.name,
        }
      }));
      setIsLoading(false);
    }

    fetchLabels();
  }, []);

  useEffect(() => {
    if (isLoading) return;
    clinicalCaseRepository.findById(id!).then((clinicalCase) => {
      const {
        diagnosticTestsUsed,
        diagnosticMadeBy,
        confirmedDiagnosticLabels,
        confirmedTriage,
        triageMadeBy,
        clinicalInterventions,
        clinicalOutcomes,
        clinicalDiagnosis
      } = checkClinicalCaseAttributes(clinicalCase);

      dispatchClinicalCase(clinicalCase);
      setSelectedValues((prev) => ({
        ...prev,
        diagnostic_tests_used: diagnosticTestsUsed,
        diagnostic_made_by: diagnosticMadeBy,
        confirmed_diagnostic_labels: confirmedDiagnosticLabels,
        confirmed_triage: confirmedTriage,
        triage_made_by: triageMadeBy,
        clinical_interventions: clinicalInterventions,
        clinical_outcomes: clinicalOutcomes,
        clinical_diagnosis: clinicalDiagnosis,
        case_notes: clinicalCase.case_notes,
        admission_decision_date: clinicalCase?.admission_decision_date ? clinicalCase.admission_decision_date : '',
        clinical_outcomes_date: clinicalCase.clinical_outcomes_date ? clinicalCase.clinical_outcomes_date : '',
      }));
    });
  }, [isLoading]);

  if (isLoading) return <div>Loading</div>;

  // TODO bug case notes text is not updated when i remove it.
  return (
    <BasePage header={<PatientHeaderWidgets />}>
      <Grid item sx={{ backgroundColor: 'background.paper', margin: '50px', borderRadius: '10px', padding: '30px' }}>
        <Title fontSize="34px" fontSizeSubtitle="21px" title={translations.CASE_NOTES}
          subtitle={translations.CASE_NOTES_HINT} align={'left'} />

        <Grid sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: 'auto',
          paddingTop: '30px',
        }}>

          <CaseNoteSection
            title="Diagnosis"
            icon={<DiagnosisConfirmationIcon />}
            color="#00ADEE"
            withDivider={false}
          >
            <Grid sx={{ display: 'grid', justifyContent: 'space-between', margin: 'auto', gridTemplateColumns: '1fr' }}>
            <Autocomplete
                multiple
                sx={{ flexGrow: 1, margin: '10px' }}
                id="clinical_diagnosis"
                options={diseases}
                value={selectedValues.clinical_diagnosis}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => setValues('clinical_diagnosis', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Clinical Diagnosis by Specialist Doctor"
                    placeholder="What was the diagnosis made clinacally?"
                  />
                )}
              />
            </Grid>
            <Grid sx={{ display: 'grid', justifyContent: 'space-between', margin: 'auto', gridTemplateColumns: '1fr 1fr' }}>
              <Autocomplete
                multiple
                sx={{ flexGrow: 1, margin: '10px' }}
                id="diagnostic_tests_used"
                options={diagnosticTests}
                value={selectedValues.diagnostic_tests_used}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => setValues('diagnostic_tests_used', value)}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Diagnostic Tests Used (if any)"
                    placeholder="Add diagnoctic tests used"
                  />
                )}
              />
              <Autocomplete
                multiple
                sx={{ flexGrow: 1, margin: '10px' }}
                id="confirmed_diagnostic_labels"
                options={diseases}
                getOptionLabel={(option) => option.name}
                value={selectedValues.confirmed_diagnostic_labels}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => setValues('confirmed_diagnostic_labels', value)}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Confirmed Diagnosis"
                    placeholder="Add diagnosis confirmed"
                  />
                )}
              />
            </Grid>
          </CaseNoteSection>
          <CaseNoteSection
            title="Triage"
            icon={<GeneralAndManagementIcon />}
            color="#0ACA9C"
            withDivider={false}
          >
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
              <Autocomplete
                multiple
                sx={{ flexGrow: 1, margin: '10px' }}
                id="triage_made_by"
                options={healthcareProffesionalTypes}
                getOptionLabel={(option) => option.name}
                value={selectedValues.triage_made_by}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => setValues('triage_made_by', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Triage Made By"
                    placeholder="Who made the triage?"
                  />
                )}
              />
              <Autocomplete
                multiple
                sx={{ flexGrow: 1, margin: '10px' }}
                id="confirmed_triage"
                options={triages}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => setValues('confirmed_triage', value)}
                value={selectedValues.confirmed_triage}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Confirmed Triage"
                    placeholder="Add confirmed triage"
                  />
                )}
              />
            </Grid>

          </CaseNoteSection>
          <CaseNoteSection
            title="Documentation"
            icon={<ClinicalOutcomesIcon />}
            color="#F16D6A"
            withDivider={false}
          >
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
              <Autocomplete
                multiple
                sx={{ flexGrow: 1, margin: '10px' }}
                id="clinical_interventions"
                options={clinicalInterventions}
                value={selectedValues.clinical_interventions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => setValues('clinical_interventions', value)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Hospital Admission Status"
                    placeholder="Add hospital admission status"
                  />
                )}
              />
              <Grid item xs={5}>
                <DatePicker
                    error={errors.admission_decision_date}
                    label="Admission status decision date"
                    onChange={handleOnChange('admission_decision_date')}
                    value={selectedValues.admission_decision_date}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
              <Autocomplete
                multiple
                sx={{ flexGrow: 1, margin: '10px' }}
                id="clinical_outcomes"
                options={clinicalOutcomes}
                value={selectedValues.clinical_outcomes}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => setValues('clinical_outcomes', value)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Clinical outcomes at the moment of discarge"
                    placeholder="Add clinical outcomes at the moment of discarge"
                  />
                )}
              />
              <Grid item xs={5}>
                <DatePicker
                    error={errors.clinical_outcomes_date}
                    label="Clinical outcomes date"
                    onChange={handleOnChange('clinical_outcomes_date')}
                    value={selectedValues.clinical_outcomes_date}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
              <TextArea
                label="Case Notes"
                value={selectedValues.case_notes}
                sx={{ flexGrow: 1, margin: '20px 10px 10px 10px' }}
                maxRows={6}
                onChange={(value) => {
                  setValues('case_notes', value as string);
                }}
                placeholder="Add any other symptoms or physical signs not provided by MedBrain or any other information about this patient's case"
              />
            </Grid>
          </CaseNoteSection>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '60px', marginTop: '2rem' }}>
          <GoBackButton onClick={() => navigate(`/clinical-case/${id}`)} />
          <Button
            size="medium"
            variant="contained"
            onClick={() => save()}
            style={{ textTransform: 'none', fontWeight: 'bold', width: '177px' }}
          >
            Save
          </Button>
        </div>
      </Grid>
      <VersionPill/>
    </BasePage>
  );
};
