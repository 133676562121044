import {
  CreatePatient,
  GetPatient,
} from "../api";
import {useUserStore} from "../store/userStore";
import {useApi} from "./useApi";
import { ClinicalCaseViewModel } from "../../modules/diagnosis/clinical-case/features/get-clinical-case/view-models/ClinicalCase.viewModel";
import {
  addEntities,
  addEntity,
  count,
  findById,
  findMedbrainUser,
  findRelatedTagCategories,
  findTagsBy,
  getAllClinicalInterventions,
  getAllClinicalOutcomes,
  getAllDiagnosticTests,
  getAllDiseaseArticles,
  getAllHealthcareProffesionalTypes,
  getAllTriage,
  getClinicalCasesFromDate,
  getPatientsFromDate,
  updateById
} from '../database/Database';
import { Utils } from "../../modules/shared/Utils";
import { TagViewModel } from "modules/medical-library/tags/features/list-tags/requests/Tag.viewModel";
import {TagApiResponse} from "modules/medical-library/tags/features/list-tags/requests/Tag.apiResponse";
import { TagFilters } from "../../modules/medical-library/tags/features/list-tags/TagFilter.enum";
import { DiseaseArticleViewModel } from "../../modules/medical-library/disease-articles/features/get-disease-article/view-models/DiseaseArticleViewModel";

export const useApiService = () => {
  const { post, get, patch } = useApi();
  const setSessionToken = useUserStore((state) => state.setSessionToken);
  const idToken = useUserStore((state) => state.idToken);
  const userClaims = useUserStore((state) => state.claims);

  const getLocalPatient = async (id: string) => {
    const data = await findById("patients", id).then((c) => c.toJSON());
    return GetPatient.toPatient(data);
  };

  const getLocalClinicalCase = async (
    id: string
  ): Promise<ClinicalCaseViewModel> => {
    const data = await findById("clinicalCases", id).then((clinicalCase) =>
      clinicalCase.toJSON()
    );
    return new ClinicalCaseViewModel({ ...data, patient: data.patient.id });
  };

  const createLocalPatient = async (
    body: CreatePatient.Request
  ): Promise<{ id: string }> => {
    const date = new Date();
    //TODO set valid datetime format
    const userInfo = localStorage.getItem("user-info");
    let userInfoJSON = {
      state: {
        claims: {
          userId: null,
          nickName: null,
        },
      },
    };

    if (userInfo) {
      userInfoJSON = JSON.parse(userInfo);
    }

    const medbrainUser = await findMedbrainUser(
      userInfoJSON.state.claims.userId
    );

    const patient = {
      id: Utils.generateUUID(),
      created_at: date.toISOString(),
      updated_at: date.toISOString(),
      first_name: body.first_name,
      last_name: body.last_name,
      sex: body.sex,
      date_of_birth: body.date_of_birth,
      is_simulated: body.is_simulated,
      created_by: {
        id: medbrainUser?.user.id
          ? medbrainUser?.user.id
          : userInfoJSON.state.claims.userId,
        name: userInfoJSON.state.claims.nickName,
      },
    };

    await addEntity("patients", patient);
    return patient;
  };

  async function getLocalChiefComplaintOptions() {
    try {
      const tagsWithChiefComplain: TagApiResponse[] = await findTagsBy(
        TagFilters.isChiefComplaint
      );
      const tagsViewModel: TagViewModel[] = [];

      for (const tag of tagsWithChiefComplain) {
        tagsViewModel.push(new TagViewModel(tag));
      }

      return tagsViewModel;
    } catch (error) {
      throw new Error("Error getting chief complaints options");
    }
  }

  async function syncPatients(action: "pull" | "push", date: string) {
    switch (action) {
      case "push":
        const patients = await getPatientsFromDate(date);
        const pushPatientsResponse = await post(
          `diagnosis/create-patients-sync`,
          {
            body: {
              patients: patients,
            },
          }
        );
        if (
          pushPatientsResponse &&
          pushPatientsResponse.data &&
          pushPatientsResponse.status === 200
        ) {
          localStorage.setItem(
            "last-sync-date-patients",
            new Date().toISOString()
          );
        }

        return pushPatientsResponse;

      case "pull":
        const getPatientsResponse = await get(
          `diagnosis/get-patients-sync?date=${date}`
        );
        if (
          getPatientsResponse &&
          getPatientsResponse.data &&
          getPatientsResponse.status === 200
        ) {
          const patients = getPatientsResponse.data;
          if (patients.length > 0) {
            await addEntities(patients, "patients");
            localStorage.setItem(
              "last-sync-date-patients",
              new Date().toISOString()
            );
          }
        }
    }
  }

  async function syncClinicalCases(action: "pull" | "push", date: string) {
    switch (action) {
      case "pull":
        const getClinicalCasesResponse = await get(
          `diagnosis/clinical_case/get-sync_clinical_cases?date=${date}`
        );

        if (
          getClinicalCasesResponse &&
          getClinicalCasesResponse.data &&
          getClinicalCasesResponse.status === 200
        ) {
          const clinicalCases = getClinicalCasesResponse.data;
          if (clinicalCases.length > 0) {
            await addEntities(clinicalCases, "clinicalCases");
            localStorage.setItem(
              "last-sync-date-clinical-cases",
              new Date().toISOString()
            );
          }
        }
        break;
      case "push":
        const clinicalCases = await getClinicalCasesFromDate(date);
        const response = await post(
          `/diagnosis/clinical_case/sync_clinical_cases`,
          { body: { clinical_cases: clinicalCases, action: action } }
        );
        if (response && response.data && response.data.result === true) {
          localStorage.setItem(
            "last-sync-date-clinical-cases",
            new Date().toISOString()
          );
        }

        return response;
    }
  }

  return {
    login: async (token?: string) => {
      if (!token && !idToken) {
        throw new Error("No token available to perform login");
      }

      await post("/user_management/auth/login", {
        token: token || idToken!,
      }).then(({ data }) => setSessionToken(data.token));
    },
    fetchUserInfo: async () => {
      const { data: response } = await get(
        `/user_management/medbrain_users/me`
      );
      return response;
    },
    saveUserInfo: async (body: unknown) => {
      const { data: response } = await patch(
        `/user_management/medbrain_users/${userClaims?.userId}/`,
        { body }
      );
      return response;
    },
    fetchHealthCenters: async () => {
      const { data: response } = await get("/user_management/health_centers/");
      return response;
    },
    getLocalClinicalCase: getLocalClinicalCase,
    getDiagnosisMetrics: async (): Promise<
      { key: string; value: number }[]
    > => {
      let numOfPatients = await count("patients");
      let numOfClinicalCases = await count("clinicalCases");
      return [
        { key: "numOfPatients", value: numOfPatients },
        { key: "numOfClinicalCases", value: numOfClinicalCases },
      ];
    },
    getMedicalLibraryMetrics: async (): Promise<
      { key: string; value: number }[]
    > => {
      let numOfTags = await count("tags");
      let numOfTagCategories = await count("tagCategories");
      let numOfTCOs = await count("tagCategoryOptions");
      let numOfDiseaseArticles = await count("diseaseArticles");

      return [
        { key: "numOfTags", value: numOfTags },
        { key: "numOfTagCategories", value: numOfTagCategories },
        { key: "numOfTCOs", value: numOfTCOs },
        { key: "numOfDiseaseArticles", value: numOfDiseaseArticles },
      ];
    },
    getLocalPatient,
    createLocalPatient,
    getLocalChiefComplaintOptions,
    syncPatients,
    syncClinicalCases,
    findTagsBy,
    getTagViewModel: async (id: string): Promise<TagViewModel> => {
      let tag = await findById("tags", id).then((r) => r.toJSON());
      tag.tag_categories = await findRelatedTagCategories(id);

      return new TagViewModel(tag);
    },
    getDiseaseArticleViewModel: async (
      id: string
    ): Promise<DiseaseArticleViewModel> => {
      return new DiseaseArticleViewModel(
        await findById("diseaseArticles", id).then((r) => r.toJSON())
      );
    },
    // TODO: Remove deprecated syncMedicalLibrary
    syncMedicalLibrary: async (date: string): Promise<any> => {
      const response = await post(`/medical_library/sync`, {
        body: { last_sync_date: date },
      });

      const findOrInsertImage = async (url: string) => {
        try {
          // Open a cache named 'images-cache'
          const cache = await caches.open("images");

          const cacheResponse = await cache.match(url);
          if (!!cacheResponse) {
            return;
          }

          // Fetch the image from the network
          const response = await fetch(url);

          // Clone the response since it can only be consumed once
          const responseClone = response.clone();

          // Add the response to the cache
          await cache.put(url, responseClone);
        } catch (error) {
          console.error("Error fetching image: ", url);
        }
      };

      // Tags
      if (response.data.tags) {
        for (const tag of response.data.tags) {
          let localTag = undefined;

          for (let image of tag.images) {
            await findOrInsertImage(image.url);
          }
          // TODO change by upsert method
          await findById("tags", tag.id).then((r) => {
            if (Object.keys(r).length != 0) {
              localTag = r.toJSON();
            }
          });
          if (localTag) {
            await updateById("tags", tag.id, tag);
          } else {
            await addEntity("tags", tag);
          }
        }
      }

      // Tag Categories
      if (response.data.tag_categories) {
        for (const tagCategory of response.data.tag_categories) {
          // TODO change by upsert method
          let localTagCategory = undefined;
          await findById("tagCategories", tagCategory.id).then((r) => {
            if (Object.keys(r).length != 0) {
              localTagCategory = r.toJSON();
            }
          });
          if (localTagCategory) {
            await updateById("tagCategories", tagCategory.id, tagCategory);
          } else {
            await addEntity("tagCategories", tagCategory);
          }
        }
      }

      // Tag Category Options
      if (response.data.tag_category_options) {
        for (const tagCategoryOption of response.data.tag_category_options) {
          if (tagCategoryOption.image) {
            await findOrInsertImage(tagCategoryOption.image.url);
          }
          // TODO change by upsert method
          let localTagCategoryOption = undefined;
          await findById("tagCategoryOptions", tagCategoryOption.id).then(
            (r) => {
              if (Object.keys(r).length != 0) {
                localTagCategoryOption = r.toJSON();
              }
            }
          );
          if (localTagCategoryOption) {
            await updateById(
              "tagCategoryOptions",
              tagCategoryOption.id,
              tagCategoryOption
            );
          } else {
            await addEntity("tagCategoryOptions", tagCategoryOption);
          }
        }
      }

      // Diagnosis Outcome Config Categories
      if (response.data?.diagnosis_outcome_config?.id) {
        const diagnosisOutcomeConfig = response.data.diagnosis_outcome_config;
        let localDiagnosisOutcomeConfig = undefined;
        await findById(
          "diagnosisOutcomeConfig",
          diagnosisOutcomeConfig?.id
        ).then((r) => {
          if (Object.keys(r).length != 0) {
            localDiagnosisOutcomeConfig = r.toJSON();
          }
        });
        if (localDiagnosisOutcomeConfig) {
          await updateById(
            "diagnosisOutcomeConfig",
            diagnosisOutcomeConfig?.id,
            diagnosisOutcomeConfig
          );
        } else {
          await addEntity("diagnosisOutcomeConfig", diagnosisOutcomeConfig);
        }
      }

      // Triage Categories
      if (response.data.triage) {
        for (const triage of response.data.triage) {
          // TODO change by upsert method
          let localTriage = undefined;
          await findById("triage", triage.id).then((r) => {
            if (Object.keys(r).length != 0) {
              localTriage = r.toJSON();
            }
          });
          if (localTriage) {
            await updateById("triage", triage.id, triage);
          } else {
            await addEntity("triage", triage);
          }
        }
      }

      // QuestionRound Categories
      if (response.data.question_round) {
        for (const questionRound of response.data.question_round) {
          // TODO change by upsert method
          let localQuestionRound = undefined;
          await findById("questionRounds", questionRound.id).then((r) => {
            if (Object.keys(r).length != 0) {
              localQuestionRound = r.toJSON();
            }
          });
          if (localQuestionRound) {
            await updateById("questionRounds", questionRound.id, questionRound);
          } else {
            await addEntity("questionRounds", questionRound);
          }
        }
      }

      // Healthcare Professional Types Categories
      if (response.data.healthcare_professional_types) {
        for (const healthcareProfessionalType of response.data
          .healthcare_professional_types) {
          // TODO change by upsert method
          let localHealthcareProfessionalType = undefined;
          await findById(
            "healthcareProfessionalType",
            healthcareProfessionalType.id
          ).then((r) => {
            if (Object.keys(r).length != 0) {
              localHealthcareProfessionalType = r.toJSON();
            }
          });
          if (localHealthcareProfessionalType) {
            await updateById(
              "healthcareProfessionalType",
              healthcareProfessionalType.id,
              healthcareProfessionalType
            );
          } else {
            await addEntity(
              "healthcareProfessionalType",
              healthcareProfessionalType
            );
          }
        }
      }

      if(response.data.clinical_outcomes) {
        for(const clinicalOutcome of response.data.clinical_outcomes) {
          // TODO change by upsert method
          let localClinicalOutcome = undefined;
          await findById('clinicalOutcome', clinicalOutcome.id).then(r => {
            if (Object.keys(r).length != 0) {
              localClinicalOutcome = r.toJSON();
            }
          });
          if (localClinicalOutcome) {
            await updateById('clinicalOutcome', clinicalOutcome.id, clinicalOutcome);
          } else {
            await addEntity('clinicalOutcome', clinicalOutcome)
          }
        }
      }


        if(response.data.clinical_interventions) {
          for(const clinicalIntervention of response.data.clinical_interventions) {
            // TODO change by upsert method
            let localClinicalIntervention = undefined;
            await findById('clinicalIntervention', clinicalIntervention.id).then(r => {
              if (Object.keys(r).length != 0) {
                localClinicalIntervention = r.toJSON();
              }
            });
            if (localClinicalIntervention) {
              await updateById('clinicalIntervention', clinicalIntervention.id, clinicalIntervention);
            } else {
              await addEntity('clinicalIntervention', clinicalIntervention);
            }
          }
        }

      // Medbrain users Categories
      if (response.data.medbrain_users) {
        for (const medbrainUser of response.data.medbrain_users) {
          let localMedbrainUser = undefined;
          if (medbrainUser.profile_picture) {
            await findOrInsertImage(medbrainUser.profile_picture);
          }
          // TODO change by upsert method
          await findById("medbrainUsers", medbrainUser.id).then((r) => {
            if (Object.keys(r).length != 0) {
              localMedbrainUser = r.toJSON();
            }
          });
          if (localMedbrainUser) {
            await updateById("medbrainUsers", medbrainUser.id, medbrainUser);
          } else {
            await addEntity("medbrainUsers", medbrainUser);
          }
        }
      }

      // Disease Articles
      if (response.data.disease_article) {
        for (const diseaseArticle of response.data.disease_article) {
          if (diseaseArticle.disease_icon) {
            await findOrInsertImage(diseaseArticle.disease_icon);
          }
          if (diseaseArticle.disease_image) {
            await findOrInsertImage(diseaseArticle.disease_image);
          }
          if (diseaseArticle.image) {
            await findOrInsertImage(diseaseArticle.image.url);
          }
          // TODO change by upsert method
          let localDiseaseArticle = undefined;
          await findById("diseaseArticles", diseaseArticle.id).then((r) => {
            if (Object.keys(r).length != 0) {
              localDiseaseArticle = r.toJSON();
            }
          });
          if (localDiseaseArticle) {
            await updateById(
              "diseaseArticles",
              diseaseArticle.id,
              diseaseArticle
            );
          } else {
            await addEntity("diseaseArticles", diseaseArticle);
          }
        }
      }

      // Diseases
      if (response.data.diseases) {
        for (const disease of response.data.diseases) {
          let localDisease = undefined;
          // TODO change by upsert method
          await findById("disease", disease.id).then((r) => {
            if (Object.keys(r).length != 0) {
              localDisease = r.toJSON();
            }
          });
          if (localDisease) {
            await updateById("disease", disease.id, disease);
          } else {
            await addEntity("disease", disease);
          }
        }
      }

      localStorage.setItem(
        "last-sync-date-medical-library",
        new Date().toISOString()
      );

      return response.data;
    },
    getLocalAllDiseaseArticle: async (): Promise<any> => {
      try {
        const diseaseArticles = await getAllDiseaseArticles();
        return diseaseArticles;
      } catch (error) {
        console.error("There has been an error fetching disease articles.");
        return [];
      }
    },
    getLocalAllHealthcareProffesionalTypes: async (): Promise<any> => {
      try {
        const healthcareProffesionalTypes =
          await getAllHealthcareProffesionalTypes();
        return healthcareProffesionalTypes;
      } catch (error) {
        console.error("There has been an error fetching disease articles.");
        return [];
      }
    },
    getLocalAllClinicalinterventons: async (): Promise<any> => {
      try {
        const clinicalIntervention = await getAllClinicalInterventions();
        return clinicalIntervention;
      } catch (error) {
        console.error("There has been an error fetching disease articles.");
        return [];
      }
    },
    getLocalAllDiagnosticTest: async (): Promise<any> => {
      try {
        const diagnosticTests = await getAllDiagnosticTests();
        return diagnosticTests;
      } catch (error) {
        console.error("There has been an error fetching disease articles.");
        return [];
      }
    },
    getLocalAllClinicalOutcome: async (): Promise<any> => {
      try {
        const clinicalOutcome = await getAllClinicalOutcomes();
        return clinicalOutcome;
      } catch (error) {
        console.error("There has been an error fetching disease articles.");
        return [];
      }
    },
    getLocalAllTriage: async (): Promise<any> => {
      try {
        const triage = await getAllTriage();
        return triage;
      } catch (error) {
        console.error("There has been an error fetching disease articles.");
        return [];
      }
    },
    getLocalMedbrainUser: async (id: string): Promise<any | undefined> => {
      try {
        const user = await findMedbrainUser(id);
        return user;
      } catch (error) {
        console.error("There has been an error fetching ");
        return undefined;
      }
    },
  };
};
