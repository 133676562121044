import { Card, Grid } from "@mui/material";
import { PatientInterface } from "modules/shared/entities/PatientInterface";
import { PatientInfo } from "../../PatientInfo/PatientInfo";
import { ClinicalCaseInfo } from "../../ClinicalCaseInfo/ClinicalCaseInfo";
import { useNavigate } from "react-router-dom";

export const PatientItem = ({
  patient,
  index,
}: {
  patient: PatientInterface;
  index: number;
}) => {
  const marginTop: string = index == 0 ? "0px" : "18px";
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => {
        navigate(`/patient/${patient.id}`);
      }}
      tabIndex={0}
      key={patient.id}
      sx={{
        borderRadius: "10px",
        border: "1px solid #DBD8E6",
        padding: "20px 30px",
        marginTop: marginTop,
        boxShadow: "none",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Grid
        container
        key={patient.id}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item width={"100%"}>
          <PatientInfo patient={patient} index={index} />
          <ClinicalCaseInfo patient={patient} />
        </Grid>
      </Grid>
    </Card>
  );
};
