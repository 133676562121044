import {LocalRepositoryBase} from "./LocalRepositoryBase";
import {LocalRepositoryInterface} from "../../modules/shared/interfaces/LocalRepositoryInterface";
import {getDB, upsertEntity} from "../database/Database";

const TABLE_NAME = "medbrainUsers";
export class MedbrainUserLocalRepository extends LocalRepositoryBase implements LocalRepositoryInterface {
    public async upsert(entity: any): Promise<any> {
        await upsertEntity(TABLE_NAME, entity);
        this.records = entity;

        return entity;
    }

    public async getAll() {
        const db = await getDB();
        return await db[TABLE_NAME]
          .find()
          .exec()
          .then((documents: any[]) => documents.map(document => document.toJSON()))
    }
}