import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement, useEffect, useState } from 'react';
import { useApiService } from '../hooks/useApiService';
import { isExpired } from 'react-jwt';
import { useUserStore } from '../store/userStore';

interface Props {
  children: ReactElement;
}

export const AuthGuard = (props: Props) => {
  const setIdToken = useUserStore((state) => state.setIdToken);
  const sessionToken = useUserStore((state) => state.sessionToken);
  const idToken = useUserStore(state => state.idToken);
  const userClaims = useUserStore(state => state.claims);
  const { loginWithRedirect, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const { login } = useApiService();

  const queryClient = useQueryClient();

  const [isLogging, setIsLogging] = useState<boolean>(false);

  const reloadUserInformation = () => {
    !userClaims?.hasUserDetailsFilled && queryClient.refetchQueries(['me'])
  };

  useEffect(() => {
    if (
      (!isLoading && !isAuthenticated && !sessionToken)
      || (sessionToken && isExpired(sessionToken))
      || (!isLoading && !isAuthenticated && sessionToken && !userClaims?.isActive)
    ) {
      sessionToken && useUserStore.persist.clearStorage();
      loginWithRedirect({ redirectUri: window.location.origin });
      return;
    }

    if (!isLoading && isAuthenticated && !sessionToken) {
      getIdTokenClaims().then(idTokenClaims => {
        idTokenClaims && setIdToken(idTokenClaims.__raw);

        if (idTokenClaims) {
          setIsLogging(true);
          login(idTokenClaims.__raw)
            .then(() => reloadUserInformation())
            .finally(() => setIsLogging(false));
        }
      });
      return;
    }

    if (
      !isLoading && !isLogging &&
      sessionToken && isAuthenticated && idToken &&
      !userClaims?.isActive
    ) {
      setIsLogging(true);
      login()
        .then(() => reloadUserInformation())
        .finally(() => setIsLogging(false));
      return;
    }

  }, [isAuthenticated, isLoading, sessionToken]);

  return !isLoading && sessionToken
    ? props.children
    : <></>;
};