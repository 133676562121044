import { useContext } from "react";
import { YesNoQuestionsStepperContext } from "./YesNoQuestionsStepperContext";

export function useYesNoQuestionsStepperContext() {
  const context = useContext(YesNoQuestionsStepperContext);

  if(!context) {
    throw new Error(
      "useYesNoQuestionsStepperContext must be used within a YesNoQuestionsStepperContextProvider"
    )
  }

  return context;
}