import {LocalRepositoryBase} from "./LocalRepositoryBase";
import {getDB, insertItem} from "../database/Database";
import {Utils} from "../../modules/shared/Utils";
import {ChangelogRecordInterface, EventType} from "../../modules/sync/changelog/ChangelogRecordInterface";

export class ChangelogLocalRepository extends LocalRepositoryBase{
    public async getFirstIn(): Promise<ChangelogRecordInterface|undefined> {
        try {
            const db = await getDB();
            const record = await db["changelog"]
                .findOne()
                .sort('created_at')
                .exec();

            if (record) {
                return record.toJSON()
            }


        } catch (error) {
            console.error("Error fetching records:", error);
        }

        return undefined
    }

    public async deleteRecordById(id: string) {
        try {
            const db = await getDB();
            let row = await db["changelog"]
                .findOne(id)
                .exec();

            if (row) {
                await row.remove();
            }
        } catch (error) {
            console.error("Error fetching records:", error);
        }

        return undefined
    }

    public async insert(payload: object, event_type: EventType){
        let date = new Date();
        let record = {
            id: Utils.generateUUID(),
            event_type: event_type,
            payload: payload,
            version: 1,
            created_at: date.toISOString(),
        }
        return await insertItem("changelog", record)
    }

    public async findRecordsByPayloadId(id: string)
    {
        const db = await getDB();
        const records = await db["changelog"]
            .find()
            .where("payload.id")
            .eq(id)
            .sort({ created_at: 1 })
            .exec();

        return records.map((record: any) => record.toJSON());
    }
}