import { CaseNotesPage } from "../../modules/diagnosis/case-notes/CaseNotes.page";
import { PatientPage } from "../../modules/diagnosis/patient/Patient.page";
import { UserPage } from "../../modules/user/User.page";
import { ClinicalCaseBasePage } from "../../modules/diagnosis/clinical-case/ClinicalCase.basePage";
import { DiseaseArticlePage } from "../../modules/diagnosis/clinical-case/DiseaseArticle.page";
import { ErrorBoundaryPage } from "../high-order-components/error-boundary-page/ErrorBoundaryPage";
import DatabaseInsertTesting from "../../DatabaseInsertTesting";
import { OfflineLoginPage } from "modules/offline-login/OfflineLogin.page";
import { MetricsPage } from "modules/shared/pages/Metrics.page";
import { UserProfilePage } from "modules/user/user-profile/UserProfile.page";
import { TriagePage } from "modules/diagnosis/clinical-case/Triage.page";

export const Routes = {
  UserDashboard: {
    path: "/",
    element: <UserPage />,
  },

  UserProfile: {
    path: "/user-profile",
    element: <UserProfilePage />,
  },

  MetricsDashboard: {
    path: "/metrics",
    element: <MetricsPage />,
  },

  ClinicalCase: {
    path: "/clinical-case/:id",
    element: <ClinicalCaseBasePage />,
  },

  DiseaseArticle: {
    path: "/disease-article/:diseaseArticleId",
    element: <DiseaseArticlePage />,
  },

  PatientPage: {
    path: "/patient/:id",
    element: <PatientPage />,
  },

  ErrorPage: {
    path: "/error",
    element: <ErrorBoundaryPage />,
  },

  CaseNotesPage: {
    path: "/clinical-case/:id/case-notes",
    element: <CaseNotesPage />,
  },

  DatabaseInsertTesting: {
    path: "database-testing",
    element: <DatabaseInsertTesting />,
  },

  OfflineLogin: {
    path: "/offline-login",
    element: <OfflineLoginPage />,
  },

  TriagePages: {
    path: "/triage",
    element: <TriagePage />,
  },
};
