import { DiseaseArticleResponse } from '../api/response/DiseaseArticleResponse';
import { DiseaseArticle, DiseaseTagCategoryOptionLikelihood } from '../../../../../shared/entities/DiseaseArticle';
import { Tag } from '../../../../../shared/entities/Tag';
import { TagCategoryOption } from '../../../../../shared/entities/TagCategoryOption';
import { TagCategory } from '../../../../../shared/entities/TagCategory';
import { Utils } from '../../../../../shared/Utils';

interface TagsViewModel {
  name: string;
  tagCategoryOptions: string[];
  presenceLikelihoodRatio: number;
}

interface TestsViewModel {
  name: string;
  tagCategoryOptions: {
    name: string;
    likelihood: number;
  }[];
}

export class DiseaseArticleViewModel {
  private diseaseArticle: DiseaseArticle;
  name: string;
  diseaseImage: string;
  diseaseIcon: string;
  overview: string;
  tagCategoryOptions: DiseaseTagCategoryOptionLikelihood[];
  symptoms: TagsViewModel[];
  signs: TagsViewModel[];
  relatedFactors: TagsViewModel[];
  tests: TestsViewModel[];
  clinicalPlanHomeCare: string;
  clinicalPlanHomeCareImages: [];
  clinicalPlanManagementPlan: string;
  clinicalPlanManagementPlanImages: [];
  clinicalPlanMedication: string;
  clinicalPlanMedicationImages: [];
  clinicalPlanReferral: string;
  clinicalPlanReferralImages: [];
  clinicalPlanTests: string;
  clinicalPlanTestsImages: [];
  sources: [];


  constructor(apiResponse: DiseaseArticleResponse) {
    this.diseaseArticle = this.getDiseaseArticleFromResponse(apiResponse);
    this.name = this.diseaseArticle.name;
    this.diseaseImage = this.diseaseArticle.diseaseImage;
    this.diseaseIcon = this.diseaseArticle.diseaseIcon;
    this.overview = this.diseaseArticle.overview;
    this.tagCategoryOptions = this.diseaseArticle.tagCategoryOptions;
    this.symptoms = this.buildTagsByType('isSymptom');
    this.signs = this.buildTagsByType('isSign');
    this.relatedFactors = this.buildTagsByType('isRelatedFactor');
    this.tests = this.getTestsFromTCOs();
    this.clinicalPlanHomeCare = this.diseaseArticle.clinicalPlanHomeCare;
    this.clinicalPlanHomeCareImages = this.diseaseArticle.clinicalPlanHomeCareImages;
    this.clinicalPlanManagementPlan = this.diseaseArticle.clinicalPlanManagementPlan;
    this.clinicalPlanManagementPlanImages = this.diseaseArticle.clinicalPlanManagementPlanImages;
    this.clinicalPlanMedication = this.diseaseArticle.clinicalPlanMedication;
    this.clinicalPlanMedicationImages = this.diseaseArticle.clinicalPlanMedicationImages;
    this.clinicalPlanReferral = this.diseaseArticle.clinicalPlanReferral;
    this.clinicalPlanReferralImages = this.diseaseArticle.clinicalPlanReferralImages;
    this.clinicalPlanTests = this.diseaseArticle.clinicalPlanTests;
    this.clinicalPlanTestsImages = this.diseaseArticle.clinicalPlanTestsImages;
    this.sources = this.diseaseArticle.sources;
  }

  private getDiseaseArticleFromResponse(diseaseArticleResponse: DiseaseArticleResponse) {

    const diseaseTags = diseaseArticleResponse.tag;
    const diseaseTagCategoryOptions = diseaseArticleResponse.tag_category_options;

    return new DiseaseArticle({
      id: diseaseArticleResponse.id,
      name: diseaseArticleResponse.name,
      overview: diseaseArticleResponse.overview,
      prevalence: diseaseArticleResponse.prevalence,
      diseaseImage: diseaseArticleResponse.disease_image,
      diseaseIcon: diseaseArticleResponse.disease_icon,
      tags: diseaseTags.map(diseaseTag => {
        const tag = diseaseTag.tag;
        return {
          absenceLikelihoodRatio: diseaseTag.absence_likelihood_ratio,
          presenceLikelihoodRatio: diseaseTag.presence_likelihood_ratio,
          tag: new Tag({
            id: tag.id,
            name: tag.name,
            isRelatedFactor: tag.is_related_factor,
            isSign: tag.is_sign,
            isSymptom: tag.is_symptom,
            isTest: tag.is_test,
            complexity: tag.complexity
          }),
        };
      }),
      tagCategoryOptions: diseaseTagCategoryOptions.map(diseaseTagCategoryOption => {
        const tagCategoryOption = diseaseTagCategoryOption.tag_category_option;
        return {
          likelihoodRatio: diseaseTagCategoryOption.likelihood_ratio,
          tagCategoryOption: new TagCategoryOption({
            id: tagCategoryOption.id,
            option: tagCategoryOption.option,
            tagCategory: new TagCategory({
              id: tagCategoryOption.tag_category.id,
              name: tagCategoryOption.tag_category.name,
              tag: new Tag({
                id: tagCategoryOption.tag_category.tag.id,
                name: tagCategoryOption.tag_category.tag.name,
                isRelatedFactor: tagCategoryOption.tag_category.tag.is_related_factor,
                isSign: tagCategoryOption.tag_category.tag.is_sign,
                isSymptom: tagCategoryOption.tag_category.tag.is_symptom,
                isTest: tagCategoryOption.tag_category.tag.is_test,
                complexity: tagCategoryOption.tag_category.tag.complexity
              }),
            }),
          }),
        };
      }),
      neonatesLikelihoodRatio: diseaseArticleResponse.neonates_likelihood_ratio,
      infantsLikelihoodRatio: diseaseArticleResponse.infants_likelihood_ratio,
      under5LikelihoodRatio: diseaseArticleResponse.under5_likelihood_ratio,
      childrenLikelihoodRatio: diseaseArticleResponse.children_likelihood_ratio,
      adolescentsLikelihoodRatio: diseaseArticleResponse.adolescents_likelihood_ratio,
      hyperacuteLikelihoodRatio: diseaseArticleResponse.hiperacute_likelihood_ratio,
      acuteLikelihoodRatio: diseaseArticleResponse.acute_likelihood_ratio,
      subacuteLikelihoodRatio: diseaseArticleResponse.subacute_likelihood_ratio,
      chronicLikelihoodRatio: diseaseArticleResponse.chronic_likelihood_ratio,
      clinicalPlanHomeCare: diseaseArticleResponse.clinical_plan_home_care,
      clinicalPlanHomeCareImages: diseaseArticleResponse.clinical_plan_home_care_images,
      clinicalPlanManagementPlan: diseaseArticleResponse.clinical_plan_management_plan,
      clinicalPlanManagementPlanImages: diseaseArticleResponse.clinical_plan_management_plan_images,
      clinicalPlanMedication: diseaseArticleResponse.clinical_plan_medication,
      clinicalPlanMedicationImages: diseaseArticleResponse.clinical_plan_medication_images,
      clinicalPlanReferral: diseaseArticleResponse.clinical_plan_referral,
      clinicalPlanReferralImages: diseaseArticleResponse.clinical_plan_referral_images,
      clinicalPlanTests: diseaseArticleResponse.clinical_plan_tests,
      clinicalPlanTestsImages: diseaseArticleResponse.clinical_plan_tests_images,
      sources: diseaseArticleResponse.sources,
    });
  }

  private getTestsFromTCOs(): TestsViewModel[] {

    const testsFromTCOs = this.tagCategoryOptions
      .filter(diseaseTCO => diseaseTCO.tagCategoryOption.tagCategory.tag.isTest)
      .map(diseaseTCO => diseaseTCO.tagCategoryOption.tagCategory.tag);

    const uniqueTests = Utils.deleteDuplicates(testsFromTCOs);

    return uniqueTests.map(test => ({
      name: test.name,
      tagCategoryOptions: this.tagCategoryOptions
        .filter(tco => tco.tagCategoryOption.tagCategory.tag.id === test.id)
        .map(tco => ({
          name: tco.tagCategoryOption.option,
          likelihood: tco.likelihoodRatio,
        }))
    }));
  }

  private filterByTagType(tagType: string) {
    return this.diseaseArticle.tags.filter(diseaseTag => diseaseTag.tag[tagType as keyof Tag]);
  }

  private buildTagsByType(tagType: string): TagsViewModel[] {
    const tags = this.filterByTagType(tagType);
    return tags.map(diseaseTag => ({
      name: diseaseTag.tag.name,
      tagCategoryOptions: this.tagCategoryOptions
        .filter(tagCategoryOption => {
          return tagCategoryOption.tagCategoryOption.tagCategory.tag.id === diseaseTag.tag.id;
        })
        .map(tco => tco.tagCategoryOption.option),
      presenceLikelihoodRatio: diseaseTag.presenceLikelihoodRatio,
    }));
  }
}
