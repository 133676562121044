import { OptionApiResponse } from '../requests/Option.apiResponse';

export class OptionViewModel {
  optionId: string;
  name: string;
  imageUrl?: string;
  imageDescription?: string;

  constructor(apiResponse: OptionApiResponse) {
    this.optionId = apiResponse.id;
    this.name = apiResponse.option;
    this.imageUrl = apiResponse.image?.url;
    this.imageDescription = apiResponse.image?.description;
  }
}
