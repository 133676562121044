import {Component} from "react";
import {Button} from "../index";
import {ArrowForward} from "@mui/icons-material";

interface ContinueButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

export class ContinueButton extends Component<ContinueButtonProps> {
  render() {
    return  <Button
      variant="contained"
      onClick={this.props.onClick}
      disabled={this.props.disabled}
      endIcon={<ArrowForward />}
      sx={{
        height: '48px',
        width: '177px',
        textTransform: 'none',
        fontWeight: 'bold',
      }}>
      Continue
    </Button>;
  }
}
