import { TagApiResponse, TagImageResponse, TagVideoResponse } from './Tag.apiResponse';
import { CategoryViewModel } from '../../../../tag-templates/features/get-tag-template/view-models/Category.viewModel';
import { CategoryApiResponse } from '../../../../tag-templates/features/get-tag-template/requests/Category.apiResponse';

export interface TagImage {
  url: string,
  description?: string,
}

export interface TagVideo {
  url: string,
  description?: string,
}

const toTagImage = (response: TagImageResponse): TagImage => ({
  url: response.url,
  description: response.description,
});

const toTagVideo = (response: TagVideoResponse): TagImage => ({
  url: response.url,
  description: response.description,
});

export class TagViewModel {
  id: string;
  name: string;
  description?: string;
  categories: Array<CategoryViewModel>;
  tagImages: TagImage[];
  tagVideos: TagVideo[];
  isRelatedFactor?: boolean;
  isSign?: boolean;
  isSymptom?: boolean;
  isTest?: boolean;
  emergencySignCategory?: string;
  prioritySignCategory?: string;

  constructor(apiResponse: TagApiResponse) {
    this.id = apiResponse.id;
    this.name = apiResponse.name;
    this.isSymptom = apiResponse.is_symptom;
    this.isSign = apiResponse.is_sign;
    this.isRelatedFactor = apiResponse.is_related_factor;
    this.isTest = apiResponse.is_test;
    this.description = apiResponse.description;
    this.tagImages = apiResponse.images?.map(toTagImage) || [];
    this.tagVideos = apiResponse.videos?.map(toTagVideo) || [];
    this.categories = apiResponse.tag_categories ? apiResponse.tag_categories.map((category: CategoryApiResponse) => new CategoryViewModel(category)): [];
    this.emergencySignCategory = apiResponse.emergency_sign_category;
    this.prioritySignCategory = apiResponse.priority_sign_category;
  }
}
