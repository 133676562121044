import {LocalRepositoryBase} from "./LocalRepositoryBase";
import {LocalRepositoryInterface} from "../../modules/shared/interfaces/LocalRepositoryInterface";
import {getDB, upsertEntity} from "../database/Database";
import {TagCategoryOptionDataTransformer} from "../../modules/medical-library/tags/transformers/TagCategoryOptionDataTransformer";

const TABLE_NAME = "tagCategoryOptions";
export class TagCategoryOptionLocalRepository extends LocalRepositoryBase implements LocalRepositoryInterface {
    private transformer: TagCategoryOptionDataTransformer;

    constructor() {
        super();
        this.transformer = new TagCategoryOptionDataTransformer();
    }

    public async upsert(entity: any): Promise<any> {
        await upsertEntity(TABLE_NAME, entity);
        this.records = entity;

        return this.transformer.transform(entity)
    }

    public async getAll() {
        const db = await getDB();
        return await db[TABLE_NAME]
          .find()
          .exec()
          .then((documents: any[]) => documents.map(document => this.transformer.transform(document.toJSON())))
    }
}
