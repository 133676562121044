import { styled, keyframes } from '@mui/system';
import './ProgressBar.scss';

interface ProgressBarProps {
  startWidth: number;
  endWidth: number;
}

type ProgressBarStyledProps = {
  startWidth: number;
  endWidth: number;
}

const ProgressAnimation = (startWidth: number, endWidth: number) => keyframes`
  from {
    width: ${startWidth}%;
  }
  to {
    width: ${endWidth}%;
  }
`;

const ProgressBarStyled = styled('div')<ProgressBarStyledProps>(({ startWidth, endWidth }) => ({
    backgroundColor: '#4265CF',
    width: '33%',
    height: '6px',
    borderRadius: '8px',
    animation: `1s ease-in-out ${ProgressAnimation(startWidth, endWidth)}`,
    animationFillMode: 'forwards',
}));

export function ProgressBar ({ startWidth, endWidth }:ProgressBarProps) {
  return (
    <div className="progress-bar__container">
      <ProgressBarStyled startWidth={startWidth} endWidth={endWidth}/>
    </div>
  );
}