import MUILinearProgress from '@mui/material/LinearProgress';
import { Utils } from '../../../modules/shared/Utils';

interface Props {
  value: number;
  minProgress?: number;
  maxProgress?: number;
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
}

export const LinearProgress = (props: Props) => {
  const MIN_PROGRESS = props.minProgress || 0;
  const MAX_PROGRESS = props.maxProgress || 100;

  const value = Utils.clampAndNormalize(props.value, MIN_PROGRESS, MAX_PROGRESS);

  return (
    <MUILinearProgress
        variant={props.variant}
        value={value}
    />
  );
};