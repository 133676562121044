import { Box } from "@mui/material";
import { PatientInterface } from "modules/shared/entities/PatientInterface";
import './PatientList.scss';
import { PatientItem } from "./PatientItem/PatientItem";


type PatientsListProps = {
  patients: PatientInterface[]
}

export const PatientsList = ({ patients }: PatientsListProps) => {
  return (
    <Box>
      {
        patients.map((patient, index) => (
          <PatientItem
            patient={patient}
            index={index}
            key={patient.id}
          />
        ))
      }
    </Box>
  )
}