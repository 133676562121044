import {TagLocalRepository} from "../../../../../infrastructure/repositories/TagLocalRepository";

export enum TriageQuestionsValues {
    NORMAL = "normal",
    MODERATE = "moderate",
    MODERATE_L = "moderate-low",
    MODERATE_H = "moderate-high",
    SEVERE = "severe",
}

export function getRespiratoryRateOptions(months: number) {
    let options;

    if (months < 3) {
        options = { normal: "<40", moderate: "40 - 59", severe: ">59" };
    } else if (months < 12) {
        options = { normal: "<30", moderate: "30 - 49", severe: ">49" };
    } else if (months < 24) {
        options = { normal: "<30", moderate: "30 - 39", severe: ">39" };
    } else if (months < 60) {
        options = { normal: "<30", moderate: "30 - 45", severe: ">45" };
    } else {
        options = { normal: "<20", moderate: "20 - 35", severe: ">35" };
    }

    return [
        { label: options.normal, value: TriageQuestionsValues.NORMAL },
        { label: options.moderate, value: TriageQuestionsValues.MODERATE },
        { label: options.severe, value: TriageQuestionsValues.SEVERE },
    ];
}

export function getFeverRateOptions(months: number) {
    let options: { normal: string; moderate?: string; severe: string; };

    if (months < 3) {
        options = { normal: ">35.8º - 37.4ºC", severe: ">37.4ºC" };
    } else if (months < 36) {
        options = { normal: ">35.4º - 37.6ºC", moderate: ">37.6º - 38.5ºC", severe: ">38.5ºC" };
    } else {
        options = { normal: ">35.4º - 37.7ºC", moderate: ">37.7º - 39.4ºC", severe: ">39.4ºC" };
    }

    return [
        {label: options.normal, value: TriageQuestionsValues.NORMAL},
        options.moderate ? {label: options.moderate, value: TriageQuestionsValues.MODERATE} : undefined,
        {label: options.severe, value: TriageQuestionsValues.SEVERE}
    ]
        .filter(option => option !== undefined)
        .map(option => ({label: option!.label, value: option!.value}));
}

export function getHeartRateOptions(months: number) {
    let options;

    if (months < 1) {
        options = { low: "< 70", normal: "70 - 190", high: "> 190" };
    } else if (months >= 1 && months < 11) {
        options = { low: "< 80", normal: "80 - 160", high: "> 160"};
    } else if (months >= 11 && months < 30) { // 30 months 2.5 years
        options = { low: "< 80", normal: "80 - 130", high: "> 130"};
    } else if (months >= 30 && months < 54) { // 54 months 4.5 years
        options = { low: "< 80", normal: "80 - 120", high: "> 120"};
    } else if (months >= 54 && months < 78) { // 78 months 6.5 years
        options = { low: "< 75", normal: "75 - 115", high: "> 115"};
    } else if (months >=78 && months < 114) { // 114 months 9.5 years
        options = { low: "< 70", normal: "70 - 110", high: "> 110"};
    } else {
        options = { low: "< 60", normal: "60 - 100", high: "> 100", severe: ''};
    }

    return [
        {label: options.low, value: TriageQuestionsValues.MODERATE_L},
        {label: options.normal, value: TriageQuestionsValues.NORMAL},
        {label: options.high, value: TriageQuestionsValues.MODERATE_H},
        options.severe ? {label: options.severe, value: TriageQuestionsValues.SEVERE} : undefined,
    ]
        .filter(option => option !== undefined)
        .map(option => ({label: option!.label, value: option!.value}));
}

export function getNutritionalStatusMUACOptions() {
    let options = { normal: ">125", moderate: "115 - 125", severe: "<115mm"   };

    return [
        { label: options.severe, value: TriageQuestionsValues.SEVERE },
        { label: options.moderate, value: TriageQuestionsValues.MODERATE },
        { label: options.normal, value: TriageQuestionsValues.NORMAL },
    ];
}

export function getNutritionalStatusWHRatioOptions() {
    let options = { severe: "-3 SD", moderate: "-2 SD", normal: "-1 SD" };

    return [
        { label: options.normal, value: TriageQuestionsValues.NORMAL },
        { label: options.moderate, value: TriageQuestionsValues.MODERATE },
        { label: options.severe, value: TriageQuestionsValues.SEVERE },
    ];
}

export function getNutritionalStatusOedemaOptions() {
    let options = { severe: "Yes", normal: "No",  moderate: '' };

    return [
        { label: options.severe, value: TriageQuestionsValues.SEVERE },
        {label: options.normal, value: TriageQuestionsValues.NORMAL},
        options.moderate ? {label: options.moderate, value: TriageQuestionsValues.MODERATE} : undefined,
    ]
        .filter(option => option !== undefined)
        .map(option => ({label: option!.label, value: option!.value}));
}

export async function getEmergencySignsOptions() {
    let tagRepo = new TagLocalRepository()
    return await tagRepo.findBySignType('emergency')
}

export async function getPrioritySignsOptions() {
    let tagRepo = new TagLocalRepository()
    return await tagRepo.findBySignType('priority')
}