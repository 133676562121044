import { MedbrainUser } from '../../user/MedbrainUser';

export interface CaseNote {
  id: string,
  created_at: Date,
  created_by: MedbrainUser,
  severity: Severity
  diagnosisConfirmation?: string,
  diagnosticTests?: string,
  treatment?: string,
  clinicalOutcomes?: string,
  generalAndManagement?: string,
}

export enum Severity {
  NON_URGENT = 'NON_URGENT',
  EXPEDIENT = 'EXPEDIENT',
  MEDICAL_EMERGENCY = 'MEDICAL_EMERGENCY'
}