import { Container, Typography } from '@mui/material';
import { BasePage } from 'infrastructure/high-order-components/base-page/BasePage';
import { useApiService } from 'infrastructure/hooks/useApiService';
import { useUserStore } from 'infrastructure/store/userStore';
import { PatientHeaderWidgets } from 'modules/diagnosis/patient/components/PatientHeaderWidgets';
import { useEffect, useState } from 'react';
import { MedbrainUser } from '../MedbrainUser';
import { UserProfile } from './components/UserProfile';
import { PatientsList } from './components/PatientList/PatientsList';
import { PatientInterface } from 'modules/shared/entities/PatientInterface';
import { GetClinicalCaseNote } from 'infrastructure/api';
import {PatientLocalRepository} from "../../../infrastructure/repositories/PatientLocalRepository";
import {VersionPill} from "../../shared/components/VersionPill";

export const UserProfilePage = () => {
  const { getLocalMedbrainUser } = useApiService();
  const patientRepository = new PatientLocalRepository();
  const [user, setUser] = useState<MedbrainUser | undefined>(undefined);
  const [patients, setPatients] = useState<PatientInterface[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { claims } = useUserStore();

  useEffect(() => {
    const fecthUser = async () => {
      setIsLoading(true);
      const user = await getLocalMedbrainUser(claims?.userId!);
      const patients = await patientRepository.findByCreatorUserId(user?.user?.id);
      setUser(GetClinicalCaseNote.toMedBrainUser(user));
      setPatients(patients);
      setIsLoading(false);
    }

    fecthUser();
  }, []);

  return (
    <BasePage header={<PatientHeaderWidgets />}>
      <Container
        disableGutters
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '10px',
          padding: '50px 52px 0px 52px',
        }}
      >
        {
          isLoading || !user ? <p>Loading...</p> : <UserProfile user={user} />
        }

        <Typography
          textTransform="uppercase"
          fontSize="20px"
          fontWeight={900}
          lineHeight="27px"
          letterSpacing="0.1rem"
          marginBottom="30px"
          marginTop="50px"
        >
          Your Patients
        </Typography>
        {
          isLoading || !patients ? <p>Loading...</p> : <PatientsList patients={patients} />
        }
      </Container>
      <VersionPill/>
    </BasePage>
  )
}