import { Box } from '@mui/material';
import { Button } from 'infrastructure/components';
import { useCustomNavigate } from 'infrastructure/hooks/useCustomNavigate';
import { Routes } from 'infrastructure/router/Routes';
import useTranslation from 'infrastructure/i18n/useTranslation';
import {ClinicalCaseLocalRepository} from "../../../../../infrastructure/repositories/ClinicalCaseLocalRepository";

interface CreateClinicalCaseFeatureProps {
  patientId: string;
}

export const CreateClinicalCaseFeature = (props: CreateClinicalCaseFeatureProps) => {

  const clinicalCaseRepository = new ClinicalCaseLocalRepository();
  const navigate = useCustomNavigate();
  const welcomePageTranslations = useTranslation('WELCOME_PAGE');

  const onNewCaseClick = async () => {
    const clinicalCaseId = await clinicalCaseRepository.create(props.patientId);
    navigate(Routes.ClinicalCase.path, { id: clinicalCaseId });
  };

  return (
    <Box p={4}>
      <Button
        variant="contained"
        size="large"
        onClick={onNewCaseClick}
        sx={{
          height: '48px',
          width: '177px',
          textTransform: 'none',
          fontWeight: 'bold'
        }}
      >
        {welcomePageTranslations.CTA_BUTTON_TEXT}
      </Button>
    </Box>
  );
};
