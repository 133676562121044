export const diagnosisOutcomeConfigSchema = {
  title: 'Diagnosis Outcome Config Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    _singleton: {
      type: 'boolean',
    },
    diagnostic_prediction_score_threshold: {
      type: 'number',
    },
    diagnostic_prediction_probability_threshold: {
      type: 'number',
    },
    diagnostic_prediction_diff_probability: {
      type: 'number',
    },
    diagnostic_suspicion_score_threshold: {
      type: 'number',
    },
    diagnostic_suspicion_probability_threshold: {
      type: 'number',
    },
  },
  required: [
    'id',
    'created_at',
    'updated_at',
    '_singleton',
    'diagnostic_prediction_score_threshold',
    'diagnostic_prediction_probability_threshold',
    'diagnostic_prediction_diff_probability',
    'diagnostic_suspicion_score_threshold',
    'diagnostic_suspicion_probability_threshold',
  ],
};
