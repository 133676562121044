import { Box, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';

export interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  label?: string,
  value: unknown,
  options: SelectOption[];
  onChange?: (value: unknown) => void;
  error?: string
}

export const Select = (props: SelectProps) => {

  return (
    <Box>
      <InputLabel sx={{ pb: '0.6rem' }}>{props.label}</InputLabel>
      <MuiSelect
        fullWidth={true}
        value={props.value}
        error={!!props.error}
        onChange={(event) => props.onChange?.(event.target.value) ?? null}
      >
        {
          props.options.map(option =>
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>,
          )
        }
      </MuiSelect>
      { props.error && <FormHelperText error sx={{ml: '0.875rem'}}>{props.error}</FormHelperText> }
    </Box>
  );
};
