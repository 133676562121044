import {TriageQuestionsValues} from "./TriageQuestionsValues";
import {useState} from "react";
import {Box, Modal} from "../../../../../infrastructure/components";

type TriageQuestionProps = {
    options: Array<{ label: string, value: string }>;
    title: string;
    optionsHandler: any;
    currentValue: TriageQuestionsValues;
    isSubElement?: boolean
    mainTitle?: string
    image?: string
    imageContent?: string
    videoContent?: string
};

const TriageQuestion = ({ options, title, optionsHandler, currentValue, isSubElement = false, mainTitle = undefined, image = undefined, imageContent = undefined, videoContent = undefined }: TriageQuestionProps) => {
    const [open, setOpen] = useState(false);
    const inputName = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    const containerClass = isSubElement ? "" : "triage-question__container";

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    return (
        <div className={containerClass}>
            {isSubElement ? (
                <div>
                    <h2 style={{
                        display: 'flex',
                        alignItems: 'center',
                    }} className="title">{image ? (
                        <img alt="logo" style={{width: '2em', marginRight: '1em'}} src={image}/>) : ''} {mainTitle}</h2>
                    <h3 style={{
                        display: 'flex',
                        alignItems: 'center',
                    }} className="subtitle" >
                        {title}
                        {imageContent ? (
                            <div style={{
                                'borderRadius': '100%',
                                'width': '2.5em',
                                'height': '2.5em',
                                'padding': '0.2em',
                                'textAlign': 'center',
                                'border': '1px solid #eeeef2',
                                'position': 'relative',
                                'overflow': ' hidden',
                                'justifySelf': 'end',
                                'marginLeft': '1em'
                            }}>
                                <img src={imageContent}
                                    alt="Versión pequeña"
                                    style={{
                                        'cursor': 'pointer',
                                        'width': '68px',
                                        'height': '40px',
                                        'position': 'absolute',
                                        'left': '-33%',
                                        'top': '0px',
                                    }}
                                    onClick={() => handleOpen()}
                                />
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-title"
                                    aria-describedby="modal-description"
                                >
                                    <Box>
                                        <img src={imageContent} alt="Contenido en tamaño completo"
                                             style={{width: '100%'}}/>
                                        <p style={{textAlign: 'center'}}>{title}</p>
                                    </Box>
                                </Modal>
                            </div>
                        ) : ''}
                        {videoContent ? (
                            <div style={{
                                'borderRadius': '100%',
                                'width': '2.5em',
                                'height': '2.5em',
                                'padding': '0.2em',
                                'textAlign': 'center',
                                'border': '1px solid #eeeef2',
                                'position': 'relative',
                                'overflow': ' hidden',
                                'justifySelf': 'end',
                                'marginLeft': '1em'
                            }}>
                                <video
                                    crossOrigin="anonymous"
                                    src={videoContent}
                                    style={{
                                        'cursor': 'pointer',
                                        'width': '68px',
                                        'height': '40px',
                                        'position': 'absolute',
                                        'left': '-33%',
                                        'top': '0px',
                                    }}
                                    onClick={() => handleOpen()}
                                />
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-title"
                                    aria-describedby="modal-description"
                                >
                                    <Box>
                                        <video crossOrigin="anonymous"
                                               src={videoContent}
                                               style={{width: '100%'}}
                                               controls
                                        />
                                        <p style={{textAlign: 'center'}}>{title}</p>
                                    </Box>
                                </Modal>
                            </div>
                        ) : ''}
                    </h3>
                </div>
            ) : (
                <div>
                    <h2 style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '1em',
                    }} className="title">{image ? (
                        <img alt="logo" style={{width: '2em', marginRight: '1em',}} src={image}/>) : ''} {title}</h2>
                </div>
            )}
            <div className="triage-question-answers__container">
                {options.map(({value, label}, index) => {
                    return (
                        <div
                            className="triage-question-answer__container"
                            key={`${index}-${inputName}-${value}`}
                        >
                            <label htmlFor={`${index}-${inputName}-${value}`}
                                   className={value === currentValue ? 'is-selected' : ''}>{label}</label>
                            <input
                                type="radio"
                                id={`${index}-${inputName}-${value}`}
                                name="tag-category-answers"
                                value={`${value}`}
                                onChange={(e) => {
                                    optionsHandler(e.target.value)
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export {TriageQuestion}