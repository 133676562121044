import {ApiRepositoryBase} from "./ApiRepositoryBase";
import {patientCreateApiResource, patientGetApiResource} from "../../config/const";
import {ApiRepositoryInterface} from "../../modules/shared/interfaces/ApiRepositoryInterface";

export class PatientApiRepository extends ApiRepositoryBase implements ApiRepositoryInterface{
    public async create(patientPayload: object) {
        const pushPatientsResponse = await this.doRequestWithSessionToken(patientCreateApiResource, new URLSearchParams(), "POST", patientPayload)
        if (
            pushPatientsResponse &&
            pushPatientsResponse.data &&
            pushPatientsResponse.status === 201
        ) {
            localStorage.setItem(
                "last-sync-date-patients",
                new Date().toISOString()
            );
        }

        return pushPatientsResponse;
    }

    public async getAllFromDatePaginated(...queryStringParams: Array<{ name: string, value: string }>): Promise<{ data: Array<any>, next?: string }> {
        const response = await this.handlePaginationRequest(patientGetApiResource, ...queryStringParams);

        return {
            data: response.data,
            next: response.next,
        };
    }
}