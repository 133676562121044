const setupLocalStorageProxy = () => {
    const originalSetItem = Storage.prototype.setItem;
    Storage.prototype.setItem = function(key, value) {
        const event = new CustomEvent('localStorageChange', {
            detail: { key, value }
        });
        window.dispatchEvent(event);
        originalSetItem.apply(this, [key, value]);
    };
};

export default setupLocalStorageProxy;
