import { AccordionImageModalComponent } from "infrastructure/components/accordion-template/AccordionImageModal.component";
import { useAppUIStore } from "infrastructure/store/appUIStore";
import { TagImage } from "modules/medical-library/tags/features/list-tags/requests/Tag.viewModel";

interface DescriptiveImagesProps {
  images: TagImage[];
  planImages?: { description: string; image: string }[]
}

export function DescriptiveImages({ images, planImages }: DescriptiveImagesProps) {
    const openModal = useAppUIStore((state) => state.openModal);
    if (planImages && planImages.length > 0) {
        images = planImages.map((image) => ({
            description: image.description,
            url: image.image
        }));
    }
    return (
        <div className='descriptive-images__container'>
            {images.map(({description, url}) => {
                return (
                    <div
                        key={url}
                        style={{
                            position: "relative",
                            width: "330px",
                            height: "220px"
                        }}
                        className="descriptive-image"
                        onClick={() => openModal(<AccordionImageModalComponent image={{url, description}}/>)}
                    >
                        <img style={{
                            width: "330px",
                            height: "220px"
                        }} src={url} alt={description}/>
                        <p
                            style={{
                                background: "#ffffffa1",
                                padding: "0.2em 1em",
                                borderRadius: "20px",
                                fontSize: "0.95rem",
                                position: "absolute",
                                bottom: 0,
                            }}
                        >
                            {description}
                        </p>
                    </div>
                );
            })}
        </div>
    );
}
