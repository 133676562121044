import {TagLocalRepository} from "../../infrastructure/repositories/TagLocalRepository";
import {TagCategoryLocalRepository} from "../../infrastructure/repositories/TagCategoryLocalRepository";
import {TagCategoryOptionLocalRepository} from "../../infrastructure/repositories/TagCategoryOptionLocalRepository";
import {DiagnosisOutcomeConfigLocalRepository} from "../../infrastructure/repositories/DiagnosisOutcomeConfigLocalRepository";
import {TriageLevelLocalRepository} from "../../infrastructure/repositories/TriageLevelLocalRepository";
import {QuestionRoundLocalRepository} from "../../infrastructure/repositories/QuestionRoundLocalRepository";
import {HealthcareProfessionalTypeLocalRepository} from "../../infrastructure/repositories/HealthcareProfessionalTypeLocalRepository";
import {ClinicalOutcomeLocalRepository} from "../../infrastructure/repositories/ClinicalOutcomeLocalRepository";
import {ClinicalInterventionLocalRepository} from "../../infrastructure/repositories/ClinicalInterventionLocalRepository";
import {MedbrainUserLocalRepository} from "../../infrastructure/repositories/MedbrainUserLocalRepository";
import {DiseaseArticleLocalRepository} from "../../infrastructure/repositories/DiseaseArticleLocalRepository";
import {S3Repository} from "../../infrastructure/repositories/S3Repository";
import {DiagnosticTestLocalRepository} from "../../infrastructure/repositories/DiagnosticTestLocalRepository";
import {medicalLibraryEntities} from "../../config/const";

export class CacheRestorationService {

    private s3Repository: S3Repository = new S3Repository();


    public async reviewAndRestoreCache(): Promise<void> {
        console.log("starts CacheRestorationService.reviewAndRestoreCache");
        let models = medicalLibraryEntities;

        for (let model of models) {
            let repository = this.getLocalRepository(model.entityName);

            const entities = await repository.getAll();

            this.syncEntityMedia(entities);
        }

        console.log("finishes CacheRestorationService.reviewAndRestoreCache")
    }

    protected async syncEntityMedia(entities: Array<any>) {
        const mediaInsertPromises = entities.flatMap(entity => {
            const allMedia = [...(entity.images || []), ...(entity.media || [])];
            return allMedia.map((media: { url: string }) => this.s3Repository.findAndCheckOrInsertMedia(media.url));
        });

        await Promise.all(mediaInsertPromises);
    }


    private getLocalRepository(entityName: string) {
        const className = `${entityName}LocalRepository`;
        switch (className) {
            case 'TagLocalRepository':
                return new TagLocalRepository();
            case 'TagCategoryLocalRepository':
                return new TagCategoryLocalRepository();
            case 'TagCategoryOptionLocalRepository':
                return new TagCategoryOptionLocalRepository();
            case 'DiagnosisOutcomeConfigLocalRepository':
                return new DiagnosisOutcomeConfigLocalRepository();
            case 'TriageLevelLocalRepository':
                return new TriageLevelLocalRepository();
            case 'QuestionRoundLocalRepository':
                return new QuestionRoundLocalRepository();
            case 'HealthcareProfessionalTypeLocalRepository':
                return new HealthcareProfessionalTypeLocalRepository();
            case 'ClinicalOutcomeLocalRepository':
                return new ClinicalOutcomeLocalRepository();
            case 'ClinicalInterventionLocalRepository':
                return new ClinicalInterventionLocalRepository();
            case 'MedbrainUserLocalRepository':
                return new MedbrainUserLocalRepository();
            case 'DiseaseArticleLocalRepository':
                return new DiseaseArticleLocalRepository();
            case 'DiagnosticTestLocalRepository':
                return new DiagnosticTestLocalRepository();
            default:
                throw new Error(`Repository class no found for: ${className}`);
        }
    }
}
