import React from 'react';
import { Avatar, Box, Grid, Typography } from 'infrastructure/components';

interface CaseNoteSectionProps {
  color: string,
  icon: React.ReactNode,
  title: string,
  subtitle?: string,
  withDivider?: boolean
  children?: React.ReactNode
}

export const CaseNoteSection = (
  {
    color,
    icon,
    title,
    subtitle,
    children,
    withDivider = true,
  }: CaseNoteSectionProps,
) => {

  return (
    <Grid container columnSpacing={3} mb={3}
          wrap="nowrap" overflow={'hidden'}
    >
      <Grid item style={{paddingLeft: '32px'}}>
        <Avatar sx={{ 'bgcolor': color, p: 1.2 }}>{icon}</Avatar>
      </Grid>
      <Grid item flexGrow={1} flexShrink={1}  style={{paddingLeft: '16px'}}>
        <Typography
          variant="h1"
          lineHeight={'2.25rem'}
          fontSize={'x-large'}
          fontWeight={'normal'}
        >
          {title}
        </Typography>
        {
          subtitle ? (
              <Typography
                variant="h2"
                fontSize="small"
                lineHeight="1.35rem"
                marginTop="0.25rem"
                fontWeight="normal"
                color={theme => theme.palette.text.secondary}
              >
                {subtitle}
              </Typography>
          ) : <></>
        }
        <Box
          pt={0}
          pb={withDivider ? 3 : 0.5}
          borderBottom={withDivider ? 1 : 0}
          borderColor={theme => theme.palette.secondary.main}
          mb={2}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
