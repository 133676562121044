import {
  DiseaseTagCategoryOptionLikelihoodResponse,
  DiseaseTagLikelihoodResponse,
} from './DiseaseArticleSchemaResponses';

export const diseaseArticleSchema = {
  title: 'Disease Article Schema',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    created_at: {
      type: 'string',
    },
    updated_at: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    overview: {
      type: 'string',
    },
    prevalence: {
      type: 'number',
    },
    disease_image: {
      type: 'string',
    },
    disease_icon: {
      type: 'string',
    },
    tag: {
      type: 'array',
      items: DiseaseTagLikelihoodResponse, // replace this with the schema of DiseaseTagLikelihoodResponse.
    },
    tag_category_options: {
      type: 'array',
      items: DiseaseTagCategoryOptionLikelihoodResponse, // replace this with the schema of DiseaseTagCategoryOptionLikelihoodResponse.
    },
  },
  required: [
    'id',
  ],
};
