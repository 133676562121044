import { TagApiResponse } from '../../../medical-library/tags/features/list-tags/schema/TagsSchemaResponse';

export const LabelResponse = {
  title: 'Label',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
  },
};

export const TagCategoryApiResponse = {
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    name: {
      type: 'string',
    },
    tag: TagApiResponse,
  },
  required: ['id', 'name'],
};

export const TagCategoryOptionResponse = {
  type: 'object',
  properties: {
    id: {
      type: 'string',

    },
    option: {
      type: 'string',
    },
    tag_category: TagCategoryApiResponse,
  },
  required: ['id', 'option', 'tag_category'],
};

export const QuestionRoundAnswersResponse = {
  title: 'Question Round Answers',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    static_subround: {
      type: 'number',
    },
    question_round: {
      type: 'number',
    },
    presence_tags: {
      type: 'array',
      items: TagApiResponse,
    },
    absence_tags: {
      type: 'array',
      items: TagApiResponse,
    },
    uncertain_tags: {
      type: 'array',
      items: TagApiResponse,
    },
    tag_category_options: {
      type: 'array',
      items: TagCategoryOptionResponse,
    },
  },
};

export const TriageStateSchema = {
  type: 'object',
  properties: {
    respiratory_rate: {
      type: ["null", "string"],
      default: null
    },
    heart_rate: {
      type: ["null", "string"],
      default: null
    },
    fever: {
      type: ["null", "string"],
      default: null
    },
    nutritional_status: {
      type: ["null", "string"],
      default: null
    },
  }
}
