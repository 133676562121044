import { Box } from '@mui/material';
import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import { Card, Title } from 'infrastructure/components';
import { useSteps } from '../../hooks/useSteps';
import { Steps } from '../../hooks/Steps';
import { useClinicalCase } from "../../context/useClinicalCase";
import { ClinicalCaseState } from "../../context/ClinicalCase.context";
import { useWindowSize } from 'infrastructure/hooks/useWindowSize';
import { ProgressBarContextProvider } from '../../context/ProgressBar/ProgressBarContext';

interface ClinicalCasePageProps {
  title?: string;
  subtitle?: string;
}

export const UpdateClinicalCaseFeature = () => {
  const steps: Steps = useSteps();
  const clinicalCase: ClinicalCaseState = useClinicalCase();
  const [currentStep, setCurrentStep] = useState(-1);
  const { width } = useWindowSize();

  useEffect(() => {
    const stepHandler = async () => {
      let stepIndexes = Object.keys(steps);

      let nextIndex = null;
      for (const index in stepIndexes) {
        if (steps[index].name === clinicalCase.currentStep) {
          nextIndex = index;
          break;
        }
      }

      if (null !== nextIndex) {
        setCurrentStep(nextIndex as any as number);
      }
    }

    stepHandler()
  }, [clinicalCase]);


  const renderHeader: React.ReactNode = useMemo(() => {
    if (width <= 900) return null;

    if (currentStep < 0) {
      return null;
    }

    if (!steps[currentStep].title) {
      return null;
    }

    return (
      <Box sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        mb: '30px',
      }}>
        <Title fontSize="34px" fontSizeSubtitle="21px" title={steps[currentStep].title} subtitle={steps[currentStep].subtitle} align="left" />
      </Box>
    );
  }, [currentStep]);

  const renderContent = useMemo(() => {
    if (currentStep < 0) {
      return <div></div>;
    }
    const Page = steps[currentStep].content as React.FC<ClinicalCasePageProps>;

    return (
      <Page
        title={steps[currentStep].title}
        subtitle={steps[currentStep].subtitle}
      />
    );
  }, [currentStep]);

  const cardBackground = width <= 900 ? 'unset' : '#fff';

  return (
    <ProgressBarContextProvider>
      <div id='test-id'>
      <Card sx={{ borderRadius: '10px', border: '0px', boxShadow: '0px 40px 30px -25px rgba(0, 0, 0, 0.05)', position: 'initial', background: cardBackground }} >
        <Box height="100%">
          <Box
            height="calc(100% - 100px)"
            overflow="auto"
            paddingTop={width > 900 ? "48px" : 0}
            paddingLeft={width > 900 ? "87px" : 0}
            paddingBottom={width > 900 ? "60px" : 0}
          >
            {renderHeader}
            {renderContent}
          </Box>
        </Box>
      </Card>
      </div>
    </ProgressBarContextProvider>
  );
};

