
export abstract class LocalRepositoryBase {
    private _records: Array<any> = [];

    get records(): Array<any> {
        return this._records;
    }

    set records(value: any) {
        this._records.push(value);
    }

    public getLastRecordUpsertDate(): Date {
        let lastRecord = this.records[this.records.length -1];
        return new Date(lastRecord.updated_at);
    }

}
