import { DateTime } from 'luxon';
import { Sex } from '../../../SexEnum';
import { ClinicalCaseResponse } from '../requests/ClinicalCase.apiResponse';

export class BasicInformationViewModel {
  name: string;
  surname: string;
  birthdate: DateTime;
  sex: Sex;

  constructor(apiResponse: ClinicalCaseResponse) {
    this.name = apiResponse.patient_first_name ?? '';
    this.surname = apiResponse.patient_last_name ?? '';
    this.birthdate = DateTime.fromISO(apiResponse.patient_birth_date);
    this.sex = apiResponse.patient_sex;
  }
}
