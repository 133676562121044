import {ApiRepositoryBase} from "./ApiRepositoryBase";
import {ApiRepositoryInterface} from "../../modules/shared/interfaces/ApiRepositoryInterface";
import {tagCategoryGetApiResource} from "../../config/const";

export class TagCategoryApiRepository extends ApiRepositoryBase implements ApiRepositoryInterface {
    public async getAllFromDatePaginated(...queryStringParams: Array<{ name: string, value: string }>): Promise<{ data: Array<any>, next?: string }> {
        const response = await this.handlePaginationRequest(tagCategoryGetApiResource, ...queryStringParams);

        return {
            data: response.data,
            next: response.next,
        };
    }
}