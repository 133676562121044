import { addAllCollections } from './infrastructure/database/Database';

function DatabaseInsertTesting() {
  const handleAddAllCollections = async () => {
    await addAllCollections();
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <button onClick={handleAddAllCollections}>Add all collections</button>
    </div>
  );
}

export default DatabaseInsertTesting;
