import {useEffect, useRef, useState} from "react";
import {ClinicalCaseLocalRepository} from "../../../infrastructure/repositories/ClinicalCaseLocalRepository";
import {usePatientData} from "../../../infrastructure/hooks/usePatientData";
import {ClinicalCaseStep} from "../../shared/entities/ClinicalCaseStep";
import {useDispatchClinicalCase} from "./context/useDispatchClinicalCase";
import {useClinicalCase} from "./context/useClinicalCase";
import {TriageState} from "./context/ClinicalCase.context";
import {
    getEmergencySignsOptions,
    getPrioritySignsOptions,
    getHeartRateOptions,
    getRespiratoryRateOptions,
    getFeverRateOptions,
    getNutritionalStatusMUACOptions,
    getNutritionalStatusOedemaOptions, getNutritionalStatusWHRatioOptions,
} from "./components/triage/TriageQuestionsValues";
import {ContinueButton} from "../../../infrastructure/components/continue-button/continue-button";
import InputWithMedia from "../../../infrastructure/components/accordion-template/InputWithMedia.component";
import {TriageQuestion} from "./components/triage/TriageQuestion";
import "./Triage.page.scss";
import {TagApiResponse} from "../../medical-library/tags/features/list-tags/requests/Tag.apiResponse";
import {ModalLoader} from "../../../infrastructure/components/modal/ModalLoader";

export const TriagePage = () => {
    const isMounted = useRef<boolean>(true);
    const dispatchClinicalCase = useDispatchClinicalCase();
    const clinicalCase = useClinicalCase();
    const clinicalCaseRepository = new ClinicalCaseLocalRepository();
    const patientData = usePatientData(clinicalCase.patient);
    const respiratoryRateOptions =  getRespiratoryRateOptions(patientData.patientAgeInMonths!)
    const feverRateOptions =  getFeverRateOptions(patientData.patientAgeInMonths!)
    const heartRateOptions =  getHeartRateOptions(patientData.patientAgeInMonths!)
    const nutritionalStatusMUACOptions =  getNutritionalStatusMUACOptions()
    const nutritionalStatusWHRatioOptions =  getNutritionalStatusWHRatioOptions()
    const nutritionalStatusOedemaOptions =  getNutritionalStatusOedemaOptions()
    const [signsOptions, setSignsOptions] = useState<{emergency: TagApiResponse[], priority: TagApiResponse[]}>({ emergency: [], priority: [] });
    const [triageData, setTriageData] = useState<TriageState>(clinicalCase.triageState || {})
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, [])

    useEffect(() => {
        const fetchSignsOptions = async () => {
            const emergencyOptions: TagApiResponse[] = await getEmergencySignsOptions();
            const priorityOptions: TagApiResponse[] = await getPrioritySignsOptions();
            if (isMounted.current) {
                setSignsOptions({ emergency: emergencyOptions, priority: priorityOptions });
            }
        };

        fetchSignsOptions();
    }, []);

    useEffect(() => {
        if (triageData && JSON.stringify(triageData) !== JSON.stringify(clinicalCase.triageState)) {
            clinicalCaseRepository.update(clinicalCase.clinicalCaseId!, {triage_state: triageData}).catch(console.error)
        }
    }, [triageData, clinicalCase.triageState, clinicalCase.clinicalCaseId]);

    const handleDataChange = (field: string, value: any) => {
        setTriageData((prev: any) => {
            if (field === "priority_signs" || field === "emergency_signs") {
                const currentIndex = prev[field].indexOf(value);
                let newValues = [...prev[field]];
                if (currentIndex === -1) {
                    newValues.push(value);
                } else {
                    newValues.splice(currentIndex, 1);
                }
                return { ...prev, [field]: newValues };
            }

            if (field.startsWith('nutritional_status.')) {
                const [, nestedField] = field.split('.');
                return {
                    ...prev,
                    nutritional_status: {
                        ...prev.nutritional_status,
                        [nestedField]: value,
                    },
                };
            }

            return { ...prev, [field]: value };
        });
    }

    if (patientData.patientAgeInMonths === undefined) return <ModalLoader open={open} onClose={handleClose}></ModalLoader>;

    return (
        <section className="triage__container">
            <div className="title__container">
                <h1 className="title">Triage</h1>
            </div>

            <div className="triage-question__container">
                <h2 style={{display: 'flex', alignItems: 'center',}} className="title"> <img crossOrigin="anonymous" alt="emergency signs logo" style={{width: '2em', marginRight: '1em'}} src={'/assets/emergency-signs.webp'}/> Emergency Signs</h2>
                <div className="triage-question-answers__container" style={{'gridTemplateColumns': 'repeat(2, 1fr)'}}>
                    {signsOptions.emergency.map((tag, index) => (
                        <InputWithMedia
                            key={index} tag={tag}
                            optionsHandler={(value: any) => handleDataChange('emergency_signs', value)}
                            isSelected={triageData?.emergency_signs?.includes(tag.id)}
                        />
                    ))}
                </div>
            </div>

            <div className="triage-question__container">
                <h2 style={{display: 'flex', alignItems: 'center',}} className="title"><img crossOrigin="anonymous" alt="priority signs logo" style={{width: '2em', marginRight: '1em'}} src={'/assets/priority-signs.png'}/>Priority Signs</h2>
                <div className="triage-question-answers__container" style={{'gridTemplateColumns': 'repeat(2, 1fr)'}}>
                    {signsOptions.priority.map((tag, index) => (
                        <InputWithMedia
                            key={index}
                            tag={tag}
                            optionsHandler={(value: any) => handleDataChange('priority_signs', value)}
                            isSelected={triageData?.priority_signs?.includes(tag.id)}
                        />
                    ))}
                </div>
            </div>

            <TriageQuestion
                title="Heart Rate"
                optionsHandler={(value: any) => handleDataChange('heart_rate', value)}
                options={heartRateOptions}
                currentValue={triageData?.heart_rate!}
                image={"/assets/heart-rate.png"}
            />

            <TriageQuestion
                title="Respiratory Rate"
                optionsHandler={(value: any) => handleDataChange('respiratory_rate', value)}
                options={respiratoryRateOptions}
                currentValue={triageData?.respiratory_rate!}
                image={"/assets/respiratory-rate.png"}
            />

            <TriageQuestion
                title="Fever"
                optionsHandler={(value: any) => handleDataChange('fever', value)}
                options={feverRateOptions}
                currentValue={triageData?.fever!}
                image={"/assets/fever.png"}
            />

            <div className="triage-question__container">
                {patientData.patientAgeInMonths > 6 && patientData.patientAgeInMonths < 180 ?
                    (
                <TriageQuestion
                    mainTitle="Nutritional Status"
                    title="MUAC (Middle upper arm circumference)"
                    optionsHandler={(value: any) => handleDataChange('nutritional_status.muac', value)}
                    options={nutritionalStatusMUACOptions}
                    currentValue={triageData?.nutritional_status?.muac!}
                    isSubElement={true}
                    image={"/assets/nutritional-status.png"}
                    imageContent={"/assets/MUAC_Measurement.png"}
                />
                    ) : <div></div>}
                {patientData.patientAgeInMonths > 6 && patientData.patientAgeInMonths < 180 ?
                    (
                    <TriageQuestion
                        title="W/H (Weight/height ratio)"
                        optionsHandler={(value: any) => handleDataChange('nutritional_status.wh', value)}
                        options={nutritionalStatusWHRatioOptions}
                        currentValue={triageData?.nutritional_status?.wh!}
                        isSubElement={true}
                        imageContent={patientData.nutritionalStatusImages.wh}
                    />
                    ) :
                    <TriageQuestion
                        mainTitle="Nutritional Status"
                        title="W/H (Weight/height ratio)"
                        optionsHandler={(value: any) => handleDataChange('nutritional_status.wh', value)}
                        options={nutritionalStatusWHRatioOptions}
                        currentValue={triageData?.nutritional_status?.wh!}
                        isSubElement={true}
                        image={"/assets/nutritional-status.png"}
                        imageContent={patientData.nutritionalStatusImages.wh}
                    />
                }
                <TriageQuestion
                    title="Is there bilateral feet oedema present?"
                    optionsHandler={(value: any) => handleDataChange('nutritional_status.oedema', value)}
                    options={nutritionalStatusOedemaOptions}
                    currentValue={triageData?.nutritional_status?.oedema!}
                    isSubElement={true}
                    videoContent={'/assets/oedema.mp4'}
                />
            </div>

            <ContinueButton
                onClick={() => {
                    clinicalCaseRepository.update(
                        clinicalCase.clinicalCaseId!,
                        {clinical_case_state: ClinicalCaseStep.SELECTING_CASE_DURATION}
                    ).then(cc => dispatchClinicalCase(cc))
                }}
            />
        </section>
    );
};