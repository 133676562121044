import { AvatarGroup } from '@mui/material';
import { Avatar } from '../avatar/Avatar';
import {TagViewModel} from "../../../modules/medical-library/tags/features/list-tags/requests/Tag.viewModel";

interface Props {
  template: TagViewModel;
}

export const TemplateImagesPreview = (props: Props) => {
  if (!props.template.tagImages || props.template.tagImages.length == 0) {
    return null;
  }

  const renderImagePreview = (image: any, index: number) => {

    return (
      <Avatar
        key={`${index}${image.url}`}
        src={image.url}
        sx={{
          width: '26px',
          height: '26px',
        }}
      />
    );
  };

  return (
    <AvatarGroup>
      {props.template.tagImages.map(renderImagePreview)}
    </AvatarGroup>
  );
};
