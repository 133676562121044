import {LocalRepositoryBase} from "./LocalRepositoryBase";
import {addEntity, findById, findMedbrainUser, getDB, upsertEntity} from "../database/Database";
import {CreatePatient, GetPatient} from "../api"; // TODO: Refactor this (why is it placed under "api"? idk
import {Utils} from "../../modules/shared/Utils";
import {GetPatients} from "../api/GetPatients"; // TODO: Refactor this (why is it placed under "api"? idk
import {ChangelogLocalRepository} from "./ChangelogLocalRepository";
import {EventType} from "../../modules/sync/changelog/ChangelogRecordInterface";
import {LocalRepositoryInterface} from "../../modules/shared/interfaces/LocalRepositoryInterface";

const TABLE_NAME = "patients";
export class PatientLocalRepository extends LocalRepositoryBase implements LocalRepositoryInterface{
    private changelog: ChangelogLocalRepository;

    constructor() {
        super();
        this.changelog = new ChangelogLocalRepository();
    }

    public async getAll() {
        const db = await getDB();
        return await db[TABLE_NAME]
          .find()
          .exec()
          .then((documents: any[]) => documents.map(document => document.toJSON()))
    }

    public async findById (id: string) { // TODO: old getLocalPatient
        const data = await findById(TABLE_NAME, id).then((c) => c.toJSON());
        return GetPatient.toPatient(data);
    };

    public async findByCreatorUserId(id: string) { // TODO: old useApiService.getLocalPatientsCreatedBy and db.getAllPatientsCreatedById
        try {
            const db = await getDB();
            const patients = await db[TABLE_NAME]
                .find()
                .where("created_by.id")
                .eq(id)
                .sort({ created_at: -1 })
                .exec();

            let map = patients.map((patient:any) => patient.toJSON());
            return GetPatients.toPatients(map);
        } catch (error) {
            console.error("Error fetching patients:", error);
            return [];
        }
    }

    public async findAll() { // TODO: old db.getAllPatients && useApiService.getLocalPatients
        try {
            const db = await getDB();
            const patients = await db[TABLE_NAME].find().exec();
            const response = patients.map((patient: any) => patient.toJSON());

            return GetPatients.toPatients(response);
        } catch (error) {
            console.error("Error fetching patients:", error);
            return [];
        }
    };

    public async create (body: CreatePatient.Request): Promise<{ id: string }> { // TODO: old createLocalPatient
        const date = new Date();
        const userSession = window.localStorage.getItem('user-info');
        if (!userSession) throw Error('Session data not found');

        const medbrainUser = await findMedbrainUser(
            JSON.parse(userSession).state.claims.userId
        );

        const patient = {
            id: Utils.generateUUID(),
            created_at: date.toISOString(),
            updated_at: date.toISOString(),
            first_name: body.first_name,
            last_name: body.last_name,
            sex: body.sex,
            date_of_birth: body.date_of_birth,
            is_simulated: body.is_simulated,
            created_by: {
                id: medbrainUser?.user.id
                    ? medbrainUser?.user.id
                    : JSON.parse(userSession).state.claims.userId,
                name: JSON.parse(userSession).state.claims.nickName,
            },
        };

        await addEntity(TABLE_NAME, patient);
        await this.changelog.insert(patient, EventType.PatientCreated);

        return patient;
    };

    public async upsert(entity: any): Promise<void> {
        await upsertEntity(TABLE_NAME, entity);
        this.records = entity;

        return entity;
    }
}